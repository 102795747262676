import styles from "../Sales.module.css"

import {
  Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import SalesDetails from "../SalesDetails"
import { useEffect, useState } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from "src/hooks/useAuth";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import { useDropDowns } from "src/hooks/useDropDowns";
import { useCategories } from "src/hooks/useCategories";
import { useProducts } from "src/hooks/useProducts";
import ViewModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import moment from "moment";
import { BallTriangle } from 'react-loader-spinner'
import CircularProgress from '@mui/material/CircularProgress';
import { downloadFile2 } from "src/utils";
import { Pagination } from "src/components"
// ----------------------------------------------------------------------
import Filter1 from "src/pages/Filter/Filter1";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TABLE_HEAD = [
  { id: 'brandName', label: 'Brand Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'transactionType', label: 'Transaction Type', alignRight: false },
  { id: 'accountDetails', label: 'Account Details', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor:'#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius:'5px',
  boxShadow: 24,
  p: 2,
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------

function changeView(d) {
  localStorage.setItem('ptvs', d);
}

// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;
export default function ShopOwners() {
  const { data } = useAuth();
  const dropdownFilters = useDropDowns()
  const categories = useCategories();
  const productLists = useProducts()


  const [appView, setAppview] = useState(app_view);
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState(1);
  const [showDiv, setShowDiv] = useState(data.merchant_type === "brand");
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);
  const [searchvalue, setSearchvalue] = useState('');
  const [isPending, setIsPending] = useState('');
  const [brandData, setBrandData] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoading, setExportLoading] = useState(false)
  const [filterSearch, setFilterSearch] = useState({
    transaction_type: '',
    transaction_status: '',
    transaction_min_price: '',
    transaction_max_price: '',
    dateStart: '',
    dateEnd: '',
  });
  const notify = (msg =null) => toast.success(msg === null ?'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);
  const [viewModalValue, setViewModalValue] = useState({
    amount: {}
  });





  const [modalOpen, setModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [refundPending, setRefundPending] = useState(false);
  
  const handleClose = () => {
    setModalOpen(false);
  };
  const handleCloseViewModal = () => {
    setViewModalOpen(false);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded
    let val = event.target.value
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val)
        setProductsAdded(data)

      }
    }
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value)
  }

  const handleFilterChange = (key, value) => {
        setFilterSearch((filterSearch) => ({
            ...filterSearch,
            [key]: value
        }));
  };

  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvs')
  }, [])

  const handleViewClick = (d) => {
    navigate('/app/sales_details')
    // handle view click action
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0)
  }

  const setViewModal = (val) => {
    setViewModalOpen(true)
    setViewModalValue(val)
  }

  const handleRefundTransaction = () => {
    setRefundPending(true)
    setViewModalOpen(false);
    axiosInstance.post('/admin/transactions/refund/'+viewModalValue.transaction_id, {
      transaction_id: viewModalValue.transaction_id
    }).then(res => {
      notify(res.data.message)
    }).catch(err => {

      try {
          notifyError(err.response.data.message);

      } catch (error) {
          notifyError("Unable to refund transactiom, try again later");

      }

    }).finally(() => setRefundPending(false))

  }

  const handleDownloadFile = async (extension) => {
    setExportLoading(true)
    try {
      const result = `/admin/transactions/brands?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`
      await downloadFile2('Brands Transaction', result, extension);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setExportLoading(false); 
    }
  };

  const fetchData = (page = 1, pageSize = 10, file = null) => {
    let filter_payload = {}
    if (filterSearch.transaction_min_price) {
    filter_payload.transaction_min_price = filterSearch.transaction_min_price
    }
    if (filterSearch.transaction_max_price) {
      filter_payload.transaction_max_price = filterSearch.transaction_max_price
      }
    if (searchvalue != '') {
    filter_payload.search = searchvalue
    }
    if (startDate != '') {
      filter_payload.dateStart = startDate
      }
      if (endDate != '') {
      filter_payload.dateEnd = endDate
      }
    if (filterSearch.transaction_type != '') {
    filter_payload.transaction_type = filterSearch.transaction_type
    }
    if (filterSearch.transaction_status != '' && filterSearch.transaction_status != 'all') {
    filter_payload.transaction_status = filterSearch.transaction_status
    }
    setIsPending(true);
    handleClose(false)
    axiosInstance.get('/admin/transactions/brands?', {
        params: {
            page,
            pageSize,
            file,
            ...filter_payload
        }
    })
        .then(res => {
            setBrandData(res.data.data.data)
            setDataPagination(res.data.data.pagination)
            setFilterSearch({
              transaction_status: '',
              transaction_type: '',
              dateEnd: '',
              dateStart: '',
              transaction_max_price: '',
              transaction_min_price: '',
            })


        }).catch(err => {

            try {
                notifyError(err.response.data.message);

            } catch (error) {
                notifyError("Unable to fetch transactions, please try again later");

            }

        }).finally(() => setIsPending(false))
  }

  useEffect(() => {
    fetchData()
  }, [searchvalue])


  return Number(appView) === 0 ? (
    <>
      <div className="pt-4">
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input 
                type="text" 
                value={searchvalue} 
                onChange={searchFilter} 
                placeholder="Search" 
                style={{ 
                  textIndent: '25px', 
                  width: '100%' 
                }} 
                className=" form-control" 
              />
            </div>
            <div className="col-md-9 col-6  text-right">
              <button className="btn  border" onClick={() => 
                setModalOpen(true)}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px'
                }}>
                <img 
                  className="" 
                  style={{ 
                    display: 'inline', 
                    width: '28%' 
                  }} 
                  src="/assets/icons/filterlines.svg" 
                  alt="" 
                />
                &nbsp;
                <small className="d-none d-md-inline-block">
                  Filters
                </small>
              </button>
            </div>
          </div>
        </div>
        <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
          <div className='p-3 '>
            <div className="d-md-flex justify-content-between" >
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  Transaction Details
                </h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  Details of brands withdrawals and top ups
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button 
                    id="dropdownMenuButton" 
                    data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false" 
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span style={{
                      paddingTop: '8px',
                    }}>
                      <img src="/assets/icons/download.svg" alt="" />
                       &nbsp;
                      Export {exportLoading && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                    </span>
                    &nbsp;
                    &nbsp;
                    <span style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',

                    }}>
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                  <button 
                      type="button" 
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile("csv")}
                    >
                      CSV
                    </button>
                    <button 
                      type="button" 
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile("excel")}
                    >
                      Excel
                    </button>
                    <button 
                      type="button" 
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile("pdf")}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`} 
              >
              <table className={`${styles.table} table-hover table-striped`} style={{
                minWidth:'100em'
              }}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;
                      // let lIndex = el.lenght - 1;

                      return (
                        <th key={i} className="text-muted">{el.label} {sIndex && (
                          <span style={{ cursor: 'pointer' }}>
                            <img className="pl-2" src="/assets/icons/down.svg" alt="" />
                          </span>
                        )}</th>



                      )
                    })}

                  </tr>

                </thead>
                <tbody>
                  {!isPending && brandData.map((el,i) => (
                    <tr key={i} className={`${styles.tr}`}>
                      <td>
                      <div className=" ">
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                              <strong>
                                {el?.name}
                              </strong>
                            </p>
                            <span style={{ fontSize: '13px' }}>
                              {el?.transaction_id}
                            </span>
                          </div>

                        </div>
                      </td>
                      <td>
                        <p>
                          {el?.email}
                        </p>
                      </td>
                      {/* onClick={() => handleViewClick(1)} */}
                      <td >
                        <span>{moment(el?.createdAt).format('DD/MM/YYYY')}</span><br />
                        <small>{moment(el?.createdAt).format('HH:mm')}PM</small>
                      </td>
                      <td>
                        <span className={`badge badge-pill ${styles.badge_muted}`}>
                          {el?.transaction_type?.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                        </span>
                      </td>
                      <td>
                        {
                          el?.transaction_type === "top_up" ?
                          "---"
                          :
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                                {el?.reciever_bank}
                            </p>
                            <span style={{ fontSize: '13px' }}>
                              {el?.bank_name}
                            </span>
                          </div>
                        }
                      </td>
                      <td>
                        ₦ {Number(el?.amount.$numberDecimal).toLocaleString()}
                      </td>
                      <td>
                        {el?.transaction_status === "completed" ? (
                              <span className="verified_badge">{el.transaction_status}</span>
                            ) : el?.transaction_status === "pending" ? (
                              <span className="pending_badge">{el.transaction_status}</span>
                            ) : (
                              <span className="not_verified_badge">{el.transaction_status}</span>
                            )
                          }
                      </td>
                      <td>
                      <div className="d-inline-block dropleft ">
                          <span
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                            className={`${styles.action_button} px-3 py-2`}  style={{
                              cursor: 'pointer',

                            }}>
                            <img src="/assets/icons/action.svg" alt="" />

                          </span>

                          <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                            <button type="button" onClick={() => setViewModal(el)} className="rss dropdown-item btn border-0">View</button>


                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>

            </div>
            <div className="d-flex justify-content-center">
                {isPending &&
                  <BallTriangle

                    height={50}
                    width={55}
                    radius={5}
                    color="#53138D"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />

                }

              </div>
              {
                (!brandData.length && !isPending) &&

                <div className="alert alert-[#53138D] text-center">
                  <h4>No record found</h4>
                </div>

              }
          </Scrollbar>
          <div>
              <Pagination
                  currentPage={currentPage}
                  dataPagination={dataPagination}
                  itemsPerPage={itemsPerPage}
                  setCurrentPage={setCurrentPage}
                  fetchData={fetchData}
              />
          </div>
        </Card>

      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#FFFFFF'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="name">Status</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.transaction_status}
                    name="transaction_status"
                    required
                    onChange={(e) => handleFilterChange('transaction_status', e.target.value)}
                  >

                    <MenuItem value='all'>All</MenuItem>
                    <MenuItem value='completed'>Success</MenuItem>
                    <MenuItem value='pending'>Pending</MenuItem>
                    <MenuItem value='failed'>Failed</MenuItem>
                  </Select>
                </div>

                <div className="mb-3">
                  <label htmlFor="name">Transaction Type</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.transaction_type}
                    name="transaction_type"
                    required
                    onChange={(e) => handleFilterChange('transaction_type', e.target.value)}
                  >
                    <MenuItem value='select'>Select Status</MenuItem>
                    <MenuItem value='Kiosk'>Transfer</MenuItem>
                    <MenuItem value='Neighborhood store'>Top up</MenuItem>
                    {/* <MenuItem value='Supermarket'>Supermarket</MenuItem>
                    <MenuItem value='E-commerce store'>E-commerce store</MenuItem> */}
                  </Select>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholderText="Start"
                      required
                      maxDate={new Date()}
                    />
                    </div>
                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                    <DatePicker
                      showIcon
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholderText="End"
                      required
                      maxDate={new Date()}
                    />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Price </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="transaction_min_price"
                        value={filterSearch.transaction_min_price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('transaction_min_price', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"
                        name="transaction_max_price"
                        value={filterSearch.transaction_max_price}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('transaction_max_price', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button type="button" onClick={() => fetchData()} className={`${styles.modal_btn} btn btn-block`}>
                Proceed {isPending && <CircularProgress style={{ color: '#fff' }} size="1rem" />}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      <ViewModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewModalOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewModalOpen}>
          <Box sx={style}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h3>Transaction Details</h3>
                <span onClick={handleCloseViewModal} style={{cursor:'pointer'}}>
                  <img src="/assets/icons/x.svg" alt="" />

                </span>

              </div>
              <div className="row text-muted border_buttom  mt-4 mb-4">
                <div className="col-md-6">
                  Customer
                </div>
                <div className="col-md-6">
                  {viewModalValue?.name}
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                <div className="col-md-6">
                  Email address
                </div>
                <div className="col-md-6 ">
                  {viewModalValue?.email}
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                <div className="col-md-6">
                  Phone Number
                </div>
                <div className="col-md-6 ">
                  {viewModalValue?.phone_number ? viewModalValue.phone_number : '---'}
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                <div className="col-md-6">
                  Date
                </div>
                <div className="col-md-6 ">
                  <span>
                    {moment(viewModalValue?.createdAt).format('DD/MM/YYYY')}
                  </span>
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                <div className="col-md-6">
                  Time
                </div>
                <div className="col-md-6 ">
                  <span>
                    {moment(viewModalValue?.createdAt).format('HH:mm')}
                  </span>
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                <div className="col-md-6">
                  Customer Type
                </div>
                <div className="col-md-6 ">
                  <span>
                    Store Owner
                  </span>
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                <div className="col-md-6">
                  Amount
                </div>
                <div className="col-md-6 ">
                  <small>
                    ₦ {viewModalValue?.amount ? Number(viewModalValue.amount.$numberDecimal).toLocaleString() : 0}
                  </small>
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted border_buttom mt-4 mb-4">
                <div className="col-md-6">
                  Transaction Type
                </div>
                <div className="col-md-6 ">
                  <span className="text-danger">
                    {viewModalValue?.payment_method}
                  </span>
                </div>
                <hr />
              </div>
              <div className="row mt-3 text-muted mt-4 mb-4">
                <div className="col-md-12">
                  <button className="btn btn-outline-secondary form-control">
                    Close
                  </button>
                </div>
                {/* <div className="col-md-6 ">
                {viewModalValue?.transaction_status === "completed" ? <button onClick={() => handleRefundTransaction(viewModalValue?.transaction_id)} className="btn btn-primary form-control">
                    Refund {refundPending && <CircularProgress style={{ color: '#fff' }} size="1rem" />}
                  </button> : <button disabled className="btn btn-primary form-control">
                    Refund
                  </button>
                }
                </div> */}
                <hr />
              </div>

            </div>
           
          </Box>
        </Fade>
      </ViewModal>
    </>
  ) : <SalesDetails key={1} onChange={handleChange} />
}
