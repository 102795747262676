import styles from "./Order.module.css"

import {
  Card,
  Avatar,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import OrderDetails from "./OrderDetailsBrandOwner"
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Filter1 from "../Filter/Filter1";
import LiveLocation from "src/pages/LiveLocation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "src/axiosInstance";
import bus from 'src/bus';
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner'
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import { downloadFile2, shortenText } from "src/utils";
import { Pagination } from "src/components";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'customerName', label: 'Customers', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'productUnit', label: 'Product Items', alignRight: false },
  { id: 'customerLocation', label: 'Customer Location', alignRight: false },
  { id: 'price', label: 'Total Cost', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'order_confirmation', label: 'Order Confirmation', alignRight: false },
  { id: 'created_at', label: 'Date ordered', alignRight: false },
  { id: '' },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// ----------------------------------------------------------------------


// ptvo=PAGE TO VIEW (ORDER)
let app_view = localStorage.getItem('ptvo') ?? 0;
export default function OrderPage() {
  const navigate = useNavigate();
  const [appView] = useState(app_view);
  const [orderType, setOrderType] = useState('All');
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reload, setReload] = useState(false);
  const [searchvalue, setSearchValue] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const [dataFilter, setDataFilter] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [modalOpenConfirm, setModalOpenConfirm] = useState(false);
  const [modalOpenConfirm1, setModalOpenConfirm1] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);
  const [trx_id, setTrx_id] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [tooltipData, setToolTipData] = useState({ product_items: [] });
  const [tooltipLoading, setToolTipLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [filterSearch, setFilterSearch] = useState({
    location: '',
    status: '',
    category: '',
    min_price: '',
    max_price: '',
    min_order: '',
    max_order: '',
  })

  const notify = (msg) => toast.success(msg);
  const notifyError = (err) => toast.error(err);

  const searchFilter = (e) => {
    setSearchValue(e.target.value)
  }
  const [dataDetails, setDetailsData] = useState({
    completed: {
      current_order_count: 0,
      previous_order_count: 0,
      percentage_completed: 0,
    },
    abandoned: {
      current_order_count: 0,
      previous_order_count: 0,
      percentage_abandoned: 0,
    }
  })

  const handleCloseConfirm1 = () => {
    setModalOpenConfirm1(false);
  };
  const handleCloseConfirm = () => {
    setModalOpenConfirm(false);
  };


  const handleTrxChange = (event) => {
    setTrx_id(event.target.value);
  };

  const handleConfirmOrder = (val, order_number) => {
    setTrx_id(val)
    setOrderNumber(order_number)
    setModalOpenConfirm(true)
  }

  const handleClose = () => {
    setModalOpen(false);
  };


  const handleStatusChange = (event) => {
    const { name, value } = event.target;
    setFilterSearch((prev) => ({
      ...prev,
      [name]: value
    }));

  };


  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvo')
  }, [])


  const processLocation = (e) => {
    // console.log(e);
  }

  const handleViewClick = (val) => {
    localStorage.setItem('order_details_info', JSON.stringify(val))
    navigate('/app/order_details_brand')
  };

  const handleSendReminderClick = (val) => {
    // handle send reminder click action
    axiosInstance.get('/brand/order/send-reminder/' + val)
      .then(res => {
        notify(res.data.message)
      }).catch(err => {
        try {
          notifyError(err.response.data.message)
        } catch {
          notifyError('Something went wrong, Please try again later')
        }
      })
  };
  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0)
  }

  const fetchCardDetails = (period = dataFilter) => {
    let periodValue = typeof period === 'object' ? 'today' : period
    setIsPending(true);
    axiosInstance.get('/brand/order/cards?period=' + periodValue)
      .then(res => {
        setDetailsData(res.data.data)
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch analytics, please try again later");

        }

      }).finally(() => setIsPending(false))
  }

  const fetchData = async (page = 1, perpage = 10, search = searchvalue, file = null) => {
    let filter_payload = {}
    if (filterSearch.status !== '') {
      filter_payload.status = filterSearch.status
    }
    if (filterSearch.category !== '') {
      filter_payload.category = filterSearch.category
    }
    if (filterSearch.min_price !== '') {
      filter_payload.min_price = filterSearch.min_price
    }
    if (filterSearch.max_price !== '') {
      filter_payload.max_price = filterSearch.max_price
    }
    if (filterSearch.location !== '') {
      filter_payload.location = filterSearch.location
    }
    if (filterSearch.min_order !== '') {
      filter_payload.min_order = filterSearch.min_order
    }
    if (filterSearch.max_order !== '') {
      filter_payload.max_order = filterSearch.max_order
    }
    if (startDate &&startDate !== '') {
      filter_payload.dateFrom = startDate
    }
    if (endDate&&endDate !== '') {
      filter_payload.dateTo = endDate
    }
    if (file) {
      setExporting(true);
      let queryParams = new URLSearchParams({ page, perpage, ...filter_payload, searchvalue, export_type: file }).toString();

      try {
        await downloadFile2('All Orders', `/brand/order?${queryParams}`, file);
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }
    setIsPending(true);
    handleClose()
    axiosInstance.get('/brand/order?', {
      params: {
        page,
        perpage,
        search,
        ...filter_payload
      }
    })
      .then(res => {
        setOrderData(res.data.data.data)
        setCategoryPagination(res.data.data.pagination)
        setStartDate(null)
        setEndDate(null)
        setFilterSearch({
          location: '',
          status: '',
          category: '',
          min_price: '',
          max_price: '',
          min_order: '',
          max_order: '',
        })

      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch orders, please try again later");

        }

      }).finally(() => setIsPending(false))
  }

  useEffect(() => {
    fetchCardDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter])

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      // console.log(el);
      setDataFilter(el)
    })
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchvalue, reload]);

  const processConfirmOrder = () => {
    setConfirmOrder(true);
    axiosInstance.post(`/brand/order/confirm/${trx_id}`, {
      order_number: orderNumber
    })
      .then((res) => {
        handleCloseConfirm();
        notify(res.data.message)
        setReload(!reload)

      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Something went wrong , please try again later");

        }

      }).finally(() => setConfirmOrder(false))
  }


  const getHoverProduct = (id) => {
    setToolTipLoading(true);
    setToolTipData({ product_items: [] })
    axiosInstance.get(`/brand/order/on-hover/${id}`)
      .then(res => {
        setToolTipData(res.data.data[0] || { product_items: [] })

      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Something went wrong , please try again later");

        }

      }).finally(() => setToolTipLoading(false))
  };


  return Number(appView) === 0 ? (
    <>
      <div className="wrapper mt-4 px-2">
        <div className="header d-block d-md-none mb-4">
          <h4 className={`mb-0 pb-0`}>Orders</h4>

          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            Manage and monitor your orders here.
          </p>

        </div>
        <div className={`${styles.analytics} `}>
          <Filter1 width='100%' />
          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Completed Orders</small>

                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>{dataDetails?.completed?.current_order_count}</span>
                      <small> from {dataDetails?.completed?.previous_order_count}</small>
                    </div>
                    <div>
                      {
                        Number(dataDetails?.completed?.current_order_count).toLocaleString() >= Number(dataDetails?.completed?.previous_order_count).toLocaleString() ?
                          <div className={styles.percent}>
                            <img src="/assets/icons/up.svg" alt="" />
                            <small className="pl-1">{Math.floor(Number(dataDetails?.completed?.percentage_completed))}%</small>
                          </div>
                          :
                          <div className={`${styles.percent2}`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">{Math.floor(Number(dataDetails?.completed?.percentage_completed))}%</small>
                          </div>
                      }

                    </div>



                  </div>

                </div>


              </div>

            </div>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Abandoned Order</small>

                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>{dataDetails?.abandoned?.current_order_count}</span>
                      <small> from {dataDetails?.abandoned?.previous_order_count}</small>
                    </div>
                    <div>
                      {
                        Number(dataDetails?.abandoned?.current_order_count).toLocaleString() >= Number(dataDetails?.abandoned?.previous_order_count).toLocaleString() ?
                          <div className={styles.percent}>
                            <img src="/assets/icons/up.svg" alt="" />
                            <small className="pl-1">{Math.floor(Number(dataDetails?.abandoned?.previous_order_count))}%</small>
                          </div>
                          :
                          <div className={`${styles.percent2}`}>
                            <img src="/assets/icons/downr.svg" alt="" />
                            <small className="pl-1">{Math.floor(Number(dataDetails?.abandoned?.previous_order_count))}%</small>
                          </div>
                      }

                    </div>



                  </div>

                </div>


              </div>

            </div>

          </div>
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input type="text" value={searchvalue} onChange={searchFilter} placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />

            </div>
            <div className="col-md-9 col-6  text-right">

              <div className="dropleft ">
                <button className="btn  border" onClick={() => setModalOpen(true)} aria-haspopup="true" aria-expanded="false"
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px'
                  }}>
                  <img className="" style={{ display: 'inline', width: '17px' }} src="/assets/icons/filterlines.svg" alt="" />
                  &nbsp;
                  <small className="d-none d-md-inline-block">
                    Filters
                  </small>
                </button>

                <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                  <button type="button" onClick={() => setOrderType('All')} className="rss dropdown-item btn border-0">All</button>
                  <button type="button" onClick={() => setOrderType('Abandoned')} className="rss dropdown-item btn border-0">Abandoned </button>
                  <button type="button" onClick={() => setOrderType('Completed')} className="rss dropdown-item btn border-0">Completed</button>
                </div>
              </div>


            </div>
          </div>


        </div>

        <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
          <div className='p-3 '>
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  {orderType} Orders
                </h5>
                <small style={{ fontWeight: '200', fontSize: '12px', display: 'inline-block', marginTop: '0px' }}>
                  Detailed information of all orders

                </small>

              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>

                    <span style={{
                      paddingTop: '8px',
                    }}>
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;

                      &nbsp;
                      {
                        !exporting &&
                        <span>Export</span>
                      }
                      {
                        exporting &&
                        <CircularProgress
                          style={{
                            color: 'white',
                            display: 'inline-block'
                          }}
                          size="1rem"
                        />
                      }
                    </span>
                    &nbsp;
                    &nbsp;
                    <span style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',

                    }}>
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton">
                    <button
                      onClick={() => fetchData(currentPage, 200000, searchvalue, "csv")}

                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      CSV
                    </button>
                    <button
                      onClick={() => fetchData(currentPage, 200000, searchvalue, "excel")}
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      Excel
                    </button>
                    <button
                      onClick={() => fetchData(currentPage, 200000, searchvalue, "pdf")}
                      type="button"
                      className="rss dropdown-item btn border-0"
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}  >
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;

                      return (
                        <th className="text-muted">{el.label} {sIndex && (
                          <span style={{ cursor: 'pointer' }}>
                            <img className="pl-2" src="/assets/icons/down.svg" alt="" />
                          </span>
                        )}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {(!isPending && orderData?.length > 0) && orderData?.map((el, i) => {
                    return (
                      <tr className={`${styles.tr}`}>
                        <td>
                          <div className=" ">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                            >
                              <Avatar
                                className={`${styles.avatrr} mr-3`}
                                alt={el?.shop_name} src={el.shop_logo}
                                style={{ width: '50px', height: '50px' }}
                              />
                              <div>
                                <p className="mb-0" style={{ fontSize: '14px' }}>
                                  <strong>
                                    {el?.shop_name}
                                  </strong>
                                </p>
                                <span style={{ fontSize: '10px' }}>
                                  {el?.order_number}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p>
                            {el?.category}
                          </p>
                        </td>
                        <td>
                          <p className="text-center">
                            <BootstrapTooltip title={
                              <div>
                                {
                                  !tooltipLoading && tooltipData.product_items.length > 0 && tooltipData.product_items.map((tooltip, ind) => (
                                    <span key={ind} className="mr-1">
                                      {tooltip.product_name},
                                    </span>

                                  ))
                                }
                                {
                                  !tooltipLoading && tooltipData.product_items.length < 1 &&
                                  <span>
                                    Empty
                                  </span>
                                }
                                {
                                  tooltipLoading &&
                                  <span>
                                    Loading
                                  </span>
                                }
                              </div>
                            }>
                              <span onMouseEnter={() => getHoverProduct(el._id)}>
                                {el?.product_items}
                              </span>

                            </BootstrapTooltip>

                          </p>
                        </td>
                        <td>
                          <p>
                            <BootstrapTooltip title={
                              <div>
                                <span>
                                  {el?.customer_location}
                                </span>
                              </div>
                            }>
                              <span>
                                {shortenText(el?.customer_location)}

                              </span>

                            </BootstrapTooltip>



                          </p>
                        </td>
                        <td>
                          <p>
                            ₦{el?.total_cost ? Number(el?.total_cost).toLocaleString() : 0}

                          </p>
                        </td>
                        <td>
                          <p>
                            <span className={
                              `${el?.status === 'completed' ?
                                styles.customer :
                                el?.status === 'abandoned' ?
                                  styles.abandoned :
                                  el?.status === 'refunded' ?
                                    styles.refunded :
                                    styles.pending
                              }`
                            }>
                              {el?.status}
                            </span>
                            {/* <span className={
                              `${customStatus === 'Abandoned' ? styles.abandoned : (customStatus === 'Pending' ? styles.pending : styles.customer)}`
                            }>
                              {customStatus}
                            </span> */}

                          </p>
                        </td>
                        <td>
                          <div className={`${styles.btn_controls}`}>
                            {el?.status === "completed" || el?.status === "refunded" ? (
                              <button
                                className={`btn  ${styles.confirmed_disabled}`}
                                disabled
                                style={{
                                  cursor: 'auto'
                                }}
                              >
                                Confirmed
                              </button>
                            ) :
                              <button
                                className={`btn ${styles.confirm}`}
                                onClick={() => handleConfirmOrder(el?._id, el?.order_number)}

                              >
                                Confirm
                              </button>
                            }

                          </div>
                        </td>
                        <td>
                          <p>
                            {moment(el?.date_ordered).format('DD/MM/YYYY')}

                          </p>
                        </td>
                        <td>
                          <div className="dropleft ">

                            <span
                              id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                              className={`${styles.action_button} px-3 py-2`}  style={{
                                cursor: 'pointer',

                              }}>
                              <img src="/assets/icons/action.svg" alt="" />

                            </span>

                            <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                              <button type="button" onClick={() => handleViewClick(el)} className="rss dropdown-item btn border-0">View</button>
                              {el?.status === 'abandoned' && <button
                                className="rss btn border-0 dropdown-item"
                                type="button"
                                onClick={() => handleSendReminderClick(el?._id)}
                              >
                                Send Reminder
                              </button>}
                            </div>
                          </div>

                        </td>
                      </tr>

                    )


                  })}

                </tbody>

              </table>

            </div>
            <div className="d-flex justify-content-center">
              {isPending &&
                <BallTriangle

                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />

              }

            </div>
            {
              (!orderData.length && !isPending) &&

              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>

            }


          </Scrollbar>

          <div>
              <Pagination
                  currentPage={currentPage}
                  dataPagination={categoryPagination}
                  itemsPerPage={itemsPerPage}
                  setCurrentPage={setCurrentPage}
                  fetchData={fetchData}
              />
          </div>
        </Card>

      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#FFFFFF'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label='Location' passData={processLocation} />
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Status</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.status}
                    required
                    onChange={handleStatusChange}
                    name="status"
                  >

                    <MenuItem value='select'>Select Status</MenuItem>
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'completed'}>Completed</MenuItem>
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'abandoned'}>Abandoned</MenuItem>
                  </Select>


                </div>
                {/* <div className="mb-3">
                  <label htmlFor="cat">Category</label> <div />
                  {categories.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input type="checkbox" value={el.name} name="" className="myCheckbox" />
                      &nbsp;
                      {el.name}


                    </div>

                  ))}


                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div> */}
                <div className="mb-3">
                  <label htmlFor="name">Category</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.category}
                    name="category"
                    placeholder="Select category"
                    required
                    onChange={handleStatusChange}
                  >

                    <MenuItem value='select'>Select Category</MenuItem>
                    <MenuItem value='Kiosk'>Kiosk</MenuItem>
                    <MenuItem value='Neighborhood store'>Neighborhood store</MenuItem>
                    <MenuItem value='Supermarket'>Supermarket</MenuItem>
                    <MenuItem value='E-commerce store'>E-commerce store</MenuItem>
                  </Select>


                </div>



              </div>
              <div className="col-md-6 mb-1">

                <label htmlFor="date">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                      />

                    </div>

                  </div>

                </div>

                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"
                        value={filterSearch.min_price}
                        name="min_price"
                        onChange={handleStatusChange}

                      />
                    </div>
                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"
                        value={filterSearch.max_price}
                        name="max_price"
                        onChange={handleStatusChange}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ouamount">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ouamount"
                        placeholder="Min"
                        size="small"
                        value={filterSearch.min_order}
                        name="min_order"
                        onChange={handleStatusChange}
                      />
                    </div>
                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"
                        value={filterSearch.max_order}
                        name="max_order"
                        onChange={handleStatusChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="name">Product</label> <div></div>
                  {productLists.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input onClick={(e) => handleProductChange(e, el.name)} type="checkbox" value={el.name} name="" className="myCheckbox" />
                      &nbsp;
                      {el.name}
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
            <div className="mb-4">
              <button type="button" onClick={() => fetchData()} className={`${styles.modal_btn} btn btn-block`}>
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      <GlobalModal
        open={modalOpenConfirm}
        onClose={handleCloseConfirm}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='500px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        backgroundColor='#FFFFFF'
        boxShadow='24'
        p='4px'
      >
        <div className={`${styles.modal_content}`} style={{ backgroundColor: '#FFFFFF' }}>


          <form action="" className="mt-0 p-3">
            <div className="text-center">
              <img src="/assets/carticon.svg" alt="" />
            </div>
            <div className="conten text-center mt-3 pt-3">
              <h4>Confirm Customer Order</h4>
              <p>
                Input Customer after for confirmation of valid order
              </p>
            </div>
            <div className='my-4 inputs_n_label'>
              <label htmlFor='trx'>Transaction Id</label>
              <TextField
                required
                disabled
                value={trx_id}
                onChange={handleTrxChange}
                className="d-none"
                id="trx"
                size="small"
                fullWidth

              />
              <TextField
                required
                disabled
                value={orderNumber}
                onChange={handleTrxChange}
                id="trx"
                size="small"
                fullWidth

              />
            </div>

            <div className="mb-4 text-center">
              <button type="button" onClick={handleCloseConfirm} className={`${styles.cancel} btn`}>
                Cancel

              </button>
              &nbsp;
              &nbsp;
              <button disabled={confirmOrder} type="button" onClick={() => processConfirmOrder()} className={`${styles.confirm_order} btn `}>
                Confirm Order {confirmOrder && <CircularProgress style={{ color: 'white' }} size="1rem" />}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      <GlobalModal
        open={modalOpenConfirm1}
        onClose={handleCloseConfirm1}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='500px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        backgroundColor='#FFFFFF'
        boxShadow='24'
        p='4px'
      >
        <div className={`${styles.modal_content}`} style={{ backgroundColor: '#FFFFFF' }}>
          <form action="" className="mt-0 p-3">
            <div className="text-center">
              <img src="/assets/carticon.svg" alt="" />
            </div>
            <div className="conten text-center mt-3 pt-3">
              <h4 className="mb-3">Order Confirmed </h4>
              {/* <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing  elit. Eius aliquam
                laudantium explicabo pariatur iste dolorem animi vitae error totam. At
                sapiente aliquam accusamus facere veritatis.

              </p> */}

            </div>
            <div className="mb-4 mt-4 text-center">

              <button type="button" onClick={handleCloseConfirm1} className={`${styles.confirm_order} btn btn-block w-100`}>
                Back to  Order

              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  ) : <OrderDetails key="1" onChange={handleChange} />
}
