// ----------------------------------------------------------------------
import styles from '../../BrandOwner.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Card } from '@mui/material';
import Scrollbar from '../../../../components/scrollbar';
// sections
// mock
import Button from '@mui/material/Button';
import bus from 'src/bus';
import axiosInstance from 'src/axiosInstance';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { createRef } from 'react';
import { useLayoutEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import { BallTriangle } from 'react-loader-spinner';
import { percentageDiscount } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};
const style1 = {
  position: 'absolute',
  height: '600px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};
const style2 = {
  position: 'absolute',
  height: '200px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  // boxShadow: 24,
  p: 3,
};

// ptvp=PAGE TO VIEW (SALES)

export default function RetailerProductSingle({ categoryId }) {
  let textInput = createRef();
  const [openDialog, setDiologOpen] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const handleGoToCartClose = () => setopenGoToCart(false);
  const [data, setData] = useState([]);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [productValue, setProductValue] = useState({});
  const [searchvalue, setSearchvalue] = useState('');
  const navigate = useNavigate();
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [url, setUrl] = useState('/assets/miloContainer.svg');
  const [isPending, setIsPending] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const notifyError = (err) => toast.error(err);
  const notify = (msg) => toast.success(msg);

  const handleClickOpen = (val) => {
    setDiologOpen(true);
    setProductValue(val);
  };
  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  const [editValue, setEditValue] = useState({
    price_per_product: {
      $numberDecimal: 0,
    },
  });

  function closePromts() {
    handleDialogClose();
    setOpenDialog2(false);
  }

  useLayoutEffect(
    () => () => {
      // Your code here.
    },
    []
  );

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleButtonClick = () => {
    textInput.current.click();
  };

  const reload = () => {
    navigate(`/app/products`);
  };

  const handleEditProduct = (val) => {
    handleOpen1();
    setEditValue(val);
    // console.log(val);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditValue((prev) => ({
      ...prev,
      [name]: value,
      $numberDecimal: value,
    }));
  };

  useEffect(() => {
    fetchCategorySingle();
  }, [reloadData, searchvalue]);

  bus.on('reload', () => {
    // fetchData()
  });
  const fetchCategorySingle = (
    page = 1,
    pageSize = 10,
    search = searchvalue
  ) => {
    setIsPending(true);
    axiosInstance
      .get('/shop/product/category/get/' + categoryId, {
        params: {
          search,
        },
      })
      .then((res) => {
        const modifyResData = res.data.data.map((dataItem) => ({
          ...dataItem,
          proposed_market_price:
            dataItem?.proposed_market_price?.$numberDecimal,
          discount: dataItem?.discount?.$numberDecimal,
          maximum_market_price: dataItem?.maximum_market_price?.$numberDecimal,
          proposed_discount_budget:
            dataItem?.proposed_discount_budget?.$numberDecimal,
        }));

        setData(modifyResData);
        console.log(res.data.data, ' This iss valuee --- ', modifyResData);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch products, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditLoader(true);
    axiosInstance
      .put('/shop/product/edit', {
        product_id: editValue.product_id,
        stock_available: editValue.stock_available,
        price: editValue.price,
        description: editValue.description,
      })
      .then((res) => {
        if (res.status < 300) {
          // setEditData(res.data.data)
          handleClose1();
          setopenGoToCart(true);
          setReloadData(!reloadData);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch store, try again later');
        }
      })
      .finally(() => setEditLoader(false));
  };

  const handleDeleteProduct = () => {
    setIsDelete(true);
    axiosInstance
      .delete('/shop/product/delete/' + productValue._id)
      .then((res) => {
        if (res.status < 300) {
          // setOpenDialog2(true)
          notify('Product deleted successfully!');
          fetchCategorySingle();
        }
        setDiologOpen(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to delete store, try again later');
        }
      })
      .finally(() => setIsDelete(false));
  };

  return (
    <>
      <div className={` px-2`}>
        <div className={`${styles.filters}`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6   `}>
              <img
                style={{ marginLeft: '10px' }}
                src="/assets/icons/search.svg"
                alt=""
              />
              <input
                type="text"
                placeholder="Search"
                value={searchvalue}
                onChange={searchFilter}
                style={{
                  textIndent: '25px',
                  width: '60%',
                }}
                className=" form-control"
              />
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '10px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div
              className="mb-3 d-inline-block cursor_pointer"
              onClick={reload}
            >
              <img
                src="/assets/icons/Chevronleft.svg"
                className="pr-1"
                alt=""
              />
            </div>

            <div className="d-md-flex justify-content-between">
              <div>
                <h4>{data[0]?.category}</h4>
                <p className="mb-0 header_control">
                  View all products you have bought here and update your
                  available stock
                </p>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`py-3 px-3  ${styles.cover_products}`}>
              <div className={`${styles.customized_row}`}>
                {!isPending &&
                  data?.length > 0 &&
                  data?.map((el) => (
                    <div className="  mb-2">
                      <div
                        className="card"
                        style={{ backgroundColor: 'inherit !important' }}
                      >
                        <div
                          className="card-body"
                          style={{ backgroundColor: 'inherit !important' }}
                        >
                          <div className={`${styles.customized_row1}`}>
                            <div className=" ">
                              <img
                                style={{
                                  width: '70%',
                                }}
                                src={el?.product_image}
                                alt=""
                              />
                            </div>
                            <div className={`${styles.product_txt}`}>
                              <h6>{el?.name}</h6>
                              <p>{el?.description}</p>
                              <div className="mb-4 d-flex justify-content-between">
                                <span>
                                  {el?.product_spec ? el?.product_spec : ''}
                                </span>
                                {/* <span>
                                                                <img style={{ with: '2%' }} src="/assets/icons/packagec.svg" alt="cube" className={`mr-2`} />

                                                                {el?.stock_available} Left
                                                            </span> */}
                              </div>
                              <div className="d-flex justify-content-between">
                                <span
                                  style={{ fontSize: '15px' }}
                                  className="text-[#53138D] font-weight-bold"
                                >
                                  ₦{' '}
                                  {el?.proposed_market_price
                                    ? Number(
                                        el?.proposed_market_price
                                      ).toLocaleString()
                                    : 0}
                                </span>
                              </div>
                              <div
                                className={`${styles.product_price} border-top`}
                              >
                                <div className="row pt-2">
                                  <div className="col-6 text-center border-right">
                                    <span
                                      onClick={() => handleEditProduct(el)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <img
                                        src="/assets/icons/edit.svg"
                                        alt="edit"
                                      />
                                      &nbsp;&nbsp;
                                      <span style={{ fontSize: '15px' }}>
                                        View
                                      </span>
                                    </span>
                                  </div>
                                  <div className="col-6 text-center">
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => handleClickOpen(el)}
                                    >
                                      <img
                                        src="/assets/icons/trash-2.svg"
                                        alt="delete"
                                      />
                                      &nbsp;&nbsp;
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: '15px' }}
                                      >
                                        Remove
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="d-flex justify-content-center">
                {isPending && (
                  <BallTriangle
                    height={50}
                    width={55}
                    radius={5}
                    color="#53138D"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
              </div>
              {!data.length && !isPending && (
                <div className="alert alert-[#53138D] text-center">
                  <h4>No record found</h4>
                </div>
              )}
            </div>
          </Scrollbar>
        </Card>
      </div>

      {/*  DIALOG*/}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '30px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h5>Remove Product ({productValue?.name}) in Store</h5>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                ></p>
              </div>
            </div>
            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={() => handleDeleteProduct()}
              >
                Remove{' '}
                {isDelete && (
                  <CircularProgress
                    style={{ color: 'white', marginLeft: '5px' }}
                    size="1rem"
                  />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      {/* Withdraw */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style1}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>View Product </h3>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p>View product in this category</p>

              <form
              // onSubmit={handleSubmit}
              >
                <p className={`${styles.modal_product_title}`}>
                  Product Image{' '}
                </p>
                <input type="file" hidden ref={textInput} name="" />
                {/* onChange={handleFile} */}
                <div
                  className={`${styles.retailer_modal_product_img}`}
                  style={{
                    backgroundImage: `url('${editValue?.product_image}')`,
                  }}
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleButtonClick()}
                  ></span>
                </div>
                <div className="mb-3">
                  <label
                    className={`${styles.modal_product_title}`}
                    htmlFor="pname"
                  >
                    Product Name
                  </label>
                  <TextField
                    type="text"
                    id="pname"
                    placeholder=""
                    size="small"
                    fullWidth
                    disabled
                    value={editValue.name}
                    onChange={handleEditChange}
                    name="name"
                  />
                </div>
                {/* <div className="mb-3">
                                    <label className={`${styles.modal_product_title}`} htmlFor="stock">Stock Available in Store*</label>
                                    <TextField
                                        type='number'
                                        id="stock"
                                        placeholder="4000"
                                        size="small"
                                        fullWidth
                                        value={editValue.stock_available}
                                        onChange={handleEditChange}
                                        name="stock_available"

                                    />
                                </div> */}

                <div className="mb-3">
                  <label
                    className={`${styles.modal_product_title}`}
                    htmlFor="stock"
                  >
                    percentage discount off
                  </label>
                  <TextField
                    id="percentage"
                    disabled
                    placeholder="Percentage Discount"
                    size="small"
                    fullWidth
                    value={`${editValue?.discount}%`}
                    onChange={handleEditChange}
                    name="description"
                  />
                </div>

                <div className="mb-3">
                  <label
                    className={`${styles.modal_product_title}`}
                    htmlFor="desc"
                  >
                    Description{' '}
                  </label>
                  <TextField
                    id="desc"
                    disabled
                    multiline
                    rows="4"
                    cols="3"
                    placeholder=" Best Product"
                    size="small"
                    fullWidth
                    value={editValue.description}
                    onChange={handleEditChange}
                    name="description"
                  />
                </div>
                <div className="mb-3">
                  <button
                    type="button"
                    onClick={() => setOpen1(false)}
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Close
                    {/* {editLoader && <CircularProgress style={{ color: 'white' }} size="1rem" />} */}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box
            className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
            sx={style}
          >
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Product Updated </h5>
              <div>
                <button
                  onClick={() => setopenGoToCart(false)}
                  className={`modal_btn hover btn btn-block`}
                >
                  Go to Product
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDialog2}
        onClose={() => setOpenDialog2(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openDialog2}>
          <Box className={`mobile_modal_size modal_bg`} sx={style2}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <p
                className="text-center"
                style={{ textAlign: 'center !important' }}
              >
                Product Deleted
              </p>
              <button
                onClick={() => closePromts()}
                className={`modal_btn hover btn btn-block`}
              >
                Go to Product
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
