import styles from "../../SalesPage/Sales.module.css"
import { useAuth } from "src/hooks/useAuth";
import {
    Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "src/axiosInstance"
import moment from "moment";
import { BallTriangle } from 'react-loader-spinner'
import { useEffect, useState } from "react"
import toast from 'react-hot-toast';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// ----------------------------------------------------------------------

let TABLE_HEAD = [
    { id: 'details', label: 'Product Details', alignRight: false },
    { id: 'quantity', label: 'Quantity', alignRight: false },
    { id: 'unitPrice', label: 'Unit price', alignRight: false },
    { id: 'totalPrice', label: 'Total price', alignRight: false },

];

// ----------------------------------------------------------------------

export default function OrderPage({ onChange }) {
    const navigate = useNavigate();
    const {data} = useAuth();
    const [isPending, setIsPending] = useState(false);
    const [refundPending, setRefundPending] = useState(false);
    const [purchaseData, setdata] = useState({items:[]});
    const [purchasePagination, setPurchasePagination] = useState({ total: 0 });
    const notifyError = (err) => toast.error(err);
    const notify = (msg) => toast.success(msg);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [filter, setFilter] = useState(false);
    const [showCheck, setShowCheck] = useState(false);
    const [refundIds, setRefundIds] = useState([]);
    
    if (data.user_type == "admin") {
        TABLE_HEAD = [
            { id: 'details', label: 'Product Details', alignRight: false },
            { id: 'brand', label: 'Brand', alignRight: false },
            { id: 'quantity', label: 'Quantity', alignRight: false },
            { id: 'unitPrice', label: 'Unit price', alignRight: false },
            { id: 'totalPrice', label: 'Total price', alignRight: false },
        
        ]; 
    }
    const {purchase_id} = useParams()

    function handleClick(event) {
        event.preventDefault();
        navigate('/app/purchases')
    }

    useEffect(() => {
        fetchData()
      }, [filter]);
    
      bus.on('reload', () => {
        fetchData()
      })


    const fetchData = () => {
        setIsPending(true);
        axiosInstance.get('/admin/purchases/get-shoppers-purchases/'+purchase_id)
        .then(res => {
            setdata(res.data.data)
            setPurchasePagination(res.data.data.pagination)
        }).catch(err => {
            try {
            notifyError(err.response.data.message);

            } catch (error) {
            notifyError("Unable to fetch purchases, try again later");

            }

        }).finally(() => setIsPending(false))
    }

    const handleCheck = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
    
        // Update the refundIds array based on the checked state of the current checkbox
        setRefundIds(prevRefundIds => {
            if (checked) {
                // If the checkbox is checked, add its value to the refundIds array
                return [...prevRefundIds, value];
            } else {
                // If the checkbox is unchecked, remove its value from the refundIds array
                return prevRefundIds.filter(id => id !== value);
            }
        });
    }

    const handleRefundTransaction = (val) => {
        setRefundPending(true)
        axiosInstance.post('/admin/purchases/customer-order/refund/'+purchaseData?._id, {
            shop_product_ids: refundIds
        }).then(res => {
            notify(res.data.message)
            fetchData()
            setRefundIds([])
        }).catch(err => {
    
          try {
              notifyError(err.response.data.message);
    
          } catch (error) {
              notifyError("Unable to refund transactiom, try again later");
    
          }
    
        }).finally(() => setRefundPending(false))
    
      }
    


    return (
        <>
            <div className={`px-2`}>
                <div className={`${styles.header} row`} style={{ marginTop: '6rem'}}>
                    <img src="/assets/shoprite.svg" className="float-md-left" alt="" />
                    <div className="col-md-5">
                        <span style={{ display: 'inline-block !important' }}>
                            <div className="row">
                                <div className="col-4">
                                    <h1 style={{ display: 'inline !important' }}>
                                        {purchaseData?.user_id?.full_name}
                                    </h1>
                                </div>
                                <div className="col-4 pt-4">
                                {
                                    data.user_type != "admin" && <div>
                                        <small className="">
                                            {/* (Mom & Pop) */}
                                        </small>
                                    </div> 
                                    
                                }
                                </div>
                            </div>
                        </span>
                        <p className="mb-1 text-muted">
                            <small>
                                <img className="d-inline-block mr-1" src="/assets/icons/mail2.svg" alt="" />
                                {purchaseData?.user_id?.email}
                            </small>
                            &nbsp;&nbsp;&nbsp;
                            <small>
                                <img className="d-inline-block mr-1" src="/assets/icons/phone.svg" alt="" />

                                {purchaseData?.user_id?.phone_number}
                            </small>
                        </p>
                        <p className="pl-2">
                        <small className="text-muted">
                            <img className="d-inline-block mr-1 text-muted" src="/assets/icons/map.svg" alt="" />

                            {purchaseData?.delivery_information?.address}
                        </small>
                        </p>
                    </div>
                    <div className="col-md-5 text-right" >
                        {
                            data.user_type == 'admin' &&
                            purchaseData?.status === "completed" &&
                            <div className="col-md-12 d-flex text-right">
                            {
                                !showCheck &&
                                <button onClick={() => setShowCheck(true)} className={`modal_btn btn ml-auto mt-md-5`} >
                                    Refund
                                </button>

                            }
                            {
                                showCheck &&
                                <button onClick={() => {
                                    setShowCheck(false); setRefundIds([])
                                }} className={`modal_btn btn ml-auto mt-md-5`} >
                                    Cancel
                                </button>

                            }
                        </div>
                        }
                    </div>
                </div>

                <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
                    <Scrollbar style={{ overflowX: 'hidden' }}>
                        <div className="row">
                            <div className="col-md-8  mb-2">
                            {
                                data.user_type == "admin" && <div className='pl-3 pr-3 pt-3 pb-0 mb-0'>
                                    <button onClick={handleClick} className="btn">
                                        <div className="p-2" style={{
                                            border: "1px solid lightgrey",
                                            borderRadius: "3px"
                                        }}>
                                            <img src="/assets/icons/Chevronleft.svg" alt="" /> 
                                        </div>
                                    </button>
                                    &nbsp;
                                    <b className="mt-1">
                                        Purchases
                                    </b>
                                    <br/>
                                    {/* <h3>
                                        Orders
                                    </h3> */}
                                    <span className="text-muted" style={{ fontWeight: '200', fontSize: '16px', marginLeft: '68px', }}>
                                        Order Number: {purchaseData?.order_number}
                                    </span>
                                    <small style={{ display: 'inline-block', float: 'right' }}>
                                    {purchaseData.items.length} items
                                    </small>

                                </div>
                            }
                                <Scrollbar>
                                    <div className={`${styles.overflow_table} pt-3 pb-3 pl-0 pr-0`}  >
                                        <table className={`${styles.table} mt-0 table-striped`} style={{ minWidth: '50em' }}>
                                            <thead className={`${styles.thead}`}>
                                                <tr>
                                                    {TABLE_HEAD.map((el, i) => {

                                                        return (
                                                            <th style={{ fontSize: '16px !important'}}>
                                                                {el.label}
                                                            </th>
                                                        )
                                                    })}

                                                </tr>
                                            </thead>
                                            <tbody>
                                            {purchaseData.items.length > 0 && purchaseData.items.map((el, i) => {
                                            return (
                                                <tr key={el._id} className={`${styles.tr}`}>
                                                    <td>
                                                        <div className=" ">
                                                            <div className=" ">
                                                            {
                                                                showCheck &&
                                                                <div className="d-inline-block" >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={refundIds.includes(el?.shop_product_id?._id)}
                                                                                onChange={handleCheck}
                                                                                value={el?.shop_product_id?._id}
                                                                                className="float-left"
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                                <img
                                                                    
                                                                    src={
                                                                        el?.shop_product_id?.product_id?.product_image ?
                                                                        el.shop_product_id.product_id?.product_image :
                                                                        '/assets/purelifetablewater.svg'}
                                                                    alt=""
                                                                    style={{
                                                                        height:'70px',
                                                                        width:'70px'
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                &nbsp;
                                                                <div style={{
                                                                    display: 'inline-block'
                                                                }}>
                                                                    <p className="mb-0" style={{ fontSize: '14px' }}>
                                                                        <span>
                                                                            {el?.shop_product_id?.product_id?.product_name}
                                                                        </span>


                                                                    </p>
                                                                    <span style={{ fontSize: '10px' }}>
                                                                        {/* 50kg */}


                                                                    </span>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </td>
                                                    {
                                                        data.user_type == "admin" &&
                                                        <td>
                                                            {el?.shop_product_id?.product_id?.brand_id.business_name}
                                                        </td>
                                                    }
                                                    <td>
                                                        {el?.quantity}
                                                    </td>
                                                    <td>
                                                        ₦ {Number(el?.price).toLocaleString()}
                                                    </td>
                                                    <td>
                                                        ₦ {Number(el?.total_amount).toLocaleString()}


                                                    </td>

                                                </tr>
                                                )
                                            })}
                                            </tbody>

                                        </table>
                                        <div className="d-flex justify-content-center">
                                            {isPending &&
                                            <BallTriangle
                                                height={50}
                                                width={55}
                                                radius={5}
                                                color="#53138D"
                                                ariaLabel="ball-triangle-loading"
                                                wrapperClass={{}}
                                                wrapperStyle=""
                                                visible={true}
                                            />
                                            }

                                        </div>
                                    </div>
                                </Scrollbar>
                            </div>
                            <div className={`${styles.container_checkout} col-md-4 ${styles.height} `} style={{ background: '#E5E7EB' }}>
                                <div className=" pt-4  px-2 border-bottom" style={{ background: 'inherit !important' }}>
                                <h4 className="font-weight-bold">Summary</h4>


                                </div>
                                <div className="border-bottom-white mt-4 px-2">
                                    <div className="d-flex justify-content-between">
                                        <p className={`${styles.summarytitles}`}>
                                            Total Order
                                        </p>
                                        <p className={`${styles.summaryValues}`}>
                                            ₦ {purchaseData?.total ? Number(purchaseData.total).toLocaleString() : 0}
                                        </p>
                                    </div>
                                </div>
                               
                                <div className={`${styles.sub_total} px-2`} style={{
                                }}>
                                    <div className=" border-top" style={{
                                        marginTop: "150px",
                                    }}>
                                        {/* <div className="d-flex justify-content-between">
                                            <p style={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                lineHeight: ' 150%',
                                                color: '#1F2937',
                                                marginBottom: '0px',
                                            }}>
                                                Shipping fee:
                                            </p>
                                            <p style={{
                                                fontWeight: '400',
                                                fontSize: '15px',
                                                lineHeight: ' 150%',
                                                color: '##374151',
                                                marginBottom: '0px',
                                            }}>
                                                ₦ 7,000
                                            </p>

                                        </div> */}
                                        
                                        <p className="my-2" style={{
                                            fontFamily: 'Roboto',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '150%',
                                            color: '#4B5563'

                                        }}>
                                            Delivery Option: {purchaseData?.delivery_option?.toUpperCase()}

                                        </p>

                                    </div>
                                    <div className="" style={{ borderTop: '2px solid #FFFFFF' }}>
                                        {
                                            data.user_type == 'admin' && 
                                            <div>
                                                {/* {
                                                    refundIds.length > 0 && */}
                                                    <button 
                                                        onClick={() => handleRefundTransaction(purchaseData?.items[0]._id)} 
                                                        className={`btn btn-block btn-[#53138D] text-white mt-2 mb-2`} 
                                                        style={{ background: '#53138D',  }}
                                                    >
                                                        Refund {refundPending && <CircularProgress style={{ color: '#fff' }} size="1rem" />}
                                                    </button>
                                                {/* } */}
                                            </div>
                                        }
                                        <div className="d-flex justify-content-between mt-1">
                                            <p style={{
                                                 fontWeight: 'bold',
                                                 fontSize: '14px',
                                                 lineHeight: ' 150%',
                                                 color: '#1F2937'
                                            }}>
                                                Total
                                            </p>
                                            <p style={{
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                                lineHeight: ' 150%',
                                                color: '##374151'
                                            }}>
                                                ₦ {Number(purchaseData?.total).toLocaleString()}
                                            </p>

                                        </div>

                                    </div>


                                </div>


                            </div>
                        </div>
                        
                    {
                        ( !Object.keys(purchaseData).length  && !isPending) &&

                        <div className="alert alert-[#53138D] text-center">
                        <h4>No record found</h4>
                        </div>

                    }


                    </Scrollbar>

                </Card>

            </div>
        </>
    );
}
