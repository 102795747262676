

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import {
  Card,
  Avatar,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import { useNavigate } from "react-router-dom"
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner'
import { downloadFile2 } from "src/utils";
import { Pagination } from "src/components";

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isPending, setIsPending] = useState('');
  const [customerReviewData, setCustomerReviewData] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filterSearch, setFilterSearch] = useState({
    transaction_type: '',
    transaction_status: '',
    transaction_min_price: '',
    transaction_max_price: '',
    dateStart: '',
    dateEnd: '',
  });
  const notifyError = (err) => toast.error(err);

  const handleViewClick = (val) => {
    // bus.emit('user-review', val)
    navigate('/app/customers/' + val)
    // handle view click action
  };

  
  const handleClose = () => {
    setModalOpen(false);
  };


  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvp');

    // Your code here.
  }, []);
  
  const searchFilter = (e) => {
    setSearchValue(e.target.value)
  }

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/reviews/all?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`
      await downloadFile2('Customers - Reviews', result, extension);
    } catch (err) {
      notifyError(`Unble to export ${extension} file`);
    }
  };

  const fetchData = (page = 1, perpage = 10 ) => {
    let filter_payload = {}
    if (filterSearch.transaction_min_price) {
    filter_payload.transaction_min_price = filterSearch.transaction_min_price
    }
    if (filterSearch.transaction_max_price) {
      filter_payload.transaction_max_price = filterSearch.transaction_max_price
      }
    if (searchValue !== '') {
    filter_payload.search = searchValue
    }
    if (filterSearch.dateStart !== '') {
    filter_payload.dateStart = filterSearch.dateStart
    }
    if (filterSearch.dateEnd !== '') {
    filter_payload.dateEnd = filterSearch.dateEnd
    }
    if (filterSearch.transaction_type !== '') {
    filter_payload.transaction_type = filterSearch.transaction_type
    }
    if (filterSearch.transaction_status !== '' && filterSearch.transaction_status !== 'all') {
    filter_payload.transaction_status = filterSearch.transaction_status
    }
    setIsPending(true);
    handleClose(false)
    axiosInstance.get('/admin/reviews/all?', {
        params: {
            page,
            perpage,
            ...filter_payload
        }
    })
        .then(res => {
          // console.log(res.data.data);
          if(res.status < 300) {
            setCustomerReviewData(res.data.data.data)
          }
            setDataPagination(res.data.data.pagination)
            setFilterSearch({
              transaction_status: '',
              transaction_type: '',
              dateEnd: '',
              dateStart: '',
              transaction_max_price: '',
              transaction_min_price: '',
            })


        }).catch(err => {

            try {
                notifyError(err.response.data.message);

            } catch (error) {
                notifyError("Unable to fetch reviews, please try again later");

            }

        }).finally(() => setIsPending(false))
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  return   (
    <>
    <div className="px-2">
        <div className={`${styles.filters} mt-4`   }>
            <div className="row">
                <div className={`${styles.hold_input} col-6   `}>
                <img src="/assets/icons/search.svg" alt="" />
                <input type="text" value={searchValue} onChange={searchFilter} placeholder="Search" style={{textIndent:'25px',width:'60%'}} className=" form-control" />

                </div>
                <div className="col-6  text-right">
                {/* <button 
                onClick={() => setModalOpen(true)}
                className="btn  border"  
                style={{
                    backgroundColor:'#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px'
                }}>
                <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                &nbsp;
                <small className="d-none d-md-inline-block">
                Filters
                </small>
                </button> */}

                </div>
                
            </div>
                

        </div>
        <Card style={{marginTop:'50px',borderRadius:'10px'}} className='p-0'>
        <div className='p-4 border-bottom'>
        
            <div className="d-md-flex justify-content-between" >
            <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  Customer Reviews
                </h5>
                <small style={{ fontWeight: '200',  fontSize: '12px'}}>
                  Product reviews from customers
                </small>

            </div>
          </div>
        </div>
        <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}  >
            <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">
                      S/N
                    </th>
                    <th  className="text-muted">
                      Brand Name
                      <img 
                        className="pl-2" 
                        src="/assets/icons/down.svg" 
                        alt="" 
                      />
                    </th>
                    <th  className="text-muted">
                      Total Reviews
                    </th>
                    <th  className="text-muted">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                    {(!isPending && customerReviewData.length>0) && 
                    customerReviewData.map((el,i) => (
                    <tr key={i} className={`${styles.tr} `}>
                      <td>
                        {(currentPage - 1) * itemsPerPage + i + 1}
                      </td>
                      <td>
                      <Avatar 
                        className={`${styles.avatrr} mr-3 float-md-left`} 
                        alt="" src={el?.brand_info?.logo} 
                      />
                      <div 
                        className=" ">
                          <p className="mb-0" 
                            style={{fontSize: '14px'}}
                          >
                            {el.brand_info?.brand_name}
                          </p>
                          <small>
                            {el.brand_info?.brand_email}
                          </small>
                      </div>
                      </td>
                      <td>
                        <p>
                        {el?.total_reviews ? Number(el.total_reviews).toLocaleString() : 0}
                        </p>
                      </td>
                      <td>
                        <p>
                          <p>
                          {/* <button onClick={() => handleViewClick(el._id)} className={`btn ${styles.view_action}`}>
                                    <img src="/assets/icons/eyewhite.svg" className="pr-1" alt="" />

                                    View
                                  </button>
                            <button className="btn btn_primary"> */}
                          <button onClick={() => handleViewClick(el._id)} className="btn btn_primary">
                            <img src="/assets/icons/eyewhite.svg" className="pr-1" alt="" />
                                View
                          </button>
                          </p>
                        </p>
                      </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <div className="d-flex justify-content-center">
                {isPending &&
                  <BallTriangle

                    height={50}
                    width={55}
                    radius={5}
                    color="#53138D"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />

                }

              </div>
              {
                (!customerReviewData.length && !isPending) &&

                <div className="alert alert-[#53138D] text-center">
                  <h4>No record found</h4>
                </div>

              }
        </Scrollbar>
          <div>
              <Pagination
                  currentPage={currentPage}
                  dataPagination={dataPagination}
                  itemsPerPage={itemsPerPage}
                  setCurrentPage={setCurrentPage}
                  fetchData={fetchData}
              />
          </div>
        </Card>
    </div>
     <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
          <h5 style={{
            fontSize: '19px',
            fontWeight: '700',

          }}>Filter</h5>
            <span onClick={handleClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Location</label>
                  <TextField
                    required
                    type='text'
                    id="loc"
                    placeholder="Enter Location"
                    size="small"
                    fullWidth

                  />
                    

                </div>
                <label htmlFor="name">Date </label>

                <div className=" d-flex">
                  <div className="" style={{width:'70%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        id="name"
                        placeholder="Start"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-sflex   align-items-center " style={{heigsht:'40px'}}>
                    {/* sss */}
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        placeholder="End "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>

              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{height:'40px'}}>
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className=""  style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{height:'40px'}}>
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>
                
              </div>
            </div>
            <div className="mb-4">
            <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
              Proceed

            </button>
            
            
            </div>

          </form>


        </div>
       
       
    </GlobalModal>



    </>
  ) ;
}
