import styles from '../Sales.module.css';

import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import SalesDetails from '../SalesDetails';
import { useState, useEffect } from 'react';
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from 'src/hooks/useAuth';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useDropDowns } from 'src/hooks/useDropDowns';
import bus from 'src/bus';
import toast from 'react-hot-toast';
import axiosInstance from 'src/axiosInstance';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { downloadFile2, shortenText } from 'src/utils';
import LiveLocation from 'src/pages/LiveLocation';
import { Pagination } from 'src/components';
import DateFormat from 'src/hooks/useDateFormat';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sn', label: 'S/N', alignRight: false },
  { id: 'shopOwnersName', label: 'Store name', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'orderUnit', label: 'Order Unit', alignRight: false },
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'dateOrdered', label: 'Date Ordered', alignRight: false },
  // { id: 'created_at', label: 'Total Quantity', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------

// function changeView(d) {
//   localStorage.setItem('ptvs', d);
// }
// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;
export default function ShopOwners() {
  const { data } = useAuth();
  const [appView, setAppview] = useState(app_view);
  const dropdownFilters = useDropDowns();
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState('select');
  const [showDiv, setShowDiv] = useState(data.merchant_type === 'brand');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [searchvalue, setSearchvalue] = useState('');
  const open1 = Boolean(anchorEl1);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);
  const [modalOpen, setModalOpen] = useState(false);

  // pagination
  const [storeData, setStoreData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [purchasePagination, setPurchasePagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  //filter fields
  const [product, setProduct] = useState('select');
  const [startDate, setStartDate] = useState(null);
  const [startDates, setStartDates] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDates, setEndDates] = useState(null);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState({
    filterLocation: '',
    category: '',
    filterStatus: '',
    startDateFilter: null,
    endDateFilter: null,
    priceFilter: '',
    priceToFilter: '',
    orderUnitFilter: '',
    orderUnitToFilter: '',
  });

  const handleClose = () => {
    setModalOpen(false);
    filterSearch.filterLocation = '';
    filterSearch.category = '';
    filterSearch.filterStatus = '';
    filterSearch.startDateFilter = '';
    filterSearch.endDateFilter = '';
    filterSearch.priceFilter = '';
    filterSearch.priceToFilter = '';
    filterSearch.orderUnitFilter = '';
    filterSearch.orderUnitToFilter = '';
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded;
    let val = event.target.value;
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val);
        setProductsAdded(data);
      }
    }
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvs');
    },
    []
  );

  const handleViewClick = (val) => {
    localStorage.setItem(
      'sales_details',
      JSON.stringify({
        order_id: val.order_id,
        shop_id: val._id,
      })
    );
    navigate('/app/sales_details');
    // handle view click action
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(-1);
  };

  useEffect(() => {
    fetchData();
  }, [filter, searchvalue]);

  useEffect(() => {}, [storeData]);

  bus.on('reload', () => {
    fetchData();
  });

  const processLocation = (e) => {
    console.log(e);
    handleFilterStatus('filterLocation', e.formatted_address);
    handleFilterStatus('latitude', e.lat);
    handleFilterStatus('longitude', e.lng);
  };

  const handleFilterStatus = (key, value) => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    setFilterSearch((prev) => {
      return {
        ...prev,
        startDateFilter: startDates,
        endDateFilter: endDates,
      };
    });
  }, [startDates, endDates]);

  const handleSubmitFilter = () => {
    setModalOpen(false);
    fetchData();
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/sales/get-sales-store?page=${purchasePagination?.currentPage}&perpage=${purchasePagination?.total}&export_type=${extension}`;
      await downloadFile2('Store Sales', result, extension);
    } catch (err) {
      notifyError(`Unble to export ${extension} file`);
    }
  };

  //Getting Table Data
  const fetchData = (page = 1, perpage = 10) => {
    let filter_payload = {};
    if (filterSearch.priceToFilter != '') {
      filter_payload.max_price = filterSearch.priceToFilter;
    }
    if (filterSearch.priceFilter != '') {
      filter_payload.min_price = filterSearch.priceFilter;
    }
    if (
      filterSearch.filterStatus != 'select' &&
      filterSearch.filterStatus != 'all'
    ) {
      filter_payload.status = filterSearch.filterStatus;
    }
    if (searchvalue != '') {
      filter_payload.search = searchvalue;
    }
    if (startDate) {
      filter_payload.dateFrom = DateFormat(startDate);
    } else if (startDate === '1970-01-01') {
      filter_payload.dateFrom = '';
    } else {
      filter_payload.dateFrom = '';
    }
    if (endDate) {
      filter_payload.dateTo = DateFormat(endDate);
    } else if (endDate === '1970-01-01') {
      filter_payload.dateTo = '';
    } else {
      filter_payload.dateTo = '';
    }
    if (filterSearch.orderUnitToFilter != '') {
      filter_payload.max_order_unit = filterSearch.orderUnitToFilter;
    }
    if (filterSearch.orderUnitFilter != '') {
      filter_payload.min_order_unit = filterSearch.orderUnitFilter;
    }
    if (filterSearch.category != '') {
      filter_payload.category = filterSearch.category;
    }
    if (filterSearch.filterLocation != '') {
      filter_payload.location = filterSearch.filterLocation;
      filter_payload.latitude = filterSearch.latitude;
      filter_payload.longitude = filterSearch.longitude;
    }
    setIsPending(true);
    axiosInstance
      .get('/admin/sales/get-sales-store', {
        params: {
          page,
          perpage,
          search,
          ...filter_payload,
        },
      })
      .then((res) => {
        setStoreData(res.data.data.data);
        setPurchasePagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch sales, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  return Number(appView) === 0 ? (
    <>
      <div className="pt-4">
        <div className={`${styles.analytics}  `}></div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3 col-6`}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{ textIndent: '25px', width: '100%' }}
                className=" form-control"
              />
            </div>
            <div className="col-md-9 col-6 text-right">
              <button
                className="btn  border"
                onClick={() => setModalOpen(true)}
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-3 ">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Store sales</h5>
                <small style={{ fontWeight: '200', fontSize: '14px' }}>
                  Details of sales made by stores on Retail Xpress
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/download.svg" alt="" />
                      &nbsp; Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/arrowDown.svg" alt="" />
                    </span>
                  </button>
                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i === 1 ? 1 : false;

                      return (
                        <th key={el.id} className="text-muted">
                          {el.label}{' '}
                          {sIndex && (
                            <span style={{ cursor: 'pointer' }}>
                              <img
                                className="pl-2"
                                src="/assets/icons/down.svg"
                                alt=""
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending &&
                    storeData.map((el, i) => {
                      return (
                        <tr key={i} className={`${styles.tr}`}>
                          <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                          <td>
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                <strong>{el?.store_name}</strong>
                              </p>
                              <span style={{ fontSize: '13px' }}>
                                {el?.order_number}
                              </span>
                            </div>
                          </td>
                          <td>
                            <p>{el?.category}</p>
                          </td>
                          <td>
                            <p className="">
                              <span>{el?.order_unit}</span>
                            </p>
                          </td>
                          <td>
                            <div className=" ">
                              <div className=" ">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  <strong>{el?.full_name}</strong>
                                </p>
                                <span style={{ fontSize: '13px' }}>
                                  {el?.email}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>₦{Number(el?.price).toLocaleString()}</td>
                          {/* <td>
                          <span className="">
                            {el?.status === "completed" ? (
                                <span className="verified_badge">{el?.status}</span>
                              ) : el?.status === "pending" ? (
                                <span className="pending_badge">{el?.status}</span>
                              ) : (
                                <span className="not_verified_badge">{el?.status}</span>
                              )
                            }
                          </span>
                        </td> */}
                          <td>
                            <p>
                              <span>
                                {moment(el?.date_ordered).format('DD/MM/YYYY')}
                              </span>{' '}
                              <br />
                              <small>
                                {moment(el?.date_ordered).format('hh:mm A')}
                              </small>
                            </p>
                          </td>
                          <td>
                            <div className="dropleft ">
                              <span
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className={`${styles.action_button} px-3 py-2`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <img src="/assets/icons/action.svg" alt="" />
                              </span>
                              <div
                                className="dropdown-menu drop-left"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button
                                  type="button"
                                  onClick={() => handleViewClick(el)}
                                  className="rss dropdown-item btn border-0"
                                >
                                  View
                                </button>
                                {/* <button
                                  type="button"
                                  className="rss dropdown-item btn border-0">Verify</button> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!storeData.length && !isPending && (
              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={purchasePagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <LiveLocation label="Location" passData={processLocation} />
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Category</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filterSearch.category}
                    required
                    onChange={(e) =>
                      handleFilterStatus('category', e.target.value)
                    }
                  >
                    <MenuItem value="select">Select Category</MenuItem>
                    <MenuItem value="Kiosk">Kiosk</MenuItem>
                    <MenuItem value="Neighborhood store">
                      Neighborhood store
                    </MenuItem>
                    <MenuItem value="Supermarket">Supermarket</MenuItem>
                    <MenuItem value="E-commerce store">
                      E-commerce store
                    </MenuItem>
                  </Select>
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="name">Status</label>
                  <Select
                    labelId="mod"
                    id="mod"
                    fullWidth
                    size="small"
                    name='filterStatus'
                    value={filterSearch.filterStatus}
                    required
                    onChange={(e) => handleFilterStatus('filterStatus', e.target.value)}
                  >
                    <MenuItem value='select'>Select Status</MenuItem>
                    <MenuItem value='pending'>Pending</MenuItem>
                    <MenuItem value='completed'>Completed</MenuItem>
                    <MenuItem value='failed'>Failed</MenuItem>
                  </Select>
                </div> */}
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                {showDiv && (
                  <div>
                    <label htmlFor="ora">Total amount </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="price"
                            placeholder="Min"
                            size="small"
                            name="priceFilter"
                            value={filterSearch.priceFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterStatus('priceFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            name="priceToFilter"
                            value={filterSearch.priceToFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterStatus('priceToFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <label htmlFor="name">Date </label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '70%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="Start"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-sflex align-items-center "
                        style={{ heigsht: '40px' }}
                      >
                        {/* sss */}
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="End"
                            required
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!showDiv && (
                  <div>
                    <label htmlFor="patronage">Price</label>
                    <div className=" d-flex">
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            id="price"
                            placeholder="Min"
                            size="small"
                            name="priceFilter"
                            value={filterSearch.priceFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterStatus('priceFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="px-1 d-flex align-items-center "
                        style={{ height: '40px' }}
                      >
                        <img
                          src="/assets/icons/line.svg"
                          style={{ width: '30px' }}
                          alt=""
                        />
                      </div>
                      <div className="" style={{ width: '100%' }}>
                        <div className="mb-3">
                          <TextField
                            required
                            type="number"
                            placeholder="Max "
                            size="small"
                            name="priceToFilter"
                            value={filterSearch.priceToFilter}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === '' ||
                                parseFloat(inputValue) >= 0
                              ) {
                                handleFilterStatus('priceToFilter', inputValue);
                              }
                            }}
                            inputProps={{
                              inputMode: 'decimal',
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === '-' ||
                                e.key === 'e' ||
                                e.key === '.'
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitFilter"
                        value={filterSearch.orderUnitFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('orderUnitFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        name="orderUnitToFilter"
                        value={filterSearch.orderUnitToFilter}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterStatus('orderUnitToFilter', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleSubmitFilter}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  ) : (
    <SalesDetails key={1} onChange={handleChange} />
  );
}
