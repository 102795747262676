import styles from "./index.module.css"
import { useAuth } from "src/hooks/useAuth";
import {
    Card,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

let TABLE_HEAD = [
    { id: 'details', label: 'Product Details', alignRight: false },
    { id: 'quantity', label: 'Quantity', alignRight: false },
    { id: 'unitPrice', label: 'Unit price', alignRight: false },
    { id: 'totalPrice', label: 'Total price', alignRight: false },

];



// ----------------------------------------------------------------------



export default function OrderPage({ onChange }) {
    const navigate = useNavigate();
    const {data} = useAuth();
    
    if (data.user_type == "admin") {
         TABLE_HEAD = [
            { id: 'details', label: 'Product Details', alignRight: false },
            { id: 'brand', label: 'Brand', alignRight: false },
            { id: 'quantity', label: 'Quantity', alignRight: false },
            { id: 'unitPrice', label: 'Unit price', alignRight: false },
            { id: 'totalPrice', label: 'Total price', alignRight: false },
        
        ]; 
    }
    
    function handleClick(event) {
        event.preventDefault();
        navigate('/app/purchases')


    }
    


    return (
        <>
            <div className={` px-2`}>
                <button onClick={handleClick} className="btn">
                    <img src="/assets/icons/Steplink.svg" alt="" /> &nbsp;
                    Purchase
                </button>
                <div className={`${styles.header}   mt-4`}>
                    <img src="/assets/shoprite.svg" className="float-md-left" alt="" />

                    <div >
                        <h1 style={{ display: 'inline-block' }}>
                            Shoprite
                        </h1>
                        {
                            data.user_type != "admin" && <div>
                                <small>
                                    (Supermarket)
                                </small>
                            </div> 
                            
                        }
                        
                        <p className="mb-1 text-muted">
                            <small>
                                <img className="d-inline-block mr-1" src="/assets/icons/mail2.svg" alt="" />
                                outlet@gmail.com
                            </small>
                            &nbsp;&nbsp;&nbsp;
                            <small>
                                <img className="d-inline-block mr-1" src="/assets/icons/phone.svg" alt="" />

                                +23481867755
                            </small>
                        </p>
                        <p>
                            <small className="text-muted">
                                <img className="d-inline-block mr-1 text-muted" src="/assets/icons/map.svg" alt="" />

                                32, Bornu road, Alagomeji, Lagos, Nigeria
                            </small>

                        </p>

                    </div>

                </div>


                <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>

                    <Scrollbar style={{ overflowX: 'hidden' }}>
                        <div className="row">
                            <div className="col-md-8  mb-2">
                            {
                                data.user_type != "admin" && <div className='pl-3 pr-3 pt-3 pb-0 mb-0'>
                                    <h3>
                                        Sales
                                    </h3>
                                    <small style={{ fontWeight: '200', fontSize: '12px' }}>
                                        Sale Number: 1xr538
                                    </small>
                                    <small style={{ display: 'inline-block', float: 'right' }}>
                                        4 items
                                    </small>

                                </div>
                            }
                            {
                                data.user_type == "admin" && <div className='pl-3 pr-3 pt-3 pb-0 mb-0'>
                                    <h3>
                                        Orders
                                    </h3>
                                    <span className="text-muted" style={{ fontWeight: '200', fontSize: '16px' }}>
                                        Order Number: 1xr538
                                    </span>
                                    <small style={{ display: 'inline-block', float: 'right' }}>
                                        4 items
                                    </small>

                                </div>
                            }
                                
                                <div className={`table-responsive-sm mt-0 p-3`} style={{
                                    paddingTop: '0 !important',
                                }}>
                                    <table className={`${styles.table} mt-0`}>
                                        <thead className={`${styles.thead}`}>


                                            <tr>
                                                {TABLE_HEAD.map((el, i) => {

                                                    return (
                                                        <th style={{ fontSize: '16px !important'}}>
                                                            {el.label}
                                                        </th>
                                                    )
                                                })}

                                            </tr>

                                        </thead>
                                        <tbody>

                                            <tr className={`${styles.tr}`}>
                                                <td>
                                                    <div className=" ">
                                                        <div className=" ">
                                                            <img
                                                                className="flsoat-left"
                                                                src={'/assets/purelifetablewater.svg'}
                                                                alt=""
                                                                style={{
                                                                }}
                                                            />
                                                            &nbsp;
                                                            &nbsp;
                                                            <div style={{
                                                                display: 'inline-block'
                                                            }}>
                                                                <p className="mb-0" style={{ fontSize: '14px' }}>
                                                                    <span>
                                                                        Pure life Table water
                                                                    </span>


                                                                </p>
                                                                <span style={{ fontSize: '10px' }}>
                                                                    50kg


                                                                </span>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </td>
                                                {
                                                    data.user_type == "admin" &&
                                                    <td>
                                                        Nestle
                                                    </td>
                                                }
                                                <td>
                                                    50
                                                </td>
                                                <td>
                                                    ₦ 5,000
                                                </td>
                                                <td>
                                                    ₦ 250,000


                                                </td>

                                            </tr>
                                            <tr className={`${styles.tr}`}>
                                                <td>
                                                    <div className=" ">
                                                        <div className=" ">
                                                            <img
                                                                className="flsoat-left"
                                                                src={'/assets/milo.svg'}
                                                                alt=""
                                                                style={{
                                                                }}
                                                            />
                                                            &nbsp;
                                                            &nbsp;
                                                            <div style={{
                                                                display: 'inline-block'
                                                            }}>
                                                                <p className="mb-0" style={{ fontSize: '14px' }}>
                                                                    <span>
                                                                        Pure life Table water
                                                                    </span>


                                                                </p>
                                                                <span style={{ fontSize: '10px' }}>
                                                                    50kg


                                                                </span>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </td>
                                                {
                                                    data.user_type == "admin" &&
                                                    <td>
                                                        Nestle
                                                    </td>
                                                }
                                                <td>
                                                    50
                                                </td>
                                                <td>
                                                    ₦ 5,000
                                                </td>
                                                <td>
                                                    ₦ 250,000


                                                </td>

                                            </tr>
                                            <tr className={`${styles.tr}`}>
                                                <td>
                                                    <div className=" ">
                                                        <div className=" ">
                                                            <img
                                                                className="flsoat-left"
                                                                src={'/assets/milo2.svg'}
                                                                alt=""
                                                                style={{
                                                                }}
                                                            />
                                                            &nbsp;
                                                            &nbsp;
                                                            <div style={{
                                                                display: 'inline-block'
                                                            }}>
                                                                <p className="mb-0" style={{ fontSize: '14px' }}>
                                                                    <span>
                                                                        Pure life Table water
                                                                    </span>


                                                                </p>
                                                                <span style={{ fontSize: '10px' }}>
                                                                    50kg


                                                                </span>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </td>
                                                {
                                                    data.user_type == "admin" &&
                                                    <td>
                                                        Nestle
                                                    </td>
                                                }
                                                <td>
                                                    50
                                                </td>
                                                <td>
                                                    ₦ 5,000
                                                </td>
                                                <td>
                                                    ₦ 250,000


                                                </td>

                                            </tr>
                                            <tr className={`${styles.tr}`}>
                                                <td>
                                                    <div className=" ">
                                                        <div className=" ">
                                                            <img
                                                                className="flsoat-left"
                                                                src={'/assets/shoe.svg'}
                                                                alt=""
                                                                style={{
                                                                }}
                                                            />
                                                            &nbsp;
                                                            &nbsp;
                                                            <div style={{
                                                                display: 'inline-block'
                                                            }}>
                                                                <p className="mb-0" style={{ fontSize: '14px' }}>
                                                                    <span>
                                                                        Pure life Table water
                                                                    </span>


                                                                </p>
                                                                <span style={{ fontSize: '10px' }}>
                                                                    50kg


                                                                </span>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </td>
                                                {
                                                    data.user_type == "admin" &&
                                                    <td>
                                                        Nestle
                                                    </td>
                                                }
                                                <td>
                                                    50
                                                </td>
                                                <td>
                                                    ₦ 5,000
                                                </td>
                                                <td>
                                                    ₦ 250,000


                                                </td>

                                            </tr>




                                        </tbody>

                                    </table>

                                </div>

                            </div>
                            <div className={`${styles.container_checkout} col-md-4 ${styles.height} `} style={{ background: '#E5E7EB' }}>
                                <div className={`${styles.border_bottom} pt-4  px-2`} style={{ background: 'inherit !important' }}>
                                    <strong>
                                        <h4>Summary</h4>
                                    </strong>
                                </div>
                                <div className="border-bottom-white mt-4 px-2">
                                    <div className="d-flex justify-content-between">
                                        <p className={`${styles.summarytitles}`}>
                                            Total Order
                                        </p>
                                        <p className={`${styles.summaryValues}`}>
                                            ₦ 3,350,000
                                        </p>
                                    </div>
                                    {/* {
                                        data.user_type == 'admin' && <div>
                                        <p className={`${styles.summarytitles} ${styles.inline_block}`}>
                                            Promo Code:
                                        </p>
                                        <p className={`${styles.summaryValues} ${styles.inline_block} float-right`}
                                        style={{
                                            backgroundColor: "white",
                                            padding:'5px'
                                        }}>
                                                KYL231
                                        </p>
                                    
                                        <table width={'100%'}> 
                                            <tr>
                                                <td width={'50%'}>
                                                    <p className={`${styles.summarytitles}`}>
                                                        Promo Discount:
                                                    </p>
                                                </td>
                                                <td width={'50%'}>
                                                    <p className={`${styles.summaryValues} ${styles.inline_block} float-right`}
                                                    style={{
                                                        backgroundColor: "white",
                                                        padding:'5px',
                                                        width:'65px',
                                                        textAlign:'center'
                                                    }}>
                                                            20%
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                        
                                        
                                        </div>
                                    } */}
                                </div>
                                {/* {
                                        data.user_type == 'admin' && <div className={`${styles.margin_top}`}>
                                            <button className="btn btn-primary form-control">Refund</button>
                                        </div>
                                } */}
                                {
                                        data.user_type != 'admin' && <div>
                                    <div className={`${styles.sub_total} px-2`} >
                                        <div className=" border-top" style={{
                                            marginTop: "250px",
                                        }}>
                                            {/* <div className="d-flex justify-content-between">
                                                <p style={{
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                    lineHeight: ' 150%',
                                                    color: '#1F2937',
                                                    marginBottom: '0px',
                                                }}>
                                                    Shipping fee:
                                                </p>
                                                <p style={{
                                                    fontWeight: '400',
                                                    fontSize: '15px',
                                                    lineHeight: ' 150%',
                                                    color: '##374151',
                                                    marginBottom: '0px',
                                                }}>
                                                    ₦ 7,000
                                                </p>

                                            </div> */}
                                            <p className="my-2" style={{
                                                fontFamily: 'Roboto',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '150%',
                                                color: '#4B5563'

                                            }}>
                                                32, Bornu road, Alagomeji, Lagos, <br /> Nigeria

                                            </p>

                                        </div>
                                        
                                    </div>
                                </div>
                                }
                                <div className="pt-4 mt-4 " style={{ borderTop: '2px solid #FFFFFF' }}>
                                    <div className="d-flex justify-content-between">
                                        <p style={{
                                            fontWeight: '500',
                                            fontSize: '13px',
                                            lineHeight: ' 150%',
                                            color: '#1F2937'
                                        }}>
                                            <strong>
                                                Total:
                                            </strong>
                                        </p>
                                        <p style={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                            lineHeight: ' 150%',
                                            color: '#374151',
                                            marginRight:'40px'
                                        }}>
                                            ₦ 2,925,000
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>


                    </Scrollbar>

                    {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
                </Card>

            </div>
        </>
    );
}
