
// sections
// mock
import { useLayoutEffect, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import BrandOwners from "./SuperAdminComponent/BrandOwners";
import ShopOwners from "./SuperAdminComponent/ShopOwners";
import StoreOwners from "./SuperAdminComponent/StoreOwners";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------



export default function BrandOwner() {
  const {data} = useAuth();
  const [value, setValue] = useState('1');

  


  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  



  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvs')
  }, [])
  
  


  

  return   (
    <>
      <div className="wrapper  mt-4 mt-md-1 px-2">
        <div   className="header d-block d-md-none mb-4">
          <h4 className={`mb-0 pb-0`}>Sales</h4>
          
          <p style={{fontSize: '14px'}} className={`mb-0 pb-0`}>
          An overview of your sales performance
        

          </p>

        </div>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs ">
                <Tab label="Shoppers" value="1" />
                <Tab label="Store Owners" value="2" />
                {/* <Tab label="Brand " value="3" /> */}
              </TabList>
            </Box>
            <TabPanel value="1"  style={{padding: '0'}}>
              <ShopOwners key={1} />
            </TabPanel>
            <TabPanel value="2" style={{padding: '0'}}>
              <StoreOwners key={2} />
            </TabPanel>
            {/* <TabPanel value="3" style={{padding: '0'}}>
              <BrandOwners key={2} />
            </TabPanel> */}
          </TabContext>
        

      </div>
     
    </>
  ) 
}
