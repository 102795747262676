import styles from "../Page.module.css";
// import InviteSent from "../Modals/inviteSent";

import TextField from "@mui/material/TextField";

// sections
// mock
import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import bus from "src/bus";
import axiosInstance from "src/axiosInstance";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import { BallTriangle } from "react-loader-spinner";
import { ImageUploader } from "../../../components";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   geocodeByPlaceId,
//   getLatLng,
// } from 'react-places-autocomplete';
// -----------------------------
// ----------------------------------------------------------------------
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  overflow: "auto",
  bgcolor: "#F5F5F5",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: '90vh',
  overflow: "auto",
  bgcolor: "#FFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
};
export default function ShopownerAdministration({ refresh }) {
  const [open, setOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [submitInvite, setSubmitInvite] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isCheckedIndividual, setIsCheckedIndividual] = useState([]);
  const [endpoint, changeEndPoint] = useState(
    "/shop/administration/invite-member"
  );
  const [perms, setPerms] = useState([]);
  const notify = (msg) => toast.success(msg)
  const notifyError = (error) => toast.error(error);
  const [preview, setPreview] = useState("");
  // const [formValues, setFormValues] = useState({
  //   outlet_location: '',
  //   latitude: '',
  //   longitude: ''
  // });

  // const setAddr = (val) => {
  //   setFormValues((formValues) => ({
  //     ...formValues,
  //     outlet_location: val
  //   }));
  // }

  // const handleSelect = async value => {
  //   const results = await geocodeByAddress(value);
  //   const { lat, lng } = await getLatLng(results[0]);
  //   setFormValues((prev) => {
  //     return {
  //       ...prev,
  //       latitude: lat,
  //       longitude: lng,
  //     }
  //   });
  //   setAddr(value);
  // }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleClose = () => {
    setEmailAddress('');
    // setFormValues({
    //   outlet_location: '',
    //   latitude: '',
    //   longitude: ''
    // });
    setIsCheckedIndividual([]);
    setOpen(false)
  };
  const handleChange = (e) => {
    setEmailAddress(e.target.value);
  };

  useEffect(() => {
    bus.on("open-invite-modal", (val = {}, edit = null) => {
      changeEndPoint("/shop/administration/invite-member");
      setOpen(true);
    });

    return () => {
      bus.off("open-outlet", () => {
        changeEndPoint("/shop/administration/invite-member");
        setOpen(false);
      });
    };
  }, []);

  const fetchPerm = () => {
    setIsPending(true);
    axiosInstance
      .get("/shop/administration/get-permissions")
      .then((res) => {
        setPerms(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError("Something went wrong, Please try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    fetchPerm();
  }, []);

  const handleInviteImageUrl = (imageUrl) => {
    setPreview(imageUrl);
  };

  const handleInviteSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email: emailAddress,
      permissions: isCheckedIndividual,
      // latitude: formValues.latitude,
      // longitude: formValues.longitude,
      // invite_image: preview,
    };
    // if (formValues.latitude === '' && formValues.longitude === '')
    //   return notifyError("Please select your location");
    if (!payload.permissions.length)
      return notifyError("Please select at least one permission");

    setSubmitInvite(true);
    axiosInstance
      .post(endpoint, payload)
      .then((res) => {
        if (res.status < 300) {
          setPerms(res.data.data);
          setOpen(false);
          refresh();
          bus.emit("imageUrl", preview);
          setEmailAddress("");
          // setFormValues({
          //   outlet_location: '',
          //   latitude: '',
          //   longitude: ''
          // });
          setPreview("");
          fetchPerm();
          notify(res.data.message)
        }
        setIsCheckedIndividual([]);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          const errorMessage = err.response.data.message;
          if (typeof errorMessage === "object") {
            // notifyError(JSON.stringify(errorMessage));
            notifyError("An error occured.");
          } else {
            notifyError(errorMessage);
          }
        } else {
          notifyError("Something went wrong. Please try again later.");
        }
      })
      .finally(() => setSubmitInvite(false));
  };

  const handlePermChange = (val, e) => {
    const updatedCheckedIndividual = [...isCheckedIndividual];
    if (e.target.checked) {
      if (!updatedCheckedIndividual.includes(val)) {
        updatedCheckedIndividual.push(val);
      }
    } else {
      // Remove value if it's unchecked and in the array
      const index = updatedCheckedIndividual.indexOf(val);
      if (index !== -1) {
        updatedCheckedIndividual.splice(index, 1);
      }
    }
    console.log(val);
    setIsCheckedIndividual(updatedCheckedIndividual);
  };

  const handleCheckAllChange = (e) => {
    const data = [];

    if (e.target.checked) {
      perms?.forEach((item) => {
        if (!data.includes(item._id)) data.push(item._id);
      });
    }
    setIsCheckedIndividual(data);
  };

  useEffect(() => { }, [perms]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header} mb-0`}>
                <div>
                  <div
                    className="mb-1"
                    style={{
                      fontSize: "19px",
                      fontWeight: "700",
                    }}
                  >
                    Invite Admin
                  </div>
                </div>
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <div className="mb-3" style={{ fontSize: "14px" }}>
                Add Admin to manage dashboard
              </div>
              {/* <div className="border rounded px-3 my-3 pt-4 pb-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className={`${styles.img_wrap} p-3 `}>
                      <label>
                        {preview === "" && (
                          // <AiOutlinePicture className={styles.pic_icon} style={{ cursor: 'pointer' }} />
                          <div
                            style={{
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                            className="rounded-circle border p-4"
                          >
                            <img
                              className={`${styles.retailer_product_image} rounded-circle`}
                              src="/assets/image.svg"
                              alt=""
                            />
                          </div>
                        )}
                        {
                          preview !== "" && (
                            <div
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                padding: "0px",
                              }}
                              className="rounded-circle border p-4"
                            >
                              <div
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  overflow: "hidden",
                                }}
                                className="border"
                              >
                                <img
                                  className={`${styles.retailer_product_image}`}
                                  src={preview}
                                  style={{
                                    width: "100px",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                          )
                          // <img style={{ width: '100%' }} src={preview} alt='' />
                        }
                        <p
                          className="mb-0 text-center text-[#53138D] mt-2"
                          style={{
                            fontSize: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Upload profile image
                        </p>
                        <input onChange={handleFile} type="file" />
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <small className="mb-0 p-0 text-primary">upload image</small> */}
              {/* <div 
                                    className="col-md-6 mt-3 mt-md-0" 
                                    style={{
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div className=" w-100">
                                        <small className="mb-3">Choose Avatar</small>
                                        <div className="d-flex w-100 mt-2">
                                        <img
                                            src="/assets/images/avatars/avatar_1.jpg"
                                            className="rounded-circle mx-1"
                                            style={{ width: '30px', cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => handleInviteImageUrl('/assets/images/avatars/avatar_1.jpg')}
                                        />
                                        <img
                                            src="/assets/images/avatars/avatar_2.jpg"
                                            className="rounded-circle mx-1"
                                            style={{ width: '30px', cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => handleInviteImageUrl('/assets/images/avatars/avatar_2.jpg')}
                                        />
                                        <img
                                            src="/assets/images/avatars/avatar_3.jpg"
                                            className="rounded-circle mx-1"
                                            style={{ width: '30px', cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => handleInviteImageUrl('/assets/images/avatars/avatar_3.jpg')}
                                        />
                                        <img
                                            src="/assets/images/avatars/avatar_4.jpg"
                                            className="rounded-circle mx-1"
                                            style={{ width: '30px', cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => handleInviteImageUrl('/assets/images/avatars/avatar_4.jpg')}
                                        />
                                        <img
                                            src="/assets/images/avatars/avatar_5.jpg"
                                            className="rounded-circle mx-1"
                                            style={{ width: '30px', cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => handleInviteImageUrl('/assets/images/avatars/avatar_5.jpg')}
                                        />
                                        </div>
                                    </div>
                                </div> */}
              <form onSubmit={handleInviteSubmit} className="mt-0">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="name">
                        <strong>Email Address</strong>
                      </label>
                      <TextField
                        required
                        type="email"
                        value={emailAddress}
                        name="outlet_name"
                        onChange={handleChange}
                        id="name"
                        placeholder="sample@email.com"
                        size="small"
                        fullWidth
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <PlacesAutocomplete
                      value={formValues.outlet_location}
                      onChange={setAddr}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <div className='inputs_n_label'>
                            <label htmlFor="loc">
                              <strong>Location</strong>
                            </label>
                            <TextField
                              id="loc"
                              placeholder='Input your location'
                              size="small"
                              fullWidth
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                              })}

                            />
                          </div>

                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, i) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  key={i}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div> */}
                  {/* <div className="col-md-3 col-3 upper_space">
                                <button className="btn btn-primary"> Send Invite</button>
                                </div> */}
                </div>

                <div className="row mt-4">
                  {/* <div className="col-6">
                    <strong>
                      <p>Select Permissions For This Role</p>
                    </strong>
                  </div> */}
                  <div className="col-12 text-right">
                    <p className="mb-0" style={{lineHeight: '1'}}>
                      <input
                        type="checkbox"
                        name=""
                        onChange={handleCheckAllChange}
                        id="select_all"
                        style={{cursor: 'pointer', verticalAlign: 'middle'}}
                      />{" "}<label htmlFor="select_all" className="mb-0" style={{cursor: 'pointer', verticalAlign: 'middle'}}>Select All</label>
                    </p>
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    color: "#585c63",
                    fontSize: "14px",
                  }}
                >
                  {/* <p className="adjust_p_margin">
                                <strong>
                                Dashboard
                                </strong>
                            </p> */}
                  {!isPending &&
                    perms?.length > 0 &&
                    perms?.map((el, i) => (
                      <div className="col-md-6">
                        <p key={i} className="adjust_p_margin">
                          <input
                            type="checkbox"
                            checked={isCheckedIndividual.includes(el?._id)}
                            onChange={(e) => handlePermChange(el?._id, e)}
                            name=""
                            id=""
                          />
                          &nbsp;
                          {el?.permission_description}
                        </p>
                      </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center">
                  {isPending && (
                    <BallTriangle
                      height={50}
                      width={55}
                      radius={5}
                      color="#53138D"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  )}
                </div>
                {!perms?.length && !isPending && (
                  <div className="alert alert-[#53138D] text-center">
                    <h4>No record found</h4>
                  </div>
                )}
                <div className="mt-4">
                  <button className={`${styles.go_to} btn-block`} type="submit">
                    Invite Admin{" "}
                    {submitInvite && (
                      <CircularProgress
                        style={{ color: "white" }}
                        size="1rem"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openDialog}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: "bold" }}>
                Invitation Sent
              </h5>
              <p
                className="text-center"
                style={{ textAlign: "center !important" }}
              >
                Invitation has been sent to email address
              </p>
              <button
                onClick={() => setOpenDialog(false)}
                className={`modal_btn hover btn btn-block`}
              >
                Confirm
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
