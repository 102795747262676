
import { useLayoutEffect, useState } from "react";

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Shoppers from "./BrandOwnerComponent/Shoppers";
import ShopOwners from "./BrandOwnerComponent/ShopOwners";



export default function BrandOwner() {
  
  const [value, setValue] = useState('1');
  
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  



  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvs')
  }, [])
  
 


  

  return   (
    <>
      <div className="wrapper  mt-4 mt-md-1 px-2">
       
      <Shoppers />
        {/* <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs ">
              <Tab label="Shop Owners" value="1" />
              <Tab label="Shoppers" value="1" />
            </TabList>
          </Box>
          <TabPanel value="1"  style={{padding: '0'}}>
            <ShopOwners key={1} />
          </TabPanel>
          <TabPanel value="1" style={{padding: '0'}}>
            <Shoppers key={2} />
          </TabPanel>
        </TabContext> */}
      </div>
     
    </>
  ) 
}
