

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import {
  Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import bus from 'src/bus';

import Button from '@mui/material/Button';
import GlobalModal from "src/Modals/GlobalModal";
import DatePicker from "react-datepicker";
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import AddToCart from "src/components/globalMethods/addToCart";
import "react-datepicker/dist/react-datepicker.css";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createRef } from "react";
import { useLayoutEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { BallTriangle } from 'react-loader-spinner'
import DiscountTimer from "src/hooks/useDiscountCountDown";
import { useAuth } from 'src/hooks/useAuth';


// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};
const style1 = {
  position: 'absolute',
  // height: '500px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

// ptvp=PAGE TO VIEW (SALES)

export default function RetailerSingleView() {
  let textInput = createRef();
  const [openDialog, setDiologOpen] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {data} = useAuth();


  const handleClickOpen = () => {
    setDiologOpen(true);
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };


  const [notify, setNotify] = useState(false);

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleDelete = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [weight, setWeight] = useState(20);
  const [url, setUrl] = useState('');
  const handleGoToCartClose = () => setopenGoToCart(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [searchvalue, setSearchvalue] = useState('');
  const [reload, setReload] = useState(false)
  const [mutate, setMutate] = useState(false)
  const [isPending, setIsPending] = useState(false);
  const [categoriesData, setdata] = useState([]);
  const [dataPagination, setDataPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const [discountData, setDiscountData] = useState([]);




  const searchFilter = (e) => {
    setSearchvalue(e.target.value)
  }

  const handleClose = () => {
    setModalOpen(false);
  };



  useLayoutEffect(() => () => {
    // Your code here.
  }, [])


  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]))

    }


  }
  const handleButtonClick = () => {

    textInput.current.click()
  }
  
  const fetchData = (page = 1, pageSize = 10, search = searchvalue) => {
    setIsPending(true);
    axiosInstance.get('/shop/product/discount/list', {
      params: {
        search
      }
    })
      .then(res => {
        setDiscountData(res.data.data.data)


      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch discounts, try again later");

        }

      }).finally(() => setIsPending(false))
  }

  const handleAddToCart = (el) => {
    setIsPending(true)
    axiosInstance.post('/shop/cart/add-to-cart/' + el.product_id, {
      quantity: 1
    })
      .then(res => {
        setopenGoToCart(true);
        setReload(!reload)

      }).catch(err => {
        notifyError(err.response.data.message)
      }).finally(() => {
        // hasBeenHandled.current = false; // mark the event as handled

      })
  }





  const handlePushNotify = (e) => {
    setNotify(e.target.value)

  }

  const removeFromList = (index) => {
    let data = [...productsAdAdded]
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1)
    setProductsAdded(data)




  }

  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded
    let val = event.target.value
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val)
        setProductsAdded(data)

      }
    }
  };

  useEffect(() => {
    fetchData()
  }, [searchvalue, reload]);

  useEffect(() => {
    bus.on('added-to-cart', () => {
      setopenGoToCart(true);
      setReload(!reload)

    })

  }, [])


  return (
    <>
      <div className={` pt-2 px-2`}>

        <div className={`${styles.filters}`}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3 mb-2 col-6 `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input type="text" value={searchvalue} onChange={searchFilter} placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />

            </div>
            {/* <div className="col-md-9 col-6  text-md-right">
              <button className="btn  border" onClick={() => setModalOpen(true)} aria-haspopup="true" aria-expanded="false"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px'
                }}>
                <img className="" style={{ display: 'inline', width: '17px', minWidth: '30%' }} src="/assets/icons/filterlines.svg" alt="" />
                &nbsp;
                <small className="d-none d-md-inline-block">
                  Filters
                </small>
              </button>

            </div> */}
          </div>
        </div>
        <Scrollbar>
          <div className="card">
            <div className="card-body">
              <div className={`py-3  ${styles.cover_products}`}>
                <div className={`${styles.customized_row}`}>
                  { 
                    data.is_verified &&
                    (!isPending && discountData.length > 0) && discountData.map((el) => (
                      <div className="mb-4" key={el._id}>
                        <div className="card " style={{ position: 'relative !important', overflow: 'hidden' }}>
                          <span className={`${styles.discount_banner}`}>
                            {Math.floor(Number(el.discount?.$numberDecimal))}% Off
                          </span>
                          <div className="card-body">
                            <div className={`${styles.customized_row1}`}>
                              <div className=" ">
                                <img style={{
                                  width: '300px',
                                  height: '200px'
                                }} src={el.product_image} alt="" />
                                <h6 className="text-danger" style={{ fontWeight: 'lighter' }}>
                                  {
                                    el?.is_approved && 
                                    el?.discount_start_date && el?.discount_end_date !== null ? 
                                    (
                                    <DiscountTimer start={el?.discount_start_date} end={el?.discount_end_date} />
                                    ) : 
                                    (
                                      <p className="text-center">
                                        00:00:00:00
                                      </p>
                                    )
                                  }
                                </h6>
                              </div>
                              <div className={`${styles.product_txt}`}>
                                <h6>{el.product_name}</h6>
                                <p>
                                  {/* Amet minim mollit non des erunt ullamco est sit aliqua do lor do amet sint. */}
                                </p>
                                <div className="d-flex justify-content-between">
                                  <span>
                                    {el?.product_spec ? Number(el?.product_spec) : ''}
                                  </span>
                                  <span>
                                    <img style={{ with: '2%' }} src="/assets/icons/packagec.svg" alt="cube" className={`mr-2`} />

                                    {Number(el.max_sales_quantity).toLocaleString()} Left
                                  </span>
                                </div>
                                <div className={`${styles.product_price}`}>
                                  <p>
                                    ₦{Number((el.price?.$numberDecimal) -
                                      (Math.floor(Number(el.discount?.$numberDecimal)) / 100) *
                                      Number(el.price?.$numberDecimal)).toLocaleString()
                                    }
                                  </p>
                                  &nbsp;
                                  <span className={`${styles.price_off}`}>
                                    {Math.floor(Number(el.discount?.$numberDecimal))}%

                                  </span>
                                  <div className={`${styles.price_strike} border-bottom pb-2`}>
                                    ₦{Number(el.price?.$numberDecimal).toLocaleString()}

                                  </div>
                                  <div className="pt-2">
                                    <button className={`${styles.go_to} btn-block`} onClick={() => handleAddToCart(el)} type="button">
                                      Add to Cart
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>

                    ))
                  }
                </div>
              </div>
              {
                !data.is_verified &&
                <div className="alert alert-[#53138D] text-center">
                  <p>This account is yet to be verified by the admin. 
                      Kindly send an email to support@flexdeals.com.ng 
                      if it is over 24hours of registeration. Thanks 🤝
                  </p>
                </div>
              }
              <div className="d-flex justify-content-center">
                {isPending &&
                  <BallTriangle
                    height={50}
                    width={55}
                    radius={5}
                    color="#53138D"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                }
              </div>
              {
                data.is_verified &&
                (!discountData.length && !isPending) &&
                <div className="alert alert-[#53138D] text-center">
                  <h4>No record found</h4>
                </div>
              }
            </div>
          </div>
        </Scrollbar>
      </div>
      <AddToCart />

      {/*  DIALOG*/}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

              </div>
              <div>
                <h4>
                  Delete Product

                </h4>
                <p style={{
                  fontSize: '14px',
                  color: '#6B7280',
                }}>
                  Are you sure you want to delete this product? All your data will be permanently removed from our servers forever. This action cannot be undone.
                </p>

              </div>

            </div>

            <DialogActions>
              <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
              <Button className={`${styles.delete_product}`} onClick={handleDialogClose}>Delete</Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      {/* Withdraw */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style1}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Add Product </h3>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />

                </span>

              </div>
              <p>
                Add product to this category
              </p>


              <form action="">
                <p className={`${styles.modal_product_title}`}>Product Image </p>
                <input type="file" hidden ref={textInput} name="" onChange={handleFile} />
                <div className={`${styles.retailer_modal_product_img}`} style={{
                  backgroundImage: `url('${url}')`,


                }}>
                  <span style={{ cursor: 'pointer' }} onClick={() => handleButtonClick()}>
                    <img
                      className={`${styles.retailer_product_image}`}
                      src="/assets/image.svg"
                      alt=""

                    />

                  </span>
                </div>
                <div className="mb-3">
                  <label className={`${styles.modal_product_title}`} htmlFor="pname">Product Name</label>
                  <TextField
                    required
                    type='text'
                    id="pname"
                    placeholder=""
                    size="small"
                    fullWidth

                  />


                </div>
                <div className="mb-3">
                  <label className={`${styles.modal_product_title}`} htmlFor="name">Weight</label>
                  <Select
                    labelId="name"
                    id="name"
                    fullWidth
                    size="small"
                    value={weight}
                    required
                    onChange={handleWeightChange}
                  >

                    <MenuItem value={20}>20kg</MenuItem>
                    <MenuItem value={30}>30kg</MenuItem>
                  </Select>


                </div>
                <div className="mb-3">
                  <label className={`${styles.modal_product_title}`} htmlFor="price">Price </label>
                  <TextField
                    required
                    id="price"
                    type="number"
                    placeholder=" ₦"
                    size="small"
                    fullWidth

                  />


                </div>
                <div className="mb-3">
                  <label className={`${styles.modal_product_title}`} htmlFor="discount">Discount </label>
                  <TextField
                    required
                    id="discount"
                    type="number"
                    placeholder=" 0%"
                    size="small"
                    fullWidth

                  />


                </div>
                <div className="mb-3 pl-4">
                  <label htmlFor='notify' className='form-check-label'>
                    <input id='notify' value={notify} onChange={handlePushNotify} type="checkbox" className='form-check-input' />
                    Push Notification about promo to retailers
                  </label>

                </div>
                <div className="mb-3">
                  <label className={`${styles.modal_product_title}`} htmlFor="stock">Stock </label>
                  <TextField
                    required
                    id="stock"
                    type="number"
                    size="small"
                    fullWidth

                  />


                </div>
                <div className="mb-3">
                  <label className={`${styles.modal_product_title}`} htmlFor="desc">Description </label>
                  <TextField
                    required
                    id="desc"
                    multiline
                    rows='4'
                    cols='3'
                    placeholder=" Best Product"
                    size="small"
                    fullWidth

                  />


                </div>

                <div className="mb-3">
                  <button type="submit" className={`${styles.modal_btn} btn btn-block`}>
                    Save
                  </button>


                </div>

              </form>


            </div>

          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box className={`mobile_modal_size ${styles.modal_bg}`} sx={style1}>
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3 font-weight-bold">Product Added to Cart</h5>
              {/* <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing  elit. Eius aliquam 
                 laudantium explicabo pariatur iste dolorem animi vitae error totam. At  
                  sapiente aliquam accusamus facere veritatis. 

              </p> */}
              <div className={`${styles.buttons}  pt-2`}>
                <button className={`${styles.cancel} `} onClick={handleGoToCartClose}>
                  Cancel
                </button>
                &nbsp;
                <button className={`${styles.go_to} `} onClick={() => navigate('/app/cart')}>

                  Go to cart
                </button>

              </div>

            </div>



          </Box>
        </Fade>
      </Modal>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#ffff'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">

                <div className="mb-3">
                  <label htmlFor="name">Product</label>
                  <Select
                    labelId="name"
                    id="name"
                    fullWidth
                    size="small"
                    value={product}
                    required
                    onChange={handleProductChange}
                  >

                    <MenuItem value={1}>Select</MenuItem>
                    <MenuItem value={'Toiletries'}>Toiletries</MenuItem>
                    <MenuItem value={'Biscuite'}>Biscuit</MenuItem>
                    <MenuItem value={'Paper'}>Paper</MenuItem>
                  </Select>


                  <div className="products mt-2">
                    {productsAdAdded.map((el, i) => {

                      return (
                        <div className={`${styles.product_selected}`}>
                          <div className=" d-flex justify-content-between">
                            <span >{el}</span>
                            &nbsp;
                            &nbsp;
                            <span style={{ cursor: 'pointer' }} onClick={() => removeFromList(i)}>
                              <img style={{ width: '5px' }} src="/assets/icons/x.svg" alt="" />

                            </span>

                          </div>
                        </div>
                      )
                    })}
                  </div>


                </div>
                <label htmlFor="name">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                      />

                    </div>

                  </div>

                </div>

              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div className="mb-4">
              <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
                Proceed

              </button>


            </div>

          </form>


        </div>


      </GlobalModal>
    </>
  )
}
