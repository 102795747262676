// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LiveLocation from 'src/pages/LiveLocation';

import { Card, Avatar } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { createRef } from 'react';
import { useLayoutEffect } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { shortenText } from 'src/utils';
import DateFormat from 'src/hooks/useDateFormat';
import { downloadFile3 } from 'src/utils';
import { Pagination } from 'src/components';
// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};

export default function DashboardAppPage() {
  let textInput = createRef();
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentData, setcurrentData] = useState({});
  const [viewData, setViewData] = useState({
    shops: [],
    products: [],
    brands: [],
    address: {},
    wallet_balance: 0,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [openDialog, setDiologOpen] = useState(false);
  const [filter, setFilter] = useState({
    verified: 'select',
    dateFrom: '',
    dateTo: '',
    min_products: '',
    max_products: '',
    min_balance: '',
    max_balance: '',
    min_order_unit: '',
    max_order_unit: '',
    min_brands: '',
    max_brands: '',
    min_stores: '',
    max_stores: '',
    location: '',
    search: '',
  });

  const [viewBrands, setViewBrands] = useState(false);
  const [brandsVal, setBrandsVal] = useState([]);
  const [viewStores, setViewStores] = useState(false);
  const [viewProduct, setViewProduct] = useState(false);
  const [reload, setReload] = useState(false);
  const [isPending, setIsPending] = useState('');
  const [viewPending, setViewPending] = useState(false);
  const [shoppersData, setShoppersData] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loadingState, setLoadingState] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchBrands, setSearchBrands] = useState('');
  const [searchShops, setSearchShops] = useState('');
  const [searchProducts, setSearchProducts] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const notify = (msg = null) =>
    toast.success(msg === null ? 'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);

  const handleSearchBrands = (e) => {
    const query = e.target.value;
    setSearchBrands(query);

    const filteredResults = (viewData?.brands || []).filter((el) =>
      el.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const handleSearchShops = (e) => {
    const query = e.target.value;
    setSearchShops(query);

    const filteredResults = (viewData?.shops || []).filter((el) =>
      el.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const handleSearchProducts = (e) => {
    const query = e.target.value;
    setSearchProducts(query);

    const filteredResults = (viewData?.products || []).filter((el) =>
      el.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const handleCloseViewBrands = () => {
    setViewBrands(false);
  };
  const handleViewMore = (val) => {
    setBrandsVal(val);
    setFilteredData(val);
  };
  const handleCloseViewFilter = () => {
    setFilterModalOpen(false);
  };
  const handleCloseViewStores = () => {
    setViewStores(false);
  };
  const handleCloseViewProduct = () => {
    setViewProduct(false);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const handleButtonClick = () => {
    textInput.current.click();
  };
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem('ptvp');

      // Your code here.
    },
    []
  );

  const processAction = (val) => {
    setcurrentData(val);
    setDiologOpen(true);
  };

  const viewRecord = (el) => {
    setModalOpen(true);
    setViewData({
      shops: [],
      products: [],
      brands: [],
      address: {},
      wallet_balance: {},
    });

    setViewPending(true);
    axiosInstance
      .post('/admin/customers/view-shopper', {
        id: el._id,
      })
      .then((res) => {
        if (res.status < 300) {
          setViewData(res.data.data);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setViewPending(false));
  };

  const handleDownloadFile = async (extension) => {
    try {
      const result = `/admin/customers/get-shoppers?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`;
      await downloadFile3('Customers - Shoppers', result, extension);
    } catch (err) {
      notifyError(`Unable to export ${extension} file`);
    }
  };

  const fetchData = (page = 1, pageSize = 10) => {
    const filterObject = { ...filter, page, perpage: pageSize };

    if (filterObject.verified === 'select') filterObject.verified = '';
    if (startDate) {
      const dValue = DateFormat(startDate);
      filterObject.dateFrom = dValue;
    } else {
      filterObject.dateFrom = '';
    }
    if (endDate) {
      const dValue = DateFormat(endDate);
      filterObject.dateTo = dValue;
    } else {
      filterObject.dateTo = '';
    }
    let queryParams = new URLSearchParams({ ...filterObject }).toString();

    setIsPending(true);
    handleClose(false);
    axiosInstance
      .post(`/admin/customers/get-shoppers?${queryParams}`)
      .then((res) => {
        if (res.status < 300) {
          setShoppersData(res.data.data.data);
          setDataPagination(res.data.data.pagination);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError(
            'Unable to fetch shoppers record, please try again later'
          );
        }
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.search, reload]);
  useEffect(() => {
    const obValue = {
      target: {
        name: 'dateFrom',
        value: startDate,
      },
    };
    const obValue1 = {
      target: {
        name: 'dateTo',
        value: endDate,
      },
    };

    handleFilterChange('dateFrom', obValue.target.value);
    handleFilterChange('dateFrom', obValue1.target.value);
  }, [startDate, endDate]);

  const processLocation = (e) => {
    const value = {
      target: {
        name: 'location',
        value: e.formatted_address,
      },
    };
    handleFilterChange('location', value.target.value);
  };

  const getInitials = (val) => {
    const splitName = val?.full_name?.split(' ');
    const initials = splitName
      ?.map((name) => name.charAt(0))
      .join('')
      .toUpperCase();
    return initials;
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };
  const blockUser = () => {
    setLoadingState(true);
    axiosInstance
      .post('/admin/customers/block-customer', {
        id: currentData._id,
        type: 'shoppers',
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          notify(
            'You have succesfully blocked ' +
              currentData.full_name +
              '. Access Blacklisted tab to view blocked users'
          );
          handleDialogClose();
          setReload(!reload);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Something went wrong , please try again later');
        }
      })
      .finally(() => setLoadingState(false));
  };

  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <>
      <div className=" px-2">
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={filter.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                placeholder="Search"
                style={{
                  textIndent: '25px',
                  width: '60%',
                }}
                className=" form-control"
              />
            </div>
            <div className="col-6  text-right">
              <button
                onClick={() => setFilterModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #D0D5DD',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                  borderRadius: '4px',
                }}
              >
                <img
                  className=""
                  style={{ display: 'inline', width: '17px' }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '50px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Shoppers List</h5>
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '14px',
                    color: '#4b5563',
                  }}
                >
                  Details of shoppers on FlexDeals
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: '1px solid white',
                        display: 'inline-block',
                        height: 'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('csv')}
                    >
                      CSV
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('excel')}
                    >
                      Excel
                    </button>
                    <button
                      type="button"
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile('pdf')}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`${styles.overflow_table} pt-3 pb-3 pl-0 pr-0`}>
              <table
                className={`${styles.table} table-hover table-striped`}
                style={{ minWidth: '100em' }}
              >
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">S/N</th>
                    <th className="text-muted" style={{ width: '16%' }}>
                      Shopper Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">
                      Phone Number
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">Purchases</th>
                    <th className="text-muted">Stores</th>
                    <th className="text-muted">Brand</th>
                    <th className="text-muted">Ranks</th>
                    <th className="text-muted">Orders</th>
                    <th className="text-muted">Balance</th>
                    <th className="text-muted">Date Registered</th>
                    <th className="text-muted">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {!isPending &&
                    shoppersData?.length > 0 &&
                    shoppersData?.map((el, i) => (
                      <tr className={`${styles.tr} `}>
                        <td>{(currentPage - 1) * itemsPerPage + i + 1}</td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <div className="initials">
                              {el?.profile_image !== null ? (
                                <Avatar
                                  className={`${styles.avatrr} mr-3 float-md-left`}
                                  alt=""
                                  src={el?.profile_image}
                                />
                              ) : (
                                getInitials(el)
                              )}
                            </div>
                            &nbsp; &nbsp; &nbsp;
                            <div className=" ">
                              <p className="mb-0" style={{ fontSize: '14px' }}>
                                <strong>{el?.full_name || '---'}</strong>
                              </p>
                              <span style={{ fontSize: '10px' }}>
                                {el?.email ? el?.email : '---'}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p>{el.phone_number ? el.phone_number : ' ---'}</p>
                        </td>
                        <td>
                          <span>
                            {el?.total_purchases
                              ? Number(el?.total_purchases).toLocaleString()
                              : 0}
                          </span>
                        </td>
                        <td>
                          {el?.stores ? Number(el?.stores).toLocaleString() : 0}
                        </td>
                        <td>
                          <p className="mt-3">{el?.brands}</p>
                        </td>
                        <td>
                          <p className="mt-3">Novice Shopper</p>
                        </td>
                        <td>
                          {el?.orders ? Number(el?.orders).toLocaleString() : 0}
                        </td>
                        <td>
                          ₦
                          {el?.wallet_balance
                            ? Number(
                                el?.wallet_balance?.$numberDecimal
                              ).toLocaleString()
                            : 0}
                        </td>
                        <td>
                          <span>
                            {el?.date_registered
                              ? moment(el?.date_registered).format('DD/MM/YYYY')
                              : '---'}
                          </span>{' '}
                          <br />
                          <small>
                            {el?.date_registered
                              ? moment(el?.date_registered).format('HH:mm')
                              : '---'}{' '}
                            PM
                          </small>
                        </td>
                        <td>
                          <div className="dropleft ">
                            <span
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className={`${styles.action_button} px-3 py-2`}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <img src="/assets/icons/action.svg" alt="" />
                            </span>

                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => viewRecord(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                View
                              </button>
                              <button
                                type="button"
                                onClick={() => processAction(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                Block
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!shoppersData?.length && !isPending && (
              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={dataPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position="fixed"
        top="0"
        left="50%"
        right="0"
        bottom="0"
        transform="translate(-50%, 0%)"
        width="840px !important"
        overflow="auto"
        bgcolor="#FFFF"
        border="1px solid #F5F5F5"
        m="auto"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <div
          className={`${styles.modal_content} mx-auto`}
          style={{ height: '90vh', overflowY: 'auto' }}
        >
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Shoppers Info
            </h5>

            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <span className="text-muted mb-4">Details of Shopper</span>

          {/* className={`${styles.retailer_modal_product_img}`} style={{
                  // backgroundImage: `url('${url}')`,
                  width:"15%",
                  height:"100px"
              }} */}
          <form action="" className="mt-0">
            {viewPending && (
              <BallTriangle
                height={50}
                width={55}
                radius={5}
                color="#53138D"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
            <p className={` label mt-3`}>Shopper's Photo* </p>
            <input
              type="file"
              hidden
              ref={textInput}
              name=""
              onChange={handleFile}
            />
            <div
              className={`${styles.retailer_modal_product_img}`}
              style={{
                // backgroundImage: `url('${url}')`,
                width: '15%',
                height: '100px',
              }}
            >
              <div
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  backgroundColor: 'white',
                }}
                onClick={() => handleButtonClick()}
              >
                <img
                  className={`${styles.retailer_product_image} ${styles.rounded_border}`}
                  src={viewData?.logo || '/assets/icons/profileicon.svg'}
                  alt=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Shopper's Name</label>
                  <TextField
                    type="text"
                    value={viewData?.name}
                    id="loc"
                    placeholder="Benedict James"
                    size="small"
                    readonly
                    fullWidth
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="loc">Email Address:</label>
                  <TextField
                    type="email"
                    value={viewData?.email}
                    readonly
                    id="loc"
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="loc">Phone Number:</label>
                  <TextField
                    value={viewData?.phone_number}
                    readonly
                    type="tel"
                    id="loc"
                    size="small"
                    fullWidth
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="loc">Address:</label>
                  <TextField
                    value={viewData?.address?.address}
                    readonly
                    type="tel"
                    id="loc"
                    placeholder=""
                    size="small"
                    fullWidth
                  />
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Wallet Balance:</label>
                  <h5 className="mt-2">
                    ₦
                    {viewData?.wallet_balance?.$numberDecimal
                      ? Number(
                          viewData?.wallet_balance?.$numberDecimal ||
                            viewData?.wallet_balance
                        ).toLocaleString()
                      : 0}
                  </h5>
                </div>

                <label htmlFor="price">Brands </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: '100%' }}>
                    {viewData?.brands.length < 1 && (
                      <div className="alert alert-[#53138D] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}
                    {viewData?.brands.slice(0, 3).map((el, i) => (
                      <span className={`${styles.brands} mb-2`} key={i}>
                        {el}
                      </span>
                    ))}

                    {viewData?.brands.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: '13px',
                          }}
                        >
                          +
                          {viewData &&
                            viewData.brands &&
                            viewData?.brands.length - 3}
                        </span>
                        <span
                          onClick={() => [
                            setViewBrands(true),
                            handleViewMore(viewData?.brands),
                          ]}
                          className={`${styles.view_more} mb-1`}
                        >
                          View More
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <label htmlFor="price">Stores </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: '100%' }}>
                    {viewData?.shops.length < 1 && (
                      <div className="alert alert-[#53138D] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}

                    {viewData?.shops.slice(0, 3).map((el, i) => (
                      <span className={`${styles.brands} mb-1`} key={i}>
                        {el}
                      </span>
                    ))}

                    {viewData?.shops.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: '13px',
                          }}
                        >
                          +
                          {viewData &&
                            viewData.shop &&
                            viewData?.shops.length - 3}
                        </span>
                        <span
                          onClick={() => [
                            setViewStores(true),
                            handleViewMore(viewData?.shops),
                          ]}
                          className={`${styles.view_more}`}
                        >
                          View More
                        </span>
                      </div>
                    )}
                    {/* {
                      viewData.shops.length < 1 && viewData.shops.length
                    } */}
                  </div>
                </div>

                <label htmlFor="price">Products </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: '100%' }}>
                    {viewData?.products.length < 1 && (
                      <div className="alert alert-[#53138D] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}

                    {viewData?.products.slice(0, 3).map((el, i) => (
                      <span className={`${styles.brands} mb-1`} key={i}>
                        {el}
                      </span>
                    ))}

                    {viewData?.products.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: '13px',
                          }}
                        >
                          +
                          {viewData &&
                            viewData.products &&
                            viewData?.products.length - 3}
                        </span>
                        <span
                          onClick={() => [
                            setViewProduct(true),
                            handleViewMore(viewData?.products),
                          ]}
                          className={`${styles.view_more}`}
                        >
                          View More
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-1">
              <button
                type="button"
                onClick={handleClose}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      {/* View Brands Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewBrands}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewBrands}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Brands
                </h5>
                <span
                  onClick={handleCloseViewBrands}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Brands this shopper has bought from
              </p>

              <div className="hold_input4" style={{ width: '50%' }}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchBrands}
                  onChange={handleSearchBrands}
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>

              <div className={`row mt-2`}>
                {filteredData.length > 0 &&
                  filteredData.map((el, i) => {
                    return (
                      <div key={i} className="col-4 mb-3 mt-3">
                        <strong className={`${styles.brands}`}>{el}</strong>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* View Stores Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewStores}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewStores}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Stores
                </h5>
                <span
                  onClick={handleCloseViewStores}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Shops this shopper has bought from
              </p>

              <div className="hold_input4" style={{ width: '50%' }}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchShops}
                  onChange={handleSearchShops}
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>
              <div className={`row mt-2`}>
                {filteredData.length > 0 &&
                  filteredData.map((el, i) => {
                    return (
                      <div key={i} className="col-4 mb-3 mt-3">
                        <strong className={`${styles.brands}`}>{el}</strong>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* View Product Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewProduct}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewProduct}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Products
                </h5>
                <span
                  onClick={handleCloseViewProduct}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                All products sold in this store
              </p>

              <div className="hold_input4" style={{ width: '50%' }}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchProducts}
                  onChange={handleSearchProducts}
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>
              <div className={`row mt-2`}>
                {filteredData.length > 0 &&
                  filteredData.map((el, i) => {
                    return (
                      <div key={i} className="col-6 mb-3 mt-3">
                        <strong className={`${styles.brands}`}>{el}</strong>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* Fiter Modal */}
      <GlobalModal
        open={filterModalOpen}
        onClose={handleCloseViewFilter}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: '19px',
                fontWeight: '700',
              }}
            >
              Filter
            </h5>
            <span onClick={handleCloseViewFilter} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                {/* <div className="mb-3">
                  <label htmlFor="name">Verification</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    required
                    value={filter.verified}
                    onChange={(e) => handleFilterChange('verified', e.target.value)}
                  >

                    <MenuItem value='select'>Select Customer Status</MenuItem>
                    <MenuItem value={true}>Verified</MenuItem>
                    <MenuItem value={false}>Unverified</MenuItem>
                  </Select>
                </div> */}

                {/* <div className="mb-3">
                  <LiveLocation label='Location' passData={processLocation} />
                </div> */}

                <div className="mbf-3">
                  <label htmlFor="name">Ranks</label>
                  <div className=" d-flex">
                    <div className="mb-3" style={{ width: '100%' }}>
                      <div className="categories mr-2 mb-3">
                        <input type="checkbox" name="" className="myCheckbox" />
                        &nbsp; Novice
                      </div>
                      <div className="categories mr-2 mb-3">
                        <input type="checkbox" name="" className="myCheckbox" />
                        &nbsp; Regular
                      </div>
                      <div className="categories mr-2 mb-3">
                        <input type="checkbox" name="" className="myCheckbox" />
                        &nbsp; Occasional
                      </div>
                      <div className="categories mr-2 mb-3">
                        <input type="checkbox" name="" className="myCheckbox" />
                        &nbsp; Elite
                      </div>
                      <div className="categories mr-2 mb-3">
                        <input type="checkbox" name="" className="myCheckbox" />
                        &nbsp; Valuable
                      </div>
                      <div className="categories mr-2 mb-3">
                        <input type="checkbox" name="" className="myCheckbox" />
                        &nbsp; Frequent
                      </div>
                      <div className="categories mr-2 mb-3">
                        <input type="checkbox" name="" className="myCheckbox" />
                        &nbsp; VIP
                      </div>
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Products </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        value={filter.min_products}
                        required
                        type="number"
                        id="ounit"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_products', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.max_products}
                        name="max_products"
                        type="number"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_products', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Brands </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_brands}
                        id="ounit"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_brands', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_brands}
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_brands', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <label htmlFor="ounit">Wallet Balance </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.min_balance}
                        type="number"
                        id="ounit"
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_balance', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_balance}
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_balance', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_order_unit}
                        id="ounit"
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_order_unit}
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Stores </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_stores}
                        name="min_stores"
                        id="ounit"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('min_stores', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: '40px' }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.max_stores}
                        type="number"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === '' ||
                            parseFloat(inputValue) >= 0
                          ) {
                            handleFilterChange('max_stores', inputValue);
                          }
                        }}
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => {
                  handleCloseViewFilter();
                  fetchData();
                }}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed{' '}
                {isPending && (
                  <CircularProgress style={{ color: '#fff' }} size="1rem" />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%' } }}
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Block {currentData.full_name}</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to perform this operation
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={blockUser}
              >
                Block{' '}
                {loadingState && (
                  <CircularProgress style={{ color: '#53138D' }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </>
  );
}
