/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Outlet.module.css"
import {
    Card,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner'
import { useEffect, useState } from "react"
import moment from 'moment'
import ViewAdsRequest from "./modals/viewAdsRequest";
import bus from "src/bus";
import { Pagination } from "src/components";

export default function AdRequest({reload}) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const notifyError = (err) => toast.error(err);
    const [pagination, setPagination] = useState({ total: 0 })
    const [itemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const closeModal = () => {
        setReloadData(!reloadData)
        reload()

    }

    const view = (val) => {
        bus.emit('open-view', val)
    }

    const fetchData = (page = 1, perpage = 10,) => {
        setIsLoading(true);
        axiosInstance.get('/admin/marketing/ads-request/all', {
            params: {
                page,
                perpage,
            }
        })
            .then(res => {
                setData(res.data.data.data)
                setPagination(res.data.data.pagination)

            }).catch(err => {

                if (err) {
                    notifyError(err.response.data.message);

                }
            }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        fetchData()
    }, [reloadData])

    return (
        <>
            <div className=" px-2">
                <div>
                    <Card style={{ marginTop: '10px', borderRadius: '10px' }} className='p-0'>
                        <div className='p-4 d-md-flex justify-content-between'>
                            <div>
                                <h5 className="table_desc_header">
                                    Recent Ads
                                </h5>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <small style={{ fontWeight: '200', fontSize: '12px' }}>
                                        Your Recent Ads history
                                    </small>
                                </div>
                            </div>
                        </div>
                        <Scrollbar>
                            <div className={`table-responsive-sm `}  >
                                <table className={`${styles.table} table-striped `}>
                                    <thead>
                                        <tr className={`${styles.tr} border-top ${styles.thead_tr} pb-3`}>
                                            <td style={{ width: '40%' }}>Description</td>
                                            <td>Brand Name</td>
                                            <td>Price</td>
                                            <td>Status</td>
                                            <td>Date Placed</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {(!isLoading && data?.length > 0) && data?.map((el, i) => (
                                            <tr className={`${styles.tr} `} key={i}>
                                                <td>
                                                    <p>
                                                        {el?.ads_description}
                                                    </p>
                                                </td>
                                                <td>
                                                    {el?.brand_id?.business_name} 
                                                    <br/> 
                                                    {el?.brand_id?.brandID}
                                                </td>
                                                <td>
                                                    ₦{Number(el?.pricing_id?.subscription_fee || 0).toLocaleString()}
                                                </td>
                                                <td>
                                                    {/* <span className="ongoing_badge">{el.status}</span>
                                                    <span className="rejected_badge">{el.status}</span> */}
                                                    {
                                                    el.status === "approved" ? (
                                                        <span className="verified_badge">{el.status}</span>
                                                    ) : el.status === "pending" ? (
                                                        <span className="pending_badge">{el.status}</span>
                                                    ) : el.status === "ongoing" ? (
                                                        <span className="ongoing_badge">{el.status}</span>
                                                    ) : el.status === "rejected" ? (
                                                        <span className="rejected_badge">{el.status}</span>
                                                    ) : (
                                                        <span className="not_verified_badge">{el.status}</span>
                                                    )
                                                    }
                                                </td>
                                                <td>{moment(el?.createdAt).format('DD/MM/YYYY')}</td>
                                                <td>
                                                    <div className="dropleft ">
                                                        <span
                                                            id="dropdownMenuButton" 
                                                            data-toggle="dropdown" 
                                                            aria-haspopup="true" 
                                                            aria-expanded="false"
                                                            className={`${styles.action_button} px-3 py-2`}  style={{
                                                                cursor: 'pointer',
                                                            }}>
                                                            <img src="/assets/icons/action.svg" alt="" />
                                                        </span>
                                                        <div className="dropdown-menu drop-left" 
                                                            aria-labelledby="dropdownMenuButton"
                                                        >
                                                            <button 
                                                                type="button" 
                                                                className="rss dropdown-item btn border-0" 
                                                                onClick={() => view(el)}>
                                                                    View
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-center">
                                {isLoading &&
                                    <BallTriangle
                                        height={50}
                                        width={55}
                                        radius={5}
                                        color="#53138D"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperClass={{}}
                                        wrapperStyle=""
                                        visible={true}
                                    />
                                }
                            </div>
                            {
                                (!data.length && !isLoading) &&

                                <div className="alert alert-[#53138D] text-center">
                                    <h4>No record found</h4>
                                </div>
                            }
                        </Scrollbar>
                        <div>
                            <Pagination
                                currentPage={currentPage}
                                dataPagination={pagination}
                                itemsPerPage={itemsPerPage}
                                setCurrentPage={setCurrentPage}
                                fetchData={fetchData}
                            />
                        </div>
                    </Card>
                </div>
            </div>
            <ViewAdsRequest closeModal={closeModal} />
        </>
    )
}
