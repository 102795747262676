

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { useNavigate, useParams } from "react-router-dom"
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment"
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



export default function UserComments() {
    const navigate = useNavigate();

    const { pageId } = useParams()

    const [searchvalue, setSearchvalue] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [data, setData] = useState([]);
    const [replyData, setReply] = useState('');
    const [reload, setReload] = useState(false);


    const notify = (msg = null) => toast.success(msg === null ? 'Operation successful .' : msg);
    const notifyError = (err) => toast.error(err);




    const searchFilter = (e) => {
        setSearchvalue(e.target.value)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchvalue, reload])

    const fetchData = (page = 1, perpage = 10) => {
        let filter_payload = {}

        if (searchvalue !== '') {
            filter_payload.search = searchvalue
        }


        setIsPending(true);
        let queryParams = new URLSearchParams({ ...filter_payload, page, perpage }).toString();

        axiosInstance.get(`/admin/reviews/feedbacks/view/${pageId}?${queryParams}`)
            .then(res => {
                if (res.status < 300) {
                    setData(res.data.data.data)
                }
            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Something went wrong , please try again later");

                }

            }).finally(() => setIsPending(false))
    }

    const reply = (id) => {

        if (replyData === '') return notifyError('Reply cannot be empty')

        setIsProcessing(true);

        axiosInstance.post(`/admin/reviews/feedbacks/reply/${id}`, { reply: replyData })
            .then(res => {
                if (res.status < 300) {
                    setReply('')
                    notify(res.data.message)
                    setReload(!reload)
                }

            }).catch(err => {
                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to process data, try again later");

                }

            }).finally(() => setIsProcessing(false))
    }
    const toUserFeedback = () => {
        navigate(-1);
    };
    return (
        <>
            <div className="px-2">
                <div className={`${styles.filters} mt-4`}>
                    <div className="row">
                        <div className={`${styles.hold_input} col-6   `}>
                            <img src="/assets/icons/search.svg" alt="" />
                            <input 
                                type="text" 
                                value={searchvalue} 
                                onChange={searchFilter} 
                                placeholder="Search" 
                                style={{ 
                                    textIndent: '25px', 
                                    width: '60%' 
                                }} 
                                className=" form-control" 
                            />
                        </div>
                        <div className="col-6  text-right">
                            <div 
                                className="mb-3 p-2 border rounded d-inline-block cursor_pointer" 
                                onClick={toUserFeedback}
                            >
                                <img 
                                    src="/assets/icons/Chevronleft.svg" 
                                    className="pr-1" 
                                    alt="" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Scrollbar>
                    <div className="py-4">
                        {
                            !isPending && data?.length > 0 && data[0]?.users?.map((el, i) => (
                                <div className={`${styles.accordion_wrap} mb-3`} key={i} >
                                    <Accordion >
                                        <AccordionSummary
                                            className={`${styles.acc_summary}`}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div className='d-flex'>
                                                <div>
                                                    <Avatar 
                                                        src={el?.image}
                                                        alt="" 
                                                        style={{ 
                                                            width: '50px', 
                                                            height: '50px', 
                                                            borderRadius: '15px' 
                                                        }} 
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <h5 
                                                        className="font-weight-bold" 
                                                        style={{ 
                                                            fontSize: '18px' 
                                                        }}
                                                    >
                                                        {el?.name}
                                                        <small 
                                                            className="text-muted font-weight-bold ml-3 mt-3" 
                                                            style={{ 
                                                                fontSize: '11px' 
                                                            }}
                                                        >
                                                            <span>
                                                                {moment(el?.date).format('hh:mm A')}
                                                            </span>
                                                            <span className="ml-2">
                                                                {moment(el?.date).format('DD/MM/YYYY')}
                                                            </span>
                                                        </small>
                                                        <p className="mt-1" style={{fontSize: '14px'}}>
                                                            <small>
                                                                {el?.email}
                                                            </small>
                                                        </p>
                                                    </h5>
                                                    <p 
                                                        className="mb-0 text-muted" 
                                                        style={{ 
                                                            fontSize: '14px' 
                                                        }}
                                                    >
                                                        {el?.about_it || 'No comment'}.
                                                    </p>
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='px-2 pb-0 mb-0 pl-5'>
                                                <div className="pl-5">
                                                    <TextField
                                                        required
                                                        id="i"
                                                        value={replyData}
                                                        onChange={(e) => setReply(e.target.value)}
                                                        name='reply'
                                                        placeholder='Type your response'
                                                        size="small"
                                                        multiline
                                                        rows='5'
                                                        cols='5'
                                                        fullWidth
                                                    />
                                                    <p className="mt-3">
                                                        <button 
                                                            className="btn mr-2 rounded px-4" 
                                                            style={{ 
                                                                border: '1px solid grey', 
                                                                backgroundColor: 'transparent' 
                                                            }} 
                                                            onClick={() => setReply('')}
                                                        >
                                                            Cancel
                                                        </button>
                                                        {
                                                            !isProcessing &&
                                                            <button 
                                                                className="btn mx-2 rounded border-0 text-white px-4" 
                                                                style={{ 
                                                                    backgroundColor: 'royalblue' 
                                                                }} 
                                                                type="button" 
                                                                onClick={() => reply(el._id)}
                                                            >
                                                                Send
                                                            </button>
                                                        }
                                                        {isProcessing &&
                                                            <button 
                                                                type="submit" 
                                                                disabled 
                                                                className={`btn mx-2 rounded border-0 text-white px-4`} 
                                                                style={{ 
                                                                    backgroundColor: 'royalblue' 
                                                                }}
                                                            >
                                                                <CircularProgress 
                                                                    style={{ 
                                                                        color: 'white' 
                                                                    }} 
                                                                    size="1rem" 
                                                                />
                                                            </button>


                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                </div>

                            ))
                        }


                    </div>
                    <div className="d-flex justify-content-center">
                        {isPending && (
                            <BallTriangle
                                height={50}
                                width={55}
                                radius={5}
                                color="#53138D"
                                ariaLabel="ball-triangle-loading"
                                wrapperClass={{}}
                                wrapperStyle=""
                                visible={true}
                            />
                        )}
                    </div>
                    {!data.length && !isPending && (
                        <div className="alert alert-[#53138D] text-center">
                            <h4>No record found</h4>
                        </div>
                    )}
                </Scrollbar>
            </div>

        </>
    );
}
