// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Card, Avatar } from "@mui/material";
import Scrollbar from "../../../components/scrollbar";
// sections
// mock
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createRef } from "react";
import { Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import axiosInstance from "src/axiosInstance";
import toast from "react-hot-toast";
import moment from "moment";
import { BallTriangle } from "react-loader-spinner";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateFormat from "src/hooks/useDateFormat";
import LiveLocation from "src/pages/LiveLocation";
import { downloadFile3 } from "src/utils";
import { Pagination } from "src/components";

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F5F5F5",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
};



export default function DashboardAppPage() {
  let textInput = createRef();
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
      handleProductChange("product_image", e.target.files[0]);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [isPending, setIsPending] = useState("");
  const [storeOwnersData, setStoreOwnersData] = useState([]);
  const [dataPagination, setDataPagination] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openVerify, setOpenVerify] = useState(false);
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const handleOpenVerify = () => setOpenVerify(false);
  const [exportLoading, setExportLoading] = useState(false)
  const [searchBrands, setSearchBrands] = useState('');
  const [searchCategories, setSearchCategories] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [openDialog, setDiologOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [reload, setReload] = useState(false);
  const handleClose = () => {
    setModalOpen(false);
  };
  const [viewBrands, setViewBrands] = useState(false);
  const [viewCategories, setViewCategories] = useState(false);
  const [viewPending, setViewPending] = useState(false);
  const [currentData, setcurrentData] = useState({});
  const [viewData, setViewData] = useState({
    shops: [],
    products: [],
    productcategories: [],
    brands: [],
    address: {},
    wallet_balance: {},
    revenueData: {
      bold:0,
      small:0,
      percentagDiff:0,
    }
  });
  const [brandsVal, setBrandsVal] = useState(viewData?.brands || []);
  const [filteredData, setFilteredData] = useState(viewData?.brands || []);
  const [filteredCategoryData, setFilteredCategoryData] = useState(viewData?.productcategories.map(el => el.category_name) || []);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filter, setFilter] = useState({
    verified: 'select',
    dateFrom: '',
    dateTo: '',
    min_categories: '',
    max_categories: '',
    min_balance: '',
    max_balance: '',
    min_order_unit: '',
    max_order_unit: '',
    min_brands: '',
    max_brands: '',
    min_stores: '',
    max_stores: '',
    location: '',
    search: '',
  })

  const notify = (msg = null) =>
    toast.success(msg === null ? "Store saved ." : msg);
  const notifyError = (err) => toast.error(err);
  // eslint-disable-next-line no-unused-vars
  const [newProducts, setNewProducts] = useState({
    notifications: [],
    product_name: "",
    product_image: null,
    price_per_case: "",
    price_per_product: "",
    number_of_cases: "",
    products_in_case: "",
    description: "",
    cases_type: "Cartons",
    category_id: "",
  });

  const handleViewMore = (val) => {
    setBrandsVal(val)
    setFilteredCategoryData(val)
    
  };

  const handleSearchBrands = (e) => {
    const query = e.target.value;
    setSearchBrands(query);

    const filteredResults = (viewData?.brands || []).filter((el) =>
      el.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const handleSearchCategories = (e) => {
    const query = e.target.value;
    setSearchCategories(query);

    const filteredResults = (viewData?.productcategories || []).filter((el) =>
      el.category_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCategoryData(filteredResults);
  };

  const handleProductChange = (key, value) => {
    setAnchorEl1(null);
    setNewProducts((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleCloseViewBrands = () => {
    setViewBrands(false);
  };
  const handleCloseViewCategories = () => {
    setViewCategories(false);
  };
  const handleButtonClick = () => {
    textInput.current.click();
  };

  const handleCloseViewFilter = () => {
    setFilterModalOpen(false);
  };
  useLayoutEffect(
    () => () => {
      localStorage.removeItem("ptvp");

      // Your code here.
    },
    []
  );

  const handleFilterChange = (key, value) => {
    setFilter((prev) => {
        return {
            ...prev,
            [key]: value,
        };
      }
    );
  };

  const processAction = (val) => {
    setcurrentData(val);
    setDiologOpen(true);
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const viewRecord = (el) => {
    setModalOpen(true);
    setViewData({
      shops: [],
      productcategories: [],
      products: [],
      brands: [],
      address: {},
      wallet_balance: {},
      revenueData: {
        bold:0,
        small:0,
        percentagDiff:0,
      }
    });

    setViewPending(true);
    axiosInstance
      .post("/admin/customers/view-storeowner", {
        id: el._id,
      })
      .then((res) => {
        if (res.status < 300) {
          // console.log(res.data.data);
          setViewData(res.data.data);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Something went wrong , please try again later");
        }
      })
      .finally(() => setViewPending(false));
  };

  const blockUser = () => {
    setLoadingState(true);
    axiosInstance
      .post("/admin/customers/block-customer", {
        id: currentData._id,
        type: "storeowners",
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.status < 300) {
          notify(
            "You have succesfully blocked " +
            currentData.store_name +
            ". Access Blacklisted tab to view blocked users"
          );
          handleDialogClose();
          setReload(!reload);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Something went wrong , please try again later");
        }
      })
      .finally(() => setLoadingState(false));
  };

  const handleVerifyDialog = () => {
    setOpenVerify(true)
  }

  const handleDownloadFile = async (extension) => {
    setExportLoading(true)
    try {
      const result = `/admin/customers/get-storeowners?page=${dataPagination.currentPage}&perpage=${dataPagination.total}&export_type=${extension}`
      await downloadFile3('Customer - Stores', result, extension);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setExportLoading(false); 
    }
  };


  const fetchData = (page = 1, pageSize = 10 ) => {

    const filterObject = { ...filter, page, perpage: pageSize };
    if (filterObject.verified === 'select') filterObject.verified = ''
    if (filterObject.dateFrom) {
      const dValue = DateFormat(filterObject.dateFrom);
      filterObject.dateFrom = dValue

    } else {
      filterObject.dateFrom = ''
    }
    if (filterObject.dateTo) {
      const dValue = DateFormat(filterObject.dateTo)
      filterObject.dateTo = dValue

    } else {
      filterObject.dateTo = ''
    }
    let queryParams = new URLSearchParams({ ...filterObject }).toString();

    setIsPending(true);
    handleClose(false);

    axiosInstance
      .post(
        `/admin/customers/get-storeowners?${queryParams}`
      )
      .then((res) => {
        if (res.status < 300) {
          setStoreOwnersData(res.data.data.data);
        }
        setDataPagination(res.data.data.pagination);

      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Something went wrong , please try again later");
        }
      })
      .finally(() => setIsPending(false));
  };
  const verifyUser = () => {
    setLoadingState(true);
    handleClose(false);

    axiosInstance
      .post(
        `/admin/customers/verify`,
        {
          id: currentData._id,
          type: 'storeowners'
        }
      )
      .then((res) => {
        if (res.status < 300) {
          setOpenVerifyDialog(false)
          notify(res.data.message)
          handleVerifyDialog()
          setReload(!reload)

        }

      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Something went wrong , please try again later");
        }
      })
      .finally(() => setLoadingState(false));

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.search, reload]);
  useEffect(() => {

    handleFilterChange('dateFrom', startDate)
    handleFilterChange('dateTo', endDate)

  }, [startDate, endDate])

  const processLocation = (e) => {
    handleFilterChange('location', e.formatted_address)
    handleFilterChange('latitude', e.lat)
    handleFilterChange('longitude', e.lng)
  }
  return (
    <>
      <div className="px-2">
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={filter.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                placeholder="Search"
                style={{ textIndent: "25px", width: "60%" }}
                className=" form-control"
              />
            </div>
            <div className="col-6  text-right">
              <button
                onClick={() => setFilterModalOpen(true)}
                className="btn  border"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #D0D5DD",
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "4px",
                }}
              >
                <img
                  className=""
                  style={{ display: "inline", width: "17px" }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: "50px", borderRadius: "10px" }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Store Owners</h5>
                <small style={{ fontWeight: "200", fontSize: "14px" }}>
                  Details of store owners on FlexDeals
                </small>
              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`${styles.export_btn} btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: "8px",
                      }}
                    >
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp; &nbsp;
                    <span
                      style={{
                        borderLeft: "1px solid white",
                        display: "inline-block",
                        height: "inherit",
                        paddingTop: "8px",
                        paddingLeft: "8px",
                        textAlign: "center",
                      }}
                    >
                      <img src="/assets/icons/chevron-downblue.svg" alt="" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu drop-left"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button 
                      type="button" 
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile("csv")}
                    >
                      CSV
                    </button>
                    <button 
                      type="button" 
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile("excel")}
                    >
                      Excel
                    </button>
                    <button 
                      type="button" 
                      className="rss dropdown-item btn border-0"
                      onClick={() => handleDownloadFile("pdf")}
                    >
                      PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`pt-3 pb-3 pl-0 pr-0`}  >
              <table className={`${styles.table} table-hover table-striped `} style={{ minWidth: '100em', }}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">S/N</th>
                    <th className="text-muted" style={{width: '16rem'}}>
                      Store Owner
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted">
                      Email

                    </th>
                    <th className="text-muted">
                      Phone Numbers

                    </th>
                    <th className="text-muted">
                      Status

                    </th>
                    <th className="text-muted">
                      Total Sales

                    </th>
                    <th className="text-muted">
                      Balance

                    </th>
                    <th className="text-muted">
                      Date Register

                    </th>
                    <th className="text-muted">
                      Action

                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(!isPending && storeOwnersData.length >= 1) && storeOwnersData.map((el, i) => (
                    <tr className={`${styles.tr} `}>
                      <td>
                      {(currentPage - 1) * itemsPerPage + i + 1}
                        {/* {(currentPage - 1) * itemsPerPage + i + 1} */}
                      </td>
                      <td>
                        <div className="d-flex justify-content-start align-items-center">
                          <Avatar 
                            className={`${styles.avatrr} mr-3 float-md-left`} 
                            alt="" src={el?.store_logo} 
                          />

                          <div className="">
                            <p className="mb-0" style={{ fontSize: '14px', whiteSpace: 'wrap' }}>
                            {
                              el?.onboarded_by  &&
                                <small 
                                  className="px-2 py-0 rounded text-[#53138D] d-block" 
                                  style={{
                                    width: 'fit-content',
                                    fontSize: '11px', 
                                    background: '#E8F3FB', letterSpacing: '1px'}}>
                                      Invite
                                </small>
                              }
                              {el?.store_name ? el.store_name : '---'}
                            </p>
                            <span>
                              {el?.storeID ? el.storeID : '---'}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p >
                          {el?.email ? el.email : '---'}
                        </p>
                      </td>
                      <td>
                        <p>
                          {el?.phone_number ? el.phone_number : '---'}
                        </p>
                      </td>
                      <td>
                        <span className="">
                          {el?.is_verified === true ? (
                            <span className="verified_badge">
                              <img src="/assets/icons/check-circlel.svg" alt="" />
                              Verified
                            </span>
                          ) : el?.is_verified === false ? (
                            <span className="not_verified_badge">
                              Not Verified
                            </span>
                          ) : (
                            <span className="pending_verified_badge">
                              {el?.is_verified}
                            </span>
                          )
                          }
                        </span>
                      </td>
                      <td>
                        {el?.total_sales ? el.total_sales : 0}
                      </td>
                      <td>
                        ₦{el?.wallet_balance?.$numberDecimal ? Number(el.wallet_balance?.$numberDecimal).toLocaleString() : 0}
                      </td>
                      <td>
                        <span>
                          {el?.date_registered ? moment(el?.date_registered).format('DD/MM/YYYY') : 'NOT SET'}
                        </span> <br />
                        <small>{el?.date_registered ? moment(el?.date_registered).format('HH:mm') : 'NOT SET'} PM</small>
                      </td>
                      <td>
                        <div className="dropleft ">
                          <span
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                            className={`${styles.action_button} px-3 py-2`}  style={{
                              cursor: 'pointer',

                            }}>
                            <img src="/assets/icons/action.svg" alt="" />

                          </span>

                          <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                            <button type="button" onClick={() => viewRecord(el)} className="rss dropdown-item btn border-0">View</button>
                            {
                              !el?.is_verified && (
                                <button type="button" onClick={() => {
                                  setcurrentData(el);
                                  setOpenVerifyDialog(true);
                                }} className="rss dropdown-item btn border-0">Verify</button>
                              )
                            }
                            <button type="button" onClick={() => processAction(el)} className="rss dropdown-item btn border-0">Block</button>

                            <div
                              className="dropdown-menu drop-left"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                type="button"
                                onClick={() => viewRecord(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                View
                              </button>
                              <button
                                type="button"
                                onClick={() => processAction(el)}
                                className="rss dropdown-item btn border-0"
                              >
                                Block
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!storeOwnersData.length && !isPending && (
              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
              <Pagination
                  currentPage={currentPage}
                  dataPagination={dataPagination}
                  itemsPerPage={itemsPerPage}
                  setCurrentPage={setCurrentPage}
                  fetchData={fetchData}
              />
          </div>
        </Card>
      </div>
      {/* Fiter Modal */}
      <GlobalModal
        open={filterModalOpen}
        onClose={handleCloseViewFilter}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: "19px",
                fontWeight: "700",
              }}
            >
              Filter
            </h5>
            <span onClick={handleCloseViewFilter} style={{ cursor: "pointer" }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="name">Verification</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={filter.verified}
                    onChange={(e) => handleFilterChange('verified', e.target.value)}
                  >
                    <MenuItem value="select">Select Customer Status</MenuItem>
                    <MenuItem value={true}>Verified</MenuItem>
                    <MenuItem value={false}>Unverified</MenuItem>
                  </Select>
                </div>

                <div className="">
                  <div className="mb-3">
                    <LiveLocation label='Location' passData={processLocation} />
                  </div>
                </div>

                <label htmlFor="ounit">Brands </label>
                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_brands}
                        id="ounit"
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('min_brands', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: "40px" }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_brands}
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('max_brands', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: "40px" }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Wallet Balance </label>
                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.min_balance}
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('min_balance', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: "40px" }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.max_balance}
                        type="number"
                        placeholder="Max "
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('max_balance', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.min_order_unit}
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('min_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: "40px" }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_order_unit}
                        placeholder="Max "
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('max_order_unit', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">Product Categories </label>
                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        value={filter.min_categories}
                        type="number"
                        id="ounit"
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('min_categories', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: "40px" }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        value={filter.max_categories}
                        placeholder=""
                        size="small"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) >= 0) {
                            handleFilterChange('max_categories', inputValue);
                          }
                        }}
                        inputProps={{
                            inputMode: 'decimal',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                e.preventDefault();
                            }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="name">Product</label> <div></div>
                  {productLists.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input onClick={(e) => handleProductChange(e, el.name)} type="checkbox" value={el.name} name="" className="myCheckbox" />
                      &nbsp;
                      {el.name}


                    </div>

                  ))}


                </div>
              </div> */}
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={() => {
                  handleCloseViewFilter();
                  fetchData()
                }}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed{" "}
                {isPending && (
                  <CircularProgress style={{ color: "#fff" }} size="1rem" />
                )}
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      {/* View Modal */}
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='fixed'
        top='0'
        left='50%'
        right='0'
        bottom='0'
        transform='translate(-50%, 0%)'
        width='840px !important'
        overflow='auto'
        bgcolor='#FFFF'
        border='1px solid #F5F5F5'
        m='auto'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <div className={`${styles.modal_content} mx-auto`} style={{height: '90vh', overflowY: 'auto'}}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: "19px",
                fontWeight: "700",
              }}
            >
              Store Info
            </h5>

            <span onClick={handleClose} style={{ cursor: "pointer" }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>
          <span className="text-muted mb-4">Details of Shop</span>
          <form action="" className="mt-0">
            {viewPending && (
              <BallTriangle
                height={50}
                width={55}
                radius={5}
                color="#53138D"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            )}
            <p className={` label mt-3`}>Manager's Photo* </p>
            <div className="row">
              <div className="col-md-6">
                {/* <input
                  type="file"
                  hidden
                  ref={textInput}
                  name=""
                  onChange={handleFile}
                /> */}
                <div
                  className={`${styles.retailer_modal_product_img}`}
                  style={{
                    // backgroundImage: `url('${url}')`,
                    width: "30%",
                    height: "100px",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      backgroundColor: "white",
                    }}
                  >
                    <img
                      className={`${styles.retailer_product_image} ${styles.rounded_border}`}
                      src={viewData.logo}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={` mb-2 pr-0 float-right`}
                  style={{
                    width: "60%",
                  }}
                >
                  <div className={`${styles.card} mr-5`}>
                    <div className={`card-body`}>
                      <div className="d-flex justify-content-between">
                        <small>Total Revenue</small>
                      </div>
                      <div className={`${styles.numbers}`}>
                        <div>
                          <span>
                            ₦{viewData.revenueData.bold ? 
                            Number(viewData.revenueData.bold).toLocaleString() :
                            0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Manager's Name</label>
                  <TextField
                    value={viewData?.name}
                    type="text"
                    id="loc"
                    placeholder=""
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="loc">Email Address:</label>
                  <TextField
                    value={viewData?.email}
                    type="email"
                    id="loc"
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="loc">Phone Number:</label>
                  <TextField
                    value={viewData?.phone_number}
                    type="tel"
                    id="loc"
                    placeholder="+234"
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="loc">Location:</label>
                  <TextField
                    value={viewData?.address?.address}
                    type="email"
                    id="loc"
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Wallet Balance:</label>
                  <div>
                    <b>
                      ₦ { 
                          viewData?.wallet_balance?.$numberDecimal ?
                          Number(viewData?.wallet_balance?.$numberDecimal || viewData?.wallet_balance).toLocaleString() :
                          0
                        }
                    </b>
                  </div>
                </div>

                <label htmlFor="price">Category </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: "100%" }}>
                    {!viewData?.productcategories?.length  && (
                      <div className="alert alert-[#53138D] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}

                    {viewData?.productcategories.length > 0 && viewData?.productcategories.slice(0, 3).map((el, i) => (
                      <span className={`${styles.productcategories} categories ${el?.is_deleted ? 'text-muted' : 'text-dark'}`} key={i}>
                        {el?.category_name}
                      </span>
                    ))}

                    {viewData?.productcategories.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          +{viewData.productcategories.length -3}
                        </span>
                        <span
                          onClick={() => [setViewCategories(true), setFilteredCategoryData(viewData?.productcategories.map(el => el.category_name))]}
                          className={`${styles.view_more}`}
                        >
                          View More
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <label htmlFor="price">Brands </label>
                <div className=" d-flex">
                  <div className="mb-3" style={{ width: "100%" }}>
                    {viewData?.brands?.length < 1 && (
                      <div className="alert alert-[#53138D] text-center">
                        <h6>No record found</h6>
                      </div>
                    )}

                    {viewData?.brands?.slice(0, 3).map((el, i) => (
                      <span className={`${styles.brands}`} key={i}>
                        {el?.business_name}
                      </span>
                    ))}

                    {viewData?.brands?.length > 3 && (
                      <div>
                        <span
                          className="text-muted ml-2"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          +{viewData?.brands?.length -3}
                        </span>
                        <span
                          onClick={() => [setViewBrands(true), setFilteredData(viewData?.brands)]}
                          className={`${styles.view_more}`}
                        >
                          View More
                        </span>
                      </div>
                    )}
                    {/* {
                      viewData?.brands.length < 1 && viewData?.brands.length
                    } */}
                  </div>
                </div>
                {viewData?.business_image && (
                  <img
                    src={viewData?.business_image}
                    width={"100%"}
                    height={"200px"}
                    alt="shop"
                  />
                )}
              </div>
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleClose}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openVerify}
        onClose={handleOpenVerify}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openVerify}>
          <Box className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`} sx={style}>
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Store Verified</h5>
              <div>
                <button className={`${styles.go_to} d-block w-100 `} style={{ fontSize: '14px' }} onClick={() => setOpenVerify(false)}>
                  Confirm
                </button>
              </div>

            </div>
          </Box>
        </Fade>
      </Modal>

      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="">
                <img
                  style={{ width: "40px" }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Block {currentData.store_name}</h4>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#6B7280",
                  }}
                >
                  Are you sure you want to perform this operation
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={blockUser}
              >
                Block{" "}
                {loadingState && (
                  <CircularProgress style={{ color: "#53138D" }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        open={openVerifyDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenVerifyDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="">
                <img
                  style={{ width: "40px" }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Verify  {currentData.store_name}</h4>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#6B7280",
                  }}
                >
                  Are you sure you want to perform this operation
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={() => setOpenVerifyDialog(false)}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={verifyUser}
              >
                Verify{" "}
                {loadingState && (
                  <CircularProgress style={{ color: "#53138D" }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>

      {/* View Brands Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewBrands}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewBrands}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Brands
                </h5>
                <span
                  onClick={handleCloseViewBrands}
                  style={{ cursor: "pointer" }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: "14px",
                }}
              >
                Brands this store has bought from
              </p>

              <div className="hold_input4" style={{ width: "50%" }}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchBrands}
                  onChange={handleSearchBrands}
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>
              <div className={`row mt-2`} >
                {
                  filteredData && filteredData.map((el,i) => {
                    return (
                      <div key={i} className="col-4 mb-3 mt-3">
                        <strong className={`${styles.brands}`}>
                        {el?.business_name}
                        </strong>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* View Categories Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewCategories}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={viewCategories}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Categories
                </h5>
                <span
                  onClick={handleCloseViewCategories}
                  style={{ cursor: "pointer" }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: "14px",
                }}
              >
                Categories this store has bought from
              </p>

              <div className="hold_input4" style={{ width: "50%" }}>
                <img src="/assets/icons/search.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchCategories}
                  onChange={handleSearchCategories}
                  style={{ textIndent: '25px', width: '100%' }}
                  className=" form-control"
                />
              </div>
              <div className={`row mt-2`} >
                {
                  filteredCategoryData.length > 0 && filteredCategoryData.map((el,i) => {
                    return (
                      <div key={i} className="col-4 mb-3 mt-3">
                        <strong className={`${styles.brands}`}>
                        {el}
                        </strong>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
