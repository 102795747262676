// ----------------------------------------------------------------------
import styles from '../BrandOwner.module.css';
import ReactApexChart from 'react-apexcharts';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

import { Card, CardContent, Avatar } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import GlobalModal from 'src/Modals/GlobalModal';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import bus from 'src/bus';
import Filter2 from 'src/pages/Filter/Filter2';
import Filter3 from 'src/pages/Filter/Filter3';
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import { GoogleMapHeatmap } from '../../../components';
// import HeatmapGrid from 'react-heatmap-grid';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const notifyError = (err) => toast.error(err);
  const xLabels = [];
  const yLabels = [];
  const [rvModalOpen, setRvModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hpModalOpen, setHpModalOpen] = useState(false);
  const [oblModalOpen, setOBLModalOpen] = useState(false);
  const [toAndCoModalOpen, setToAndCoModalOpen] = useState(false);
  const [msModalOpen, setMsModalOpen] = useState(false);
  const [sbpModalOpen, setSbpModalOpen] = useState(false);
  const [dataFilter, setDataFilter] = useState('alltime');
  const [chartFilter, setChartFilter] = useState('alltime');
  const [cardLoader, setCardLoader] = useState(false);
  const [categoryPending, setCategoryPending] = useState(false);
  const [searchCategory, setSearchCategory] = useState('');
  const [searchProducts, setSearchProducts] = useState('');
  const [searchBrands, setSearchBrands] = useState('');
  const [searchStates, setSearchStates] = useState('');
  const [searchStates2, setSearchStates2] = useState('');
  const [searchStates3, setSearchStates3] = useState('');
  const [country, setCountry] = useState('Nigeria');
  const [categoriesData, setCategoriesData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productCatData, setProductCatData] = useState([]);
  const [catProductData, setCatProductData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState('');
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedProductCatIds, setSelectedProductCatIds] = useState([]);
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const [orderHeatMapData, setOrderHeatMapData] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedState2, setSelectedState2] = useState('');
  const [selectedState3, setSelectedState3] = useState('');
  const [modalVal, setModalVal] = useState('');
  const [selectAllBrand, setSelectAllBrand] = useState(false);
  const [selectAllProduct, setSelectAllProduct] = useState(false);
  const [selectAllProductCategory, setSelectAllProductCategory] =
    useState(false);
  const [selectAllStates, setSelectAllStates] = useState(false);
  const [isProductPending, setIsProductPending] = useState(false);
  const [isCategoryPending, setIsCategoryPending] = useState(false);
  const [isProductCatPending, setIsProductCatPending] = useState(false);
  const [isBrandPending, setIsBrandPending] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  // const [isPending, setIsPending] = useState(false);
  const [isShopperStatPending, setIsShopperStatPending] = useState(false);
  const [isPurchasedProductPending, setIsPurchasedProductPending] =
    useState(false);
  const [isProductSalesPending, setIsProductSalesPending] = useState(false);
  const [isBrandSignupPending, setIsBrandSignupPending] = useState(false);
  const [isShopSignupPending, setIsShopSignupPending] = useState(false);
  const [isShoppersSignupPending, setIsShoppersSignupPending] = useState(false);
  const [isAverageRevPending, setIsAverageRevPending] = useState(false);
  const [isTrendsOfPurchasePending, setIsTrendsOfPurchasePending] =
    useState(false);
  const [isRetentionRatePending, setIsRetentionRatePending] = useState(false);
  const [isMarketSharePending, setIsMarketSharePending] = useState(false);
  const [isOBLPending, setIsOBLPending] = useState(false);
  const [isOrderHeatMapPending, setIsOrderHeatMapPending] = useState(false);
  const [categoryProductId, setCategoryProductId] = useState('');
  const [cardsData, setCardsData] = useState({
    abandonOrder: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    completedOrder: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    grossMerchandiseValue: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    netMerchandiseValue: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
    totalOrder: {
      boldCount: 0,
      percentageDiff: 0,
      smallCount: 0,
    },
  });

  const allChartData = [
    {
      value: 'active_inactive_store_stat',
      name: 'Active User/Inactive Store Stats',
    },
    {
      value: 'active_inactive_user_stat',
      name: 'Active User/Inactive User Stats',
    },
    {
      value: 'active_inactive_brand_stat',
      name: 'Active User/Inactive Brand Stats',
    },
    { value: 'p_p_b_s_v', name: 'Purchased product by SKU variation' },
    // {value: 'p_p', name: 'Product Purchased'},
    { value: 'b_s_s', name: 'Brand Sign Up Stats' },
    { value: 's_s_s', name: 'Shop Owner Sign Up Stats' },
    { value: 'sh_s_s', name: 'Shoppers Owner Sign Up Stats' },
    // {value: 't_t_r', name: 'Time to Resolution'},
    // {value: 'r_r', name: 'Recurring Revenue'},
    { value: 'a_r_p_s', name: 'Average Revenue per Shopper' },
    { value: 't_o_p', name: 'Trends of Purchases' },
    { value: 'r_rate', name: 'Retention Rate' },
    { value: 'm_s', name: 'Market Share' },
    { value: 'o_b_l', name: 'Order by location' },
    // {value: 'a_r_p_b', name: 'Average Revenue per Brand'},
    // {value: 'a_r_p_s_o', name: 'Average Revenue per Shop Owner'},
    // {value: 'b_r', name: 'Bounce Rate'},
  ];

  const handleCloseRvModal = () => {
    setRvModalOpen(false);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const handleCloseHpModal = () => {
    setHpModalOpen(false);
    setSearchStates3('');
  };

  const handleModalVal = (val) => {
    setHpModalOpen(true);
    setModalVal(val);
  };

  const handleCloseOBLModal = () => {
    setOBLModalOpen(false);
    setSearchStates3('');
  };

  const handleCloseToAndCoModal = () => {
    setToAndCoModalOpen(false);
  };
  const handleCloseMsModal = () => {
    setMsModalOpen(false);
    setSearchStates3('');
  };
  const handleCloseSbpModal = () => {
    setSbpModalOpen(false);
  };

  // ==============================  For tabs  ================================================
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTab01, setSelectedTab01] = useState(1);
  const [selectedTab1, setSelectedTab1] = useState(1);
  const [selectedTab2, setSelectedTab2] = useState(1);
  const [selectedTab3, setSelectedTab3] = useState(1);
  const [selectedTab4, setSelectedTab4] = useState(1);
  const [selectedTab5, setSelectedTab5] = useState(1);
  const [selectedTab6, setSelectedTab6] = useState(1);
  const [selectedTab7, setSelectedTab7] = useState(1);
  const [selectedTab8, setSelectedTab8] = useState(1);
  const [selectedTab9, setSelectedTab9] = useState(1);
  const [selectedTab10, setSelectedTab10] = useState(1);
  const [selectedTab11, setSelectedTab11] = useState(1);
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleTabChange01 = (tab) => {
    setSelectedTab01(tab);
  };
  const handleTabChange1 = (tab) => {
    setSelectedTab1(tab);
  };
  const handleTabChange2 = (tab) => {
    setSelectedTab2(tab);
  };
  const handleTabChange3 = (tab) => {
    setSelectedTab3(tab);
  };
  const handleTabChange4 = (tab) => {
    setSelectedTab4(tab);
  };
  const handleTabChange5 = (tab) => {
    setSelectedTab5(tab);
  };
  const handleTabChange6 = (tab) => {
    setSelectedTab6(tab);
  };
  const handleTabChange7 = (tab) => {
    setSelectedTab7(tab);
  };
  const handleTabChange8 = (tab) => {
    setSelectedTab8(tab);
  };
  const handleTabChange9 = (tab) => {
    setSelectedTab9(tab);
  };
  const handleTabChange10 = (tab) => {
    setSelectedTab10(tab);
  };
  const handleTabChange11 = (tab) => {
    setSelectedTab11(tab);
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [
        'Nigeria',
        'Ghana',
        'South Africa',
        'Egypt',
        'Benin',
        'Liberia',
        'Togo',
        'Sierrelone',
        'Senegal',
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Order',
      data: [900, 550, 800, 810, 810, 600, 400, 400],
    },
    {
      name: 'Depletion',
      data: [800, 700, 600, 500, 800, 700, 650, 600],
    },
    {
      name: 'In-stock',
      data: [650, 700, 750, 890, 800, 400, 350, 480],
    },
  ]);
  const [lineChartSeries, setLineChartSeries] = useState([
    {
      name: 'Series 1',
      data: [30, 40, 45, 50, 49, 60, 70],
    },
  ]);
  const [rrChartSeries, setRrChartSeries] = useState([
    {
      name: 'Retention Rate',
      data: [],
    },
  ]);
  // ===============================RHighest Performing Chart=========================================

  const [highestPerformingchartOptions, setHighestPerformingChartOptions] =
    useState({
      chart: {
        type: 'bar',
        toolbar: {
          show: true,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
      colors: ['#af3585', '#d792e5', '#D1E9FF'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          borderRadius: 5,
          borderRadiusApplication: 'end',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          'Nigeria',
          'Ghana',
          'Morrocco',
          'Kenya',
          'South Africa',
          'Egypt',
          'Benin',
          'Liberia',
        ],
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return ' ' + val;
          },
        },
      },
    });

  const [highestPerformingchartSeries, setHighestPerformingChartSeries] =
    useState([
      {
        name: 'Stores',
        data: [900, 550, 800, 810, 810, 600, 400, 400],
      },
    ]);
  // ===============================Stores Stats Chart=========================================
  const [storesChartData, setStoresChartData] = useState([]);
  const [storesChartOptions, setStoresChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    chartOptions: {
      toolbar: {
        itemMargin: {
          vertical: 10, // Adjust the margin-top value as needed
        },
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: {
            download: {
              icon: 'custom-toolbar-icon',
            },
          },
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      style: {
        position: 'absolute',
        margin: 0,
        padding: '0px',
        top: '-150px',
        left: '50px',
      },
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [storesChartSeries, setStoresChartSeries] = useState([
    {
      name: 'Active Stores',
      data: [],
    },
    {
      name: 'Inctive Stores',
      data: [],
    },
  ]);
  // ===============================Shoppers Stats Session=========================================
  const [averageUserChartOptions, setAverageUserChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [averageUserChartSeries, setAverageUserChartSeries] = useState([
    {
      name: 'Active Users',
      data: [],
    },
    {
      name: 'Inactive Users',
      data: [],
    },
  ]);

  // ===============================Brands Stats Session=========================================
  const [brandChartOptions, setBrandChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#f5f2fe', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [brandChartSeries, setBrandChartSeries] = useState([
    {
      name: 'Active Brands',
      data: [],
    },
    {
      name: 'Inactive Brands',
      data: [],
    },
  ]);

  //  ================================= Purchased Products =========================================
  const [signUpAndStatChartOptions, setSignUpAndStatChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [signUpAndStatChartSeries, setSignUpAndStatChartSeries] = useState([
    {
      name: 'Brand SKU',
      data: [],
    },
  ]);

  //  ================================= Product Sales =========================================
  const [productSalesChartOptions, setProductSalesChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [productSalesChartSeries, setProductSalesChartSeries] = useState([
    {
      name: 'Product Sales',
      data: [],
    },
  ]);

  // ===============================Brand SignUp=========================================
  const [brandSignupChartOptions, setBrandSignupChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [brandSignupChartSeries, setBrandSignupChartSeries] = useState([
    {
      name: 'Brand Signup',
      data: [],
    },
  ]);

  // ===============================Shop SignUp=========================================
  const [shopSignupChartOptions, setShopSignupChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [shopSignupChartSeries, setShopSignupChartSeries] = useState([
    {
      name: 'Shop Signup',
      data: [],
    },
  ]);

  // ===============================Shoppers SignUp=========================================
  const [shoppersSignupChartOptions, setShoppersSignupChartOptions] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#f5f2fe', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
  });
  const [shoppersSignupChartSeries, setShoppersSignupChartSeries] = useState([
    {
      name: 'Shoppers Signups',
      data: [],
    },
  ]);

  //  ================================= Average Revenue per Brand =========================================
  const [
    averageRevenuePerBrandChartOptions,
    setAverageRevenuePerBrandChartOptions,
  ] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        '2000',
        '2001',
        '2002',
        '2003',
        '2004',
        '2005',
        '2006',
        '2007',
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val;
        },
      },
    },
  });
  const [
    averageRevenuePerBrandChartSeries,
    setAverageRevenuePerBrandChartSeries,
  ] = useState([
    {
      name: 'Average Rev',
      data: [900, 550, 800, 810, 810, 600, 400, 400],
    },
  ]);

  //  ================================= Average Revenue per Brand =========================================
  const [
    averageRevenuePerShopChartOptions,
    setAverageRevenuePerShopChartOptions,
  ] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        '2000',
        '2001',
        '2002',
        '2003',
        '2004',
        '2005',
        '2006',
        '2007',
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '₦ ' + Number(val).toLocaleString();
        },
      },
    },
  });
  const [
    averageRevenuePerShopChartSeries,
    setAverageRevenuePerShopChartSeries,
  ] = useState([
    {
      name: 'Average Rev',
      data: [900, 550, 800, 810, 810, 600, 400, 400],
    },
  ]);

  //  ================================= Average Revenue per Brand =========================================
  const [
    averageRevenuePerShopperChartOptions,
    setAverageRevenuePerShopperChartOptions,
  ] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val >= 1000000) {
            // Format for millions
            return '₦' + val / 1000000 + 'm';
          } else if (val >= 1000) {
            // Format for thousands
            return '₦' + val / 1000 + 'k';
          } else {
            // Default formatting for other values
            return '₦' + Number(val).toLocaleString();
          }
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '₦ ' + Number(val).toLocaleString();
        },
      },
    },
  });
  const [
    averageRevenuePerShopperChartSeries,
    setAverageRevenuePerShopperChartSeries,
  ] = useState([
    {
      name: 'Average Rev',
      data: [],
    },
  ]);

  //  ================================= Sign up and Stat =========================================
  const [timeToResolutionChartOptions, setTimeToResolutionChartOptions] =
    useState({
      chart: {
        type: 'bar',
        toolbar: {
          show: true,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
      colors: ['#af3585', '#d792e5', '#D1E9FF'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '25%',
          borderRadius: 5,
          borderRadiusApplication: 'end',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          '2000',
          '2001',
          '2002',
          '2003',
          '2004',
          '2005',
          '2006',
          '2007',
        ],
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return '$ ' + val;
          },
        },
      },
    });
  const [timeToResolutionChartSeries, setTimeToResolutionChartSeries] =
    useState([
      {
        name: 'Brand Owners',
        data: [900, 550, 800, 810, 810, 600, 400, 400],
      },
      {
        name: 'Shop Owners',
        data: [800, 700, 600, 500, 800, 700, 650, 600],
      },
      {
        name: 'Shoppers',
        data: [650, 700, 750, 890, 800, 400, 350, 480],
      },
    ]);
  //  ================================= Sign up and Stat =========================================

  // ===============================Average User Session=========================================

  // ===============================Total orders and Co Chart=========================================

  const [totalOrdersAndCoChartOptions, setTotalOrdersAndCoChartOptions] =
    useState({
      chart: {
        type: 'line',
        toolbar: {
          show: true,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
      colors: ['#af3585', '#23aa2a', '#ff0000'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          borderRadius: 5,
          borderRadiusApplication: 'end',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return ' ' + val;
          },
        },
      },
    });

  const [totalOrdersAndCochartSeries, setTotalOrdersAndCochartSeries] =
    useState([
      {
        name: 'Total Order',
        data: [900, 550, 800, 810, 810, 600, 400, 400],
      },
      {
        name: 'Completed Order',
        data: [350, 450, 500, 550, 540, 650, 750, 900],
      },
      {
        name: 'Abandoned Order',
        data: [40, 50, 55, 60, 59, 70, 80, 89],
      },
    ]);

  //  ================================= Purchase Trend =========================================
  const [purchaseTrendChartOptions, setPurchaseTrendChartOptions] = useState({
    chart: {
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: {
            download: {
              icon: 'custom-toolbar-icon',
            },
          },
        },
      },
    },
    stroke: {
      curve: 'smooth',
      dashArray: [0, 12],
      width: [4, 3],
    },
    chartOptions: {
      toolbar: {
        itemMargin: {
          vertical: 10, // Adjust the margin-top value as needed
        },
      },
      stroke: {
        curve: 'smooth',
        dashArray: [0, 12],
        width: [4, 3],
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: ['#af3585'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#af3585'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val, index) {
          return val && Number(val).toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val && Number(val).toLocaleString();
        },
      },
    },
  });
  const [purchaseTrendChartSeries, setPurchaseTrendChartSeries] = useState([
    // {
    //   name: 'Milo',
    //   data:[
    //     900, 550, 800, 810, 810, 600, 400, 400]
    // },
    // {
    //   name: 'Bournvita',
    //   data: [350, 450, 500, 550, 540, 650, 750, 900]
    // },
    // {
    //   name: 'CWay',
    //   data: [40, 50, 55, 60, 59, 70, 80, 89]
    // }
  ]);

  // ===============================Total Orders and Co Chart=========================================

  const [chartSeries2, setChartSeries2] = useState([
    35, 3, 8, 3, 10, 15, 5, 21,
  ]);

  const [marketShareChartOptions, setMarketShareChartOptions] = useState({
    chart: {
      type: 'pie',
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      '#85E13A',
      '#D444F1',
      '#16B364',
      '#06AED4',
      '#2E90FA',
      '#875BF7',
      '#F63D68',
      '#EAAA08',
    ],
    labels: [],

    plotOptions: {
      pie: {
        labels: {
          formatter: function (val, opts) {
            var value = chartSeries2[opts.index];
            return val + ' - ' + value;
          },
        },
        style: {
          padding: '40px',
          justifyContent: 'space-between',
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '';
        },
      },
    },
  });
  const [marketShareChartSeries, setMarketShareChartSeries] = useState([]);

  // Shoppers Buying power line chart  chartOptions
  const [chartOptionLine, setChartOptionLine] = useState({
    chart: {
      type: 'line',
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    // colors : ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [
        'Nigeria',
        'Ghana',
        'South Africa',
        'Egypt',
        'Benin',
        'Liberia',
        'Togo',
        'Sierrelone',
        'Senegal',
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [rrChartOptionLine, setRrChartOptionLine] = useState({
    chart: {
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: {
            download: {
              icon: 'custom-toolbar-icon',
            },
          },
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    // colors : ['#af3585', '#d792e5', '#D1E9FF'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val >= 1000000) {
            // Format for millions
            return (val / 1000000).toFixed(1) + 'm';
          } else if (val >= 1000) {
            // Format for thousands
            return (val / 1000).toFixed(1) + 'k';
          } else {
            // Default formatting for other values
            return Number(val).toLocaleString();
          }
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Number(val).toLocaleString();
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });

    bus.on('chartFilter', (el) => {
      setChartFilter(el);
    });

    bus.on('filterList', () => {
      setModalOpen(!modalOpen);
    });
  }, []);

  const fetchCategories = (page = 1, perpage = 10) => {
    setCategoryPending(true);
    axiosInstance
      .get('/admin/product/category-count', {
        params: {
          page,
          perpage,
        },
      })
      .then((res) => {
        setCategoriesData(res.data.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setCategoryPending(false));
  };

  useEffect(() => {
    handleFetchCategoryProducts(categoryProductId);
  }, [searchCategory]);

  function handleClick(event) {
    event.preventDefault();
    navigate(-1);
  }

  // const fetchStates = async () => {
  //   if (country === "") return;
  //   setStates([]);
  //   setState("");
  //   setLoadingState(true);
  //   const statesFetch = await fetch(
  //     searchStates || searchStates2 || searchStates3 ?
  //     `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json?search=${searchStates || searchStates2 || searchStates3}` :
  //     `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`,
  //     {}
  //   );
  //   const statesJson = await statesFetch.json();

  //   const statesData = statesJson;

  //   setStates(() => {
  //     return statesData.filter(
  //       (el, i) =>
  //         el.country_name.toLowerCase() === country.toLowerCase()
  //     ).filter((el) =>
  //       el.name.toLowerCase().includes(searchStates.toLowerCase()) ||
  //       el.name.toLowerCase().includes(searchStates2.toLowerCase()) ||
  //       el.name.toLowerCase().includes(searchStates3.toLowerCase())

  //     ).sort((a, b) => a.name.localeCompare(b.name));
  //   });
  //   setLoadingState(false);
  // };

  const fetchStates = async () => {
    if (country === '') return;

    setStates([]);
    setState('');
    setLoadingState(true);

    const searchParams = [searchStates, searchStates2, searchStates3].filter(
      Boolean
    );

    const url = searchParams.length
      ? `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json?search=${searchParams.join(
          ','
        )}`
      : `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`;

    try {
      const statesFetch = await fetch(url, {});
      const statesJson = await statesFetch.json();

      const filteredStates = statesJson.filter(
        (state) =>
          state.country_name.toLowerCase() === country.toLowerCase() &&
          (searchParams.length === 0 ||
            searchParams.some((param) =>
              state.name.toLowerCase().includes(param.toLowerCase())
            ))
      );

      const sortedStates = filteredStates.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setStates(sortedStates);
    } catch (error) {
      console.error('Error fetching states:', error);
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    // fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, state, searchStates, searchStates2, searchStates3]);

  const handleSearchProduct = (e) => {
    console.log(e.target.value);
    setSearchProducts(e.target.value);
  };

  const handleSearchCategory = (e) => {
    setSearchCategory(e.target.value);
  };

  const handleSearchBrand = (e) => {
    setSearchBrands(e.target.value);
  };

  const handleSearchState = (e) => {
    setSearchStates(e.target.value);
  };
  const handleSearchState2 = (e) => {
    setSearchStates2(e.target.value);
  };

  const handleSearchState3 = (e) => {
    setSearchStates3(e.target.value);
  };

  const handleSelectAllProduct = () => {
    setSelectAllProduct(!selectAllProduct);
    const selectedIds = productData?.map((option) => option._id);
    setSelectedProductIds(selectAllProduct ? [] : selectedIds);
  };

  const handleSelectAllProductCategories = () => {
    setSelectAllProductCategory(!selectAllProductCategory);
    const selectedIds = productCatData?.map((option) => option._id);
    setSelectedProductCatIds(selectAllProductCategory ? [] : selectedIds);
  };

  const handleFetchStates = () => {
    setLoadingState(true);
    axiosInstance
      .get(`/admin/data-insights/locations`, {
        params: {
          search: searchStates || searchStates2 || searchStates3,
        },
      })
      .then((res) => {
        setStates(res.data.data[0].states);
      })
      .finally(() => setLoadingState(false));
  };

  useEffect(() => {
    handleFetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, searchStates, searchStates2, searchStates3]);

  const handleSelectAllBrand = () => {
    setSelectAllBrand(!selectAllBrand);
    const selectedIds = brandData?.map((option) => option._id);
    setSelectedBrandIds(selectAllBrand ? [] : selectedIds);
  };

  // const handleSelectAllStates = () => {
  //   setSelectAllStates(!selectAllStates);
  //   const selectedStates = states?.map((option) => option.name);
  //   setSelectedState(selectAllStates ? [] : selectedStates);
  // };

  const handleCheckboxChange = (id) => {
    if (selectedProductIds.includes(id)) {
      setSelectedProductIds(
        selectedProductIds.filter((checkboxId) => checkboxId !== id)
      );
    } else {
      setSelectedProductIds([...selectedProductIds, id]);
    }
  };

  useEffect(() => {
    console.log(selectedProductIds);
  }, [selectedProductIds]);

  const handleProductCatCheckBoxChange = (id) => {
    if (selectedProductCatIds.includes(id)) {
      setSelectedProductCatIds(
        selectedProductCatIds.filter((checkboxId) => checkboxId !== id)
      );
    } else {
      setSelectedProductCatIds([...selectedProductCatIds, id]);
    }
  };

  const handleProductCatCheckBoxChange01 = (id) => {
    setSelectedProductCatIds(id);
  };

  const handleCategoryBoxChange = (id) => {
    if (selectedCategoryIds.includes(id)) {
      setSelectedCategoryIds(
        selectedCategoryIds.filter((checkboxId) => checkboxId !== id)
      );
    } else {
      setSelectedCategoryIds([...selectedCategoryIds, id]);
    }
  };

  const handleBrandboxChange = (id) => {
    if (selectedBrandIds.includes(id)) {
      setSelectedBrandIds(
        selectedBrandIds.filter((checkboxId) => checkboxId !== id)
      );
    } else {
      setSelectedBrandIds([...selectedBrandIds, id]);
    }
  };

  const handleStateChange = (val) => {
    // if (selectedState.includes(val)) {
    //   setSelectedState(selectedState.filter((checkbox) => checkbox !== val));
    // } else {
    setSelectedState(val);
    // }
  };

  const handleStateChange2 = (val) => {
    setSelectedState2(val);
  };

  const handleStateChange3 = (val) => {
    setSelectedState3(val);
  };

  const handleFetchCardData = (products, brands, locations) => {
    setCardLoader(true);
    axiosInstance
      .get(`/admin/data-insights/cards`, {
        params: {
          dateFilter: dataFilter,
          products: products ? products : null,
          brands: brands ? brands : null,
          locations: locations ? [locations] : null,
        },
      })
      .then((res) => {
        if (res.status < 300) {
          setCardsData(res.data.data);
          setSelectedProductIds([]);
          setSelectedBrandIds([]);
          setSelectedState('');
          setSearchStates('');
          setSelectAllBrand(false);
        }
      })
      .catch((err) => {
        // notifyError(err.response.data.message)
        notifyError('Unable to fetch card data');
      })
      .finally(() => setCardLoader(false));
  };

  const handleFetchProduct = () => {
    setIsProductPending(true);
    axiosInstance
      .get(`/admin/product`, {
        params: {
          search: searchProducts ? searchProducts : null,
        },
      })
      .then((res) => {
        setProductData(res.data.data);
        setIsProductPending(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Unable to fetch products!');
        }
      })
      .finally(() => setIsProductPending(false));
  };

  const handleFetchProductCategories = () => {
    setIsProductCatPending(true);
    axiosInstance
      .get(`/admin/product/category-count`, {
        params: {
          search: searchProducts ? searchProducts : null,
        },
      })
      .then((res) => {
        setProductCatData(res.data.data.data);
        setIsProductCatPending(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Unable to fetch products!');
        }
      })
      .finally(() => setIsProductCatPending(false));
  };

  const handleFetchCategoryProducts = (id) => {
    setIsCategoryPending(true);
    setCategoryProductId(id);

    const searchParam = searchCategory ? searchCategory : null;

    // To avoid calling the funccttion when  search category is null
    if (searchParam === null) return;

    axiosInstance
      .get(`/admin/product/in-category/${id}`, {
        params: {
          search: searchParam,
        },
      })
      .then((res) => {
        setCatProductData(res.data.data[0].products);
        setIsCategoryPending(false);
      })
      .finally(() => setIsCategoryPending(false));
  };

  const handleFetchBrand = () => {
    setIsBrandPending(true);
    axiosInstance
      .get(`/admin/product/brands`, {
        params: {
          search: searchBrands ? searchBrands : null,
        },
      })
      .then((res) => {
        setBrandData(res.data.data);
        setIsBrandPending(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Unable to fetch brands!');
        }
      })
      .finally(() => setIsBrandPending(false));
  };

  useEffect(() => {
    handleFetchProductCategories();
    handleFetchCardData();
    handleFetchProduct();
    handleFetchBrand();
  }, [dataFilter, searchProducts, searchBrands]);

  const handleProceedFilter = () => {
    setModalOpen(!modalOpen);
    handleFetchCardData(selectedProductIds, selectedBrandIds, selectedState);
  };

  const handleProceedChartFilter = () => {
    fetchPurchasedProductStat(
      selectedProductIds,
      selectedBrandIds,
      selectedState2
    );
    handleCloseRvModal();
  };

  const handleProceedProductSalesChartFilter = () => {
    if (modalVal === 'p_s') {
      fetchProductSalesStat(
        selectedProductCatIds,
        selectedCategoryIds,
        selectedBrandIds,
        selectedState3
      );
    } else {
      fetchTrendsOfPurchase(
        selectedProductCatIds,
        selectedCategoryIds,
        selectedBrandIds,
        selectedState3
      );
    }

    handleCloseHpModal();
    setSearchStates3('');
  };

  const handleProceedMSFilter = () => {
    fetchMarketShareChart(selectedProductCatIds, selectedState3);
    handleCloseMsModal();
    setSearchStates3('');
  };

  const handleProceedOBLFilter = () => {
    handleCloseOBLModal();
    setSearchStates3('');
    fetchOBLStat();
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const randomColors = Array.from({ length: 8 }, () => getRandomColor());

  const fetchActiveInactiveStoreStat = () => {
    setIsShopperStatPending(true);
    axiosInstance
      .get('/admin/data-insights/active-inactive-stores', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Active Stores',
            data: res.data.data.map((el) => el.totalActiveStores),
          },
          {
            name: 'Inactive Stores',
            data: res.data.data.map((el) => el.totalInactiveStores),
          },
        ];

        setStoresChartSeries((prev) => {
          return data;
        });
        setStoresChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: [''],
            },
          };
        });
        setStoresChartData(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsShopperStatPending(false));
  };

  const fetchActiveInactiveShopperStat = () => {
    setIsShopperStatPending(true);
    axiosInstance
      .get('/admin/data-insights/active-inactive-shoppers', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Active Users',
            data: res.data.data.map((el) => el.totalActiveUsers),
          },
          {
            name: 'Inactive Users',
            data: res.data.data.map((el) => el.totalInactiveUsers),
          },
        ];

        setAverageUserChartSeries((prev) => {
          return data;
        });
        setAverageUserChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: [''],
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsShopperStatPending(false));
  };

  const fetchActiveInactiveBrandStat = () => {
    setIsShopperStatPending(true);
    axiosInstance
      .get('/admin/data-insights/active-inactive-brands', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Active Brands',
            data: res.data.data.map((el) => el.totalActiveBrands),
          },
          {
            name: 'Inactive Brands',
            data: res.data.data.map((el) => el.totalInactiveBrands),
          },
        ];

        setBrandChartSeries((prev) => {
          return data;
        });
        setBrandChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: [''],
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsShopperStatPending(false));
  };

  const fetchPurchasedProductStat = (products, brands, location) => {
    setIsPurchasedProductPending(true);
    axiosInstance
      .get('/admin/data-insights/product-sales-sku', {
        params: {
          dateFilter: chartFilter,
          products: products ? products : null,
          brands: brands ? brands : null,
          location: location ? location : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Brand SKU',
            data: res.data.data.map((el) => el.totalSale),
          },
        ];

        setSignUpAndStatChartSeries((prev) => {
          return data;
        });
        setSignUpAndStatChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    const productNameWords = el.product_name.split(' ');
                    const specWords = el.spec.split(' ');

                    const productNameFormatted = productNameWords.join('\n');
                    const specFormatted = specWords.join('\n');

                    return `${productNameFormatted}\n(${specFormatted})`;
                  })
                : [''],
            },
          };
        });
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedBrandIds([]);
        setSelectedState2('');
        setSearchStates2('');
        setSelectAllBrand(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsPurchasedProductPending(false));
  };

  const fetchProductSalesStat = (categories, products, brands, location) => {
    setIsProductSalesPending(true);
    axiosInstance
      .get('/admin/data-insights/product-sales', {
        params: {
          dateFilter: chartFilter,
          products: products ? products : null,
          brands: brands ? brands : null,
          location: location ? location : null,
          categories: categories ? categories : null,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Product Sales',
            data: res.data.data.map((el) => el.totalSale),
          },
        ];

        setProductSalesChartSeries((prev) => {
          return data;
        });
        setProductSalesChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => `${el.product_name}`)
                : [''],
            },
          };
        });
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedBrandIds([]);
        setSelectedState3('');
        setSearchStates3('');
        setModalVal('');
        setSelectAllBrand(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsProductSalesPending(false));
  };

  const fetchBrandSignUpStat = () => {
    setIsBrandSignupPending(true);
    axiosInstance
      .get('/admin/data-insights/brand-sign-up', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Brand Signup',
            data: res.data.data.map((el) => el.totalCount),
          },
        ];

        setBrandSignupChartSeries((prev) => {
          return data;
        });
        setBrandSignupChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
              // categories: res.data.data.length ? res.data.data.map((el) => el.type && el.type === 'week' ?
              // `Week ${parseInt(el.date, 10) + 1}` : el.type && el.type === 'month' ? el?.date :
              // el.type && el.type === 'hour' ? moment(el?.date).format('h A') : moment(el.date).format('YYYY-MM-DD')) : ['']
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsBrandSignupPending(false));
  };

  const fetchShopSignUpStat = () => {
    setIsShopSignupPending(true);
    axiosInstance
      .get('/admin/data-insights/store-sign-up', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Shop Signup',
            data: res.data.data.map((el) => el.totalCount),
          },
        ];

        setShopSignupChartSeries((prev) => {
          return data;
        });
        setShopSignupChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
              // categories: res.data.data.length ? res.data.data.map((el) => el.type && el.type === 'week' ?
              // `Week ${parseInt(el.date, 10) + 1}` : el.type && el.type === 'month' ? el?.date :
              // el.type && el.type === 'hour' ? moment(el?.date).format('h A') : moment(el.date).format('YYYY-MM-DD')) : ['']
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsShopSignupPending(false));
  };

  const fetchTrendsOfPurchase = (categories, products, brands, location) => {
    setIsTrendsOfPurchasePending(true);
    axiosInstance
      .get('/admin/data-insights/trends-of-purchase', {
        params: {
          dateFilter: chartFilter,
          products: products ? products : null,
          brands: brands ? brands : null,
          location: location ? location : null,
          categories: categories ? categories : null,
        },
      })
      .then((res) => {
        const uniqueProductNames = Array.from(
          new Set(
            res.data.data.flatMap((item) =>
              item.products.map((product) => product.product_name)
            )
          )
        );

        const data = uniqueProductNames.map((productName) => {
          const productData = res.data.data.map((item) => {
            const product = item.products.find(
              (p) => p.product_name === productName
            );
            return product ? product.totalProducts : 0;
          });

          return {
            name: productName,
            data: productData,
          };
        });

        setPurchaseTrendChartSeries((prev) => {
          return data;
        });
        setPurchaseTrendChartOptions((prev) => {
          return {
            ...prev,
            colors: randomColors,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
            },
          };
        });

        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedBrandIds([]);
        setSelectedState3('');
        setSearchStates3('');
        setModalVal('');
        setSelectAllBrand(false);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsTrendsOfPurchasePending(false));
  };

  const fetchShoppersRevenue = () => {
    setIsAverageRevPending(true);
    axiosInstance
      .get('/admin/data-insights/shopper-revenue', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Average Rev',
            data: res.data.data.map((el) => el.totalAmount),
          },
        ];

        setAverageRevenuePerShopperChartSeries((prev) => {
          return data;
        });
        setAverageRevenuePerShopperChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
              // categories: res.data.data.length ? res.data.data.map((el) => el.type && el.type === 'week' ?
              // `Week ${parseInt(el.date, 10) + 1}` : el.type && el.type === 'month' ? el?.date :
              // el.type && el.type === 'hour' ? moment(el?.date).format('h A') : moment(el.date).format('YYYY-MM-DD')) : ['']
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsAverageRevPending(false));
  };

  const fetchShoppersSignupStat = () => {
    setIsShoppersSignupPending(true);
    axiosInstance
      .get('/admin/data-insights/shopper-sign-up', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Shoppers Signups',
            data: res.data.data.map((el) => el.totalCount),
          },
        ];

        setShoppersSignupChartSeries((prev) => {
          return data;
        });
        setShoppersSignupChartOptions((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
              // categories: res.data.data.length ? res.data.data.map((el) => el.type && el.type === 'week' ?
              // `Week ${parseInt(el.date, 10) + 1}` : el.type && el.type === 'month' ? el?.date :
              // el.type && el.type === 'hour' ? moment(el?.date).format('h A') : moment(el.date).format('YYYY-MM-DD')) : ['']
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsShoppersSignupPending(false));
  };

  const fetchRetentionRate = () => {
    setIsRetentionRatePending(true);
    axiosInstance
      .get('/admin/data-insights/retention-rate', {
        params: {
          dateFilter: chartFilter,
        },
      })
      .then((res) => {
        const data = [
          {
            name: 'Retention Rate',
            data: res.data.data.map((el) => el.totalCount),
          },
        ];

        setRrChartSeries((prev) => {
          return data;
        });
        setRrChartOptionLine((prev) => {
          return {
            ...prev,
            xaxis: {
              categories: res.data.data.length
                ? res.data.data.map((el) => {
                    if (el.type === 'week') {
                      return `Week ${parseInt(el.date, 10) + 1}`;
                    } else if (el.type === 'hour') {
                      return moment(el.date).format('h A');
                    } else if (el.type === 'month') {
                      const monthNames = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];
                      const yearMonth = el.date.split('-');
                      const monthNumber = parseInt(yearMonth[1], 10);
                      return `${monthNames[monthNumber - 1]} ${yearMonth[0]}`;
                    } else {
                      return moment(el.date).format('YYYY-MM-DD');
                    }
                  })
                : [''],
              // categories: res.data.data.length ? res.data.data.map((el) => el.type && el.type === 'week' ?
              // `Week ${parseInt(el.date, 10) + 1}` : el.type && el.type === 'month' ? el?.date :
              // el.type && el.type === 'hour' ? moment(el?.date).format('h A') : moment(el.date).format('YYYY-MM-DD')) : ['']
            },
          };
        });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsRetentionRatePending(false));
  };

  const fetchMarketShareChart = (categories, location) => {
    setIsMarketSharePending(true);
    const payload = {
      dateFilter: chartFilter,
      location: location ? location : null,
      category: null,
      categories: null,
    };

    categories && categories?.length > 1
      ? (payload.categories = [categories])
      : (payload.category = categories);

    axiosInstance
      .get('/admin/data-insights/market-share', {
        params: {
          ...payload,
        },
      })
      .then((res) => {
        const data = res.data.data.map((el) => el.totalProducts);

        setMarketShareChartSeries((prev) => {
          return data;
        });
        setMarketShareChartOptions((prev) => {
          return {
            ...prev,
            colors: randomColors,
            labels: res.data.data.length
              ? res.data.data.map(
                  (el) => `${el.brand_name} (${el?.percentage}%)`
                )
              : ['No Record Found'],
          };
        });

        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedState3('');
        setSearchStates3('');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsMarketSharePending(false));
  };

  const fetchOBLStat = () => {
    setIsOBLPending(true);
    axiosInstance
      .get(`/admin/data-insights/order-heat-map`, {
        params: {
          dateFilter: chartFilter,
          products: selectedCategoryIds ? selectedCategoryIds : null,
          location: selectedState3 ? selectedState3 : null,
          categories: selectedProductCatIds ? selectedProductCatIds : null,
        },
      })
      .then((res) => {
        setMapData(res.data.data);
        setSelectedProductCatIds([]);
        setSelectedCategoryIds([]);
        setSelectedState3([]);
        setSelectAllStates(false);
        setSearchStates3('');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {}
      })
      .finally(() => setIsOBLPending(false));
  };

  const fetchData = useCallback(async () => {
    setIsOrderHeatMapPending(true);

    try {
      const res = await axiosInstance.get(
        '/admin/data-insights/order-heat-map',
        {
          params: {
            dateFilter: chartFilter,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error('Error fetching data:', error);

      try {
        // Notify error using your notification function
        notifyError(error.response.data.message);
      } catch (innerError) {
        console.error('Error handling inner error:', innerError);
      } finally {
        setIsOrderHeatMapPending(false);
      }

      throw error; // Rethrow the error to handle it elsewhere if needed
    }
  }, [chartFilter]);

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      const data = await fetchData();

      if (data) {
        console.log(data);
        const heatmapData = data.data.map((item) => ({
          x: parseFloat(item.longitude[0]),
          y: parseFloat(item.latitude[0]),
          value: item.totalOrder,
        }));

        setOrderHeatMapData(heatmapData);
      }
    };

    fetchDataAndSetState();
  }, [fetchData]);

  useEffect(() => {
    fetchActiveInactiveStoreStat();
    fetchActiveInactiveShopperStat();
    fetchActiveInactiveBrandStat();
    fetchPurchasedProductStat();
    fetchProductSalesStat();
    fetchBrandSignUpStat();
    fetchShopSignUpStat();
    fetchShoppersSignupStat();
    fetchMarketShareChart();
    fetchTrendsOfPurchase();
    fetchShoppersRevenue();
    fetchRetentionRate();
    fetchOBLStat();
  }, [chartFilter]);

  const initialSelectedChartData = allChartData.map((item) => item.value);

  const [openEnableFilterModal, setOpenEnableFilterModal] = useState(false);
  const [selectedChartData, setSelectedChartData] = useState(
    initialSelectedChartData
  );

  const handleOpenEnableFilterModal = () => {
    setOpenEnableFilterModal(true);
  };

  const handleCloseEnableFilterModal = () => {
    setOpenEnableFilterModal(false);
  };

  const handleChartDataChange = (val) => {
    if (selectedChartData.includes(val)) {
      setSelectedChartData(
        selectedChartData.filter((checkboxVal) => checkboxVal !== val)
      );
    } else {
      setSelectedChartData([...selectedChartData, val]);
    }
  };

  const exportToExcel = () => {
    const chartData = [...storesChartData]; // Get your chart data
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(chartData);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'chart_data.xlsx');
  };

  const exportToPDF = () => {
    const chartData = storesChartData; // Replace with your actual chart data
    const pdf = new jsPDF();

    // Set up PDF content
    pdf.text('Chart Data', 15, 10);
    pdf.autoTable({ head: [['X', 'Y']], body: chartData });

    // Save or download the PDF
    pdf.save('chart_data.pdf');
  };

  const handleExport = (val) => {
    // Implement export functionality based on the selected format
    if (val === 'CSV') {
      // exportToCSV();
    } else if (val === 'Excel') {
      exportToExcel();
    } else if (val === 'PDF') {
      exportToPDF();
    }
  };

  return (
    <>
      <div className="mt-md-0 mt-4 pt-2 px-2">
        <div className="header d-block d-md-none mb-4">
          <div
            className={`${styles.wallet_actions} d-md-flex justify-content-between`}
          >
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Data Insight</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Monitor your store progress
              </p>
            </div>
          </div>
        </div>

        <Filter2 width="100%" show={true} />
        <div className="row mb-2" style={{ justifyContent: 'space-between' }}>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p className="font-weight-bold p-0 normal-text ">
                    Gross Merchandise Value (GMV)
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          '₦' +
                            Number(
                              cardsData?.grossMerchandiseValue?.boldCount
                            ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.grossMerchandiseValue?.boldCount <
                      cardsData?.grossMerchandiseValue?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.grossMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.grossMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p className="font-weight-bold p-0 normal-text ">
                    Net Merchandise Value (NMV)
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          '₦' +
                            Number(
                              cardsData?.netMerchandiseValue?.boldCount
                            ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.netMerchandiseValue?.boldCount <
                      cardsData?.netMerchandiseValue?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.netMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.netMerchandiseValue?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p className="font-weight-bold p-0 normal-text ">
                    Total Orders
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          Number(
                            cardsData?.totalOrder?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.totalOrder?.boldCount <
                      cardsData?.totalOrder?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.totalOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.totalOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p className="font-weight-bold p-0 normal-text ">
                    Completed Orders
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          Number(
                            cardsData?.completedOrder?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.completedOrder?.boldCount <
                      cardsData?.completedOrder?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.completedOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.completedOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mb-2 p-0 m-1">
            <div className={`${styles.card}`} style={{ height: '100%' }}>
              <div className={`card-body h-100`}>
                <div
                  className="d-flex justify-content-between h-100"
                  style={{ flexDirection: 'column' }}
                >
                  <p className="font-weight-bold p-0 normal-text ">
                    Abandoned Orders
                  </p>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>
                        {!cardLoader &&
                          Number(
                            cardsData?.abandonOrder?.boldCount
                          ).toLocaleString()}
                        {cardLoader && (
                          <CircularProgress
                            style={{ color: '#af3585' }}
                            size="1rem"
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      {cardsData?.abandonOrder?.boldCount <
                      cardsData?.abandonOrder?.smallCount ? (
                        <div className={styles.percent2}>
                          <img src="/assets/icons/down.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.abandonOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      ) : (
                        <div className={styles.percent}>
                          <img src="/assets/icons/up.svg" alt="" />
                          <small className="pl-1">
                            {Number(
                              cardsData?.abandonOrder?.percentageDiff
                            ).toLocaleString()}
                            %
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex">
          <button
            className="btn btn-light ml-auto"
            type="button"
            onClick={handleOpenEnableFilterModal}
            style={{ border: '1px solid lightgrey', cursor: 'pointer' }}
          >
            <span>
              <img src="/assets/icons/trending-up.svg" alt="" /> &nbsp; View
              Charts
            </span>
          </button>

          {openEnableFilterModal && (
            <div
              className={`${styles.side_modal_overlay}`}
              onClick={handleCloseEnableFilterModal}
            >
              <div
                className={`${styles.side_modal_content}`}
                onClick={(e) => e.stopPropagation()}
              >
                {/* Your modal content goes here */}
                <div className="d-flex">
                  <h5>
                    <strong>Select Chart</strong>
                  </h5>
                  <span
                    onClick={handleCloseEnableFilterModal}
                    className="ml-auto"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="/assets/icons/x.svg" alt="" />
                  </span>
                </div>
                <p>
                  <small>Select Insight to display</small>
                </p>

                {allChartData.map((el, i) => {
                  return (
                    <div className="p-2 d-md-flex" key={i}>
                      <div className="mr-3">
                        <input
                          type="checkbox"
                          checked={selectedChartData.includes(el.value)}
                          onChange={() => handleChartDataChange(el.value)}
                        />
                      </div>
                      <div className=" ">
                        <p
                          className="mb-0 text-left"
                          style={{ fontSize: '14px' }}
                        >
                          {el?.name}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {/* Stores Stats */}

        {selectedChartData.includes('active_inactive_store_stat') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <Filter3 width="100%" show={false} />
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Active / Inactive Store Stats</h5>
                <div className="d-md-flex justify-content-between">
                  <p>
                    Monitor the number of active and inactive stores on the
                    platform
                  </p>
                  <div className="d-flex">
                    {/* <div className="apexcharts-toolbar">
                      <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        className={`${styles.export_btn} btn m-0 apexcharts-menu-icon`}
                        style={{
                          fontSize:'11px'
                        }}
                      >
                        <span style={{
                        paddingTop: '8px',
                        }}>
                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                          borderLeft:'1px solid white',
                          display:'inline-block',
                          height:'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign:'center',
                        }}>
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                      <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button
                          type="button"
                          className="rss dropdown-item btn border-0"
                          onClick={() => handleExport('CSV')}
                        >
                          CSV
                        </button>
                        <button
                          type="button"
                          className="rss dropdown-item btn border-0"
                          onClick={() => handleExport('Excel')}
                        >
                          Excel
                        </button>
                        <button
                          type="button"
                          className="rss dropdown-item btn border-0"
                          onClick={() => handleExport('PDF')}
                        >
                          PDF
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="activeinactivetab1">
                          <input
                            type="radio"
                            id="activeinactivetab1"
                            name="activeinactivetab1"
                            value={1}
                            checked={selectedTab === 1}
                            onChange={() => handleTabChange(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="activeinactivetab2">
                          <input
                            type="radio"
                            id="activeinactivetab2"
                            name="activeinactivetab1"
                            value={2}
                            checked={selectedTab === 2}
                            onChange={() => handleTabChange(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                STORES
              </span>
              <div>
                {isShopperStatPending && (
                  <BallTriangle
                    height={35}
                    width={45}
                    radius={5}
                    color="#af3585"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
                <>
                  {selectedTab === 1 && (
                    <div className="chart-container">
                      {/* Content for Tab 1 */}
                      <ReactApexChart
                        type="bar"
                        series={storesChartSeries}
                        options={storesChartOptions}
                        height={500}
                      />
                    </div>
                  )}
                  {selectedTab === 2 && (
                    <div className="chart-container">
                      {/* Content for Tab 2 */}
                      <ReactApexChart
                        type="line"
                        series={storesChartSeries}
                        options={storesChartOptions}
                        height={500}
                      />
                    </div>
                  )}
                </>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Shoppers Stats*/}

        {selectedChartData.includes('active_inactive_user_stat') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Active / Inactive Shopper Stats</h5>
                <div className="d-md-flex justify-content-between">
                  <p>
                    Monitor the number of active and inactive shoppers on the
                    platform
                  </p>
                  <div className="d-flex">
                    <div className="dropleft "></div>
                    &nbsp; &nbsp;
                  </div>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="activeinactivetab3">
                          <input
                            type="radio"
                            id="activeinactivetab3"
                            name="activeinactivetab2"
                            value={1}
                            checked={selectedTab2 === 1}
                            onChange={() => handleTabChange2(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="activeinactivetab4">
                          <input
                            type="radio"
                            id="activeinactivetab4"
                            name="activeinactivetab2"
                            value={2}
                            checked={selectedTab2 === 2}
                            onChange={() => handleTabChange2(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                SHOPPERS
              </span>
              <div>
                {isShopperStatPending && (
                  <BallTriangle
                    height={35}
                    width={45}
                    radius={5}
                    color="#af3585"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
                <>
                  {selectedTab2 === 1 && (
                    <div className="chart-container">
                      {/* Content for Tab 1 */}
                      <ReactApexChart
                        type="bar"
                        series={averageUserChartSeries}
                        options={averageUserChartOptions}
                        height={500}
                      />
                    </div>
                  )}
                  {selectedTab2 === 2 && (
                    <div className="chart-container">
                      {/* Content for Tab 2 */}
                      <ReactApexChart
                        type="line"
                        series={averageUserChartSeries}
                        options={averageUserChartOptions}
                        height={500}
                      />
                    </div>
                  )}
                </>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Brand Stats*/}

        {selectedChartData.includes('active_inactive_brand_stat') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Active / Inactive Brand Stats</h5>
                <div className="d-md-flex justify-content-between">
                  <p>
                    Monitor the number of active and inactive brands on the
                    platform
                  </p>
                  <div className="d-flex">
                    <div className="dropleft ">
                      {/* <button className="btn  border" onClick={() => setRvModalOpen(true)}
                        style={{
                          backgroundColor:'#fff',
                          border: '1px solid #D0D5DD',
                          boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                          borderRadius: '4px'
                        }}>
                        <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" />
                        &nbsp;
                        <small className="d-none d-md-inline-block">
                        Filters
                        </small>
                      </button> */}
                    </div>
                    &nbsp; &nbsp;
                    {/* <div className="dropleft ">
                    <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        className={`${styles.export_btn} btn m-0`}
                        style={{
                          fontSize:'11px'
                        }}
                      >
                        <span style={{
                        paddingTop: '8px',
                        }}>
                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                          borderLeft:'1px solid white',
                          display:'inline-block',
                          height:'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign:'center',
                        }}>
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                      <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="activeinactiveBrand1">
                          <input
                            type="radio"
                            id="activeinactiveBrand1"
                            name="activeinactivetab5"
                            value={1}
                            checked={selectedTab01 === 1}
                            onChange={() => handleTabChange01(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="activeinactiveBrand2">
                          <input
                            type="radio"
                            id="activeinactiveBrand2"
                            name="activeinactivetab5"
                            value={2}
                            checked={selectedTab01 === 2}
                            onChange={() => handleTabChange01(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                BRANDS
              </span>
              {isShopperStatPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <>
                {selectedTab01 === 1 && (
                  <div className="chart-container">
                    {/* Content for Tab 1 */}
                    <ReactApexChart
                      type="bar"
                      series={brandChartSeries}
                      options={brandChartOptions}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab01 === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={brandChartSeries}
                      options={brandChartOptions}
                      height={500}
                    />
                  </div>
                )}
              </>
            </CardContent>
          </Card>
        )}

        {/* Purchased Products */}

        {selectedChartData.includes('p_p_b_s_v') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Purchased Products by SKU Variations</h5>
                <div className="d-md-flex justify-content-between">
                  <p style={{ marginBottom: '40px' }}>
                    View the total sales of product SKU
                  </p>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4"></div>
                </div>
                <div className="d-flex">
                  <div className="dropleft ml-auto">
                    <button
                      className="btn  border"
                      onClick={() => setRvModalOpen(true)}
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '28%' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                  </div>
                  &nbsp; &nbsp;
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isPurchasedProductPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div>
                {selectedTab3 === 1 && (
                  <div className="chart-container">
                    {/* Content for Tab 1 */}
                    <ReactApexChart
                      type="bar"
                      series={signUpAndStatChartSeries}
                      options={signUpAndStatChartOptions}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab3 === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={signUpAndStatChartSeries}
                      options={signUpAndStatChartOptions}
                      height={500}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Product Sales */}

        {selectedChartData.includes('p_s') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Product Sales</h5>
                <div className="d-md-flex justify-content-between">
                  <p>View the total sales of individual brands</p>
                  <div className="d-flex mb-3">
                    {/* <div className="dropleft ">
                      <button className="btn  border" onClick={() => handleModalVal('p_s')}
                        style={{
                          backgroundColor:'#fff',
                          border: '1px solid #D0D5DD',
                          boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                          borderRadius: '4px'
                        }}>
                        <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" />
                        &nbsp;
                        <small className="d-none d-md-inline-block">
                        Filters
                        </small>
                      </button>
                    </div> */}
                    &nbsp; &nbsp;
                    <div className="dropleft ">
                      <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className={`${styles.export_btn} btn m-0`}
                        style={{
                          fontSize: '11px',
                        }}
                      >
                        <span
                          style={{
                            paddingTop: '8px',
                          }}
                        >
                          <img src="/assets/icons/downloadwhite.png" alt="" />{' '}
                          &nbsp; Export
                        </span>
                        &nbsp; &nbsp;
                        <span
                          style={{
                            borderLeft: '1px solid white',
                            display: 'inline-block',
                            height: 'inherit',
                            paddingTop: '8px',
                            paddingLeft: '8px',
                            textAlign: 'center',
                          }}
                        >
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                      <div
                        className="dropdown-menu drop-left"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          type="button"
                          className="rss dropdown-item btn border-0"
                        >
                          CSV
                        </button>
                        <button
                          type="button"
                          className="rss dropdown-item btn border-0"
                        >
                          Excel{' '}
                        </button>
                        <button
                          type="button"
                          className="rss dropdown-item btn border-0"
                        >
                          PDF{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {isProductSalesPending && (
                  <BallTriangle
                    height={35}
                    width={45}
                    radius={5}
                    color="#af3585"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                )}
                <div className="chart-container">
                  {/* Content for Tab 1 */}
                  <ReactApexChart
                    type="bar"
                    series={productSalesChartSeries}
                    options={productSalesChartOptions}
                    height={500}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Brand SignUp */}

        {selectedChartData.includes('b_s_s') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Brand Sign up Stats</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Monitor the rate at which brands join flexdeals</p>
                  <div className="d-flex">
                    <div className="dropleft "></div>
                    &nbsp; &nbsp;
                    {/* <div className="dropleft ">
                    <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        className={`${styles.export_btn} btn m-0`}
                        style={{
                          fontSize:'11px'
                        }}
                      >
                        <span style={{
                        paddingTop: '8px',
                        }}>
                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                          borderLeft:'1px solid white',
                          display:'inline-block',
                          height:'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign:'center',
                        }}>
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                      <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="brandsignuptab1">
                          <input
                            type="radio"
                            id="brandsignuptab1"
                            name="brandsignuptabs1"
                            value={1}
                            checked={selectedTab4 === 1}
                            onChange={() => handleTabChange4(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="brandsignuptab2">
                          <input
                            type="radio"
                            id="brandsignuptab2"
                            name="brandsignuptabs1"
                            value={2}
                            checked={selectedTab4 === 2}
                            onChange={() => handleTabChange4(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isBrandSignupPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div>
                {selectedTab4 === 1 && (
                  <div className="chart-container">
                    {/* Content for Tab 1 */}
                    <ReactApexChart
                      type="bar"
                      series={brandSignupChartSeries}
                      options={brandSignupChartOptions}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab4 === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={brandSignupChartSeries}
                      options={brandSignupChartOptions}
                      height={500}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Shop SignUp */}

        {selectedChartData.includes('s_s_s') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Shop owners Sign up Stats</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Monitor the at which shop owners join FlexDeals</p>
                  <div className="d-flex">
                    <div className="dropleft "></div>
                    &nbsp; &nbsp;
                    {/* <div className="dropleft ">
                    <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                      style={{
                        fontSize:'11px'
                      }}
                    >
                      <span style={{
                      paddingTop: '8px',
                      }}>
                      <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                      Export
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',
                      }}>
                        <img src="/assets/icons/chevron-downblue.svg" alt="" />
                      </span>
                    </button>
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div> */}
                  </div>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="shopsignuptab1">
                          <input
                            type="radio"
                            id="shopsignuptab1"
                            name="shopsignuptabs1"
                            value={1}
                            checked={selectedTab5 === 1}
                            onChange={() => handleTabChange5(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="shopsignuptab2">
                          <input
                            type="radio"
                            id="shopsignuptab2"
                            name="shopsignuptabs1"
                            value={2}
                            checked={selectedTab5 === 2}
                            onChange={() => handleTabChange5(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isShopSignupPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div>
                {selectedTab5 === 1 && (
                  <div className="chart-container">
                    {/* Content for Tab 1 */}
                    <ReactApexChart
                      type="bar"
                      series={shopSignupChartSeries}
                      options={shopSignupChartOptions}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab5 === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={shopSignupChartSeries}
                      options={shopSignupChartOptions}
                      height={500}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Shoppers SignUp */}

        {selectedChartData.includes('sh_s_s') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Shoppers Sign up Stats</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Monitor the rate at which shoppers join flexdeals</p>
                  <div className="d-flex">
                    <div className="dropleft "></div>
                    &nbsp; &nbsp;
                    {/* <div className="dropleft ">
                      <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        className={`${styles.export_btn} btn m-0`}
                        style={{
                          fontSize:'11px'
                        }}
                      >
                        <span style={{
                        paddingTop: '8px',
                        }}>
                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                          borderLeft:'1px solid white',
                          display:'inline-block',
                          height:'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign:'center',
                        }}>
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                      <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="shoppersSignUpTab1">
                          <input
                            type="radio"
                            id="shoppersSignUpTab1"
                            name="shoppersSignUpTabs1"
                            value={1}
                            checked={selectedTab11 === 1}
                            onChange={() => handleTabChange11(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="shoppersSignUpTab2">
                          <input
                            type="radio"
                            id="shoppersSignUpTab2"
                            name="shoppersSignUpTabs1"
                            value={2}
                            checked={selectedTab11 === 2}
                            onChange={() => handleTabChange11(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                SHOPPERS
              </span>
              {isShoppersSignupPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div>
                {selectedTab11 === 1 && (
                  <div className="chart-container">
                    {/* Content for Tab 1 */}
                    <ReactApexChart
                      type="bar"
                      series={shoppersSignupChartSeries}
                      options={shoppersSignupChartOptions}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab11 === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={shoppersSignupChartSeries}
                      options={shoppersSignupChartOptions}
                      height={500}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Time resolution */}
        {/* <Card style={{ marginBottom:'20px',borderRadius:'10px', marginTop:'40px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Time to Resolution
              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  Monitor how quickly problems are resolved for all users
                </p>
                <div className="d-flex mb-3">
                  <div className="dropleft ">
                    <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                      style={{
                        fontSize:'11px'
                      }}
                    >
                      <span style={{
                      paddingTop: '8px',
                      }}>
                      <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                      Export
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',
                      }}>
                        <img src="/assets/icons/chevron-down.png" alt="" />
                      </span>
                    </button>
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
                <ReactApexChart type="line" series={timeToResolutionChartSeries} options={timeToResolutionChartOptions} height={500} />
            </div>
          </CardContent>

        </Card> */}

        {/* Recurring revenue */}
        {/* <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Reoccuring Revenue

              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  Track your company's financial growth
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                      style={{
                        fontSize:'11px'
                      }}
                    >
                      <span style={{
                      paddingTop: '8px',
                      }}>
                      <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                      Export
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',
                      }}>
                        <img src="/assets/icons/chevron-down.png" alt="" />
                      </span>
                    </button>
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button"  className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button"    className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button"    className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-md-3 mt-4">
                <div className="col-md-6 mb-2">
                  <div className="row">
                    <div className="col-md-8">

                    </div>
                  </div>
                </div>
                <div className="row ml-auto pr-5 text-md-right">
                  <div className="card p-0">
                    <div className="card-body d-flex justify-content-around pt-1 py-1">
                      <label htmlFor="tab1" className="mr-3">
                        <input type="radio"
                          id="tab1"
                          name="tabs"
                          value={1}
                          checked={selectedTab6 === 1}
                          onChange={() => handleTabChange6(1)}
                          className="mt-2 mr-2"
                        />
                          Bar Chart
                      </label>
                      <label htmlFor="tab2">
                        <input type="radio"
                          id="tab2"
                          name="tabs"
                          value={2}
                          checked={selectedTab6 === 2}
                          onChange={() => handleTabChange6(2)}
                          className="mt-2 mr-2"
                        />
                        Line Chart
                      </label>
                    </div>
                  </div>
                  <span className="ml-3 mt-3">
                    <img src="/assets/icons/ordercolor.svg" alt="" />
                    &nbsp; Revenue
                  </span>
                </div>
              </div>
            </div>
            <div>
              {selectedTab6 === 1 && (
                <div>
                  <ReactApexChart type="bar" series={chartSeries3} options={chartOptions3} height={500} />
                </div>
              )}
              {selectedTab6 === 2 && (
                <div>
                  <ReactApexChart type="line" series={lineChartSeries} options={chartOptionLine} height={500} />
                </div>
              )}

            </div>
          </CardContent>
        </Card> */}

        {/* Average Revenue Per Brand */}
        {/* <Card style={{ marginBottom:'20px',borderRadius:'10px', marginTop:'40px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Average Revenue per Brands
              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  Track the average amount of profit made from brands
                </p>
                <div className="d-flex mb-3">
                  <div className="dropleft ">
                    <button className="btn  border" onClick={() => setRvModalOpen(true)}
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                  <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                      style={{
                        fontSize:'11px'
                      }}
                    >
                      <span style={{
                      paddingTop: '8px',
                      }}>
                      <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                      Export
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',
                      }}>
                        <img src="/assets/icons/chevron-down.png" alt="" />
                      </span>
                    </button>
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-6 mb-2 mt-4">
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className="card p-0 mb-2 mt-4"  >
                    <div className="card-body d-flex justify-content-around  py-1">
                      <label htmlFor="tab1">
                        <input type="radio"
                          id="tab1"
                          name="tabs"
                          value={1}
                          checked={selectedTab7 === 1}
                          onChange={() => handleTabChange7(1)}
                          className="mt-2 mr-2"
                        />
                          Bar Chart
                      </label>
                      <label htmlFor="tab2">
                        <input type="radio"
                          id="tab2"
                          name="tabs"
                          value={2}
                          checked={selectedTab7 === 2}
                          onChange={() => handleTabChange7(2)}
                          className="mt-2 mr-2"
                        />
                        Line Chart
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {selectedTab7 === 1 && (
                <div>
                  <ReactApexChart type="bar" series={averageRevenuePerBrandChartSeries} options={averageRevenuePerBrandChartOptions} height={500} />
                </div>
              )}
              {selectedTab7 === 2 && (
                <div>
                  <ReactApexChart type="line" series={averageRevenuePerBrandChartSeries} options={averageRevenuePerBrandChartOptions} height={500} />
                </div>
              )}
            </div>

          </CardContent>

        </Card> */}

        {/* Average Revenue Per Shop */}
        {/* <Card style={{ marginBottom:'20px',borderRadius:'10px', marginTop:'40px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Average Revenue per Shop Owner
              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  Track the average amount of profit made from shop owner
                </p>
                <div className="d-flex mb-3">
                  <div className="dropleft ">
                    <button className="btn  border" onClick={() => setRvModalOpen(true)}
                      style={{
                        backgroundColor:'#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px'
                      }}>
                      <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                      Filters
                      </small>
                    </button>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                  <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                      style={{
                        fontSize:'11px'
                      }}
                    >
                      <span style={{
                      paddingTop: '8px',
                      }}>
                      <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                      Export
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',
                      }}>
                        <img src="/assets/icons/chevron-down.png" alt="" />
                      </span>
                    </button>
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-6 mb-2 mt-4">
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className="card p-0 mb-2 mt-4"  >
                    <div className="card-body d-flex justify-content-around  py-1">
                      <label htmlFor="tab1">
                        <input type="radio"
                          id="tab1"
                          name="tabs"
                          value={1}
                          checked={selectedTab8 === 1}
                          onChange={() => handleTabChange8(1)}
                          className="mt-2 mr-2"
                        />
                          Bar Chart
                      </label>
                      <label htmlFor="tab2">
                        <input type="radio"
                          id="tab2"
                          name="tabs"
                          value={2}
                          checked={selectedTab8 === 2}
                          onChange={() => handleTabChange8(2)}
                          className="mt-2 mr-2"
                        />
                        Line Chart
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {selectedTab8 === 1 && (
                <div>
                  <ReactApexChart type="bar" series={averageRevenuePerShopChartSeries} options={averageRevenuePerShopChartOptions} height={500} />
                </div>
              )}
              {selectedTab8 === 2 && (
                <div>
                  <ReactApexChart type="line" series={averageRevenuePerShopChartSeries} options={averageRevenuePerShopChartOptions} height={500} />
                </div>
              )}
            </div>


          </CardContent>

        </Card> */}

        {/* Average Revenue Per Shoppers */}

        {selectedChartData.includes('a_r_p_s') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Average Revenue per Shopper</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Track the average amount of profit made from shopper</p>
                  <div className="d-flex mb-3">
                    {/* <div className="dropleft ">
                      <button className="btn  border" onClick={() => setRvModalOpen(true)}
                        style={{
                          backgroundColor:'#fff',
                          border: '1px solid #D0D5DD',
                          boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                          borderRadius: '4px'
                        }}>
                        <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" />
                        &nbsp;
                        <small className="d-none d-md-inline-block">
                        Filters
                        </small>
                      </button>
                    </div> */}
                    &nbsp; &nbsp;
                    {/* <div className="dropleft ">
                      <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        className={`${styles.export_btn} btn m-0`}
                        style={{
                          fontSize:'11px'
                        }}
                      >
                        <span style={{
                        paddingTop: '8px',
                        }}>
                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                          borderLeft:'1px solid white',
                          display:'inline-block',
                          height:'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign:'center',
                        }}>
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                      <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="row mt-md-0 mt-4">
                  <div className="col-md-6 mb-2 mt-4"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    <div className="card p-0 mb-2 mt-4">
                      <div className="card-body d-flex justify-content-around  py-1">
                        <label htmlFor="averagerevtab1">
                          <input
                            type="radio"
                            id="averagerevtab1"
                            name="averagerevtabs1"
                            value={1}
                            checked={selectedTab9 === 1}
                            onChange={() => handleTabChange9(1)}
                            className="mt-2 mr-2"
                          />
                          Bar Chart
                        </label>
                        <label htmlFor="averagerevtab2">
                          <input
                            type="radio"
                            id="averagerevtab2"
                            name="averagerevtabs1"
                            value={2}
                            checked={selectedTab9 === 2}
                            onChange={() => handleTabChange9(2)}
                            className="mt-2 mr-2"
                          />
                          Line Chart
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isAverageRevPending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div>
                {selectedTab9 === 1 && (
                  <div className="chart-container">
                    {/* Content for Tab 1 */}
                    <ReactApexChart
                      type="bar"
                      series={averageRevenuePerShopperChartSeries}
                      options={averageRevenuePerShopperChartOptions}
                      height={500}
                    />
                  </div>
                )}
                {selectedTab9 === 2 && (
                  <div className="chart-container">
                    {/* Content for Tab 2 */}
                    <ReactApexChart
                      type="line"
                      series={averageRevenuePerShopperChartSeries}
                      options={averageRevenuePerShopperChartOptions}
                      height={500}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Bounce Rate */}
        {/* <Card style={{ marginBottom:'20px',borderRadius:'10px'}} className='p-0'>
          <CardContent>
            <div className={`${styles.insight_header} pl-md-5`}>
              <h5>
                Bounce Rate

              </h5>
              <div className="d-md-flex justify-content-between">
                <p>
                  View the rate at which people leave a webpage without <br />
                  taking an action
                </p>
                <div className="d-flex">
                  <div className="dropleft ">
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  <div className="dropleft ">
                    <button
                      id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      className={`${styles.export_btn} btn m-0`}
                      style={{
                        fontSize:'11px'
                      }}
                    >
                      <span style={{
                      paddingTop: '8px',
                      }}>
                      <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                      Export
                      </span>
                      &nbsp;
                      &nbsp;
                      <span style={{
                        borderLeft:'1px solid white',
                        display:'inline-block',
                        height:'inherit',
                        paddingTop: '8px',
                        paddingLeft: '8px',
                        textAlign:'center',
                      }}>
                        <img src="/assets/icons/chevron-down.png" alt="" />
                      </span>
                    </button>
                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                      <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                      <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                      <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row mt-md-0 mt-4">
                <div className="col-md-6 mb-2 mt-4">
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-4">
                  <div className="card  p-0 mt-4"  >
                    <div className="card-body d-flex justify-content-around  py-1">
                      <label htmlFor="tabbs">
                        <input type="radio"
                          id="tabbs"
                          name="tabsss"
                          value={1}
                          checked={selectedTab10 === 1}
                          onChange={() => handleTabChange10(1)}
                          className="mt-2 mr-2"
                        />
                          Bar Chart
                      </label>
                      <label htmlFor="ttab2">
                        <input type="radio"
                          id="ttab2"
                          name="tabsss"
                          value={2}
                          checked={selectedTab10 === 2}
                          onChange={() => handleTabChange10(2)}
                          className="mt-2 mr-2"
                        />
                        Line Chart
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {selectedTab10 === 1 && (
                <div>
                  <ReactApexChart type="bar" series={bounceRateChartSeries} options={bounceRateChartOptions} height={500} />
                </div>
              )}
              {selectedTab10 === 2 && (
                <div>
                  <ReactApexChart type="line" series={bounceRateChartSeries} options={bounceRateChartOptions} height={500} />
                </div>
              )}

            </div>

          </CardContent>

        </Card> */}

        {/* Trends of Purchase */}

        {selectedChartData.includes('t_o_p') && (
          <Card
            style={{
              marginBottom: '20px',
              borderRadius: '10px',
              marginTop: '40px',
            }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Trends of Purchase</h5>
                <div className="d-md-flex justify-content-between">
                  <p>View how well each products are doing in the market</p>
                </div>
                <div className="d-flex mb-3">
                  <div className="dropleft ml-auto">
                    <button
                      className="btn  border"
                      onClick={() => handleModalVal('t_o_p')}
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #D0D5DD',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className=""
                        style={{ display: 'inline', width: '28%' }}
                        src="/assets/icons/filterlines.svg"
                        alt=""
                      />
                      &nbsp;
                      <small className="d-none d-md-inline-block">
                        Filters
                      </small>
                    </button>
                  </div>
                  &nbsp; &nbsp;
                </div>
              </div>
              <span className="ml-md-3" style={{ fontSize: '12px' }}>
                QUANTITY
              </span>
              {isTrendsOfPurchasePending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="chart-container">
                <ReactApexChart
                  type="line"
                  series={purchaseTrendChartSeries}
                  options={purchaseTrendChartOptions}
                  height={500}
                />
              </div>
            </CardContent>
          </Card>
        )}

        {/* Retention Rate */}

        {selectedChartData.includes('r_rate') && (
          <Card
            style={{ marginBottom: '20px', borderRadius: '10px' }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Retention Rate</h5>
                <div className="d-md-flex justify-content-between">
                  <p>Track your percentage of users that keeps coming back</p>
                  <div className="d-flex">
                    <div className="dropleft ">
                      <div
                        className="dropdown-menu drop-left"
                        aria-labelledby="dropdownMenuButton"
                      ></div>
                    </div>
                    &nbsp; &nbsp;
                    {/* <div className="dropleft ">
                      <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        className={`${styles.export_btn} btn m-0`}
                        style={{
                          fontSize:'11px'
                        }}
                      >
                        <span style={{
                        paddingTop: '8px',
                        }}>
                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                          borderLeft:'1px solid white',
                          display:'inline-block',
                          height:'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign:'center',
                        }}>
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              {isRetentionRatePending && (
                <BallTriangle
                  height={35}
                  width={45}
                  radius={5}
                  color="#af3585"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
              <div className="chart-container chartOptions2">
                <ReactApexChart
                  type="line"
                  series={rrChartSeries}
                  options={rrChartOptionLine}
                  height={500}
                />
              </div>
            </CardContent>
          </Card>
        )}

        {/* Market Share */}

        {selectedChartData.includes('m_s') && (
          <Card
            style={{ marginBottom: '20px', borderRadius: '10px' }}
            className="p-0"
          >
            <CardContent>
              <div className={`${styles.insight_header} pl-md-5`}>
                <h5>Market Share</h5>
                <div className="d-md-flex justify-content-between">
                  <p>A view of your brands market share</p>
                  <div className="d-flex mb-3">
                    <div className="dropleft ">
                      <button
                        className="btn  border"
                        onClick={() => setMsModalOpen(true)}
                        style={{
                          backgroundColor: '#fff',
                          border: '1px solid #D0D5DD',
                          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                          borderRadius: '4px',
                        }}
                      >
                        <img
                          className=""
                          style={{ display: 'inline', width: '28%' }}
                          src="/assets/icons/filterlines.svg"
                          alt=""
                        />
                        &nbsp;
                        <small className="d-none d-md-inline-block">
                          Filters
                        </small>
                      </button>
                    </div>
                    &nbsp; &nbsp;
                    {/* <div className="dropleft ">
                      <button
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        className={`${styles.export_btn} btn m-0`}
                        style={{
                          fontSize:'11px'
                        }}
                      >
                        <span style={{
                        paddingTop: '8px',
                        }}>
                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                        Export
                        </span>
                        &nbsp;
                        &nbsp;
                        <span style={{
                          borderLeft:'1px solid white',
                          display:'inline-block',
                          height:'inherit',
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          textAlign:'center',
                        }}>
                          <img src="/assets/icons/chevron-down.png" alt="" />
                        </span>
                      </button>
                      <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                        <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                        <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                        <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  {isMarketSharePending && (
                    <BallTriangle
                      height={35}
                      width={45}
                      radius={5}
                      color="#af3585"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  )}
                  <div className="chart-container chartOptions2">
                    <ReactApexChart
                      type="pie"
                      series={marketShareChartSeries}
                      options={marketShareChartOptions}
                      height={400}
                    />
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Order by Location */}

        {selectedChartData.includes('o_b_l') && (
          <Card
            className={`${styles.card_wrapper} p-0`}
            style={{ marginBottom: '20px', borderRadius: '10px' }}
          >
            <CardContent>
              <div className="row">
                <div className="col-md-7">
                  <GoogleMapHeatmap data={mapData} />
                </div>
                <div className="col-md-4">
                  <div className={`${styles.insight_header} mt-5`}>
                    <h5>Order by Location</h5>
                    <div className="d-lg-flex justify-content-between">
                      <p>View how your order is doing on various location</p>
                    </div>
                    <div>
                      <div className="dropleft ">
                        <button
                          className="btn  border"
                          onClick={() => setOBLModalOpen(true)}
                          style={{
                            backgroundColor: '#fff',
                            border: '1px solid #D0D5DD',
                            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                            borderRadius: '4px',
                          }}
                        >
                          <img
                            className=""
                            style={{ display: 'inline', width: '28%' }}
                            src="/assets/icons/filterlines.svg"
                            alt=""
                          />
                          &nbsp;
                          <small className="d-none d-md-inline-block">
                            Filters
                          </small>
                        </button>
                        <div
                          className="dropdown-menu drop-left"
                          aria-labelledby="dropdownMenuButton"
                        ></div>
                      </div>
                    </div>
                    {isOBLPending && (
                      <BallTriangle
                        height={50}
                        width={55}
                        radius={5}
                        color="#af3585"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        <GlobalModal
          open={modalOpen}
          onClose={handleClose}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="1000px !important"
          maxHeight="400px !important"
          overflow="auto"
          bgcolor="#fff"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                {/* Filter */}
              </h5>
              <span
                onClick={handleClose}
                className="justify-content-end"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <h5 className="modal-title" id="exampleModalLabel">
              Filter
            </h5>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-4">
                <div className="allprod_wrapper">
                  <div
                    className="modal-header"
                    style={{ border: 'none' }}
                  ></div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllProduct}
                            onChange={handleSelectAllProduct}
                          />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductPending &&
                          productData.length > 0 &&
                          productData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedProductIds.includes(
                                      el._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(el._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.product_name}
                                  src={el?.product_image}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.product_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.product_spec}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isProductPending && productData.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-4"
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <div
                    className="modal-header"
                    style={{ border: 'none' }}
                  ></div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllBrand}
                            onChange={handleSelectAllBrand}
                          />
                          &nbsp;&nbsp;All Brands
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExampleB"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExampleB">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchBrand}
                            value={searchBrands}
                          />
                        </p>
                        {!isBrandPending &&
                          brandData.length > 0 &&
                          brandData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedBrandIds.includes(el._id)}
                                    onChange={() =>
                                      handleBrandboxChange(el._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.business_name}
                                  src={el?.business_logo}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.business_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.state} {', '} {el?.country}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isBrandPending && brandData.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isBrandPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-4`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <div
                    className="modal-header"
                    style={{ border: 'none' }}
                  ></div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">
                          {/* <input
                          type="checkbox"
                          checked={selectAllStates}
                          onChange={handleSelectAllStates}
                        />
                        &nbsp;&nbsp; */}
                          Nigeria
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState}
                            value={searchStates}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="radio"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState.includes(el)}
                                      onChange={() => handleStateChange(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_color} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleProceedFilter}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={rvModalOpen}
          onClose={handleCloseRvModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          maxHeight="400px !important"
          overflow="auto"
          bgcolor="#F5F5F5"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          background="#FFFFFF"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              {/* <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5> */}
              <span
                onClick={handleCloseRvModal}
                className="ml-auto"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Brands
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllBrand}
                            onChange={handleSelectAllBrand}
                          />
                          &nbsp;&nbsp; All Brands
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExampleB"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExampleB">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchBrand}
                            value={searchBrands}
                          />
                        </p>
                        {!isBrandPending &&
                          brandData.length > 0 &&
                          brandData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedBrandIds.includes(el._id)}
                                    onChange={() =>
                                      handleBrandboxChange(el._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.business_name}
                                  src={el?.business_logo}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.business_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.state} {', '} {el?.country}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isBrandPending && brandData.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isBrandPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allprod_wrapper mt-3">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Products
                  </h6>
                  <div className={`${styles.modal_body} mt-1`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllProduct}
                            onChange={handleSelectAllProduct}
                          />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductPending &&
                          productData.length > 0 &&
                          productData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedProductIds.includes(
                                      el._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(el._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.product_name}
                                  src={el?.product_image}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.product_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.product_spec}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isProductPending && productData.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Location
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">
                          {/* <input
                          type="checkbox"
                          checked={selectAllStates}
                          onChange={handleSelectAllStates}
                        />
                        &nbsp;&nbsp; */}
                          Nigeria
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState2}
                            value={searchStates2}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="radio"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState2.includes(el)}
                                      onChange={() => handleStateChange2(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_color} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleProceedChartFilter}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={hpModalOpen}
          onClose={handleCloseHpModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          maxHeight="400px !important"
          overflow="auto"
          bgcolor="#F5F5F5"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          background="#FFFFFF"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              {/* <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5> */}
              <span
                onClick={handleCloseHpModal}
                className="ml-auto"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Brands
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllBrand}
                            onChange={handleSelectAllBrand}
                          />
                          &nbsp;&nbsp; All Brands
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExampleB"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExampleB">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchBrand}
                            value={searchBrands}
                          />
                        </p>
                        {!isBrandPending &&
                          brandData.length > 0 &&
                          brandData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedBrandIds.includes(el._id)}
                                    onChange={() =>
                                      handleBrandboxChange(el._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.business_name}
                                  src={el?.business_logo}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.business_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.state} {', '} {el?.country}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isBrandPending && brandData.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isBrandPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allprod_wrapper mt-3">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Products/Categories
                  </h6>
                  <div className={`${styles.modal_body} mt-1`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllProductCategory}
                            onChange={handleSelectAllProductCategories}
                          />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductCatPending &&
                          productCatData?.length > 0 &&
                          productCatData?.slice(0, 5).map((el, i) => {
                            return (
                              <>
                                <div className="p-2 d-md-flex" key={i}>
                                  <div className="mr-3 mt-1">
                                    <input
                                      type="checkbox"
                                      checked={selectedProductCatIds.includes(
                                        el._id
                                      )}
                                      onChange={() =>
                                        handleProductCatCheckBoxChange(el._id)
                                      }
                                    />
                                  </div>
                                  <Avatar
                                    className={`${styles.avatrr} mr-3 floadt-md-left`}
                                    alt={el?.category_name}
                                    src={el?.category_image}
                                  />
                                  <div className=" ">
                                    <p
                                      className="mb-0 text-left"
                                      style={{ fontSize: '14px' }}
                                    >
                                      <strong>{el?.category_name}</strong>
                                    </p>
                                    <p
                                      className="mb-0 text-left"
                                      style={{ fontSize: '10px' }}
                                    >
                                      {el?.total_product < 2
                                        ? el?.total_product + ' product'
                                        : el?.total_product + ' products'}
                                    </p>
                                  </div>
                                  <div>
                                    <span
                                      className={`${styles.span}`}
                                      data-toggle="collapse"
                                      data-target={`#collapseExample${el?._id}`}
                                      aria-expanded="false"
                                      aria-controls={`collapseExample${el?._id}`}
                                      onClick={() =>
                                        handleFetchCategoryProducts(el?._id)
                                      }
                                    >
                                      <label>
                                        <input type="checkbox" name="" />
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </span>
                                      </label>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="collapse"
                                  id={`collapseExample${el?._id}`}
                                >
                                  <div
                                    className="card card-body pl-5 pb-0"
                                    style={{ border: 'none' }}
                                  >
                                    <p>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        name=""
                                        id=""
                                        onChange={handleSearchCategory}
                                        value={searchCategory}
                                      />
                                    </p>
                                    {!isCategoryPending &&
                                      catProductData?.length > 0 &&
                                      catProductData
                                        ?.slice(0, 5)
                                        .map((el, i) => {
                                          return (
                                            <div
                                              className="p-2 d-md-flex"
                                              key={i}
                                            >
                                              <div className="mr-3 mt-1">
                                                <input
                                                  type="checkbox"
                                                  checked={selectedCategoryIds.includes(
                                                    el._id
                                                  )}
                                                  onChange={() =>
                                                    handleCategoryBoxChange(
                                                      el._id
                                                    )
                                                  }
                                                />
                                              </div>
                                              <Avatar
                                                className={`${styles.avatrr} mr-3 floadt-md-left`}
                                                alt={el?.product_name}
                                                src={el?.product_image}
                                              />
                                              <div className=" ">
                                                <p
                                                  className="mb-0 text-left"
                                                  style={{ fontSize: '14px' }}
                                                >
                                                  <strong>
                                                    {el?.product_name}
                                                  </strong>
                                                </p>
                                                <p
                                                  className="mb-0 text-left"
                                                  style={{ fontSize: '10px' }}
                                                >
                                                  {el?.product_spec}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    {!isCategoryPending &&
                                      catProductData?.length < 1 && (
                                        <p>
                                          <i>No Record Found...</i>
                                        </p>
                                      )}
                                    <div className="d-flex justify-content-center">
                                      {isCategoryPending && (
                                        <BallTriangle
                                          height={30}
                                          width={35}
                                          radius={5}
                                          color="#af3585"
                                          ariaLabel="ball-triangle-loading"
                                          wrapperClass={{}}
                                          wrapperStyle=""
                                          visible={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        {!isProductCatPending && productCatData?.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductCatPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Location
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">Nigeria</label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState3}
                            value={searchStates3}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="radio"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState3.includes(el)}
                                      onChange={() => handleStateChange3(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_primary} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => handleProceedProductSalesChartFilter()}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={oblModalOpen}
          onClose={handleCloseOBLModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          maxHeight="400px !important"
          overflow="auto"
          bgcolor="#F5F5F5"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          background="#FFFFFF"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              {/* <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5> */}
              <span
                onClick={handleCloseOBLModal}
                className="ml-auto"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Brands
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input
                            type="checkbox"
                            checked={selectAllBrand}
                            onChange={handleSelectAllBrand}
                          />
                          &nbsp;&nbsp; All Brands
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExampleB"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExampleB">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchBrand}
                            value={searchBrands}
                          />
                        </p>
                        {!isBrandPending &&
                          brandData.length > 0 &&
                          brandData.slice(0, 5).map((el, i) => {
                            return (
                              <div className="p-2 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedBrandIds.includes(el._id)}
                                    onChange={() =>
                                      handleBrandboxChange(el._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.business_name}
                                  src={el?.business_logo}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.business_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.state} {', '} {el?.country}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {!isBrandPending && brandData.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isBrandPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allprod_wrapper mt-3">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Products/Categories
                  </h6>
                  <p className="ml-md-3">Select Categories</p>
                  <div className={`${styles.modal_body}`}>
                    <div
                      className="card card-body pl-3 pb-0"
                      style={{ border: 'none' }}
                    >
                      <p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          name=""
                          id=""
                          onChange={handleSearchProduct}
                          value={searchProducts}
                        />
                      </p>
                      {!isProductCatPending &&
                        productCatData?.length > 0 &&
                        productCatData?.slice(0, 5).map((el, i) => {
                          return (
                            <>
                              <div className="p-2 pl-3 d-md-flex" key={i}>
                                <div className="mr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    checked={selectedProductCatIds.includes(
                                      el._id
                                    )}
                                    onChange={() =>
                                      handleProductCatCheckBoxChange(el._id)
                                    }
                                  />
                                </div>
                                <Avatar
                                  className={`${styles.avatrr} mr-3 floadt-md-left`}
                                  alt={el?.category_name}
                                  src={el?.category_image}
                                />
                                <div className=" ">
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '14px' }}
                                  >
                                    <strong>{el?.category_name}</strong>
                                  </p>
                                  <p
                                    className="mb-0 text-left"
                                    style={{ fontSize: '10px' }}
                                  >
                                    {el?.total_product < 2
                                      ? el?.total_product + ' product'
                                      : el?.total_product + ' products'}
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className="btn w-100 d-block"
                data-dismiss="modal"
                aria-label="Close"
              >
                Proceed
              </button>
            </div>

            <div
              className={`${styles.bordered_sect} col-md-6`}
              style={{
                borderLeft: '2px solid #ddd',
              }}
            >
              <div className="allprod_wrapper">
                <h6
                  className="modal-title mb-2"
                  id="exampleModalLabel"
                  style={{ fontWeight: 'bold' }}
                >
                  Location/Store
                </h6>
                <div className={`${styles.modal_body}`}>
                  <div className="row my-0 py-0">
                    <div className="col-5 py-0 my-0">
                      <label htmlFor="country">Nigeria</label>
                    </div>
                    <div className="col-5 py-0 my-0">
                      <span
                        className={`${styles.span}`}
                        data-toggle="collapse"
                        data-target="#collapseExample02"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <label>
                          <input type="checkbox" name="" />
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-chevron-down"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                              />
                            </svg>
                            {/* <i className="bi bi-chevron-down"></i> */}
                          </span>
                        </label>
                      </span>
                    </div>
                  </div>

                  <div className="collapse pl-1" id="collapseExample02">
                    <div
                      className="card card-body pl-5 pb-0"
                      style={{ border: 'none' }}
                    >
                      <p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          name=""
                          id=""
                          onChange={handleSearchState3}
                          value={searchStates3}
                        />
                      </p>
                      <div className="row mt-3">
                        {!loadingState &&
                          states?.length > 0 &&
                          states?.slice(0, 14).map((el, i) => {
                            return (
                              <div
                                className="col-6 py-0 my-0 d-md-flex"
                                key={i}
                              >
                                <label htmlFor={`state-${i}`}>
                                  <input
                                    type="radio"
                                    id={`state-${i}`}
                                    className="mr-2"
                                    checked={selectedState3.includes(el)}
                                    onChange={() => handleStateChange3(el)}
                                  />
                                </label>
                                <div>{el}</div>
                              </div>
                            );
                          })}
                      </div>
                      {!loadingState && states.length < 1 && (
                        <p>
                          <i>No Record Found...</i>
                        </p>
                      )}
                      <div className="d-flex justify-content-center">
                        {loadingState && (
                          <BallTriangle
                            height={50}
                            width={55}
                            radius={5}
                            color="#af3585"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <button
              className={`${styles.btn_primary} btn w-100 d-block`}
              data-dismiss="modal"
              aria-label="Close"
              type="button"
              onClick={() => handleProceedOBLFilter()}
            >
              Proceed
            </button>
          </div>
        </GlobalModal>

        <GlobalModal
          open={toAndCoModalOpen}
          onClose={handleCloseToAndCoModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          height="auto !important"
          maxHeight="400px !important"
          overflow="auto"
          bgcolor="#fff"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                Filter
              </h5>
              <span
                onClick={handleCloseToAndCoModal}
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      All Products
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input type="checkbox" name="allprod" id="allprod" />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample04"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample04">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                          />
                        </p>
                        <p>
                          <label htmlFor="milo1">
                            <input type="checkbox" name="milo1" id="milo1" />
                            &nbsp;&nbsp;<span>Nestle Milo</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="water1">
                            <input type="checkbox" name="water1" id="water1" />
                            &nbsp;&nbsp;<span>Pure life table water</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="nescafe1">
                            <input
                              type="checkbox"
                              name="nescafe1"
                              id="nescafe1"
                            />
                            &nbsp;&nbsp;<span>Nescafe</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="kitkat1">
                            <input
                              type="checkbox"
                              name="kitkat1"
                              id="kitkat1"
                            />
                            &nbsp;&nbsp;<span>Kitkat</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="maggi1">
                            <input type="checkbox" name="maggi1" id="maggi1" />
                            &nbsp;&nbsp;<span>Maggi</span>
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allprod_wrapper">
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input type="checkbox" name="allprod" id="allprod" />
                          &nbsp;&nbsp;All Category
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                          />
                        </p>
                        <p>
                          <label htmlFor="milo1">
                            <input type="checkbox" name="milo1" id="milo1" />
                            &nbsp;&nbsp;<span>Beverages</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="water1">
                            <input type="checkbox" name="water1" id="water1" />
                            &nbsp;&nbsp;<span>Snacks</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="nescafe1">
                            <input
                              type="checkbox"
                              name="nescafe1"
                              id="nescafe1"
                            />
                            &nbsp;&nbsp;<span>Milk</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="kitkat1">
                            <input
                              type="checkbox"
                              name="kitkat1"
                              id="kitkat1"
                            />
                            &nbsp;&nbsp;<span>Table Water</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="maggi1">
                            <input type="checkbox" name="maggi1" id="maggi1" />
                            &nbsp;&nbsp;<span>Groceries</span>
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Location/Store
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        name=""
                        id=""
                      />
                    </p>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">
                          <input type="checkbox" name="country" id="country" />
                          &nbsp;&nbsp;Nigeria
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                          />
                        </p>
                        <div className="row mt-3">
                          <div className="col-5 py-0 my-0">
                            <label htmlFor="state">
                              <input type="checkbox" name="state" id="state" />
                              &nbsp;&nbsp;Lagos
                            </label>
                          </div>
                          <div className="col-5 py-0 my-0">
                            <span
                              className={`${styles.span}`}
                              data-toggle="collapse"
                              data-target="#collapseExample03"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <label>
                                <input type="checkbox" name="" />
                                <span>
                                  <i className="bi bi-chevron-down"></i>
                                </span>
                              </label>
                            </span>
                          </div>
                        </div>

                        <div className="collapse pl-1" id="collapseExample03">
                          <div
                            className="card card-body pl-5 pb-0"
                            style={{ border: 'none' }}
                          >
                            <p>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                name=""
                                id=""
                              />
                            </p>
                            <p>
                              <label htmlFor="milo2">
                                <input
                                  type="checkbox"
                                  name="milo2"
                                  id="milo2"
                                />
                                &nbsp;&nbsp;<span>Mama tee superstore</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="water2">
                                <input
                                  type="checkbox"
                                  name="water2"
                                  id="water2"
                                />
                                &nbsp;&nbsp;<span>Shoprite</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="nescafe2">
                                <input
                                  type="checkbox"
                                  name="nescafe2"
                                  id="nescafe2"
                                />
                                &nbsp;&nbsp;<span>Justrite</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="kitkat2">
                                <input
                                  type="checkbox"
                                  name="kitkat2"
                                  id="kitkat2"
                                />
                                &nbsp;&nbsp;<span>Mallmart</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="maggi2">
                                <input
                                  type="checkbox"
                                  name="maggi2"
                                  id="maggi2"
                                />
                                &nbsp;&nbsp;<span>Jendol</span>
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_color} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={msModalOpen}
          onClose={handleCloseMsModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          height="auto !important"
          maxHeight="400px !important"
          overflow="auto"
          bgcolor="#fff"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <span
                onClick={handleCloseMsModal}
                className="ml-auto"
                style={{ cursor: 'pointer' }}
              >
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Categories
                  </h6>
                  <div className={`${styles.modal_body} mt-1`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          {/* <input
                            type="checkbox"
                            checked={selectAllProductCategory}
                            onChange={handleSelectAllProductCategories}
                          /> */}
                          &nbsp;&nbsp;All Categories
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchProduct}
                            value={searchProducts}
                          />
                        </p>
                        {!isProductCatPending &&
                          productCatData?.length > 0 &&
                          productCatData?.slice(0, 5).map((el, i) => {
                            return (
                              <>
                                <div className="p-2 d-md-flex" key={i}>
                                  <div className="mr-3 mt-1">
                                    <input
                                      type="radio"
                                      checked={selectedProductCatIds.includes(
                                        el._id
                                      )}
                                      onChange={() =>
                                        handleProductCatCheckBoxChange01(el._id)
                                      }
                                    />
                                  </div>
                                  <div className=" ">
                                    <p
                                      className="mb-0 text-left"
                                      style={{ fontSize: '14px' }}
                                    >
                                      <strong>{el?.category_name}</strong>
                                    </p>
                                  </div>
                                  <div></div>
                                </div>
                              </>
                            );
                          })}
                        {!isProductCatPending && productCatData?.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {isProductCatPending && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <h6
                    className="modal-title mb-2"
                    id="exampleModalLabel"
                    style={{ fontWeight: 'bold' }}
                  >
                    Location
                  </h6>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">Nigeria</label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                              {/* <i className="bi bi-chevron-down"></i> */}
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                            onChange={handleSearchState3}
                            value={searchStates3}
                          />
                        </p>
                        <div className="row mt-3">
                          {!loadingState &&
                            states?.length > 0 &&
                            states?.slice(0, 14).map((el, i) => {
                              return (
                                <div
                                  className="col-6 py-0 my-0 d-md-flex"
                                  key={i}
                                >
                                  <label htmlFor={`state-${i}`}>
                                    <input
                                      type="radio"
                                      id={`state-${i}`}
                                      className="mr-2"
                                      checked={selectedState3.includes(el)}
                                      onChange={() => handleStateChange3(el)}
                                    />
                                  </label>
                                  <div>{el}</div>
                                </div>
                              );
                            })}
                        </div>
                        {!loadingState && states.length < 1 && (
                          <p>
                            <i>No Record Found...</i>
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          {loadingState && (
                            <BallTriangle
                              height={50}
                              width={55}
                              radius={5}
                              color="#af3585"
                              ariaLabel="ball-triangle-loading"
                              wrapperClass={{}}
                              wrapperStyle=""
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_color} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleProceedMSFilter}
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          open={sbpModalOpen}
          onClose={handleCloseSbpModal}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="740px !important"
          height="auto !important"
          maxHeight="400px !important"
          overflow="auto"
          bgcolor="#fff"
          border="1px solid #F5F5F5"
          borderRadius="5px"
          boxShadow="24"
          p="25px"
        >
          <div className={`${styles.modal_content}`}>
            <div className={`${styles.modal_header} mb-0`}>
              <h5
                style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}
              >
                Filter
              </h5>
              <span onClick={handleCloseSbpModal} style={{ cursor: 'pointer' }}>
                <img src="/assets/icons/x.svg" alt="" />
              </span>
            </div>
            <div className={`${styles.modal_content} row`}>
              <div className="col-md-6">
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      All Products
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input type="checkbox" name="allprod" id="allprod" />
                          &nbsp;&nbsp;All Products
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample04"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample04">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                          />
                        </p>
                        <p>
                          <label htmlFor="milo1">
                            <input type="checkbox" name="milo1" id="milo1" />
                            &nbsp;&nbsp;<span>Nestle Milo</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="water1">
                            <input type="checkbox" name="water1" id="water1" />
                            &nbsp;&nbsp;<span>Pure life table water</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="nescafe1">
                            <input
                              type="checkbox"
                              name="nescafe1"
                              id="nescafe1"
                            />
                            &nbsp;&nbsp;<span>Nescafe</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="kitkat1">
                            <input
                              type="checkbox"
                              name="kitkat1"
                              id="kitkat1"
                            />
                            &nbsp;&nbsp;<span>Kitkat</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="maggi1">
                            <input type="checkbox" name="maggi1" id="maggi1" />
                            &nbsp;&nbsp;<span>Maggi</span>
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allprod_wrapper">
                  <div className={`${styles.modal_body}`}>
                    <div className="row my-0 py-0">
                      <div className="col-7 py-0 my-0">
                        <label htmlFor="allprod">
                          <input type="checkbox" name="allprod" id="allprod" />
                          &nbsp;&nbsp;All Category
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample01"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse" id="collapseExample01">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                          />
                        </p>
                        <p>
                          <label htmlFor="milo1">
                            <input type="checkbox" name="milo1" id="milo1" />
                            &nbsp;&nbsp;<span>Beverages</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="water1">
                            <input type="checkbox" name="water1" id="water1" />
                            &nbsp;&nbsp;<span>Snacks</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="nescafe1">
                            <input
                              type="checkbox"
                              name="nescafe1"
                              id="nescafe1"
                            />
                            &nbsp;&nbsp;<span>Milk</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="kitkat1">
                            <input
                              type="checkbox"
                              name="kitkat1"
                              id="kitkat1"
                            />
                            &nbsp;&nbsp;<span>Table Water</span>
                          </label>
                        </p>
                        <p>
                          <label htmlFor="maggi1">
                            <input type="checkbox" name="maggi1" id="maggi1" />
                            &nbsp;&nbsp;<span>Groceries</span>
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.bordered_sect} col-md-6`}
                style={{
                  borderLeft: '2px solid #ddd',
                }}
              >
                <div className="allprod_wrapper">
                  <div className="modal-header" style={{ border: 'none' }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Location/Store
                    </h5>
                  </div>
                  <div className={`${styles.modal_body}`}>
                    <p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        name=""
                        id=""
                      />
                    </p>
                    <div className="row my-0 py-0">
                      <div className="col-5 py-0 my-0">
                        <label htmlFor="country">
                          <input type="checkbox" name="country" id="country" />
                          &nbsp;&nbsp;Nigeria
                        </label>
                      </div>
                      <div className="col-5 py-0 my-0">
                        <span
                          className={`${styles.span}`}
                          data-toggle="collapse"
                          data-target="#collapseExample02"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <label>
                            <input type="checkbox" name="" />
                            <span>
                              <i className="bi bi-chevron-down"></i>
                            </span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="collapse pl-1" id="collapseExample02">
                      <div
                        className="card card-body pl-5 pb-0"
                        style={{ border: 'none' }}
                      >
                        <p>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            name=""
                            id=""
                          />
                        </p>
                        <div className="row mt-3">
                          <div className="col-5 py-0 my-0">
                            <label htmlFor="state">
                              <input type="checkbox" name="state" id="state" />
                              &nbsp;&nbsp;Lagos
                            </label>
                          </div>
                          <div className="col-5 py-0 my-0">
                            <span
                              className={`${styles.span}`}
                              data-toggle="collapse"
                              data-target="#collapseExample03"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <label>
                                <input type="checkbox" name="" />
                                <span>
                                  <i className="bi bi-chevron-down"></i>
                                </span>
                              </label>
                            </span>
                          </div>
                        </div>

                        <div className="collapse pl-1" id="collapseExample03">
                          <div
                            className="card card-body pl-5 pb-0"
                            style={{ border: 'none' }}
                          >
                            <p>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                name=""
                                id=""
                              />
                            </p>
                            <p>
                              <label htmlFor="milo2">
                                <input
                                  type="checkbox"
                                  name="milo2"
                                  id="milo2"
                                />
                                &nbsp;&nbsp;<span>Mama tee superstore</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="water2">
                                <input
                                  type="checkbox"
                                  name="water2"
                                  id="water2"
                                />
                                &nbsp;&nbsp;<span>Shoprite</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="nescafe2">
                                <input
                                  type="checkbox"
                                  name="nescafe2"
                                  id="nescafe2"
                                />
                                &nbsp;&nbsp;<span>Justrite</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="kitkat2">
                                <input
                                  type="checkbox"
                                  name="kitkat2"
                                  id="kitkat2"
                                />
                                &nbsp;&nbsp;<span>Mallmart</span>
                              </label>
                            </p>
                            <p>
                              <label htmlFor="maggi2">
                                <input
                                  type="checkbox"
                                  name="maggi2"
                                  id="maggi2"
                                />
                                &nbsp;&nbsp;<span>Jendol</span>
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                className={`${styles.btn_color} btn w-100 d-block`}
                data-dismiss="modal"
                aria-label="Close"
              >
                Proceed
              </button>
            </div>
          </div>
        </GlobalModal>
      </div>
    </>
  );
}
