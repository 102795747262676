

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import StarRating from "src/context/Ratings";
// import { FaStar } from 'react-icons/fa'
import {
  Avatar,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate, useParams } from "react-router-dom"
import bus from 'src/bus'
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import moment from "moment";
import { BallTriangle } from 'react-loader-spinner'
// import StarRating from 'react-star-ratings';
// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor:'#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius:'5px',
  boxShadow: 24,
  p: 3,
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ptvp=PAGE TO VIEW (PRODUCT)

export default function DashboardAppPage( { pageId }) {
  // console.log(pageId);
  const navigate = useNavigate();

  const [value, setValue] = useState('1');

  const [modalOpen, setModalOpen] = useState(false);
  const [dataValue, setDataValue] = useState('');
  const [searchvalue, setSearchvalue] = useState('');
  const [isPending, setIsPending] = useState('');
  const [customerReview, setCustomerReview] = useState({
    brand_info: {},
  });
  const [viewReview, setViewReview] = useState([]);
  const [viewReviewOnClick, setViewReviewOnClick] = useState('');
  const [dataPagination, setDataPagination] = useState('');
  const [viewPending, setViewPending] = useState(false);
  const [currentData, setcurrentData] = useState({});
  const [viewData, setViewData] = useState({
    shops: [],
    products: [],
    productcategories:[],
    stores: [],
    address: {},
    wallet_balance: {},
  });
  const [filterSearch, setFilterSearch] = useState({
    transaction_type: '',
    transaction_status: '',
    transaction_min_price: '',
    transaction_max_price: '',
    dateStart: '',
    dateEnd: '',
  });
  const notify = (msg =null) => toast.success(msg === null ?'Store saved .' : msg);
  const notifyError = (err) => toast.error(err)

  const handleViewClick = () => {
    navigate('/app/customers/')
    // handle view click action
  };

  const handleViewReviewClick = (val) => {
    // console.log(val);
    setViewReviewOnClick(val)
  }

  
  const handleClose = () => {
    setModalOpen(false);
  };


  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvp');

    // Your code here.
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const searchFilter = (e) => {
    setSearchvalue(e.target.value)
  }

  const ViewData = (page = 1, pageSize = 10) => {
    setViewPending(true);
    axiosInstance.get('/admin/reviews/view/'+pageId)
        .then(res => {
          // console.log(res.data.data);
          if(res.status < 300) {
            setViewReview(res.data.data.data)
          }
        }).catch(err => {

            try {
                notifyError(err.response.data.message);

            } catch (error) {
                notifyError("Something went wrong , please try again later");

            }

        }).finally(() => setViewPending(false))
  }

  // const fetchData = (page = 1, pageSize = 10) => {
  //   let filter_payload = {}
  //   if (filterSearch.transaction_min_price) {
  //   filter_payload.transaction_min_price = filterSearch.transaction_min_price
  //   }
  //   if (filterSearch.transaction_max_price) {
  //     filter_payload.transaction_max_price = filterSearch.transaction_max_price
  //     }
  //   if (searchvalue != '') {
  //   filter_payload.search = searchvalue
  //   }
  //   if (filterSearch.dateStart != '') {
  //   filter_payload.dateStart = filterSearch.dateStart
  //   }
  //   if (filterSearch.dateEnd != '') {
  //   filter_payload.dateEnd = filterSearch.dateEnd
  //   }
  //   if (filterSearch.transaction_type != '') {
  //   filter_payload.transaction_type = filterSearch.transaction_type
  //   }
  //   if (filterSearch.transaction_status != '' && filterSearch.transaction_status != 'all') {
  //   filter_payload.transaction_status = filterSearch.transaction_status
  //   }
  //   setIsPending(true);
  //   handleClose(false)
  //   axiosInstance.get('/admin/reviews/all?', {
  //     params: {
  //       page,
  //       pageSize,
  //       ...filter_payload
  //   }
  //   })
  //       .then(res => {
  //         // console.log(res.data.data);
  //         if(res.status < 300) {
  //           setCustomerReview(res.data.data.data)
  //         }
  //           setDataPagination(res.data.data.pagination)
  //           setFilterSearch({
  //             transaction_status: '',
  //             transaction_type: '',
  //             dateEnd: '',
  //             dateStart: '',
  //             transaction_max_price: '',
  //             transaction_min_price: '',
  //           })


  //       }).catch(err => {

  //           try {
  //               notifyError(err.response.data.message);

  //           } catch (error) {
  //               notifyError("Unable to fetch data, try again later");

  //           }

  //       }).finally(() => setIsPending(false))
  // }

  // bus.on('user-review', (el) => {
  //   setDataValue(el)
  // })

  useEffect(() => {
    // console.log(pageId);
    ViewData()
    // fetchData()
  }, [searchvalue])
  

  return   (
    <>
    <div className="px-2">
        <div className="row">
          <div className={`${styles.hold_input} ${styles.hold_input1} col-md-4   pl-0 my-3`}>
              <img src="/assets/icons/search.svg" alt="" />
              <input type="search" value={searchvalue} onChange={searchFilter} placeholder="Search" style={{textIndent:'25px',width:'100%'}} className=" form-control" />
          </div>
          <div className={`col-md-8 pl-0 text-center my-3`}>
              <div onClick={() => handleViewClick()} className={` ${styles.back_button}`}>
                <img src="/assets/icons/caret_icon.png" className="mt-1" alt="" />
              </div>
          </div>
        </div>
        
        <div className="card mt-2">
          <div className="card-body">
            <div id="accordion">
            {(!viewPending && viewReview.length > 0) && viewReview.map((el, i) => (
              <div key={el._id} className={`card mb-2`}>
                <div className={`card-body ${styles.accordion_header}`}>
                  <div style={{cursor:'pointer'}} data-toggle="collapse" data-target={`#collapse${i}`}>
                    
                      <div className={`d-flex justify-content-between`}>
                      <div className=" " style={{width:'50%'}}>
                        <div className={`d-flex ${styles.review_contents}`}>
                          <div style={{
                        
                          }}>
                            <img style={{
                              width:'51px',
                            }} src={el?.product_info?.product_image} alt="" />
                          </div>
                          &nbsp;
                          <div style={{
                          }}>
                            <p style={{
                              cursor:'pointer',
                              display:'inline-block',
                              marginBottom:'5px'
                            }}>
                              <strong>
                              {el?.product_info?.product_name}
                                
                              </strong>  
                            </p> 
                            &nbsp;
                            &nbsp;
                            <div className="d-flex">
                              <small>
                                <StarRating rating={el?.average_rating}/>
                              </small>
                              {
                                el?.average_rating !== undefined ? (
                                  <small style={{
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '13px',
                                    marginTop: '4px',
                                    marginLeft: '4px',
                                    color: '#FFA500'
                                  }}>
                                    {el?.average_rating.toFixed(1)}
                                  </small>
                                ) : null
                              }
                            </div>
                            <p style={{
                              fontStyle: 'normal',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '150%',
                              color: '#6B7280'
                            }}>
                              {el?.total_reviews ? Number(el.total_reviews).toLocaleString() : 0} {el?.total_reviews <= 1 ? 'Review' : 'Reviews'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div >
                        <img src="/assets/icons/accordionDown.svg" alt="" />
                      </div>
                      </div>
                  </div>
                </div>
                  <div id={`collapse${i}`} key={el._id} className="collapse " data-parent="#accordion">
                    {el.users.length > 0 && el.users.map((al) => (
                      <div className="card-body border-top">
                        <div className={`${styles.review_content_header}`}>
                          <Avatar className={`${styles.avatar}`}   src={al?.image} alt="" />
                          <div className={`${styles.contact_info}`}>
                            <p style={{
                              marginBottom:'0',
                              fontSize: '14px',
                            }}>{al.name}</p>
                            <p style={{
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '150%',
                              color:' #6B7280',
                              marginBottom:'0'

                            }}>
                              {al.email}
                            </p>
                            <div className="d-flex">
                              <small>
                                <StarRating rating={al?.rating}/>
                              </small>
                              {
                                al?.rating !== undefined ? (
                                  <small style={{
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '13px',
                                    marginTop: '4px',
                                    marginLeft: '4px',
                                    color: '#FFA500'
                                  }}>
                                    {al?.rating.toFixed(1)}
                                  </small>
                                ) : null
                              }
                            </div>
                            <p className="mb-0  " style={{
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '150%',
                              color:' #6B7280',
                              marginTop:'0',
                              textAlign:'right'

                            }}>
                              {moment(al.date).format('DD/MM/YYYY')}
                            </p>
                            <div>
                              <p className={`my-3 ${styles.review_content_body}`}>
                                {al.about_it ? al.about_it : 'No Comment'}

                              </p>
                              

                            </div>

                          </div>

                        </div>
                      </div>
                    ))}
                  </div>
              </div>
            ))}
            <div className="d-flex justify-content-center">
              {viewPending &&
                <BallTriangle

                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />

              }

            </div>
            {
              (!viewReview.length && !viewPending) &&

              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>

            }
            </div>
          </div>
        </div>
       
       
    </div>
     <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '740px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p='25px'
      >
        <div  className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
          <h5 style={{
            fontSize: '19px',
            fontWeight: '700',

          }}>Filter</h5>
            <span onClick={handleClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Location</label>
                  <TextField
                    required
                    type='text'
                    id="loc"
                    placeholder="Enter Location"
                    size="small"
                    fullWidth

                  />
                    

                </div>
                <label htmlFor="name">Date </label>

                <div className=" d-flex">
                  <div className="" style={{width:'70%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        id="name"
                        placeholder="Start"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-sflex   align-items-center " style={{heigsht:'40px'}}>
                    {/* sss */}
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        placeholder="End "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>

              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{height:'40px'}}>
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className=""  style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />
                      

                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{height:'40px'}}>
                    <img src="/assets/icons/line.svg" style={{width:'30px'}} alt="" />
                  </div>
                  <div className="" style={{width:'100%'}}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />
                      

                    </div>

                  </div>
                  
                </div>
                
              </div>
            </div>
            <div className="mb-4">
            <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
              Proceed

            </button>
            
            
            </div>

          </form>


        </div>
       
       
    </GlobalModal>



    </>
  ) ;
}
