// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Card } from "@mui/material";
import Scrollbar from "../../../components/scrollbar";
// sections
// mock
import Button from "@mui/material/Button";
import DiscountModal from "../Modals/DiscountModal";
import ProductsModal from "../Modals/ProductsModal";
import bus from "src/bus";
import { useCategories } from "src/hooks/useCategories";
import Menu from "@mui/material/Menu";
import axiosInstance from "src/axiosInstance";
import { BallTriangle } from "react-loader-spinner";
import CircularProgress from "@mui/material/CircularProgress";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { createRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import GlobalModal from "src/Modals/GlobalModal";
import moment from "moment";

import { DatePickerComponent } from "../../../components";

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#FFFFFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
};
const style1 = {
  position: "absolute",
  height: "500px",
  overflow: "scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#FFFFFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
};
const style1d = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  overflow: "scroll",
  bgcolor: "#FFFFFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

// ptvp=PAGE TO VIEW (SALES)

export default function SingleViewProduct({ categoryId }) {
  let textInput = createRef();
  const [openDialog, setDiologOpen] = useState(false);
  const [action_type, setActionType] = useState("Add");
  const navigate = useNavigate();

  const [openDialogDelete, setopenDialogDelete] = useState(false);
  const [notify, setNotify] = useState(false);
  const categories = useCategories();
  const [isPending, setIsPending] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [otherCheckboxesChecked, setOtherCheckboxesChecked] = useState(false);
  const [isDelete, setIsDelete] = useState(false)
  useEffect(() => {
    setOtherCheckboxesChecked(selectAllChecked);
  }, [selectAllChecked]);
  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [modal_desc, setmodal_desc] = useState("");
  const [weight, setWeight] = useState(20);
  const [url, setUrl] = useState("");
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open2 = Boolean(anchorEl1);
  const notifyError = (err) => toast.error(err);
  const notifyToast = (err) => toast.success(err);
  const [newProducts, setNewProducts] = useState({
    notifications: [],
    cases_type: "Cartons",
  });
  const [currentData, setcurrentData] = useState({});
  const [showDiscount, setShowDiscount] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const [data, setdata] = useState({ products: [] });
  const [catData, setCatdata] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const [category, setCategory] = useState(1);
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleClose = () => setOpen(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [openPopUp, setopenPopUp] = useState(false);
  const handleClickOpen = (val) => {
    setcurrentData(val);
    setDiologOpen(true);
  };
  const handleGoToCartClose = () => setopenGoToCart(false);

  const handleDialogClose = () => {
    setDiologOpen(false);
  };
  const handleDialogDeleteClose = () => {
    setopenDialogDelete(false);
  };
  const handleCloseList = (key, value) => {
    setAnchorEl1(null);
    setNewProducts((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleClickList1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const discountPage = () => {
    handleGoToCartClose();
    setShowDiscount(true);
    bus.emit("open-discount");
  };

  const reload = () => {
    navigate(`/app/products`);
  };
  useEffect(() => {
    // fetchCategorySingle()
    fetchData();
  }, []);
  bus.on("reload", () => {
    fetchData();
  });
  // const fetchCategorySingle = (page = 1, pageSize = 10) => {
  //   setIsPending(true);
  //   axiosInstance
  //     .get("/admin/product/category/" + categoryId._id)
  //     .then((res) => {
  //       setCatdata(res.data.data);
  //     })
  //     .catch((err) => {
  //       try {
  //         notifyError(err.response.data.message);
  //       } catch (error) {
  //         notifyError("Unable to fetch store, try again later");
  //       }
  //     })
  //     .finally(() => setIsPending(false));
  // };

  const fetchData = (page = 1, pageSize = 10) => {
    setIsPending(true);
    axiosInstance
      .get("admin/product/tag/" + categoryId)
      .then((res) => {
        setdata(res.data.data[0]);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Unable to fetch tags, try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const deleteProduct = () => {
    setLoadingState(true);
    axiosInstance
      .delete("/admin/product/" + currentData._id)
      .then((res) => {
        fetchData();
        notifyToast("Product Deleted");
        handleDialogClose();
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Unable to delete product, try again later");
        }
      })
      .finally(() => setLoadingState(false));
  };

  //   useEffect(() => () => {
  //     setcurrentData(null)
  //     // Your code here.
  //   }, [currentData])

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleButtonClick = () => {
    textInput.current.click();
  };

  const deleteCategory = (e) => {
    setDeletePending(true);
    e.preventDefault();
    setTimeout(() => {
      setopenDialogDelete(false);
    }, [1000]);
    setmodalTitle("Category deleted");
    axiosInstance
      .delete("/admin/product/category/" + data._id)
      .then((res) => {
        if (res.status < 300) {
          setopenPopUp(true);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Unable to delete product, try again later");
        }
      })
      .finally(() => setDeletePending(false));
  };

  const handlePushNotify = (e) => {
    setNotify(e.target.value);
  };

  const handleProductSubmit = (e) => {
    e.preventDefault();
    setOpen1(false);
    setopenGoToCart(true);
  };

  const handleDeleteTag = (val) => {
    setIsDelete(true)
    axiosInstance.delete('/admin/product/delete-tag/'+val)
    .then(res => {
      notify(res.data.message)
    }).catch((err) => {
      try {
        notifyError(err.response.data.message);
      } catch (error) {
        notifyError("Unable to delete tag, try again later");
      }
    })
    .finally(() => setIsDelete(false));
  }

  return (
    <>
      <div className={` mt-3 px-2`}>
        <div className={`${styles.filters}`}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                placeholder="Search"
                style={{ textIndent: "25px", width: "100%" }}
                className=" form-control"
              />
            </div>
            <div className="col-md-7"></div>
            <div className="col-md-2">
              <button
                className="btn  border"
                onClick={() => setModalOpen(true)}
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #D0D5DD",
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "4px",
                }}
              >
                <img
                  className=""
                  style={{ display: "inline", width: "17px" }}
                  src="/assets/icons/filterlines.svg"
                  alt=""
                />
                &nbsp;
                <small className="d-none d-md-inline-block">Filters</small>
              </button>
              {/* &nbsp;
                  &nbsp;
                  &nbsp; */}
              <button
                className="btn  border float-right"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #D0D5DD",
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "4px",
                }}
              >
                <img
                  className=""
                  style={{ display: "inline", width: "17px" }}
                  src="/assets/icons/arrowDown.png"
                  alt=""
                />
                &nbsp; &nbsp; &nbsp;
                <span>
                  {/* <input type="checkbox" name="" id="" /> */}
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={selectAllChecked}
                    onChange={(e) => setSelectAllChecked(e.target.checked)}
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: "10px", borderRadius: "10px" }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-flex" style={{justifyContent: 'space-between', position: 'relative'}}>
                <div
                  className="mb-3 d-inline-block cursor_pointer"
                  onClick={reload}
                >
                  <img
                    src="/assets/icons/Chevronleft.svg"
                    className="pr-1"
                    alt=""
                  />
                  </div>
                  <button 
                    className={`btn ${styles.delete_action} 
                      px-3 py-2 bg-danger text-white ml-auto`
                    } 
                    onClick={() => handleDeleteTag(data?._id)}
                  >
                    Delete Tag
                    {isDelete && (
                      <CircularProgress
                        style={{ color: "white" }}
                        size="1rem"
                      />
                    )}
                  </button>

                  {selectAllChecked && (
                    // <input
                    // className={`${styles.select_all_pro}`}
                    //   type="checkbox"
                    //   id="checkbox1"
                    //   checked={otherCheckboxesChecked}
                    // />
                  <div className=" ml-auto py-4 bg-white" 
                    style={{
                      position: 'absolute', 
                      top: '50%', 
                      right: '0px', 
                      transform: 'translate(0%, -50%)'
                    }}>
                    <button 
                      className={`btn ${styles.cancel_action} px-3 ml-3 py-2 text-[#53138D]`} 
                      onClick={(e) => setSelectAllChecked(e.target.checked)}
                    >
                      Cancel
                    </button>
                    <button className={`btn ${styles.delete_action} px-3 ml-3 py-2 bg-danger text-white`} >
                      Delete All
                    </button>
                  </div>
                  )}
              </div>
            <div className="d-md-flex justify-content-between">
              <div>
                <h4>{data?.category_name}</h4>
              </div>
              {/* <div className="">
                <button type="button" onClick={setopenDialogDelete} className={`btn mb-2 mb-md-0 ${styles.delete_category}`}>
                  <span>

                    Delete Category
                  </span>
                </button>
                &nbsp;
                &nbsp;
                <button onClick={() => bus.emit('open-product', { _id: categoryId })} className={`${styles.add_product_btn} btn m-0 p-2`}>
                  <img src="/assets/icons/plus.svg" className="pr-1" alt="" />
                  Add Product

                </button>

              </div> */}
            </div>
          </div>
          <Scrollbar>
            <div className={`py-3 px-md-4 ${styles.cover_products}`}>
              <div className={`${styles.customized_row}`}>
                {data?.products.map((el, i) => {
                  return (
                    <div key={i} className="mb-2">
                      <div className="card">
                        <div className="card-body">
                          <div className={`${styles.customized_row1}`}>
                            <div
                              className=" bg-light"
                              style={{
                                overflow: "hidden",
                                maxHeight: "150px",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={el?.product_image}
                                alt=""
                              />
                            </div>
                            <div className={`${styles.product_txt}`}>
                              {/* <input type="checkbox"id="checkbox1" checked={otherCheckboxesChecked} /> */}
                              {selectAllChecked && (
                                <input
                                className={`${styles.select_all_pro}`}
                                  type="checkbox"
                                  id="checkbox1"
                                  checked={otherCheckboxesChecked}
                                />
                              )}
                              <h6>{el?.product_name}</h6>
                              <p>{el?.description}</p>
                              <div className="d-flex justify-content-between">
                                <span>
                                  {el?.product_spec ? el?.product_spec : ''}
                                </span>
                                <span>
                                  <img
                                    style={{ with: "2%" }}
                                    src="/assets/icons/packagec.svg"
                                    alt="cube"
                                    className={`mr-2`}
                                  />

                                  {Number(el?.number_of_cases)}
                                </span>
                              </div>
                              <div className={`${styles.product_price}`}>
                                <div className="d-flex justify-content-between border-bottom pb-2">
                                  <p>
                                    ₦{" "}
                                    {Number(
                                      el?.price_per_case?.$numberDecimal
                                    ).toLocaleString()}
                                  </p>
                                  <p className="text-muted">
                                    Date added:{" "}
                                    {moment(el?.createdAt).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                                <div className="row  pt-2">
                                  <div className="col-6 text-center border-right">
                                    <span
                                      onClick={() =>
                                        bus.emit(
                                          "open-product",
                                          {
                                            _id: categoryId,
                                            edit: {
                                              tag_ids: el?.tag_id,
                                              ...el,
                                            },
                                          },
                                          1
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src="/assets/icons/edit.svg"
                                        alt="edit"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-6 text-center">
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleClickOpen(el)}
                                    >
                                      <img
                                        src="/assets/icons/trash-2.svg"
                                        alt="edit"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex align-items-center pb-4  justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!data?.products.length && !isPending && (
              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
        </Card>
      </div>

      {/*  DIALOG*/}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: "40px" }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Delete {currentData.product_name} Product</h4>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#6B7280",
                  }}
                >
                  Are you sure you want to delete this product? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_product}`}
                onClick={deleteProduct}
              >
                Delete{" "}
                {loadingState && (
                  <CircularProgress style={{ color: "#53138D" }} size="1rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={openDialogDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogDeleteClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: "40px" }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Delete Category</h4>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#6B7280",
                  }}
                >
                  Are you sure you want to delete this category? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogDeleteClose}
              >
                Cancel
              </Button>
              <Button
                onClick={deleteCategory}
                className={`${styles.delete_product}`}
              >
                Delete{" "}
                {deletePending && (
                  <CircularProgress style={{ color: "white" }} size="0.7rem" />
                )}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      {/* Withdraw */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style1}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>{action_type === "Add" ? "Add" : " Edit"} Product </h3>
                <span onClick={handleClose1} style={{ cursor: "pointer" }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p>
                {action_type === "Add" ? "Add" : " Edit"} product to this
                category
              </p>

              <form onSubmit={handleProductSubmit}>
                <p className={` label`}>Product Image* </p>
                <input
                  type="file"
                  hidden
                  ref={textInput}
                  name=""
                  onChange={handleFile}
                />
                <div
                  className={`${styles.retailer_modal_product_img}`}
                  style={{
                    backgroundImage: `url('${url}')`,
                  }}
                >
                  <div
                    style={{ cursor: "pointer", display: "inline-block" }}
                    onClick={() => handleButtonClick()}
                  >
                    <img
                      className={`${styles.retailer_product_image}`}
                      src="/assets/image.svg"
                      alt=""
                    />
                    <p
                      className="mb-0 text-center text-info"
                      style={{ fontSize: "10px" }}
                    >
                      Click to upload
                    </p>
                  </div>
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="pname">
                    Product Name*
                  </label>
                  <TextField
                    required
                    type="text"
                    id="pname"
                    placeholder=""
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="category">
                    Product Category*
                  </label>
                  <div>
                    {categories.map((el, i) => (
                      <div className="categories" key={i}>
                        {el.name === "Beverages" && action_type === "Edit" && (
                          <input
                            checked
                            type="checkbox"
                            name=""
                            id="myCheckbox"
                          />
                        )}
                        {el.name !== "Beverages" && (
                          <input type="checkbox" name="" id="myCheckbox" />
                        )}
                        &nbsp;
                        {el.name}
                      </div>
                    ))}
                  </div>
                </div>
                <label className={`label`} htmlFor="price">
                  Pricing *{" "}
                </label>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <small className="small">Per Case</small>
                      <TextField
                        required
                        id="price"
                        type="number"
                        placeholder=" ₦"
                        size="small"
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <small className="small">Per Product*</small>
                    <TextField
                      required
                      id="price"
                      type="number"
                      placeholder=" ₦"
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
                <label className={`label`} htmlFor="price">
                  Stock *{" "}
                </label>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3" style={{ position: "relative" }}>
                      <small className="small">Number of Cases</small>
                      <TextField
                        required
                        id="price"
                        type="number"
                        size="small"
                        fullWidth
                      />
                      <div className="absolute">
                        <small className="small">
                          {newProducts.cases_type}
                        </small>
                        &nbsp;
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleClickList1}
                        >
                          <img src="/assets/icons/downicon.svg" alt="" />
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl1}
                          open={open2}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {["Cartons", "Crates"].map((count) => (
                            <MenuItem
                              onClick={() =>
                                handleCloseList("cases_type", count)
                              }
                            >
                              {count}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <small className="small">
                      Number of Product in a Cases*
                    </small>
                    <TextField required type="number" size="small" fullWidth />
                  </div>
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="desc">
                    Description{" "}
                  </label>
                  <TextField
                    id="desc"
                    multiline
                    rows="4"
                    cols="3"
                    placeholder=" Best Product"
                    size="small"
                    fullWidth
                  />
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPopUp}
        onClose={() => setopenPopUp(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openPopUp}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1d}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: "bold" }}>
                {modalTitle}
              </h5>
              <p
                className="text-center mb-3"
                style={{ textAlign: "center !important" }}
              >
                {modal_desc}
              </p>
              <button
                onClick={() => reload(0)}
                className={`modal_btn hover btn btn-block`}
              >
                Go to Product
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box
            className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`}
            sx={style}
          >
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Product Added </h5>
              <div>
                {action_type === "Add" && (
                  <button
                    className={`${styles.go_to} d-block w-100 `}
                    style={{ fontSize: "14px" }}
                    onClick={() => discountPage()}
                  >
                    Proceed to Create Discount
                  </button>
                )}
                {action_type === "Edit" && (
                  <button
                    onClick={() => reload(0)}
                    className={`modal_btn hover btn btn-block`}
                  >
                    Go to Product
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <GlobalModal
        open={modalOpen}
        onClose={handleCloseModal}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="740px !important"
        overflow="auto"
        bgcolor="#FFFFFF"
        border="1px solid #F5F5F5"
        borderRadius="5px"
        boxShadow="24"
        p="25px"
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5
              style={{
                fontSize: "19px",
                fontWeight: "700",
              }}
            >
              Filter
            </h5>
            <span onClick={handleCloseModal} style={{ cursor: "pointer" }}>
              <img src="/assets/icons/x.svg" alt="" />
            </span>
          </div>

          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="name">
                    <strong>Status</strong>
                  </label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value="select"
                    name="category"
                    required
                    onChange={handleCategoryChange}
                  >
                    <MenuItem value="select">Select Status</MenuItem>
                    <MenuItem value="Kiosk">Verified</MenuItem>
                    <MenuItem value="Neighborhood store">Not Verified</MenuItem>
                  </Select>
                </div>

                <div className="mb-3">
                  <label htmlFor="ounit">
                    <strong>Price</strong>
                  </label>
                  <div className=" d-flex">
                    <div className="" style={{ width: "100%" }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          id="ounit"
                          placeholder="Min"
                          size="small"
                        />
                      </div>
                    </div>
                    <div
                      className="px-1 d-flex   align-items-center "
                      style={{ height: "40px" }}
                    >
                      <img
                        src="/assets/icons/line.svg"
                        style={{ width: "30px" }}
                        alt=""
                      />
                    </div>
                    <div className="" style={{ width: "100%" }}>
                      <div className="mb-3">
                        <TextField
                          required
                          type="number"
                          placeholder="Max "
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">
                  <strong>Date Added</strong>
                </label>

                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <DatePickerComponent />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: "40px" }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <DatePickerComponent />
                    </div>
                  </div>
                </div>

                <label htmlFor="ounit">
                  <strong>Product Left</strong>
                </label>
                <div className=" d-flex">
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        id="ounit"
                        placeholder="Min"
                        size="small"
                      />
                    </div>
                  </div>
                  <div
                    className="px-1 d-flex   align-items-center "
                    style={{ height: "40px" }}
                  >
                    <img
                      src="/assets/icons/line.svg"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  </div>
                  <div className="" style={{ width: "100%" }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type="number"
                        placeholder="Max "
                        size="small"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="name">Product</label> <div></div>
                  {productLists.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input onClick={(e) => handleProductChange(e, el.name)} type="checkbox" value={el.name} name="" className="myCheckbox" />
                      &nbsp;
                      {el.name}


                    </div>

                  ))}


                </div>
              </div> */}
            </div>
            <div className="mb-4">
              <button
                type="button"
                onClick={handleClose}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
      <DiscountModal closeModal={() => setShowDiscount(false)} />
      <ProductsModal />
    </>
  );
}
