

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useState } from "react"
import TextField from '@mui/material/TextField';
import * as React from 'react';
import bus from 'src/bus';
import toast from 'react-hot-toast';
import axiosInstance from "src/axiosInstance"
// sections
// mock

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useLayoutEffect } from "react";
import Slide from '@mui/material/Slide';
import { forwardRef } from "react";
import { useEffect } from "react";
import { BallTriangle } from 'react-loader-spinner'


// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const style3 = {
    position: 'absolute',
    width: '700px',
    overflow: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    background: '#FFFFFF',
    p: 3,
};


export default function AdDuration() {

    const [open1, setOpen1] = useState(false);
    const [ads, setAds] = React.useState([])
    const [loading, setLoading] = React.useState(false);
    const notifyError = (err) => toast.error(err);


    React.useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (page = 1, pageSize = 1000,) => {
        setLoading(true);
        axiosInstance.get('/brand/sponsored-ads/get-ads', {
            params: {
                page,
                pageSize,
            }
        })
            .then(res => {
                setAds(res.data.data.data)


            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to fetch ads records, please try again later");

                }

            }).finally(() => setLoading(false))
    }

    const [search, setSearch] = useState({
        durations: [
            {
                name: '50,000',
                time: '/6 months',
                desc: 'Reach new and existing users within the space of six months'
            },
            {
                name: '5000',
                time: '/7 days',
                desc: 'Reach new and existing users within the space of one week'
            },
            {
                name: '10,000',
                time: '/month',
                desc: 'Reach new and existing users within the space of one month'
            },
            {
                name: '25,000',
                time: '/3 months',
                desc: 'Reach new and existing users within the space of three months'
            }
        ],
        selected: '50,000',
    });

    const handleClose1 = () => {
        setOpen1(false);

    };
    const selectDuration = (value) => {
        setSearch((prev) => {
            return {
                ...prev,
                selected: value.duration
            }
        })
        bus.emit('selected-add', value);

        setOpen1(false)

    }




    useEffect(() => {
        bus.on('open-sponsored-add', (val) => {
            setOpen1(true)
        });

        return () => {

        };
    }, []);


    useLayoutEffect(() => () => {
        // Your code here.
    }, [])






    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open1}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={open1}>
                    <Box className={`mobile_modal_size`} sx={style3}>
                        <div className={`${styles.modal_content}`}>
                            <div className={`${styles.modal_header}`}>
                                <h3>Ad Durations </h3>
                                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                                    <img src="/assets/icons/x.svg" alt="" />

                                </span>

                            </div>
                            <div className="container">
                                <div className="row">
                                    {ads.map((el, i) => {
                                        let status = el.duration === search.selected ? 'ad_borderooo' : 'border_none'
                                        return (
                                            (
                                                <div key={i} className="col-md-6 mb-4">
                                                    <div className="" style={{ maxHeight: '400px', boxSizing: 'border-box' }}>
                                                        <div className={`p-3 ad ${status}`}>
                                                            {/* {i < 1 && <span className="popular">Popular</span>}
                                                            {i > 0 && <span style={{ visibility: 'hidden' }} className="popular">Popular</span>} */}
                                                            <div>
                                                                <h2 className="add_heading d-inline-block">
                                                                    #{Number(el.subscription_fee).toLocaleString()}
                                                                </h2>{el.duration}
                                                                {/* <p className="text-muted">
                                                                    {el.desc}
                                                                </p> */}
                                                            </div>
                                                            <button onClick={() => selectDuration(el)} className="btn btn-[#53138D] bg-[#8c2d915d] btn-block" style={{ fontSize: '12px' }}>
                                                                Select Duration
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>
                                            )
                                        )
                                    })}
                                    <div className="d-flex justify-content-center">
                                        {loading &&
                                            <BallTriangle
                                                height={50}
                                                width={55}
                                                radius={5}
                                                color="#53138D"
                                                ariaLabel="ball-triangle-loading"
                                                wrapperClass={{}}
                                                wrapperStyle=""
                                                visible={true}
                                            />

                                        }

                                    </div>

                                </div>

                            </div>

                        </div>

                    </Box>
                </Fade>
            </Modal>
        </>
    )
}
