import styles from "../Sales.module.css"

import {
  Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import SalesDetails from "../SalesDetails"
import { useEffect, useState, forwardRef } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from "src/hooks/useAuth";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import { useDropDowns } from "src/hooks/useDropDowns";
import { useCategories } from "src/hooks/useCategories";
import { useProducts } from "src/hooks/useProducts";
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import moment from "moment";
import { BallTriangle } from 'react-loader-spinner'
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Filter1 from "src/pages/Filter/Filter1";
import { Pagination } from "src/components";
// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
  });

const TABLE_HEAD = [
  { id: 'typeOfDiscount', label: 'Type of Discount', alignRight: false },
  { id: 'dateCreated', label: 'Date Created', alignRight: false },
  { id: 'rxFee', label: 'RX Fee (%)', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------

function changeView(d) {
  localStorage.setItem('ptvs', d);
}

// ptvs=PAGE TO VIEW (SALES)

let app_view = localStorage.getItem('ptvs') ?? 0;
export default function ShopOwners() {
  const { data } = useAuth();
  const dropdownFilters = useDropDowns()
  const categories = useCategories();
  const productLists = useProducts()


  const [appView, setAppview] = useState(app_view);
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const [category, setCategory] = useState(1);
  const [showDiv, setShowDiv] = useState(data.merchant_type === "brand");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);
  const [discountViewData, setDiscountViewData] = useState({});
  const [searchvalue, setSearchvalue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteVal, setDeleteVal] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [dataPagination, setDataPagination] = useState('');
  const [editDiscountPostLoading, setEditDiscountPostLoading] = useState(false)
  const [editDiscount, setEditDiscount] = useState(false)
  const [filterSearch, setFilterSearch] = useState({
    transaction_type: '',
    transaction_status: '',
    transaction_min_price: '',
    transaction_max_price: '',
    dateStart: '',
    dateEnd: '',
  });
  const notify = (msg =null) => toast.success(msg === null ?'Store saved .' : msg);
  const notifyError = (err) => toast.error(err);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded
    let val = event.target.value
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val)
        setProductsAdded(data)

      }
    }
  };

  const removeFromList = (index) => {
    let data = [...productsAdAdded]
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1)
    setProductsAdded(data)
  }

  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvs')
  }, [])

  const handleViewClick = (d) => {
    navigate('/app/sales_details')
    // handle view click action
  };

  const handleDiscountViewClick = (val) => {
    setModalOpen(true)
    setDiscountViewData(val)
  }

  const handleviewDataChange = (e) => {
    const { name, value } = e.target;
    setDiscountViewData((prev) => ({
      ...prev,
      [name]: value
    }));
  }

  const handleDelete = () => {
    handleClose();
    // setIsDeleting(true)
    //  axiosInstance.delete('/admin/pricing/discount/delete/'+deleteVal._id)
    //  .then(res => {
    //    if(res.status < 300) {
    //      notify(res.data.message);
    //     //  fetchData(currentPage, itemsPerPage)
   
    //      handleClose();
   
    //    } 
    //  }).catch(err => {
   
    //    try {
    //      notifyError(err.response.data.message);
   
    //    } catch (error) {
    //      notifyError("Unable to delete insight, try again later");
   
    //    }
   
    //  }).finally(() => {
    //    setIsDeleting(false)
    //    handleDialogClose()
    //  })
   };

  const handleEdit = () => {
    setEditDiscountPostLoading(true)
    setModalOpen(false)
    axiosInstance.post('/admin/pricing/discounts/update', {
      id: discountViewData._id,
      rx_fee: discountViewData.rx_fee
    })
      .then(res => {
        if (res.status < 300) {
          setEditDiscount(!editDiscount)
        }
      }).catch(err => {

        try {
            notifyError(err.response.data.message);

        } catch (error) {
            notifyError("Something went wrong , please try again later");

        }

    }).finally(() => setEditDiscountPostLoading(false))

  };
  
  const handleDialogClose = () =>{
    setOpenDialog(false)
    // setSuccessOpen(false);
  }
  
  const handleOpenDialog = (val) => {
    setDeleteVal(val)
    setOpenDialog(true)
  }

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0)
  }

  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClickList1 = (e) => {
    setAnchorEl1(e.currentTarget)
  }
  const handleSelectFilter = (val, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(val)

        break;
      case 1:
        setAnchorEl1(null);
        setValue1(val)
        break;

      default:
        break;
    }


  }

  const fetchData = (page = 1, perpage = 10) => {
    let filter_payload = {}
    if (filterSearch.transaction_min_price) {
    filter_payload.transaction_min_price = filterSearch.transaction_min_price
    }
    if (filterSearch.transaction_max_price) {
      filter_payload.transaction_max_price = filterSearch.transaction_max_price
      }
    if (searchvalue != '') {
    filter_payload.search = searchvalue
    }
    if (filterSearch.dateStart != '') {
    filter_payload.dateStart = filterSearch.dateStart
    }
    if (filterSearch.dateEnd != '') {
    filter_payload.dateEnd = filterSearch.dateEnd
    }
    if (filterSearch.transaction_type != '') {
    filter_payload.transaction_type = filterSearch.transaction_type
    }
    if (filterSearch.transaction_status != '' && filterSearch.transaction_status != 'all') {
    filter_payload.transaction_status = filterSearch.transaction_status
    }
    setIsPending(true)
    axiosInstance.get('/admin/pricing/discounts?', {
        params: {
            page,
            perpage,
            ...filter_payload
        }
    })
        .then(res => {
            setDiscountData(res.data.data)
            setDataPagination(res.data.data.pagination)
            setFilterSearch({
              transaction_status: '',
              transaction_type: '',
              dateEnd: '',
              dateStart: '',
              transaction_max_price: '',
              transaction_min_price: '',
            })


        }).catch(err => {

            try {
                notifyError(err.response.data.message);

            } catch (error) {
                notifyError("Unable to fetch pricing, try again later");

            }

        }).finally(() => setIsPending(false))
  }

  useEffect(() => {
    fetchData()
  }, [searchvalue, editDiscount])



  return Number(appView) === 0 ? (
    <>
      <div className="pt-4">
        <div className={`${styles.analytics}  `}>
        
        </div>
        <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
          <div className='p-3 '>
            <div className="d-md-flex justify-content-between" >
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  Discount

                </h5>
                <small className="text-muted" style={{ fontWeight: '200', fontSize: '14px' }}>
                  Manage and create service charge
                </small>

              </div>
              {/* <div className="pt-md-2">
                <div className="dropleft ">
                  <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                    <span style={{
                      paddingTop: '8px',


                    }}>
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp;
                    &nbsp;
                    <span style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',

                    }}>
                      <img src="/assets/icons/arrowDown.svg" alt="" />

                    </span>


                  </button>

                  <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                    <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                    <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                    <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                  </div>
                </div>


              </div> */}

            </div>
          </div>
          {/* <div className="alert alert-primary text-center">
            <h4>Feature Coming Soon...</h4>
          </div> */}
          <Scrollbar>
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}  >
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;
                      return (
                        <th key={i} className="text-muted">{el.label} {sIndex && (
                          <span style={{ cursor: 'pointer' }}>
                            <img className="pl-2" src="/assets/icons/down.svg" alt="" />
                          </span>
                        )}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isPending && discountData.map((el,i) => (
                    <tr className={`${styles.tr}`}>
                      <td>
                        {el.type.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                      </td>
                      <td>
                        <p>
                          <span>
                            {moment(el.createdAt).format('DD/MM/YYYY')}
                          </span> <br />
                          <small>
                            {moment(el.createdAt).format('HH:mm')} PM
                          </small>

                        </p>
                      </td>
                      <td>
                        {el?.rx_fee ? Number(el?.rx_fee).toLocaleString() : 0}
                      </td>
                      <td>
                        <div className="dropleft ">
                          <span
                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                            className={`${styles.action_button} px-3 py-2`}  style={{
                              cursor: 'pointer',
                            }}>
                            <img src="/assets/icons/action.svg" alt="" />
                          </span>
                          <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                            <button type="button" onClick={() => handleDiscountViewClick(el)} className="rss dropdown-item btn border-0">Edit</button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>

            </div>
            <div className="d-flex justify-content-center">
                {isPending &&
                  <BallTriangle

                    height={50}
                    width={55}
                    radius={5}
                    color="#53138D"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />

                }

              </div>
              {
                (!discountData.length && !isPending) &&

                <div className="alert alert-[#53138D] text-center">
                  <h4>No record found</h4>
                </div>

              }

          </Scrollbar>
        </Card>

      </div>

      {/* Modals */}
      <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
      >
          <div className="card border-0">
              <div className="card-body border-0 ">
                  <div className={`${styles.customized_row}`}>
                      <div className="text-center">
                          <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

                      </div>
                      <div>
                          <h5>
                              Delete

                          </h5>
                          <p style={{
                              fontSize: '14px',
                              color: '#6B7280',
                          }}>
                              Are you sure you want to delete this outlet? All  your data will be permanently removed from our servers forever. This action cannot be undone.
                          </p>

                      </div>

                  </div>

                  <DialogActions>
                      <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
                      {
                          !isDeleting &&
                          <Button className={`${styles.delete_product}`} onClick={handleDelete}>Delete</Button>
                      }

                      {isDeleting && <button disabled className={`btn ${styles.delete_product}`}>

                          {isDeleting && <CircularProgress style={{ color: 'white' }} size="1rem" />

                          }
                      </button>
                      }
                  </DialogActions>
              </div>
          </div>
      </Dialog>

      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='600px !important'
        overflow='auto'
        bgcolor='#FFFFFF'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-4`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Discount</h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0">
            <h3>{discountViewData?.discount_type?.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</h3>
            <div className="row">
             <div className="col-md-6 text-muted">
              <p className="mt-2 pt-1">FlexDeals Fee (%)</p>
             </div>
             <div className="col-md-6">
              <input type="number"  value={discountViewData?.rx_fee} onChange={handleviewDataChange} name="rx_fee" className="form-control" />
             </div>
            </div>
            <div className="row mt-3">
             <div className="col-md-6 mb-3 text-muted">
              <span className="mt-1 pt-1">{moment(discountViewData?.createdAt).format('DD/MM/YYYY')}</span>
              <small style={{fontSize:'12px', marginLeft:'10px'}} >{moment(discountViewData?.createdAt).format('HH:mm')} PM</small>
             </div>
            </div>
            <div className="mb-4">
              <button type="button" onClick={() => handleEdit()} className={`${styles.modal_btn} btn btn-block`}>
                Done {editDiscountPostLoading && <CircularProgress style={{ color: '#fff' }} size="1rem" />}

              </button>


            </div>

          </form>


        </div>


      </GlobalModal>
    </>
  ) : <SalesDetails key={1} onChange={handleChange} />
}
