import styles from '../../../pages/OutletPage/Outlet.module.css';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Fade from '@mui/material/Fade';
import NotificationsPopover from './NotificationsPopover';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import './sty.css';
import { useAuth } from 'src/hooks/useAuth';
import { Navigate, NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
// ----------------------------------------------------------------------
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import bus from 'src/bus';
import { useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from 'src/axiosInstance';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 50;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
const styleAddProduct = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 580,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function Header({ onOpenNav, pathName }) {
  const { dispatch, data } = useAuth();
  const navigate = useNavigate();
  const [pageHeader, setPageHeader] = useState('');
  const [pageText, setPageText] = useState('');
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState(false);
  const handleOpen2 = () => setOpen(true);
  const [values, setValues] = useState(['', '', '', '']);
  const inputs = useRef([]);
  const [n, setN] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const innerWidth = window.innerWidth;
  const notifyError = (err) => toast.error(err);
  let [shouldFetch, setShouldFetch] = useState(0);
  let ar = [];
  const [search, setSearch] = useState({
    search: '',
    category: 'select',
    location: '',
    status: 'select',
  });
  const [outletData, setdata] = useState([]);
  const [outletPagination, setOutletPagination] = useState({ total: 0 });

  const [open1, setOpen1] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen21 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [states, setStates] = useState([]);

  const [bank, setBank] = useState('1');

  const handleBankChange = (e) => {
    setBank(e.target.value);
  };

  const topUp = (e) => {
    e.preventDefault();
    setOpenDialog(true);
    setOpen(false);
  };

  const submitFundWallet = (e) => {
    e.preventDefault();
    setOtp(true);
    setOpen1(false);
  };
  const submitOtpWallet = (e) => {
    e.preventDefault();
    setOtp(false);
    setOpenDialog(true);
  };
  const handleChange = (event) => {
    const input = event.target;
    const newValues = [...values];
    newValues[inputs.current.indexOf(input)] = input.value;
    ar.push(newValues);
    setN(newValues);
    setValues(newValues);
    moveFocus(event);
    // if(n.length ===4) {
    //   alert()
    //   const form = formRef.current;

    //   // Create a new `submit` event
    //   const submitEvent = new Event('submit');

    //   // Dispatch the event
    //   form.dispatchEvent(submitEvent);

    // }
  };
  const moveFocus = (event) => {
    setTimeout(() => {
      const input = event.target;
      const nextInput = inputs.current[inputs.current.indexOf(input) + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }, 0);
  };
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    if (innerWidth <= 743) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [innerWidth]);

  const handleOpen = () => {
    bus.emit('open-outlet', {});
  };
  const handleOpenCSV = () => {
    bus.emit('open-csv', {});
  };
  const handleOpen1 = () => {
    bus.emit('open-product', {});
  };
  const handleOpenWalletTopUp = () => {
    if (hasPermission('manage-wallets')) {
      bus.emit('open-wallet-topup', {});
    }
  };
  const handleOpenWalletWithdraw = () => {
    if (hasPermission('manage-wallets')) {
      bus.emit('open-wallet-withdraw', {});
    }
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const pathNameWithoutTrailingSlash = pathName.replace(/\/+$/, '');
    const pathNameWithoutParameter = pathNameWithoutTrailingSlash.replace(
      /\/\d+$/,
      ''
    );
    // console.log(pathNameWithoutTrailingSlash);
    switch (pathNameWithoutParameter) {
      case '/app/orders':
        setPageHeader('Orders');
        setPageText(
          data.merchant_type === 'brand'
            ? 'Keep track of your orders from  shop owners'
            : 'Manage and monitor all orders'
        );

        break;
      case '/app/sales':
        setPageHeader('Sales');
        // setPageText(data.merchant_type === 'brand' ? 'An overview of your sales performance' : 'keep track of your sales')
        setPageText(
          data.merchant_type === 'brand'
            ? 'An overview of your sales performance'
            : data.user_type == 'admin'
            ? ' Monitor all sales made on FlexDeals'
            : ' Keep track of your sales'
        );

        break;
      case '/app/stores':
        setPageHeader(data.user_type == 'admin' ? 'Stores' : 'All Stores');
        // setPageText('An overview of all stores')
        setPageText(
          data.merchant_type === 'brand'
            ? 'An overview of all stores'
            : data.user_type == 'admin'
            ? ' An overview of the stores on FlexDeals'
            : ' An overview of all stores'
        );

        break;
      case '/app/products':
        setPageHeader('Products');
        setPageText(
          data.merchant_type === 'brand'
            ? 'Review and manage product categories'
            : data.user_type == 'admin'
            ? 'Manage product categories, discounts and tags'
            : 'View all products in the categories you have indicated you sell'
        );

        break;
      case '/app/subscription':
        setPageHeader('Subscription');
        setPageText('An overview of brands subscription type and details');

        break;
      case '/app/transactions':
        setPageHeader('Transactions');
        setPageText(
          data.user_type == 'admin'
            ? 'Overview of transactions on FlexDeals'
            : 'All Brands, Shoppers and Retailers Transactions'
        );
        break;
      case '/app/pricing':
        setPageHeader('Pricing');
        setPageText(
          data.user_type == 'admin'
            ? 'Set up FlexDeals charges on discounts, ads and insights '
            : 'All Brands, Shoppers and Retailers Transactions'
        );
        break;
      case '/app/purchases':
        setPageHeader('Purchases');
        setPageText(
          data.user_type === 'admin'
            ? 'Details of orders placed by shoppers on Flexdeals'
            : 'Manage and monitor your purchases here.'
        );
        break;
      case '/app/shoppers':
        setPageHeader(
          data.merchant_type == 'brand' ? 'Shoppers Review' : 'Shoppers'
        );
        setPageText(
          data.merchant_type === 'brand'
            ? 'See what your shoppers think about your products here.'
            : ' Monitor shoppers transactions, feedbacks and review'
        );

        break;
      case '/app/customers':
        setPageHeader('Customers');
        setPageText(
          data.merchant_type === 'brand'
            ? 'Manage and monitor all customers.'
            : data.user_type == 'admin'
            ? ' Users detail, comments and feedbacks'
            : ' Manage and monitor all customers'
        );

        // setPageText('Manage and monitor all customers')

        break;
      case '/app/wallets':
        setPageHeader('Wallet');
        setPageText('Manage all your cash transactions.');

        break;
      case '/app/audit':
        setPageHeader('Audit');
        setPageText('Monitior activities of all admin members');

        break;
      case '/app/marketing':
        setPageHeader('Marketing');
        setPageText('Manage your post and adverts ');

        break;
      case '/app/administration':
        setPageHeader('Administration');
        setPageText(
          data.user_type === 'admin'
            ? 'View your admin members and their roles'
            : data.merchant_type === 'shop'
            ? 'Assign roles to your staff here '
            : 'There are 12 roles'
        );

        break;
      case '/app/data_insight':
        setPageHeader('Data Insight');
        setPageText(
          data.user_type
            ? 'Overall performance of brands and store owners'
            : data.merchant_type === 'brand'
            ? 'An overview of your orders and performance metrics.'
            : 'Access quality insight here'
        );

        break;
      case '/app/settings':
        setPageHeader('Settings');
        setPageText('');

        break;
      case '/app/incremental_insight/more':
        setPageHeader('Incremental Insight');
        setPageText('Monitor the market performance of your competitors');

        break;
      case '/app/profile':
        setPageHeader('Profile');
        setPageText('');

        break;

      default:
        break;
    }
  }, [pathName]);
  const [openAddStore, setOpenAddStore] = useState(false);
  const handleOpenAddStore = (data = null) => {
    if (data !== null) {
      setText({
        title: 'Edit Store',
        sub: 'Edit information about this store',
      });
      changeEndPoint(`/brand/store/update-store/${data._id}`);
      if (!data.hasOwnProperty('store_category')) {
        data.store_category = 1;
        data.country = 'select';
        data.state = 'select';
      }
      setFormValues(data);
      setOpen(true);
    } else {
      changeEndPoint('/brand/store/add');
      setText({
        title: 'Add Store',
        sub: 'Add information about this store',
      });
      setOpen(true);
    }
  };

  const handleCloseAddStore = () => setOpenAddStore(false);
  const LogoutUser = () => {
    localStorage.removeItem('auth');
    dispatch({ type: 'LOGIN', payload: {} });
    setTimeout(() => {
      navigate('/login');
    }, 1000);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage, setItemsPerPage] = useState(10);
  };
  const [loadingState, setLoadingState] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [formValues, setFormValues] = useState({
    store_name: '',
    store_email: '',
    store_manager: '',
    store_phone_number: '',
    outlet_location: '',
    store_category: 'select',
    store_address: '',
    country: 'select',
    state: 'select',
    latitude: '',
    longitude: '',
  });

  const [isPending, setIsPending] = useState(false);
  const [endpoint, changeEndPoint] = useState('/brand/store/add');

  const [text, setText] = useState({
    title: 'Add Store',
    sub: 'Add information about this store',
  });
  const notify = () => toast.success('Store saved .');

  // useEffect(() => {
  //     fetchData()
  // }, [shouldFetch, search.search]);

  const setAddr = (val) => {
    setFormValues((formValues) => ({
      ...formValues,
      outlet_location: val,
    }));
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);
    setFormValues((prev) => {
      return {
        ...prev,
        latitude: lat,
        longitude: lng,
      };
    });
    setAddr(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Do something with the form values
    setIsPending(true);
    let payload = formValues;
    payload.latitude = payload.latitude.toString();
    payload.longitude = payload.longitude.toString();
    delete payload.outlet_location;
    // payload.outlet_location = payload.store_address;
    axiosInstance[formValues.hasOwnProperty('_id') ? 'put' : 'post'](
      endpoint,
      payload
    )
      .then((res) => {
        if (res.status === 201) {
          notify(res.data.message);
          // fetchData(currentPage, itemsPerPage)
          setFormValues({
            store_name: '',
            store_email: '',
            store_manager: '',
            store_address: '',
            store_phone_number: '',
            store_category: 1,
            country: 'select',
            state: 'select',
          });
          handleClose();
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          // notifyError("Unable to save store, try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const fetchCountries = async () => {
    const countriesApi = await fetch('https://restcountries.com/v3.1/all');
    const countriesJson = await countriesApi.json();
    const countriesData = countriesJson.map((country) => ({
      code: country.cca2,
      name: country.name.common,
    }));
    setCountries(countriesData);
  };

  const fetchStates = async () => {
    if (formValues.country === '') return;
    setStates([]);
    setFormValues((prev) => {
      return {
        ...prev,
        state: 'select',
      };
    });
    setLoadingState(true);
    const statesFetch = await fetch(
      `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`,
      {}
    );
    const statesJson = await statesFetch.json();
    const statesData = statesJson;
    setStates(() => {
      return statesData.filter(
        (el, i) => el.country_name === formValues.country
      );
    });
    setLoadingState(false);
  };

  useEffect(() => {
    fetchCountries();
    fetchStates();
    // fetchStates()
  }, [formValues.country]);

  const hasPermission = (permission) => {
    const authData = JSON.parse(localStorage.getItem('auth')) || [];

    const hasPermission = authData?.direct_permissions?.some((el) => {
      return el.permission_alias === permission;
    });

    return hasPermission;
  };

  useEffect(() => {
    hasPermission('manage-wallets');
  }, []);

  //   const fetchData = (page = 1, pageSize = 10) => {
  //     setIsPending(true);
  //     axiosInstance.get('/brand/store/get-stores', {
  //         params: {
  //             search: search.search,
  //             status: search.status === 'All' ? '' : (search.status ==='select' ? '' : search.status),
  //             page,
  //             pageSize
  //         }
  //     })
  //     .then(res => {
  //         setdata(res.data.data.data)
  //         setOutletPagination(res.data.data.pagination)
  //     }).catch(err => {

  //     try {
  //       notifyError(err.response.data.message);
  //     } catch (error) {
  //       notifyError("Unable to fetch store, try again later");
  //     }
  //   }).finally(() => setIsPending(false))
  // }
  return (
    <StyledRoot className="pt-md-5 style_cs">
      <StyledToolbar className="px-0 style_c">
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <div className={` header d-none d-md-block`}>
          <h4 className={`mb-1 pb-0`}>{pageHeader}</h4>
          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            {pageText}
          </p>
        </div>

        <Box sx={{ flexGrow: 1 }} />
        {pathName === '/app/stores' && data.user_type === 'admin' && (
          <div className="d-none d-md-block">
            <button
              className={`import btn btn-[#8C2D91] mr-3`}
              style={{ backgroundColor: '#53138D' }}
              onClick={() => handleOpen()}
            >
              <img src="/assets/icons/plus.svg" alt="plus" /> &nbsp; Add New
              Store
            </button>
            <button className={`btn import`} onClick={() => handleOpenCSV()}>
              Import CSV File
            </button>
          </div>
        )}
        {pathName === '/app/stores' && data.merchant_type === 'brand' && (
          <div className="d-none d-md-block">
            <button
              className={`btn import mr-3`}
              onClick={() => handleOpenCSV()}
            >
              Import CSV File
            </button>
          </div>
        )}

        {/* {pathName === '/app/incremental_insight/more' &&


          <div className="d-none d-md-block">
            <button onClick={handleOpen1} className={`btn add_outlet`}>
              <img src="/assets/icons/plus.svg" className="pr-1" alt="" />
              Add New Product
            </button>
          </div>

        } */}
        {pathName === '/app/administration' && (
          <button
            type="button"
            onClick={() => bus.emit('open-invite-modal')}
            className={`btn btn_primary`}
          >
            <img src="/assets/icons/plus.svg" className="pr-1" alt="" />
            <span>Invite Admin</span>
          </button>
        )}
        {pathName === '/app/wallets' &&
          !isSmallScreen &&
          hasPermission('manage-wallets') && (
            <div className="wallet_actions">
              <div className={`wallet_button mt-3 mt-md-0`}>
                <button
                  type="button"
                  onClick={handleOpenWalletTopUp}
                  className={`btn top_up_button`}
                >
                  <span>Top Up</span>
                </button>
                &nbsp; &nbsp;
                <button
                  onClick={handleOpenWalletWithdraw}
                  className={`btn transfer`}
                >
                  <span>Withdraw</span>
                </button>
              </div>
            </div>
          )}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open1}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open1}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`modal_content`}>
                <div className={`modal_header`}>
                  <h5
                    style={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}
                  >
                    Withdraw
                  </h5>
                  <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                    <img src="/assets/icons/x.svg" alt="" />
                  </span>
                </div>
                <p
                  className="text-muted mt-0"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Fund your wallet
                </p>

                <form onSubmit={submitFundWallet}>
                  <div className="mb-4 relative_position">
                    <label htmlFor="amount">Amount</label>
                    <span>
                      <img src="/assets/icons/naira.svg" alt="" />
                    </span>
                    <TextField
                      required
                      type="number"
                      id="amount"
                      placeholder="1000"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="state">Select Bank</label>

                    <Select
                      id="state"
                      fullWidth
                      size="small"
                      value={bank}
                      required
                      onChange={handleBankChange}
                    >
                      <MenuItem value={1}>GTBANK</MenuItem>
                      <MenuItem value={2}>STERLING</MenuItem>
                    </Select>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="cnumber">Enter Account Number </label>
                    <TextField
                      required
                      id="cnumber"
                      type="number"
                      placeholder="123456"
                      size="small"
                      fullWidth
                    />
                    <small className="text-muted">Saidi Adegoke Olayemi</small>
                  </div>

                  <div className="mb-4">
                    <button type="submit" className={`modal_btn btn btn-block`}>
                      Proceed
                    </button>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>
        {/* Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`modal_content`}>
                <div className={`modal_header mb-0`}>
                  <h5
                    style={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}
                  >
                    Top up
                  </h5>
                  <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                    <img src="/assets/icons/x.svg" alt="" />
                  </span>
                </div>
                <p
                  className="text-muted mt-0"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Fund your wallet
                </p>

                <form onSubmit={topUp}>
                  <div className="mb-4 relative_position">
                    <label htmlFor="amount">Amount</label>
                    <span>
                      <img src="/assets/icons/naira.svg" alt="" />
                    </span>
                    <TextField
                      required
                      type="number"
                      id="amount"
                      placeholder="1000"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="name">Card Holder Name</label>
                    <TextField
                      required
                      id="name"
                      placeholder="James benson"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-4 relative_position card_hold">
                    <span>
                      <img
                        src="/assets/icons/cardnum.svg"
                        style={{ width: '80%' }}
                        alt=""
                      />
                    </span>
                    <label htmlFor="cnumber">Card Number </label>
                    <TextField
                      required
                      id="cnumber"
                      type="number"
                      placeholder="Card Number"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-4 row">
                    <div className="col-6 mb-1">
                      <div className="relative_position1">
                        <label htmlFor="exp">Expiry Date </label>
                        <img src="/assets/icons/calendar.svg" alt="" />

                        <TextField
                          required
                          id="exp"
                          type="date"
                          placeholder="Date "
                          size="small"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-6 mb-1">
                      <div className="relative_position1">
                        <label htmlFor="cvv">Cvv </label>
                        <img src="/assets/icons/helpicon.svg" alt="" />
                        <TextField required id="cvv" size="small" fullWidth />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button type="submit" className={`modal_btn btn btn-block`}>
                      Proceed
                    </button>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>
        {/* Withdraw */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={otp}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={otp}>
            <Box sx={style2} className={`mobile_modal_size`}>
              <div className={`modal_content`}>
                <div className={`modal_header`}>
                  <h5
                    style={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}
                  >
                    Confirmation
                  </h5>
                  <span
                    onClick={() => setOtp(false)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="/assets/icons/x.svg" alt="" />
                  </span>
                </div>
                <p
                  className="text-muted mt-0"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Enter OTP sent to your mail to confirm <br /> transaction
                </p>

                <form onSubmit={submitOtpWallet}>
                  <div className="container  pt-5 px-5 pb-5 text-center mb-4">
                    <div
                      className={`d-flex  justify-content-center input_container`}
                    >
                      {/*  */}
                      <div className=" p-0 ml-2">
                        <input
                          type="number"
                          maxLength={1}
                          className="form-control"
                          onChange={handleChange}
                          ref={(el) => (inputs.current[0] = el)}
                        />
                      </div>
                      <div className=" p-0 ml-2">
                        <input
                          type="number"
                          maxLength={1}
                          className="form-control"
                          onChange={handleChange}
                          ref={(el) => (inputs.current[1] = el)}
                        />
                      </div>
                      <div className=" p-0 ml-2">
                        <input
                          type="number"
                          maxLength={1}
                          className="form-control"
                          onChange={handleChange}
                          ref={(el) => (inputs.current[2] = el)}
                        />
                      </div>
                      <div className=" p-0 ml-2">
                        <input
                          type="number"
                          maxLength={1}
                          className="form-control"
                          onChange={handleChange}
                          ref={(el) => (inputs.current[3] = el)}
                        />
                      </div>
                    </div>
                    <small
                      className="text-[#53138D]"
                      style={{ fontWeight: 'bold' }}
                    >
                      Resend Otp
                    </small>
                  </div>

                  <div className="mb-4">
                    <button type="submit" className={`modal_btn btn btn-block`}>
                      Proceed
                    </button>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openDialog}>
            <Box className={`mobile_modal_size modal_bg`} sx={style1}>
              <div className={`text-center add_to_cart_content`}>
                <img src="/assets/icons/circleCheck.svg" alt="" />
                <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>
                  Payment Completed
                </h5>
                <p
                  className="text-center"
                  style={{ textAlign: 'center !important' }}
                >
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius
                  aliquam laudantium explicabo pariatur iste dolorem animi vitae
                  error totam. At sapiente aliquam.
                </p>
                <button
                  onClick={() => setOpenDialog(false)}
                  className={`modal_btn hover btn btn-block`}
                >
                  Done
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>

        {/* Open Add Store */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openAddStore}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openAddStore}>
            <Box sx={styleAddProduct} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5
                      style={{
                        fontSize: '19px',
                        fontWeight: '700',
                      }}
                    >
                      {text.title}
                    </h5>
                    <p
                      style={{
                        fontSize: '11px',
                        color: '#71717A',
                      }}
                    >
                      {text.sub}
                    </p>
                  </div>
                  <div>
                    <span
                      onClick={handleCloseAddStore}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="mt-0 form_label">
                  <div className="mb-3">
                    <label htmlFor="name">Store Name</label>
                    <TextField
                      required
                      type="text"
                      value={formValues.store_name}
                      name="store_name"
                      onChange={handleChange}
                      id="name"
                      placeholder="Store 1"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name"> Store Category</label>
                    <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={formValues.store_category}
                      name="store_category"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value="select">Select Category</MenuItem>
                      <MenuItem value="Kiosk">Kiosk</MenuItem>
                      <MenuItem value="Neighborhood store">
                        Neighborhood store
                      </MenuItem>
                      <MenuItem value="Supermarket">Supermarket</MenuItem>
                      <MenuItem value="E-commerce store">
                        E-commerce store
                      </MenuItem>
                    </Select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mname">Store Manager Name</label>
                    <TextField
                      required
                      value={formValues.store_manager}
                      name="store_manager"
                      onChange={handleChange}
                      type="text"
                      id="mname"
                      placeholder="Irina Shark"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <TextField
                      required
                      type="email"
                      value={formValues.store_email}
                      name="store_email"
                      onChange={handleChange}
                      id="email"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone">Phone Number</label>
                    <TextField
                      required
                      type="phone"
                      value={formValues.store_phone_number}
                      name="store_phone_number"
                      onChange={handleChange}
                      id="phone"
                      placeholder="+234908877209"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3">
                    <PlacesAutocomplete
                      value={formValues.outlet_location}
                      onChange={setAddr}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <div className="my-4 inputs_n_label">
                            <label htmlFor="loc">Address</label>
                            <TextField
                              id="loc"
                              placeholder="Input your location"
                              size="small"
                              fullWidth
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                              })}
                            />
                          </div>

                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, i) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                  };
                              return (
                                <div
                                  key={i}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  {/* <div className='my-4 inputs_n_label'>
                              <label htmlFor='businessDesc'> Store Address*</label>
                              <TextField
                                  required
                                  id="businessDesc"
                                  value={formValues.store_address}
                                  name='store_address'
                                  onChange={handleChange}
                                  placeholder='Input address'
                                  size="small"
                                  multiline
                                  rows='5'
                                  cols='5'
                                  fullWidth
                              />
                            </div> */}
                  <div className="row">
                    <div className="col-md-6 mb-1">
                      <label htmlFor="s">Country*</label>
                      <Select
                        labelId="s"
                        id="s"
                        fullWidth
                        size="small"
                        required
                        value={formValues.country}
                        name="country"
                        onChange={handleChange}
                      >
                        <MenuItem value="select">Select Country</MenuItem>
                        {countries.map((el, i) => {
                          return (
                            <MenuItem key={i} value={el.name}>
                              {el.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-6 mb-1">
                      <label htmlFor="state">State*</label>
                      &nbsp;
                      {loadingState && (
                        <CircularProgress
                          style={{ color: '#53138D' }}
                          size="1rem"
                        />
                      )}
                      <Select
                        labelId="s"
                        id="s"
                        fullWidth
                        size="small"
                        required
                        value={formValues.state}
                        name="state"
                        onChange={handleChange}
                      >
                        <MenuItem value="select">Select State</MenuItem>
                        {states.map((el, i) => {
                          return (
                            <MenuItem key={i} value={el.name}>
                              {el.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                  <div className="mb-1 mt-4">
                    {!isPending && (
                      <button
                        type="submit"
                        className={`${styles.modal_btn} btn btn-block`}
                      >
                        Save
                      </button>
                    )}
                    {isPending && (
                      <button
                        disabled
                        className={`btn btn-block ${styles.modal_btn}`}
                      >
                        Submitting &nbsp;
                        {isPending && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>

        {/* Invite Admin Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content}`}>
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5
                      style={{
                        fontSize: '19px',
                        fontWeight: '700',
                      }}
                    >
                      Bankole Stephen
                    </h5>
                  </div>
                  <div>
                    <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="mt-0">
                  <div className="row">
                    <div className="col-md-10 col-9">
                      <div className="mb-3">
                        <label htmlFor="name">Email Address</label>
                        <TextField
                          required
                          type="text"
                          // value={formValues.outlet_name}
                          name="outlet_name"
                          onChange={handleChange}
                          id="name"
                          placeholder="sample@email.com"
                          size="small"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-3 upper_space">
                      <button
                        className="btn btn-[#53138D] bg-"
                        style={{ background: '#53138D' }}
                      >
                        {' '}
                        Send Invite
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-9">
                      <strong>
                        <p>Select Permissions For This Role</p>
                      </strong>
                    </div>
                    <div className="col-3 text-right">
                      <p>Select All</p>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      color: '#585c63',
                      fontSize: '14px',
                    }}
                  >
                    <div className="col-md-4">
                      <p className="adjust_p_margin">
                        <strong>Dashboard</strong>
                      </p>
                      <p className="adjust_p_margin">User Can View Dashboard</p>
                    </div>
                    <div className="col-md-4">
                      <p className="adjust_p_margin">
                        <strong>Stores</strong>
                      </p>
                      <p className="adjust_p_margin">User Can View Stores</p>
                      <p className="adjust_p_margin">User Can Manage Stores</p>
                      <p className="adjust_p_margin">User Can Delete Stores</p>
                    </div>
                    <div className="col-md-4">
                      <p className="adjust_p_margin">
                        <strong>Admin Users</strong>
                      </p>
                      <p className="adjust_p_margin">
                        User Can View Admin Users
                      </p>
                      <p className="adjust_p_margin">
                        User Can Delete Admin Users
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>
      </StyledToolbar>
    </StyledRoot>
  );
}
