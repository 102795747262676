

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
import styles from './Page.module.css'
import Logo from "../logo/appLogo"
import MenuItem from '@mui/material/MenuItem';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LiveSelect from 'react-select';
import Select from '@mui/material/Select';
import { createRef, useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import { useAuth } from 'src/hooks/useAuth';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/axiosInstance"
import CircularProgress from '@mui/material/CircularProgress';
import { useApiKeys } from 'src/hooks/useApiKeys';
import CategoriesModal from './CategoriesModal';
import bus from 'src/bus';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SelectLocationMap } from 'src/components';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { useCategoriesApi } from 'src/context/CategoriesApi';


export default function RetailerPage() {
  const { dispatch, data } = useAuth()
  const apiKeys = useApiKeys();
  const [country, setCountry] = useState('select');
  const [state, setState] = useState('select');


  const [store_name, setstore_name] = useState('');
  const [store_address, setAddr] = useState('');
  const [store_desc, setStoreDescription] = useState('');
  const [terminal_id, setTerminalId] = useState('');
  const navigate = useNavigate();
  const [linkedIn_url, setLinkedIn] = useState('');
  const [store_website, setBusinessWebsite] = useState('');
  const [selectedOption] = useState(null);
  const [selectedOption1] = useState(null);
  const [selectedOption2] = useState(null);

  const [store_phone_number, setPhone] = useState('');
  const [store_email, setEmail] = useState(data.email);
  const [store_category, setStore] = useState('select');
  const [store_logo_preview, setUrl] = useState('/assets/icons/profileicon.svg');
  const [store_logo, setUrlP] = useState(null);
  const [store_image, setStoreImage] = useState(null);
  const [store_image_preview, setStoreImagePreview] = useState('/assets/dropBox.svg');
  const notify = () => toast.success('Onboarding successful .');
  const notifyError = (err) => toast.error(err);
  const [isPending, setIsPending] = useState(false);
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  let [location, setLocation] = useState({ latitude: '', longitude: '' });
  let [bank_info, setBankInfo] = useState({ account_no: '', account_name: '', bank_code: 'select' });
  const [banks, setBanks] = useState([]);
  const [nameEnquiryLoading, setNameEnquiryLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingState, setLoadingState] = useState(false)
  const [loadingBanks, setLoadingBanks] = useState(false)
  const categories = useCategoriesApi()
  const options = countries?.map((el) => ({ value: el.name, label: el.name }));
  const options2 = states?.map((el) => ({ value: el.name, label: el.name }));

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locationDisabled, setLocationDisabled] = useState(false);
  const [currentLocationCheck, setCurrentLocationCheck] = useState(false);

  const handleWebsiteChange = (event) => {
    setBusinessWebsite(event.target.value)
  }
  const handleLinkedChange = (event) => {
    setLinkedIn(event.target.value)
  }

  const fetchCountries = async () => {
    const countriesApi = await fetch('https://restcountries.com/v3.1/all')
    const countriesJson = await countriesApi.json();
    const countriesData = countriesJson?.map(country => ({
      code: country.cca2,
      name: country.name.common
    }));
    setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)))
  }

  const fetchStates = async () => {
    if (country === '') return;
    setStates([]);
    setState('select');
    setLoadingState(true);
  
    try {
      const statesFetch = await fetch(`https://countriesnow.space/api/v0.1/countries/states`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: country 
        }),
      });
      const statesJson = await statesFetch.json();
      const statesData = statesJson.data.states;
  
      setStates(() => {
        return statesData.filter((el) => el.name).sort((a, b) => a.name.localeCompare(b.name));
        // return statesData.filter((el) => el.name === country).sort((a, b) => a.name.localeCompare(b.name));
      });
      setLoadingState(false);
    } catch (error) {
      console.error('Error fetching states:', error);
      setLoadingState(false);
    }
  };
  

  useEffect(() => {
    console.log(states);
  }, [states])
  useEffect(() => {
    fetchCountries()

  }, [])

  useEffect(() => {
    fetchStates()
  }, [country])
  
  const passProducts = (value) => {
    setProductsAdded(value)
  };
  const removeFromList = (index) => {
    let data = [...productsAdAdded]
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1)
    setProductsAdded(data)
  }
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]))
      setUrlP(e.target.files[0])
    }
  }

  const handleFileImage = (e) => {
    if (e.target.files[0]) {
      setStoreImagePreview(URL.createObjectURL(e.target.files[0]))
      setStoreImage(e.target.files[0])
    }
  }

  useEffect(() => {
    console.log(store_image);
  }, [store_image])



  let textInput = createRef();
  let imageInput = createRef();
  const handleNameChange = (event) => {
    setstore_name(event.target.value);
  };


  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChange = (event) => {
    setCountry(event.value);
  };
  const handleChange2 = (event) => {
    setState(event.value);
  };
  const handleChange3 = (event) => {
    setStore(event.target.value);
  };

  const handleButtonClick = () => {

    textInput.current.click()
  }
  const handleSelectFIleClick = () => {

    imageInput.current.click()
  }
  const handleStoreDescription = (event) => {
    setStoreDescription(event.target.value)
  }

  const handleTerminalId = (event) => {
    setTerminalId(event.target.value)
  }


  function isValidUrl(url) {
    // Regular expression to match a valid URL
    const urlPattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

    return urlPattern.test(url);
  }

  async function HandleFormSubmit(e) {
    e.preventDefault();
    if(!productsAdAdded.length) return notifyError('Select at least a product category to continue onboarding process');

    const supportedImageFormats = ['image/png', 'image/jpeg', 'image/jpeg'];

    if (store_image && !supportedImageFormats.includes(store_image.type)) {
      // Handle the error for unsupported image format
      notifyError('Unsupported store image format. Please upload a PNG, JPG or JPEG file.');
      return;
    } 

    let payload = {
      store_name, store_email, country, store_address,
      store_phone_number, state, store_category,
      linkedIn_url, store_website,
      store_description: store_desc,
      business_image: store_image,
      latitude: location.latitude,
      longitude: location.longitude,
      store_account_name: bank_info.account_name,
      store_logo: store_logo,
      store_pos_id: terminal_id,
    }

    payload.store_account_number = bank_info.account_no
    payload.store_bank_name = banks.find(el => el.code === bank_info.bank_code)?.name

    
    if (!location.latitude) {
      return notifyError("Please select a valid address")

    }
    if (store_website !== '') {
      if (!isValidUrl(store_website)) {
        return notifyError("That isn't a valid website url")
      }
    }
    if (linkedIn_url !== '') {
      if (!isValidUrl(linkedIn_url)) {
        return notifyError("That isn't a valid LinkedIn url")
      }
    }
    setIsPending(true);
  
    const formData = new FormData();

    Object.keys(payload).forEach(key => formData.append(key, payload[key]));
    
    
    axiosInstance.put('/merchant/store-info', formData)
      .then(res => {
        if (res.status === 201) {
          notify();
          data.onboarded = true;
          localStorage.setItem('auth', JSON.stringify(data));
          dispatch({ type: 'LOGIN', payload: data })

          window.location.href = '/app/dashboard'
        }
        if(res.status === 400 && res.data.message.error === "We've found an issue with the file type image/gif selected. The file type supported is image/jpeg,image/png,image/jpg. Please check and try again") {
          notifyError('The uploaded image type is not supported, supported image type is jpeg,png,jpg. Please check and try again');
          return;
        }
      }).catch(err => {
        if(err.response.data.message?.error && err.response.data.message?.error === "We've found an issue with the file type image/gif selected. The file type supported is image/jpeg,image/png,image/jpg. Please check and try again") {
          notifyError('The uploaded image type is not supported, supported image type is jpeg,png,jpg. Please check and try again');
          return;
        }
        notifyError(err.response.data.message);

      }).finally(() => setIsPending(false))

    // notify()
    // setTimeout(() => {
    //   navigate("/app/dashboard");
    // }, 1000);
  }

  const LogoutUser = () => {
    localStorage.removeItem('auth');
    dispatch({ type: 'LOGIN', payload: {} })

    setTimeout(() => {
      navigate(data.merchant_type ? '/login' : '/auth')
    }, 1000);
  }

  const fetchBanks = async () => {
    const banksApi = await fetch('https://api.paystack.co/bank')
    const banks = await banksApi.json();
    setLoadingBanks(true)
    if (banks.status) {
      setLoadingBanks(false)
      setBanks(banks.data)
    }
  }
  
  const nameEnquiry = async () => {
    if (bank_info.account_no.length < 10 || bank_info.account_no.length > 11) return;

    setNameEnquiryLoading(true)
    bank_info.account_name = ''
    const accountNameApi = await fetch(`https://api.paystack.co/bank/resolve?account_number=${bank_info.account_no}&bank_code=${bank_info.bank_code}`, {
      headers: {
        'Authorization': `Bearer ${apiKeys.PASTACK.SECRET_KEY}`
      }
    })
    const accountName = await accountNameApi.json();
    setNameEnquiryLoading(false)


    if (accountName.status) {
      const data = bank_info;
      data.account_name = accountName.data.account_name
      setBankInfo(data)

    } else {
      notifyError(accountName.message);

    }
  }

  const handleBankChange = (e) => {
    const value = e.value
    setBankInfo((prev) => {
      return {
        ...prev,
        bank_code: value
      }
    })

  }
  const handleBankAccountChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setBankInfo((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })

  }
  useEffect(() => {
    fetchBanks()
  }, [])
  useEffect(() => {
    nameEnquiry()
  }, [bank_info.account_no])
  useEffect(() => {
    const data = bank_info;
    data.account_no = ''
    setBankInfo(data)
  }, [bank_info.bank_code])

  function logOutUser() {
    localStorage.removeItem('auth');
    dispatch({ type: 'LOGIN', payload: {} })

    setTimeout(() => {
      navigate('/login')
    }, 1000);
  }
  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);
    setLocation({
      latitude: lat,
      longitude: lng

    })
    setAddr(value);
  }

  const setAddress = (e) => {
    setAddr(e)
  }

  const [addressResults, setAddressResults] = useState([]);

  const handleUseLiveLocation = () => {
    console.log('i got here')
    if (navigator.geolocation) {
      console.log(navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          console.log('i got here too')
          const { latitude, longitude } = position.coords;
          console.log(position.coords, latitude, longitude, '000')
          if (latitude !== null && longitude !== null) {
            const input = 'Pizza near ' + latitude + ',' + longitude;
            setSelectedLocation({ latitude, longitude });
            // const API_endpoint = 'https://api.openweathermap.org/data/2.5/weather?'
            // const API_key = '7e4e207013e5d2bdc02b0c2e2b6c70a9'
            // let finalAPIEndpoint = `${API_endpoint}lat=${latitude}&lon=${longitude}&appid=${API_key}`
            // fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=03c48dae07364cabb7f121d8c1519492&no_annotations=1&language=en`)
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},+${longitude}&result_type=street_address|point_of_interest&key=AIzaSyDJSZC3d1twPS0a9nO0Ecjucu3nBgraf2M`)
            // fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=AIzaSyDJSZC3d1twPS0a9nO0Ecjucu3nBgraf2M`)
            // fetch(finalAPIEndpoint)
              .then(response => response.json())
              .then(data => {
                console.log('this is the data', data);
                if (data.results && data.results.length > 0) {
                  const uniqueAddresses = [];
                  data.results.forEach(result => {
                    if (!uniqueAddresses.includes(result.formatted_address)) {
                      uniqueAddresses.push(result.formatted_address);
                    }
                  });
                  setAddressResults(uniqueAddresses);
                } else {
                  console.error('No address found for the given coordinates.');
                  setSelectedLocationName("Unknown");
                }
              })
              .catch(error => {
                console.error('Error fetching address:', error);
                setSelectedLocationName("Unknown");
              });
          } else {
            console.error('Latitude or longitude is null.');
            setSelectedLocationName("Unknown");
          }
        },
        (error) => {
          console.error('Error getting location:', error);
        },
        {
          enableHighAccuracy: true, // Enable high accuracy
          timeout: 5000, // Timeout in milliseconds (optional)
          maximumAge: 0 // Maximum age of cached position in milliseconds (optional)
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleAddressChange = (e) => {
    setAddr(e.target.value)
  }

  const handlePasteLocation = () => {
    if(locationDisabled) {
      alert("Please enable browser location to proceed.")
    };
    console.log(selectedLocation);
    
    setLocation(selectedLocation)
    // setAddr(selectedLocationName)
    setCurrentLocationCheck(!currentLocationCheck)

    if (addressResults.length > 0) {
      setAddr(addressResults[0]);
    }
  }

  const fetchAddressResults = async () => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&key=YOUR_API_KEY`);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const uniqueAddresses = [];
        data.results.forEach(result => {
          if (!uniqueAddresses.includes(result.formatted_address)) {
            uniqueAddresses.push(result.formatted_address);
          }
        });
        setAddressResults(uniqueAddresses);
      } else {
        console.error('No address found for the given coordinates.');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  useEffect(() => {
    handleUseLiveLocation()
  }, []);

  // useEffect(() => {
  //   console.log(selectedLocation, '111');
  //   console.log(location.latitude, location.longitude, '222');
  // }, [selectedLocation, location]);
  

  return (
    <>
      <div className={`row ${styles.sav}`}>
        <div className="col-md-6 d-none d-md-block">
          <div className={styles.bg}  >
            <div className={styles.content}>
              <h5>
                Welcome, Let's get to know 
                <br/> 
                more about your business
              </h5>
              <ul className={`${styles.features} list-unstyled`}>
                <li>
                  <img src='/assets/check-circle.svg' alt='ic' />
                  <small>
                    Your business becomes public to wholesalers and retailers
                  </small>
                </li>
                <li>
                  <img 
                    src='/assets/check-circle.svg' 
                    alt='ic' 
                  />
                  <small>
                    Businesses with completed profiles are 3x more likely to be 
                    <br/> 
                    seen by customers
                  </small>
                </li>
                <li>
                  <img 
                    src='/assets/check-circle.svg' 
                    alt='ic' 
                  />
                  <small>
                    Robust dashboard including real time data for making sales 
                    <br/> 
                    decisions
                  </small>
                </li>
                <li>
                  <img 
                    src='/assets/check-circle.svg' 
                    alt='ic' 
                  />
                  <small>
                    Unlimited access to all the features on your dashboard
                  </small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <Logo />
          {/* <div className='pt-2'>
            <img 
              className='logo' 
              style={{display:'inline'}} 
              src='/assets/Logomark.png' 
              alt='logo' 
            />
            Retail Xpress
          </div> */}
          <div className={`${styles.sign_up} container`}>
            <div className={`${styles.headerSection} text-center`}>
              <div className='m-0 text-center'>
                <img 
                  src='/assets/icons/Featuredicon.svg' 
                  alt='' 
                />
              </div>
              <h3>
                Store information
              </h3>
              <p>
                Please enter the relevant details.
              </p>
            </div>
            <form onSubmit={HandleFormSubmit} className={styles.form}>
              <div className='mb-0'>
                <div className='d-flex justify-content-between'>
                  <p className='mb-1'>
                    Upload profile picture
                  </p>
                  <span 
                    className='text-[#53138D]' 
                    style={{ 
                      cursor: 'pointer' 
                    }} 
                    onClick={LogoutUser}
                  >
                    Back
                  </span>
                </div>
                <input 
                  ref={textInput} 
                  onChange={handleFile} 
                  type="file" 
                  hidden 
                  name="" 
                  id="" 
                />
                <div className={styles.main_img_container}>
                  <div className={styles.img_container}>
                    <div className={styles.avatar_container}>
                      <img 
                        style={{ 
                          width: '100%' 
                        }} 
                        src={store_logo_preview} 
                        alt='' 
                      />
                    </div>
                  </div>
                  <span 
                    style={{ 
                      cursor: 'pointer' 
                    }} 
                    onClick={() => handleButtonClick()}
                  >
                    <img 
                      className={styles.camera} 
                      src="/assets/icons/camera.svg" 
                      alt="" 
                    />
                  </span>
                </div>
                <div>
                </div>
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='storeName'>Business Name*</label>
                <TextField
                  id="storeName"
                  placeholder="Enter store name"
                  value={store_name}
                  onChange={handleNameChange}
                  size="small"
                  fullWidth
                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='category'>
                  Store category*
                </label>
                <Select
                  labelId="category"
                  id="category"
                  fullWidth
                  size="small"
                  value={store_category}
                  required
                  onChange={handleChange3}
                >

                  <MenuItem value='select'>Select</MenuItem>
                  <MenuItem value='Kiosk'>Kiosk</MenuItem>
                  <MenuItem value='Neighborhood store'>Neighborhood store</MenuItem>
                  <MenuItem value='Supermarket'>Supermarket</MenuItem>
                  <MenuItem value='E-commerce store'>E-commerce store</MenuItem>
                </Select>
              </div>
              <PlacesAutocomplete
                value={store_address}
                onChange={(e) => { setAddress(e); setCurrentLocationCheck(false); }}
                onSelect={handleSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <div className='my-4 inputs_n_label'>
                      
                      <label htmlFor='loc'>Store Location*</label>
                      
                      {currentLocationCheck && (
                        <div className={`${styles.syl} d-flex mt-0`}>
                          <FormControlLabel className='m-0' value={store_address} checked control={<Radio />} />
                          <Select
                            id="state"
                            fullWidth
                            size="small"
                            value={store_address}
                            required
                            onChange={handleAddressChange}
                          >
                            {addressResults.map((address, index) => (
                              <MenuItem key={index} value={address}>{ address }</MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}
                      {
                        !currentLocationCheck && (
                          <TextField
                            id="loc"
                            placeholder='Input your location'
                            size="small"
                            fullWidth
                            {...getInputProps({
                              placeholder: 'Search Places ...',
                              className: 'location-search-input',
                            })}
                          />
                        )
                      }
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions?.map((suggestion, i) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              key={i}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>

                      {/* this is long and lat */}
                      <div className="row mt-2">
                        <div className="col-md-6 mb-1">
                          <label htmlFor='state'>Latitude*</label>&nbsp;
                          <TextField
                            required
                            id="lat"
                            value={location?.latitude}
                            size="small"
                            fullWidth
                            disabled

                          />
                        </div>
                        <div className="col-md-6 mb-1">
                          <label htmlFor='state'>Longitude*</label>&nbsp;
                          <TextField
                            required
                            id="lng"
                            value={location?.longitude}
                            size="small"
                            fullWidth
                            disabled

                          />
                        </div>
                      </div>

                      {/* {
                        !currentLocationCheck &&
                        <p className='mt-2' style={{fontSize: 'small', fontStyle: 'italic'}}>
                          OR
                        </p>
                      } */}
                      <div className={`${styles.syl}`} style={{ position: 'relative', width: '210px' }}>
                          {
                            !currentLocationCheck &&
                            <p 
                              className='mt-2' 
                              onClick={handlePasteLocation} 
                              style={{ cursor: 'pointer', fontSize: '15px' }}>
                              <FmdGoodIcon /> Use My Current Location
                              {isLoading && <CircularProgress className='ml-1 mt-1' style={{ color: '#53138D' }} size="0.8rem" />}
                            </p>
                          }
                          {
                            currentLocationCheck &&
                            <p 
                              className='mt-2' 
                              onClick={handlePasteLocation}
                              style={{ cursor: 'pointer', fontSize: '15px' }}>
                              <FmdGoodIcon /> Manual Location (Edit)
                            </p>
                          }
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <div className='my-4 inputs_n_label'>
                <div className="row">
                  <div className="col-md-6 mb-1">
                    <label htmlFor='s'>Country*</label>
                    <LiveSelect
                      defaultValue={selectedOption}
                      onChange={handleChange}
                      options={countries?.map((option) => ({
                        value: option.name,
                        label: option.name,
                      }))}
                    />
                    {/* <Select
                      labelId="s"
                      id="s"
                      fullWidth
                      size="small"
                      value={country}
                      required
                      onChange={handleChange}
                    >

                      <MenuItem value='select'>Select Country</MenuItem>
                      {countries.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el.name}>{el.name}</MenuItem>

                        )
                      })}
                    </Select> */}

                  </div>
                  <div className="col-md-6 mb-1">
                    <label htmlFor='state'>State*</label>&nbsp;
                    {loadingState &&
                      <CircularProgress style={{ color: '#53138D' }} size="1rem" />

                    }
                    <LiveSelect
                      defaultValue={selectedOption1}
                      onChange={handleChange2}
                      options={states?.map((option) => ({
                        value: option.name,
                        label: option.name,
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='storePhone'>Store Phone Number*</label>
                <TextField
                  required
                  id="storePhone"
                  value={store_phone_number}
                  onChange={handlePhoneChange}
                  size="small"
                  fullWidth

                />
              </div>
              <div className=' inputs_n_label'>
                <label htmlFor='storePhone'>Bank Account Details*</label>
                {loadingBanks &&
                  <CircularProgress style={{ color: '#2563EB' }} size="1rem" />
                }
                <div className='inputs_n_label'>
                  <div className="row">
                    <div className="col-6">
                      <small htmlFor='businessEmail'>Bank Name*</small>
                      <LiveSelect
                        defaultValue={selectedOption2}
                        onChange={handleBankChange}
                        options={banks?.map((option) => ({
                          value: option.code,
                          label: option.name,
                        }))}
                      />

                    </div>
                    <div className="col-6">
                      <small  >Account No*</small>
                      <TextField
                        required
                        id="businessPhone"
                        size="small"
                        value={bank_info.account_no}
                        fullWidth
                        name="account_no"
                        onChange={handleBankAccountChange}

                      />
                    </div>
                    <div className="col-12 pt-1  text-right">

                      {nameEnquiryLoading &&
                        <CircularProgress style={{ color: '#53138D' }} size="1rem" />
                      }
                      {
                        !nameEnquiryLoading &&
                        <p style={{ fontSize: '12px' }}>{bank_info.account_name}</p>

                      }
                    </div>
                  </div>

                </div>


              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='storeEmail'>Store Email*</label>
                <TextField
                  required
                  id="storeEmail"
                  value={store_email}
                  onChange={handleEmailChange}
                  size="small"
                  fullWidth

                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessDesc'>Store Description*</label>
                <TextField
                  required
                  id="businessDesc"
                  value={store_desc}
                  onChange={handleStoreDescription}
                  placeholder='Your store description'
                  size="small"
                  multiline
                  rows='5'
                  cols='5'
                  fullWidth

                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='terminalId'>Terminal Id</label>
                <TextField
                  value={terminal_id}
                  onChange={handleTerminalId}
                  id="terminalId"
                  size="small"
                  fullWidth

                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessDesc'>Store Image*</label>
                <input ref={imageInput} onChange={handleFileImage} type="file" hidden name="" id="" />
                <div className={`text-center  box`}>
                  <div>
                    <p>
                      <img src={store_image_preview} style={{ width: '50%' }} alt="" />
                    </p>
                  </div>
                  <div className="mt-4">
                    {/* <p>Drop files to upload</p>
                    <small>
                      Or
                    </small> */}
                    <p>
                      <button type='button' onClick={handleSelectFIleClick} className={`btn btn_styles`}>
                        Select Files
                      </button>

                    </p>
                    <div className={`mt-4 box_buttom_content`}>
                      <p className="mb-2">
                        Recommended image size to (821x150)px
                      </p>
                      <p className="mb-2">
                        Maximum upload files size: 20MB
                      </p>
                      <p>
                        Allowed image type (png, jpg, jpeg)
                      </p>
                    </div>
                  </div>

                </div>


              </div>
              <div className={`mb-3 inputs_n_label ${styles.push_notification_icon}`}>
                <label htmlFor="changeProduct">Product category*</label>
                <TextField
                  required
                  id="businessDesc"
                  onClick={() => bus.emit('open-categories-onboarding', productsAdAdded)}
                  placeholder='Select items'
                  size="small"
                  disabled
                  fullWidth

                />
                <img onClick={() => bus.emit('open-categories-onboarding', productsAdAdded)} className="cursor_pointer" src="/assets/icons/ShapeRight.svg" alt="" />

              </div>
              <div className="products mt-2">
                {productsAdAdded?.map((el, i) => {

                  return (
                    <div key={i} className={`product_selected`}>
                      <div className=" d-flex justify-content-between">
                        <span >{el}</span>
                        &nbsp;
                        &nbsp;
                        <span style={{ cursor: 'pointer' }} onClick={() => removeFromList(i)}>
                          <img style={{ width: '5px' }} src="/assets/icons/x.svg" alt="" />

                        </span>

                      </div>
                    </div>
                  )
                })}
              </div>

              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessWebsite'>Store website</label>
                <TextField
                  value={store_website}
                  onChange={handleWebsiteChange}
                  id="businessWebsite"
                  placeholder='Enter your company URL'
                  size="small"
                  fullWidth

                />
              </div>
              <div className='my-4 inputs_n_label'>
                <label htmlFor='businessLink'>LinkedIn URL</label>
                <TextField
                  value={linkedIn_url}
                  onChange={handleLinkedChange}
                  id="businessLink"
                  placeholder='Url'
                  size="small"
                  fullWidth

                />
              </div>
              {/* <div className='my-4 inputs_n_label'>
                <label htmlFor='businessDesc'>Business Description*</label>
                <TextField
                    required
                    id="businessDesc"
                    value={store_description}
                    onChange={handleStoreDescription}
                    placeholder='Your business description'
                    size="small"
                    multiline
                    rows='5'
                    cols='5'
                    fullWidth

                  />
              </div> */}



              <div className={styles.buttons}>
                {!isPending &&
                  <button className={`btn btn-block ${styles.btn_custom_color}`}>
                    <span>
                      Submit
                    </span>

                  </button>


                }
                {isPending && <button disabled className={`btn btn-block ${styles.btn_custom_color}`}>
                  Submitting &nbsp;
                  {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
                </button>}

              </div>

            </form>


          </div>
        </div>
      </div>

      <CategoriesModal productsData={passProducts} />
    </>
  );
}
