/* eslint-disable no-unreachable */


// ----------------------------------------------------------------------
import styles from './Auth.module.css'
import Logo from "../logo/appLogo"
import { NavLink as RouterLink } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useContext, useEffect, useRef, useState } from "react"
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from "react-router-dom";
import { BaseUrlContext } from 'src/context/BaseUrlContext';
import bus from 'src/bus';
import PlacesAutocompleteComponent from 'src/components/placeAutoComplete';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { GoogleLogin } from 'react-google-login';
export default function RegisterPage() {
  let navigate = useNavigate();
  // const location = useLocation()
  const [category, setCategory] = useState('shop');
  const [StoreID, setStoreID] = useState(false);
  const [StoreIDValue, setStoreIDValue] = useState('');
  const [email, setEmail] = useState('');
  const [storeName, setStoreName] = useState('');
  const [location, setLocation] = useState({ latitude: '', longitude: '' });
  const [storeAddress, setStoreAddress] = useState('');
  const [storePhone, setStorePhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setCPassword] = useState('');
  const [agree, setAgree] = useState(false);
  const [doesNotMath, setDoesNotMath] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [response, setResponse] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const [store_address, setAddr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocationCheck, setCurrentLocationCheck] = useState(false);
  const baseUrl = useContext(BaseUrlContext);
  const [pageContent, setPageContent] = useState("Track and monitor <br /> your customers on <br /> a single dashboard")
  const [pageDescription, setDescription] = useState("  Make informed sales decisions by monitoring consumer <br /> behaviours, brand acceptance and market performance.")
  const [bg, setBg] = useState("/assets/happyMan.jpg");
  const prevCategoryRef = useRef('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationDisabled, setLocationDisabled] = useState(false);
  const [addressResults, setAddressResults] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState(null);




  const notify = () => toast.success('Registration successful .');
  const notifyError = () => toast.error("That didn't work please try again later .");

  useEffect(() => {
    if (category !== '' && category !== prevCategoryRef.current) {
      if (category === 'brand') {
        setPageContent("Track and monitor <br /> your customers on <br /> a single dashboard");
        setDescription("Make informed sales decisions by monitoring consumer <br /> behaviours, brand acceptance and market performance.");
        setBg("/assets/happyMan.jpg");
      } else if (category === 'shop') {
        setPageContent(" Buy, sell and monitor <br />  your customers on a <br /> single platform");
        setDescription(" Make purchases and keep track of all your business transactions in one <br /> convenient place.");
        setBg("/assets/retailerBg.svg");
      }
      prevCategoryRef.current = category;
    }
  }, [category])

  const handleChange = (event) => {
    let value = event.target.value;
    setCategory(value);


  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleStoreNameChange = (event) => {
    setStoreName(event.target.value);
  };
  const handleStoreAddressChange = (value) => {
    setStoreAddress(value);
    setAddress(value);
    setCurrentLocationCheck(false);
  };
  const handleStorePhoneChange = (event) => {
    setStorePhone(event.target.value);
  };
  const handleCPasswordChange = (event) => {
    setCPassword(event.target.value);

  };
  const setAddress = (e) => {
    setAddr(e)
  }
  const handleUseLiveLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          // console.log(latitude, longitude, '000')
          if (latitude !== null && longitude !== null) {
            setSelectedLocation({ latitude, longitude });
            // fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDJSZC3d1twPS0a9nO0Ecjucu3nBgraf2M`)
            // fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=03c48dae07364cabb7f121d8c1519492&no_annotations=1&language=en`)
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},+${longitude}&key=AIzaSyCvH_nDKUVAcmjtKEbv-_YDY9rVbGqg4VQ`)
              .then(response => response.json())
              .then(data => {
                if (data.results && data.results.length > 0) {
                  const uniqueAddresses = [];
                  data.results.forEach(result => {
                    if (!uniqueAddresses.includes(result.formatted_address)) {
                      uniqueAddresses.push(result.formatted_address);
                    }
                  });
                  setAddressResults(uniqueAddresses);
                } else {
                  console.error('No address found for the given coordinates.');
                  setSelectedLocationName("Unknown");
                }
              })
              .catch(error => {
                console.error('Error fetching address:', error);
                setSelectedLocationName("Unknown");
              });
          } else {
            console.error('Latitude or longitude is null.');
            setSelectedLocationName("Unknown");
          }
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    handleUseLiveLocation()
  }, []);

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);
    setLocation({
      latitude: lat,
      longitude: lng

    })
    setAddr(value);
  }

  const handlePasteLocation = () => {
    if(locationDisabled) {
      alert("Please enable browser location to proceed.")
    };
    
    setLocation(selectedLocation)
    // setAddr(selectedLocationName)
    setCurrentLocationCheck(!currentLocationCheck)

    if (addressResults.length > 0) {
      setAddr(addressResults[0]);
    }
  }
  const handleChange2 = (e) => {
    setAgree(!agree)
  }

  const changeType = () => {
    setShowText(!showText)
  }
  const changeType1 = () => {
    setShowText1(!showText1)
  }

  const handleAddressChange = (e) => {
    setAddr(e.target.value)
  }

  useEffect(() => {
    // Access the query parameters from the location object
    const searchParams = new URLSearchParams(location.search);
    const storeID = searchParams.get('StoreID');

    // Do something with the storeID
    if (storeID) {
      setStoreID(!StoreID)
      setStoreIDValue(storeID)
    };
  }, [location]);

  function HandleFormSubmit(e) {
    e.preventDefault();

    if (confirm_password !== password) {
      setDoesNotMath(true);
      return;


    }

    if (password.length < 8) {
      setSignUpError('Password must be at least 8 characters long');
      return;

    }

    setSignUpError(null)
    let payload = {
      merchant_type: StoreID ? 'shop' : category, email, password, confirm_password,
      latitude: location.latitude,
      longitude: location.longitude,
    };
    // Conditionally include additional fields based on merchant_type
    if (payload.merchant_type !== 'brand') {
      payload.store_address = store_address;
      payload.store_name = storeName;
      payload.store_phone_number = storePhone;

    }
    if (StoreID) {
      payload.shopID = StoreIDValue
    }

    const fetchData = async () => {
      try {
        setIsPending(true)
        // Make the API request
        const res = await axios({
          method: "POST",
          url: `${baseUrl}/merchant/create`,
          data: payload,
          headers: {
            'Authorization': `x-auth-token ${localStorage.getItem('token')}`,
          },
        });
        localStorage.setItem('e', email)
        localStorage.setItem('mt', payload.merchant_type)

        setResponse(res.data.message);
        bus.emit('email', email)
        setPassword('');
        setCPassword('');
        setEmail('');
        setCategory('manufacturer');
        notify()
        localStorage.removeItem('onRegister')
        localStorage.removeItem('storeID')
        setTimeout(() => {
          navigate('/verify_email');
        }, 2000);
      } catch (err) {
        setSignUpError(err.response.data.message);
        notifyError()
      } finally {
        setIsPending(false)
      }
    };
    fetchData(true);
  }

  const TandC = () => {
    localStorage.setItem('onRegister', JSON.stringify({
      email: email,
      password: password,
      confirm_password: confirm_password,
      category: category
    }))
    if (StoreID) {
      localStorage.setItem('storeID', JSON.stringify(StoreIDValue))
    }
    navigate('/privacy-policy')
  }
  useEffect(() => {
    const regValue = localStorage.getItem('onRegister') ? JSON.parse(localStorage.getItem('onRegister')) : null
    if (regValue) {
      setCategory(regValue.category)
      setEmail(regValue.email)
      setPassword(regValue.password)
      setCPassword(regValue.confirm_password)
    }
  }, [])

  const responseGoogle = (response) => {
    console.log(response);
    // Handle the response here
  };


  return (
    <div className={`row ${styles.sav}`}>
      <div className="col-md-6 d-none d-md-block">
        <div className={styles.bg} style={{ backgroundImage: `linear-gradient(to right,  #52138d9f, #8c2d9176), url(${category === 'brand' ? '/assets/happyMan.jpg' : (category === 'shop' ? '/assets/retailerBg.svg' : '/assets/happyMan.jpg')})`, backgroundPosition: 'top center' }}>
          <img className={styles.star} src='/assets/Stars.svg' alt='stars' />
          <div className={styles.content}>
            <h5 className={`${styles.header_text}`} dangerouslySetInnerHTML={{ __html: pageContent }} ></h5>
            <p dangerouslySetInnerHTML={{ __html: pageDescription }}>

            </p>
            <div className={styles.icon_n_text} >
              <img className={styles.icons} src='/assets/Avatars.svg' alt='avatars' />
              <span style={{ color: '#EAECF0' }}>
                Join 40,000+ users
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 ">
        <Logo />
        <div className={`${styles.sign_up} container`}>


          <form onSubmit={HandleFormSubmit}>
            <h4 className='mb-4' style={{
              fontSize: '36px',
              fontWeight: '600',
              color: '#111827',


            }}>Sign up</h4>
            {(signUpError) && <Alert style={{
              border: '1px solid red'
            }} severity="error">{signUpError}</Alert>}
            {(response) && <Alert style={{
              border: '1px dotted gray'
            }} severity="success">{response}</Alert>}
            {
              !StoreID &&
              <div className='my-4 inputs_n_label'>
                <label htmlFor='s'>Business Category</label>
                <Select
                  labelId="s"
                  id="s"
                  fullWidth
                  size="small"
                  value={category}
                  placeholder='Select business category'
                  required
                  onChange={handleChange}
                >

                  <MenuItem value={'brand'}>Manufacturer</MenuItem>
                  <MenuItem value={'shop'}>Merchant</MenuItem>
                </Select>

              </div>
            }
            <div className='my-4 inputs_n_label'>
              <label htmlFor='email'>Email</label>
              <TextField
                type='email'
                value={email}
                onChange={handleEmailChange}
                id="email"
                placeholder={"Joe@shoprite.com"}
                size="small"
                fullWidth

              />
              {/* <input   id='email' className='form-control' placeholder='' /> */}
            </div>
            {category !== 'brand' && (
              <>
                <div className='my-4 inputs_n_label'>
                  <label htmlFor='store_name'>Store Name</label>
                  <TextField
                    type='text'
                    value={storeName}
                    onChange={handleStoreNameChange}
                    id="store_name"
                    placeholder={"shoprite"}
                    size="small"
                    fullWidth
                  />
                </div>
                {/* <div className='my-4 inputs_n_label'>
                  <label htmlFor='store_address'>Store Address</label>
                  <TextField
                    type='text'
                    value={storeAddress}
                    onChange={handleStoreAddressChange}
                    id="store_address"
                    placeholder={"#1, street name, nearest bustop, state Country."}
                    size="small"
                    fullWidth
                  />
                </div> */}

                <PlacesAutocomplete
                  value={store_address}
                  onChange={handleStoreAddressChange}
                  onSelect={handleSelect}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <div className='my-4 inputs_n_label'>

                        <label htmlFor='loc'>Store Address</label>
                        <div className={`${styles.syl}`} style={{ position: 'relative', width: '210px' }}>
                          {
                            !currentLocationCheck &&
                            <p
                              className='mt-2'
                              onClick={handlePasteLocation}
                              style={{ cursor: 'pointer', fontSize: '15px' }}>
                              <FmdGoodIcon /> Use My Current Location
                              {isLoading && <CircularProgress className='ml-1 mt-1' style={{ color: '#53138D' }} size="0.8rem" />}
                            </p>
                          }
                          {
                            currentLocationCheck &&
                            <p
                              className='mt-2'
                              onClick={handlePasteLocation}
                              style={{ cursor: 'pointer', fontSize: '15px' }}>
                              <FmdGoodIcon /> Manual Location (Edit)
                            </p>
                          }
                        </div>

                        {currentLocationCheck && (
                          <div className={`${styles.syl} d-flex mt-0`}>
                            <FormControlLabel className='m-0' value={store_address} checked control={<Radio />} />
                            <Select
                              id="state"
                              fullWidth
                              size="small"
                              value={store_address}
                              required
                              onChange={handleAddressChange}
                            >
                              {addressResults.map((address, index) => (
                                <MenuItem key={index} value={address}>{address}</MenuItem>
                              ))}
                            </Select>
                          </div>
                        )}
                        {
                          !currentLocationCheck && (
                            <TextField
                              id="loc"
                              placeholder='Input your location'
                              size="small"
                              fullWidth
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                              })}
                            />
                          )
                        }
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions?.map((suggestion, i) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                key={i}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>

                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>


            {/* placeauto complete component */}
              {/* <PlacesAutocompleteComponent storeAddress={store_address}/> */}

                <div className='my-4 inputs_n_label'>
                  <label htmlFor='store_phone'>Store Phone</label>
                  <TextField
                    type='text'
                    value={storePhone}
                    onChange={handleStorePhoneChange}
                    id="store_phone"
                    placeholder={"+234 xxx xxx xxxx."}
                    size="small"
                    fullWidth
                  />
                </div>
              </>
            )}
            <div className='my-4 inputs_n_label'>
              <label htmlFor='password'>Password</label>
              <TextField
                type={showText ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                id="password"
                placeholder="Create a password"
                size="small"
                fullWidth

              />
              <div className="icon" onClick={changeType} >
                {
                  !showText &&
                  <img src="/assets/icons/eye.svg" alt="" />

                }
                {
                  showText &&
                  <img src="/assets/icons/eye-off.svg" alt="" />


                }

              </div>
              <small>Enter at least 8 characters</small>

            </div>
            <div className='my-4 inputs_n_label'>
              <label htmlFor='c_pass'>Confirm password</label>
              <TextField
                type={showText1 ? 'text' : 'password'}
                value={confirm_password}
                onInput={handleCPasswordChange}
                id="c_pass"
                placeholder={!StoreID ? "Confirm password" : "Re-type password"}
                size="small"
                fullWidth

              />
              <div className="icon mt-2" onClick={changeType1}>
                {
                  !showText1 &&
                  <img src="/assets/icons/eye.svg" alt="" />

                }
                {
                  showText1 &&
                  <img src="/assets/icons/eye-off.svg" alt="" />


                }

              </div>

              {doesNotMath && <small className='text-danger'>Password confirmation does not match</small>}

            </div>
            {
              StoreID &&
              <div className='my-4 inputs_n_label'>
                <label htmlFor='c_pass'>Store ID</label>
                <TextField
                  disabled
                  value={StoreIDValue}
                  id="c_pass"
                  size="small"
                  fullWidth

                />
              </div>
            }
            <div className="form-check-inline">

              <label htmlFor='agree' className='form-check-label'>
                <input id='agree' value={agree} onChange={handleChange2} type="checkbox" className='form-check-input' />
                I agree with the
              </label>
              <span
                onClick={() => TandC()}
                className='ml-1'
                style={{ fontWeight: 'bold', color: 'royalblue', cursor: 'pointer' }}>
                terms of the service and conditions
              </span>
            </div>
            <div className={styles.buttons}>
              {!isPending &&
                <button disabled={!agree} className={`btn btn-block ${styles.btn_custom_color}`}>
                  <span className={`${styles.span}`}>
                    Get started

                  </span>
                </button>}
              {isPending && <button disabled className={`btn btn-block ${styles.btn_custom_color}`}>
                Signing you in &nbsp;
                {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />}
              </button>}
              {/* <button disabled={!agree} className={`${styles.btn_g} btn btn-block`}>
                  <img src="/assets/icons/Social icon.svg" alt="" /> Sign up with Google
                </button> */}
              {/* <GoogleLogin
                  clientId="340631171197-uh0pu11osn3ignruem9mg9vl2sfdrgg7.apps.googleusercontent.com"
                  buttonText="Sign in with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                /> */}
              <p className='text-center'>
                Already have an account? <RouterLink to='/login'>Sign in</RouterLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
