import styles from "./Page.module.css"
import Scrollbar from '../../components/scrollbar';
import TextField from '@mui/material/TextField';
import { Avatar, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect } from "react";
import { BallTriangle } from 'react-loader-spinner'
import CircularProgress from '@mui/material/CircularProgress';
import { Pagination } from "src/components";

import moment from "moment";
// import bus from 'src/bus';

// sections
// mock
import { useState } from "react"
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import bus from 'src/bus';
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import logo from "src/components/logo";

// ----------------------------------------------------------------------
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// ----------------------------------------------------------------------



const style = {
  position: 'fixed',
  top: '0',
  left: '50%',
  right: '0',
  bottom: '0',
  transform: 'translate(-50%, 0%)',
  width: 900,
  // height: 580,
  // overflow: 'auto',
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const style01 = {
  position: 'fixed',
  top: '0',
  left: '50%',
  right: '0',
  bottom: '0',
  transform: 'translate(-50%, 0%)',
  width: 900,
  height: 580,
  // overflow: 'auto',
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
};
export default function OrderPage() {
  const [open, setOpen] = useState(false);
  const [adminValue, setAdminValue] = useState({});
  let [shouldFetch] = useState(0);
  const [adminData, setdata] = useState([]);
  const [outletPagination, setOutletPagination] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [openMyDialog, setOpenMyDialog] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPending1, setIsPending1] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [removedValue, setRemovedValue] = useState(null);
  const notify = (msg) => toast.success(msg);
  const notifyError = (err) => toast.error(err);
  const [search, setSearch] = useState({ search: '', category: '', location: '' });
  const [endpoint, changeEndPoint] = useState('/admin/administration/invite');
  const [fetchPageSize, setPageSize] = useState(10);
  const [formValues, setFormValues] = useState({
    outlet_name: '',
    outlet_email: '',
    outlet_manager: '',
    phone_number: '',
    outlet_location: '',
    outlet_category: 1,
    outlet_country: 10,
    outlet_state: 10,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((formValues) => ({
      ...formValues,
      [name]: value
    }));
  };
  
  const [openInviteAdmin, setOpenInviteAdmin] = useState(false);
  const [disableValue, setDisableValue] = useState({});
  const [selectedAdminValue, setSelectedAdminValue] = useState({})
  const [refetchData, setRefetchData] = useState(false);
  const [openbrandsLists, setOpenBrandsLists] = useState(false)
  const [isCheckedIndividual, setIsCheckedIndividual] = useState([]);
  const [isCheckedIndividual2, setIsCheckedIndividual2] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [brandSearch, setBrandSearch] = useState('');
  const [filteredData, setFilteredData] = useState(brandsData || []);
  const [selectButton, handleSelectButton] = useState(false)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [attachedBrandsData, setAttachedBrandsData] = useState({
    brand_id: [],
    admin_id: [],
  });
  const [permsData, setPermsData] = useState([]);
  const [rolesData, setRolesData] = useState([])
  const authData = JSON.parse(localStorage.getItem('auth'))

  const handleBrandSearch = (e) => {
    setBrandSearch(e.target.value);
  }

  const handleCloseInviteAdmin = () => {
    setOpenInviteAdmin(false);
    setIsCheckedIndividual([])
    setFormValues({})
  }

  const handleCheckboxChange = (id) => {
    if (selectedCheckboxes.includes(id)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((checkboxId) => checkboxId !== id));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, id]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleSelectButton(!selectButton)
    setBrandSearch('')
  }

  const [openDialog, setDiologOpen] = useState(false);
  const [enableAdminDialog, setEnableAdminDialog] = useState(false);


  const handleDialogClose = () => {
    setDiologOpen(false);
    setEnableAdminDialog(false);
  };

  const handleCloseBrandsLists = () => {
    setOpenBrandsLists(false)
    setBrandSearch('')
    setSelectedAdminValue({})
    // setIsCheckedIndividual([])
  }

  useEffect(() => {
    bus.on('open-outlet', () => {
      changeEndPoint('/admin/administration/invite')
      setOpen(true)
    });

    bus.on('open-invite-modal', () => {
      setOpenInviteAdmin(true)
    });

    return () => {
      bus.off('open-outlet', () => {
        changeEndPoint('/admin/administration/invite')

      });
    };
  }, []);

  const updatePageSize = (el) => {
    if (el == 'up') {
      setPageSize(fetchPageSize + 1);
    } else {
      setPageSize(fetchPageSize - 1);
    }
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch, search.search, refetchData]);


  const fetchData = (page = 1, perpage = fetchPageSize) => {
    setIsPending(true);
    axiosInstance.get('/admin/administration/get-admin-users?', {
      params: {
        search: search.search,
        page,
        perpage
      }
    })
      .then(res => {
        setdata(res.data.data.data)
        setOutletPagination(res.data.data.pagination)

      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch outlet, try again later");

        }

      }).finally(() => setIsPending(false))
  }

  const fetchUnattachedBrands = () => {
    axiosInstance.get(`/admin/administration/unattached-brand?search=${brandSearch}`)
      .then(res => {
        setBrandsData(res.data.data)

      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch outlet, try again later");

        }
      })
  }

  const fetchAttachedBrands = () => {
    if (!selectedAdminValue._id) return;
    setIsLoading(true)
    axiosInstance.get(`/admin/administration/admin-attached-brand/${selectedAdminValue._id}?search=${brandSearch}`)
      .then(res => {
        setAttachedBrandsData(res.data.data)
        setIsLoading(false)
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch outlet, try again later");

        }
      }).finally(() => {
        setIsLoading(false)
      })
  }

  const handleViewAdmin = (val) => {
    setAdminValue(val)
    setOpen(true)
  }

  useEffect(()=> {

    fetchAdminViewPermissions()
  }, [adminValue.email])

  useEffect(() => {
    if (permsData && permsData.availabe_permission) {

      const initialSelectedCheckboxes = permsData.availabe_permission.map((el) => el?._id);
      setSelectedCheckboxes(initialSelectedCheckboxes);
    }
  }, [permsData]);

  useEffect(() => {
    console.log(selectedCheckboxes);
  }, [selectedCheckboxes]);

  const fetchAdminViewPermissions = () => {
    if(!adminValue.email) return;
    setIsLoading(true)
    axiosInstance.get(`/admin/administration/get-admin-permissions/${adminValue.email}`)
      .then(res => {
        setPermsData(res.data.data)
        setIsLoading(false)
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch roles, try again later");

        }

      }).finally(() => {
        setIsLoading(false)
      })
  }

  const handleResendInvite = (id) => {
    setIsPending1(true);
    axiosInstance.post(`/admin/administration/resend-invite/${id}`)
    .then(res => {
      if (res.status < 300) {
        notify(res.data.message);
        handleClose();
      }
    }).catch(err => {
      try {
        notifyError(err.response.data.message);

      } catch (error) {
        notifyError("Unable to resend invite, try again later");

      }
    }).finally(() => setIsPending1(false))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      email: formValues.email,
      permissions: isCheckedIndividual,
    }
    if (!payload.permissions.length) return notifyError("Please select at least one permission");

    setIsPending1(true);
    axiosInstance.post(endpoint, payload)
      .then(res => {
        if (res.status === 201) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage)
          setFormValues({
            email: '',  
          })
          setAdminValue({
            email: '',  
          })
          setIsCheckedIndividual([])
          setSelectedCheckboxes([])
          handleClose();
          setOpenInviteAdmin(false);

        }
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to Attach Role, try again later");

        }

      }).finally(() => setIsPending1(false))
  };

  const handleRoleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      email: adminValue.email,
      permissions: selectedCheckboxes,
    }
    if (!payload.permissions.length) return notifyError("Please select at least one permission");

    // Do something with the form values
    setIsPending1(true);
    axiosInstance.post(endpoint, payload)
      .then(res => {
        if (res.status === 201) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage)
          setAdminValue({
            email: '',  
          })
          setSelectedCheckboxes([])
          handleClose();
          setOpen(false);

        }
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to Attach Role, try again later");

        }

      }).finally(() => setIsPending1(false))
  };

  const handleAttachBrandsSubmit = (event) => {
    event.preventDefault();
    const payload = {
      admin_email: selectedAdminValue.email,
      brand_id: isCheckedIndividual,
    }
    if (!payload.brand_id.length) return notifyError("Please select at least one permission");

    let endpoint = ''

    if (attachedBrandsData?.admin_id) {
      endpoint = '/admin/administration/attach-more-brand'
    }
    else {
      endpoint = '/admin/administration/attach-to-brand'
    }


    // Do something with the form values
    setIsPending1(true);
    axiosInstance.post(endpoint, payload)
      .then(res => {
        if (res.status < 300) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage)
          setFormValues({
            email: '',  
          })
          setIsCheckedIndividual([])
          handleClose();
          setOpenInviteAdmin(false);
          setOpenBrandsLists(false)
          setSelectedAdminValue({})

        }
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to attach brands, try again later");

        }

      }).finally(() => setIsPending1(false))
  }
  const handleRemoveAlreadyAttachedBrand = (id) => {
  
    let endpoint = '/admin/administration/remove-attached-brand'
    // Do something with the form values
    setIsPending1(true);
    axiosInstance.delete(endpoint, {
      data: {
        admin_email: selectedAdminValue.email,
        brand_id: id,
      }
    })
      .then(res => {
        if (res.status < 300) {
          notify(res.data.message);
          fetchData(currentPage, itemsPerPage)
          setFormValues({
            email: '',  
          })
        }
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to attach brands, try again later");
        }

      }).finally(() => setIsPending1(false))
  }

  const handleDisableAdmin = (val) => {
    setDiologOpen(true)
    setDisableValue(val)
  }

  const handleEnableAdmin = (val) => {
    setEnableAdminDialog(true)
    setDisableValue(val)
  }

  const setHandleAttachBrandsClick = (val) => {
    setSelectedAdminValue(val)
    setOpenBrandsLists(true)
  }

  const handleDelete = () => {
    setIsDeleting(true)
    axiosInstance.put('/admin/administration/disable/' + disableValue._id)
      .then(res => {
        if (res.status < 300) {
          notify(res.data.message);
          setRefetchData(!refetchData)
          handleClose();
        }
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to disable admin, try again later");

        }

      }).finally(() => {
        setIsDeleting(false)
        handleDialogClose()
      })
  };

  const handleEnable = () => {
    setIsDeleting(true)
    axiosInstance.put('/admin/administration/unblock/' + disableValue._id)
      .then(res => {
        if (res.status < 300) {
          notify(res.data.message);
          setRefetchData(!refetchData)
          handleClose();
        }
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to enable admin, try again later");

        }

      }).finally(() => {
        setIsDeleting(false)
        handleDialogClose()
      })
  };

  const processFilter = (e) => {
    const { name, value } = e.target;
    setSearch(() => ({
      ...search,
      [name]: value
    }));


  }

  const handleCheckAllChange = (e) => {
    const data = [];
    if (e.target.checked) {
      rolesData.map(item => {
        console.log(item);
        if (!data.includes(item._id)) data.push(item._id)
      })
    }
    setIsCheckedIndividual(data)
  };

  const handleCheckAttached = (e) => {
    const data = [];

    if (attachedBrandsData?.brand_id) {
      attachedBrandsData?.brand_id?.map(item => {
        if (!data.includes(item?._id)) data.push(item?._id)
      })
    }
    setIsCheckedIndividual2(data)
  };

  useEffect(() => {
    console.log(isCheckedIndividual)
  }, [isCheckedIndividual])

  useEffect(() => {
    handleCheckAttached()
  }, [attachedBrandsData])

  const handleValChange = (val, e) => {
    setIsCheckedIndividual([])
    const updatedCheckedIndividual = [...isCheckedIndividual];
    if (e.target.checked) {
      if (!updatedCheckedIndividual.includes(val)) {
        updatedCheckedIndividual.push(val);
      }
    }

    else {
      // Remove value if it's unchecked and in the array
      const index = updatedCheckedIndividual.indexOf(val);
      if (index !== -1) {
        updatedCheckedIndividual.splice(index, 1);
      }
    }
    setIsCheckedIndividual(updatedCheckedIndividual)
  }

  const handleValChange2 = (val, e) => {
    const updatedCheckedIndividual = [...isCheckedIndividual2];
    if (e.target.checked) {
      if (!isCheckedIndividual2.includes(val)) {
        updatedCheckedIndividual.push(val);
        isCheckedIndividual.push(val);
      }
    }

    else {
      // Remove value if it's unchecked and in the array
      const index = updatedCheckedIndividual.indexOf(val);
      if (index !== -1) {
        const removeVal = updatedCheckedIndividual.splice(index, 1);
        handleRemoveAlreadyAttachedBrand(removeVal)
        isCheckedIndividual.splice(index, 1);
      }
    }
    setIsCheckedIndividual2(updatedCheckedIndividual)

  }

  useEffect(() => {

  }, [rolesData])

  const fetchRoles = () => {
    // axiosInstance.get('/admin/role/get-roles')
    axiosInstance.get('/admin/administration/get-permissions')
      .then((res) => {
        setRolesData(res.data.data)
      }).finally(() => {
        // console.log();
      })
  }

  useEffect(() => {
    fetchRoles()
  }, [])
  
  useEffect(() => {
    fetchUnattachedBrands()
    fetchAttachedBrands()
  }, [selectedAdminValue.email, brandSearch])

  return (
    <>
      <div className="wrapper px-2 mt-4">
        <div className={`${styles.filters}`}>
          <div className="d-flex justify-content-between px-0 ">
            <div className={`${styles.hold_input} col-md-4 col-12 px-0  `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input 
                type="text" 
                placeholder="Search" 
                name="search" 
                value={search.search} 
                onChange={processFilter} 
                style={{ 
                  textIndent: '25px', 
                  width: '100%' 
                }} 
                className=" form-control" 
              />
            </div>
          </div>


        </div>
        <Scrollbar>
          <div className={`card p-0  m-0 mt-4 ${styles.card_table}`}>
            <div className="head_wrapper" style={{ fontSize: '14px' }}>
              <div className={`row px-4 px-md-5 py-3 ${styles.show_control}`}>
                <div className="col-4 p-0 py-1 py-md-0">
                  <div className={`${styles.show_numbers}`}>
                    <span className="font-weight-bold text-dark">Show </span>
                    <div className={`${styles.number_cont} d-flex py-1 px-3`}>
                      <span className="d-inline-block">{fetchPageSize}</span>
                      <span className={`d-inline-block ${styles.svg_div}`}>
                        <span onClick={() => updatePageSize('up')}>
                          <svg className="d-block mx-auto mt-1 mb-1" width="8" height="8" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.58334 6.09039V0.743164M3.58334 0.743164L0.909729 3.41677M3.58334 0.743164L6.25695 3.41677" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                        <span onClick={() => updatePageSize('down')}>
                          <svg className="d-block mx-auto mb-1 mt-1" width="8" height="8" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.58334 0.743164V6.09039M3.58334 6.09039L6.25695 3.41677M3.58334 6.09039L0.909729 3.41677" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <button className="btn btn_neutral ml-2" onClick={() => fetchData()}>Apply</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.table_section} border-bottom`}>
              <table className="table px-0 table-striped table-hover" style={{ fontSize: '13px' }}>
                <thead>
                  <tr>
                    <th scope="col"
                      style={{
                        width: '20px'
                      }}
                    >S/N</th>
                    <th
                      style={{
                        width: '60%'
                      }}
                      scope="col">Admin name</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {(!isPending && adminData.length > 0) && adminData.map((el, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <th>
                        <div className=" ">
                          <Avatar
                            className="float-md-left  mr-2"
                            src={''}
                            alt=""
                            style={{
                            }}
                          />
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                              <strong>
                                {el?.first_name}
                              </strong>
                              <br />
                              <small >
                                {el?.email}
                              </small>
                            </p>
                          </div>
                        </div>
                      </th>
                      <td>{moment(el?.createdAt).format('DD/MM/YYYY')}</td>
                      <td>
                        <div className="btn-group">
                          <button type="button" className="btn m-0 p-0 button_b" data-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots-vertical m-0 p-0 button_i"></i>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <button className="dropdown-item" onClick={() => handleViewAdmin(el)} type="button">View</button>
                            <button className="dropdown-item" type="button" onClick={() => setHandleAttachBrandsClick(el)}>Attach Brand</button>
                            {
                              !el?.is_disabled && (
                                <button 
                                  className="dropdown-item" 
                                  type="button" 
                                  onClick={() => handleDisableAdmin(el)}
                                >
                                  Disable
                                </button>
                              )
                            }
                            {
                              el?.is_disabled && (
                                <button 
                                  className="dropdown-item" 
                                  type="button" 
                                  onClick={() => handleEnableAdmin(el)}
                                >
                                  Enable
                                </button>
                              )
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending &&
                <BallTriangle

                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />

              }
            </div>
            {
              (!adminData.length && !isPending) &&

              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>

            }
            <div>
                <Pagination
                    currentPage={currentPage}
                    dataPagination={outletPagination}
                    itemsPerPage={itemsPerPage}
                    setCurrentPage={setCurrentPage}
                    fetchData={fetchData}
                />
            </div>
          </div>
        </Scrollbar>

        {/* View Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={open}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content} mx-auto w-100`} style={{height: '90vh', overflowY: 'auto'}} >
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5 style={{
                      fontSize: '19px',
                      fontWeight: '700',
                    }}>{adminValue.first_name}</h5>
                  </div>
                  {
                    adminValue.is_disabled && (
                    <div className="alert alert-danger text-center p-1" role="alert">
                      This Admin is disabled and out of the system!
                    </div>
                    )
                  }
                  <div>
                    <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>

                <form onSubmit={handleRoleSubmit} className="mt-0">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label htmlFor="name">Email Address</label>
                        <TextField
                          required
                          disabled
                          type='text'
                          value={adminValue.email}
                          name="email"
                          onChange={handleChange}
                          id="name"
                          placeholder=""
                          size="small"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <button 
                        type="button"
                        className="btn btn-primary float-right mt-md-4"
                        onClick={() => handleResendInvite(adminValue._id)}
                      >
                        {
                          !isPending1 &&
                          <span> Resend Invite</span>
                        }
                        {isPending1 && <CircularProgress style={{ color: 'white' }} size="1rem" />
                        }
                      </button>
                    </div>
                  </div>
                  <div className="alert alert-success text-center p-1" role="alert">
                    What this role can access
                  </div>
                  <div className="d-flex justify-content-center">
                    {isLoading &&
                      <BallTriangle
                        height={50}
                        width={55}
                        radius={5}
                        color="#155724"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />

                    }
                  </div>
                  {
                    !isLoading &&
                    <div className="row" style={{
                      color: '#585c63',
                      fontSize: '14px'
                    }}>
                      {
                        permsData?.availabe_permission?.map((el, i) => (
                        <div key={i} className="col-md-4 ml-1 row">
                          <p className="adjust_p_margin">
                            User Can { el?.permission_name }
                          </p>
                        </div>
                        ))
                      }
                    </div>
                  }
                  <div className="alert alert-danger text-center p-1 mt-5" role="alert">
                    What this role can't access
                  </div>
                  <div className="d-flex justify-content-center">
                    {isLoading &&
                      <BallTriangle

                        height={50}
                        width={55}
                        radius={5}
                        color="#721c24"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />

                    }
                  </div>
                  {
                    !isLoading &&
                    <div className="row" style={{
                      color: '#585c63',
                      fontSize: '14px'
                    }}>
                      {
                        permsData?.unavailable_permission?.map((el, i) => (
                        <div key={i} className="col-md-4 ml-1 row">
                          <p className="adjust_p_margin">
                            User Can { el?.permission_name }
                          </p>
                        </div>
                        ))
                      }
                    </div>
                  }
                </form>
              </div>

            </Box>
          </Fade>
        </Modal>

        {/* Invite Admin Modals */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openInviteAdmin}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openInviteAdmin}>
            <Box sx={style} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content} mx-auto`} style={{height: '90vh', overflowY: 'auto'}}>
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5 style={{
                      fontSize: '19px',
                      fontWeight: '700',

                    }}>
                      { authData.first_name }
                    </h5>
                  </div>
                  <div>
                    <span onClick={handleCloseInviteAdmin} style={{ cursor: 'pointer' }}>
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="mt-0">
                  <div className="row">
                    <div className="col-md-10 col-9">
                      <div className="mb-3">
                        <label htmlFor="name">Email Address</label>
                        <TextField
                          required
                          type='text'
                          value={formValues.email}
                          name="email"
                          onChange={handleChange}
                          id="name"
                          placeholder="sample@email.com"
                          size="small"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-3 upper_space">
                      <button 
                        disabled={isPending1} 
                        className="btn btn-[#53138D] bg-[#8c2d915d] text-white" 
                        style={{ background: '#53138D',  }} 
                        type="submit"
                      >
                        {
                          !isPending1 &&
                          <span> Send Invite</span>
                        }
                        {isPending1 && <CircularProgress style={{ color: 'white' }} size="1rem" />
                        }

                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <strong>
                        <p>Select Permissions For This Role</p>
                      </strong>
                    </div>
                    <div className="col-3 text-right">
                      <p>
                        <input type="checkbox" name="" onChange={handleCheckAllChange} id="" /> &nbsp;
                        Select All
                      </p>
                    </div>
                  </div>

                  <div className="row mb-3" style={{
                    color: '#585c63',
                    fontSize: '14px'
                  }}>
                    {rolesData?.length > 0 && rolesData?.map((el, i) => (
                      <div className="col-md-4">
                        <p className="adjust_p_margin">
                          <strong>
                            {el?.role_name}
                          </strong>
                        </p>
                        
                          <p key={i} className="adjust_p_margin">
                            <input type="checkbox" checked={isCheckedIndividual?.includes(el?._id)}
                              name="" id="" onChange={(e) => handleValChange(el?._id, e)} 
                            /> 
                              &nbsp;
                            User Can {el?.permission_name}
                          </p>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>

        {/* Attach Brands */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openbrandsLists}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openbrandsLists}>
            <Box sx={style01} className={`mobile_modal_size`}>
              <div className={`${styles.modal_content} w-100`}  >
                <div className={`${styles.modal_header} mb-0`}>
                  <div>
                    <h5 style={{
                      fontSize: '19px',
                      fontWeight: '700',

                    }}>
                      { selectedAdminValue.first_name }
                    </h5>
                  </div>
                  <div>
                    <span onClick={() => handleCloseBrandsLists()} style={{ cursor: 'pointer' }}>
                      <img src="/assets/icons/x.svg" alt="" />
                    </span>
                  </div>
                </div>
                <form onSubmit={handleAttachBrandsSubmit} className="mt-0">
                  <div className="row">
                    <div className="col-md-10 col-9">
                      <div className="mb-3">
                        <label htmlFor="name">Email Address</label>
                        <TextField
                          required
                          disabled
                          type='text'
                          value={selectedAdminValue.email}
                          name="email"
                          onChange={handleChange}
                          id="name"
                          placeholder="sample@email.com"
                          size="small"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-3 upper_space">
                      <button 
                        disabled={isPending1} 
                        className="btn btn-[#53138D] bg-[#8c2d915d] text-white" 
                        type="submit"
                        style={{ background: '#53138D',  }}
                      >
                        {
                          !isPending1 &&
                          <span> Attach</span>
                        }
                        {isPending1 && <CircularProgress style={{ color: 'white' }} size="1rem" />
                        }

                      </button>
                    </div>
                  </div>
                  <div className={`${styles.hold_input} col-4 px-0 `}>
                    <img 
                      src="/assets/icons/search.svg" 
                      alt="" 
                    />
                    <input 
                      type="text" 
                      value={brandSearch} 
                      onChange={handleBrandSearch} 
                      className="form-control w-100" 
                      placeholder="Search.."
                      style={{ 
                        textIndent: '25px', 
                      }} 
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <strong>
                        <p className="ml-5">Select Brands</p>
                      </strong>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {isLoading &&
                      <BallTriangle

                        height={50}
                        width={55}
                        radius={5}
                        color="#8C2D91"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    }
                  </div>
                  <div className="row" style={{
                    color: '#585c63',
                    fontSize: '14px'
                  }}>
                    { (!isLoading) &&
                      attachedBrandsData?.brand_id?.length > 0 && attachedBrandsData?.brand_id?.filter(el => el?.business_name && el?.business_name !== null).map((el, i) => (
                        <div key={i} className="col-md-4 mb-3">
                            <p className="adjust_p_margin">
                              <div className="d-flex">
                                <div className="col-1">
                                  <input className="mt-3" type="checkbox" checked={isCheckedIndividual2.includes(el._id)}
                                    name="" id="" onChange={(e) => handleValChange2(el._id, e)} 
                                  /> 
                                </div>
                                <div className="col-11">
                                  <b>
                                    {el?.business_name}
                                  </b>
                                  <br/>
                                  {el?.business_email}
                                </div>
                              </div>
                            </p>
                        </div>
                      ))
                    }
                    { (!isLoading) &&
                      brandsData?.length > 0 && brandsData?.filter(el => el?.business_name && el?.business_name !== null).map((el, i) => (
                      <div key={i} className="col-md-4 mb-3">
                          <p className="adjust_p_margin">
                            <div className="d-flex">
                              <div className="col-1">
                                <input className="mt-3" type="checkbox" checked={isCheckedIndividual.includes(el._id)}
                                  name="" id="" onChange={(e) => handleValChange(el._id, e)} 
                                /> 
                              </div>
                              <div className="col-11">
                                <b>
                                  {el?.business_name}
                                </b>
                                <br/>
                                {el?.email}
                              </div>
                            </div>
                          </p>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>


        {/* <GlobalModal
      open={modalOpen}
      onClose={handleClose2}
      position='absolute'
      top= '50%'
      left= '50%'
      transform= 'translate(-50%, -50%)'
      width= '740px !important'
      overflow='auto'
      bgcolor='#F5F5F5'
      border= '1px solid #F5F5F5'
      borderRadius='5px'
      boxShadow= '24'
      p='25px'
    >
      <div  className={`${styles.modal_content}`}>
        <div className={`${styles.modal_header} mb-0`}>
        <h5 style={{
          fontSize: '19px',
          fontWeight: '700',
        }}>Filter</h5>
          <span onClick={handleClose2} style={{cursor:'pointer'}}>
            <img src="/assets/icons/x.svg" alt="" />
          </span>
        </div>
        
        <form action="" className="mt-0">
          <div className="row">
            <div className="col-md-6 mb-1">
              <div className="mb-3">
                <label htmlFor="loc">Location</label>
                <TextField
                  type='text'
                  id="loc"
                  placeholder="Enter Location"
                  name="location"
                  value={search.location}
                  onChange={processFilter}
                  size="small"
                  fullWidth
                />
                  
              </div>
              
            </div>
            <div className="col-md-6 mb-1">
              <label htmlFor="cat">Category</label>
                <Select
                    labelId="s"
                    id="cat"
                    fullWidth
                    size="small"
                    value={search.category}
                    name="category"
                    onChange={processFilter}
                  >
                    
                    <MenuItem value={1}>Select Category</MenuItem>
                </Select>
              
            </div>
          </div>
          <div className="mb-4">
          <button type="button" onClick={handleClose2} className={`${styles.modal_btn} btn btn-block`}>
            Proceed
          </button>
          
          
          </div>
        </form>
      </div>
    
    
    </GlobalModal> */}
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="card border-0">
            <div className="card-body border-0 ">
              <div className={`${styles.customized_row}`}>
                <div className="text-center">
                  <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

                </div>
                <div>
                  <h5>
                    Disable {disableValue?.first_name}  {disableValue?.last_name} Admin ?
                  </h5>
                  <p style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}>
                    Are you sure you want to disable this admin with the email {disableValue?.email}.
                    <br/> Note that this action cannot be undone.
                  </p>
                </div>
              </div>

              <DialogActions>
                <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
                {
                  !isDeleting &&
                  <Button className={`${styles.delete_product}`} onClick={handleDelete}>Disable</Button>
                }

                {isDeleting && <button disabled className={`btn ${styles.delete_product}`}>

                  {
                    isDeleting && <CircularProgress style={{ color: 'white' }} size="1rem" />
                  }
                </button>
                }
              </DialogActions>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={enableAdminDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="card border-0">
            <div className="card-body border-0 ">
              <div className={`${styles.customized_row}`}>
                <div className="text-center">
                  <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

                </div>
                <div>
                  <h5>
                    Enable {disableValue?.first_name}  {disableValue?.last_name} Admin ?
                  </h5>
                  <p style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}>
                    Are you sure you want to enable this admin with the email {disableValue?.email}.
                    <br/>
                  </p>
                </div>
              </div>

              <DialogActions>
                <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
                {
                  !isDeleting &&
                  <Button className={`${styles.enable_product}`} onClick={handleEnable}>Enable</Button>
                }

                {isDeleting && <button disabled className={`btn ${styles.enable_product}`}>

                  {
                    isDeleting && <CircularProgress style={{ color: 'white' }} size="1rem" />
                  }
                </button>
                }
              </DialogActions>
            </div>
          </div>
        </Dialog>


        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openMyDialog}
          onClose={() => setOpenMyDialog(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openMyDialog}>
            <Box className={`mobile_modal_size modal_bg`} sx={style1}>
              <div className={`text-center add_to_cart_content`}>
                <img src="/assets/icons/circleCheck.svg" alt="" />
                <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>Invitation Sent</h5>
                <p className='text-center' style={{ textAlign: 'center !important' }}>
                  Invitation has been sent to email address

                </p>
                <button onClick={() => setOpenMyDialog(false)} className={`modal_btn hover btn btn-block`}>
                  Confirm

                </button>

              </div>



            </Box>
          </Fade>
        </Modal>

      </div>
    </>
  )
}
