// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProductsModal from "../Modals/ProductsModal.js";
import bus from "src/bus";
import axiosInstance from "src/axiosInstance";
import { BallTriangle } from "react-loader-spinner";
import CircularProgress from "@mui/material/CircularProgress";

import { Card } from "@mui/material";
import Scrollbar from "../../../components/scrollbar";
// sections
// mock
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLayoutEffect } from "react";
import SingleView from "./SingleView";
import { Pagination } from "src/components"
// ----------------------------------------------------------------------
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import toast from "react-hot-toast";

const sentstyle1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 190,
  bgcolor: "#FFFFFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 1,
};

const styless = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: "auto",
  bgcolor: "#FFFFFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const style1 = {
  position: "absolute",
  height: "700px",
  overflow: "scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "#FFFFFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  backgroundColor: "#FFFFFF",
  p: 3,
};

// ptvp=PAGE TO VIEW (PRODUCT)

let app_view = localStorage.getItem("ptvp") ?? 0;

export default function ProductsPageBrandowwner() {

  const [open, setOpen] = useState(false);
  const [productTag, setProductTag] = useState(false);
  const [notify, setNotify] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClosePT = () => setProductTag(false);
  const [isPending, setIsPending] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const navigate = useNavigate();
  const [openMyDialog, setOpenMyDialog] = useState(false);
  const [category, setCategory] = useState(1);
  const [appView, setAppview] = useState(app_view);
  const [tags, setdata] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState({ total: 0 });
  const notifyError = (err) => toast.error(err);
  const notifyToaster = (msg) => toast.success(msg);
  const [valChanged, setvalChanged] = useState(false);
  const [isDelete, setIsDelete] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchvalue, setSearchvalue] = useState("");
  const [addTag, setAddTag] = useState({
    color: "",
    name: "",
  });

  const searchFilter = (e) => {
    setSearchvalue(e.target.value);
  };

  useLayoutEffect(
    () => () => {
      localStorage.removeItem("ptvp");

      // Your code here.
    },
    []
  );

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleViewClick = (d) => {
    // console.log(d);
    navigate("/app/products/" + d._id);
    // handle view click action
  };

  const reloadPage = (val) => {
    setAppview(val);
  };

  useEffect(() => {
    fetchData();
  }, [valChanged, searchvalue]);

  bus.on("reload", () => {
    fetchData();
  });
  const fetchData = (page = 1, pageSize = 10, search = searchvalue) => {
    setIsPending(true);
    axiosInstance
      .get("/admin/product/tags-count", {
        params: {
          page,
          // pageSize,
          search,
        },
      })
      .then((res) => {
        setdata(res.data.data.data);
        setCategoryPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError('Oops! No records.');
          // notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Unable to fetch tags, try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const addTagForm = (e) => {
    e.preventDefault();
    setIsPending(true);
    axiosInstance
      .post("/admin/product/create-tag", addTag)
      .then((res) => {
        setProductTag(false)
        notifyToaster(res.data.message);
        handleClosePT();
        setvalChanged(!valChanged);
        setAddTag({
          color: "",
          name: "",
        });
        bus.emit('productTag')
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Something went wrong , please try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const setVal = (key, val) => {
    setAddTag((prev) => {
      return {
        ...prev,
        [key]: val,
      };
    });
  };



  const handleDeleteTag = (val) => {
    setIsDelete(true)
    axiosInstance.delete('/admin/product/delete-tag/'+val)
    .then(res => {
      if (res.status < 300) {
        setvalChanged(!valChanged);
        notifyToaster(res.data.message)
        bus.emit('deleteTag')
      }
    })
    .catch((err) => {
      try {
        notifyError(err.response.data.message);
      } catch (error) {
        notifyError("Unable to delete tag, try again later");
      }
    })
    .finally(() => setIsDelete(false));
  }

  return Number(appView) === 0 ? (
    <>
      <div className="mt-3 px-2">
        <div className={`${styles.filters}  mss`}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input
                type="text"
                value={searchvalue}
                onChange={searchFilter}
                placeholder="Search"
                style={{ textIndent: "25px", width: "100%" }}
                className=" form-control"
              />
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: "10px", borderRadius: "10px" }}
          className="p-0"
        >
          <div className="p-4 border-bottom">
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">Product Tags</h5>
                <small style={{ fontSize: "16px" }} className="text-muted">
                  Create and Manage Product Tags for Products
                </small>
              </div>
              <div className="pt-md-2 d-md-flex">
                <div className="dropleft ">
                  <button
                    onClick={() => setProductTag(true)}
                    className={`btn_primary btn m-0`}
                  >
                    <span
                      style={{
                        paddingTop: "8px",
                      }}
                    >
                      <img src="/assets/icons/plus.svg" alt="" /> &nbsp; Create
                      Tag
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table-responsive-sm  pt-3 pb-3 pl-0 pr-0`}  >
              <table className={`${styles.table} table-hover table-striped `}>
                <thead className={`${styles.thead}`}>
                  <tr>
                    <th className="text-muted">
                      Tag Name
                      <img
                        className="pl-2"
                        src="/assets/icons/down.svg"
                        alt=""
                      />
                    </th>
                    <th className="text-muted ">No of Product</th>

                    <th className="text-muted">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {!isPending &&
                    tags?.length > 0 &&
                    tags?.map((el, i) => (
                      <tr key={i} className={`${styles.tr} `}>
                        <td>
                          <div className=" ">
                            <img
                              className=" mr-2"
                              src={`/assets/Ellipse_${el.color?.toLowerCase()}.png`}
                              alt=""
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            />
                            <span className="mb-0" style={{ fontSize: "14px" }}>
                              <strong>{el?.name}</strong>
                            </span>
                          </div>
                        </td>
                        <td>
                          <p>{el?.total}</p>
                        </td>
                        <td>
                          <button
                            onClick={() => handleViewClick(el)}
                            className={`btn ${styles.view_action}`}
                          >
                            <img
                              src="/assets/icons/eyewhite.svg"
                              className="pr-1"
                              alt=""
                            />
                            View
                          </button>
                          <button
                            onClick={() => handleDeleteTag(el?._id)}
                            className={`btn ${styles.delete_action} px-2 py-2 bg-danger text-white ml-3`}
                          >
                            Delete Tag 
                            {/*  */}
                          </button>

                        </td>
                      </tr>
                    ))}
                  {/* {
                        categoriesData.map((el, i) => (

                        ))
                      } */}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!tags.length && !isPending && (
              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          <div>
              <Pagination
                  currentPage={currentPage}
                  dataPagination={categoryPagination}
                  itemsPerPage={itemsPerPage}
                  setCurrentPage={setCurrentPage}
                  fetchData={fetchData}
              />
          </div>
        </Card>
      </div>

      {/* Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box className={`mobile_modal_size`} sx={style}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Choose Category</h3>
                <span onClick={handleClose} style={{ cursor: "pointer" }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>

              <form action="">
                <div className="mb-3">
                  <label htmlFor="name">Category Name</label>
                  <Select
                    labelId="s"
                    id="s"
                    fullWidth
                    size="small"
                    value={category}
                    required
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Breveges</MenuItem>
                    <MenuItem value={2}>Table water</MenuItem>
                    <MenuItem value={3}>Snacks</MenuItem>
                  </Select>
                </div>
                <div className="mb-4">
                  <button
                    type="button"
                    onClick={handleClose}
                    className={`${styles.modal_btn} btn btn-block`}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Product Tags Modals */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={productTag}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={productTag}>
          <Box className={`mobile_modal_size`} sx={styless}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>Create Product Tag</h3>
                <span
                  onClick={handleClosePT}
                  style={{ cursor: "pointer", marginBottom: "0px" }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>

              <form onSubmit={addTagForm} style={{ marginTop: "10px" }}>
                <div className="mb-3 mt-2">
                  <label
                    htmlFor="name"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Tag Name
                  </label>
                  <input
                    type="text"
                    value={addTag.name}
                    name="name"
                    onChange={(e) => setVal("name", e.target.value)}
                    className="form-control"
                    placeholder="Enter Tag Name"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="name"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Choose Color
                  </label>{" "}
                  <br />
                  <span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "red")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_red.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "yellow")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_yellow.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "green")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_green.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "blue")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_blue.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "pink")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_pink.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "grey")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_grey.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "purple")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_purple.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "teal")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_teal.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "orangered")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_orangered.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "lightgreen")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_lightgreen.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setVal("color", "darkpurple")}
                    >
                      <img
                        className=" mr-3"
                        src={"/assets/Ellipse_darkpurple.png"}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </span>
                  </span>{" "}
                  <br />
                  {addTag.color}
                </div>

                <div className="mb-4">
                  {isPending && (
                    <button
                      disabled
                      className={`btn btn-block ${styles.modal_btn}`}
                    >
                      Submitting &nbsp;
                      {isPending && (
                        <CircularProgress
                          style={{ color: "white" }}
                          size="1rem"
                        />
                      )}
                    </button>
                  )}
                  {!isPending && (
                    <button
                      type="submit"
                      className={`${styles.modal_btn} btn btn-block btn-lg`}
                    >
                      Add
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openMyDialog}
        onClose={() => setOpenMyDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        disableEnforceFocus
    >
        <Fade in={openMyDialog}>
            <Box className={`mobile_modal_size modal_bg`} sx={sentstyle1}>
                <div className={`text-center add_to_cart_content`}>
                    <img src="/assets/icons/circleCheck.svg" alt="" />
                    <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>Product Tag Created</h5>
                    <button onClick={() => setOpenMyDialog(false)} className={`modal_btn hover btn btn-block mt-4`}>
                        Confirm

                    </button>

                </div>



            </Box>
        </Fade>
    </Modal>

      <ProductsModal key={3} closeModal={() => setShowDiscount(false)} />
    </>
  ) : (
    <SingleView key={1} reload={reloadPage} />
  );
}
