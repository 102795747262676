import styles from "./index.module.css"

import {
  Card,
  Avatar,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import USERLIST from '../../_mock/order';
import OrderDetails from "./Details"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import { useDropDowns } from "src/hooks/useDropDowns";
import Filter1 from "src/pages/Filter/Filter1";


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'orderNumber', label: 'Order Number', alignRight: false },
  { id: 'productsOrdered', label: 'Products Ordered', alignRight: false },
  { id: 'totalCost', label: 'Total cost', alignRight: false },
  { id: 'created_at', label: 'Date ordered', alignRight: false },
  { id: '' },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// ----------------------------------------------------------------------

function changeView(d) {
  localStorage.setItem('ptvp', d);
}

// ptvo=PAGE TO VIEW (ORDER)
let app_view = localStorage.getItem('ptvp') ?? 0;
export default function PurchasePage({ viewChange }) {


  const [appView, setAppview] = useState(app_view);
  const dropdownFilters = useDropDowns()
  const [orderType, setOrderType] = useState('All');
  const [product, setProduct] = useState(1);
  const [productsAdAdded, setProductsAdded] = useState([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedFilter, setValue] = useState(dropdownFilters[0]?.name);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [selectedFilter1, setValue1] = useState(dropdownFilters[0]?.name);

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleProductChange = (event) => {
    setProduct(event.target.value);
    let data = productsAdAdded
    let val = event.target.value
    if (val != 1) {
      if (data.includes(val) == false) {
        data.push(val)
        setProductsAdded(data)

      }
    }
  };
  const handleClickList = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClickList1 = (e) => {
    setAnchorEl1(e.currentTarget)
  }
  const handleSelectFilter = (val, i) => {
    switch (i) {
      case 0:
        setAnchorEl(null);
        setValue(val)

        break;
      case 1:
        setAnchorEl1(null);
        setValue1(val)

        break;
      default:

    }


  }

  const removeFromList = (index) => {
    let data = [...productsAdAdded]
    data = data.filter((_, i) => i !== index);
    if (data['length'] === 0) setProduct(1)
    setProductsAdded(data)




  }


  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0)
  }
  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvp')
  }, [])





  const handleViewClick = (d) => {
    navigate('/app/purchases_details')

  };

  const handleSendReminderClick = () => {
    // handle send reminder click action
  };




  return Number(appView) === 0 ? (
    <>
      <div className="wrapper mt-4 pt-2 ">
        <div className="header d-block d-md-none mb-4">
          <h4 className={`mb-0 pb-0 `}>Purchases</h4>

          <p style={{ fontSize: '14px' }} className={`mb-0 pb-0`}>
            Manage and monitor your purchases here.


          </p>

        </div>
        <div className={`${styles.analytics}  `}>
          <Filter1 width='100%' />

          <div className={`row`}>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Product Purchased</small>
                  </div>

                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>70,976</span><small> from 67,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>
            <div className={`col-md-6 mb-2`}>
              <div className={`${styles.card}`}>
                <div className={`card-body`}>
                  <div className="d-flex justify-content-between">
                    <small>Total Purchase</small>

                  </div>
                  <div className={`${styles.numbers}`}>
                    <div>
                      <span>₦ 3,206,976</span><small> from ₦ 2,907,000</small>
                    </div>
                    <div>
                      <div className={styles.percent}>
                        <img src="/assets/icons/up.svg" alt="" /> <small>12%</small>

                      </div>
                    </div>



                  </div>

                </div>


              </div>

            </div>

          </div>
        </div>
        <div className={`${styles.filters} mt-4`}>
          <div className="row">
            <div className={`${styles.hold_input} col-6 col-md-3   `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />

            </div>
            <div className="col-6 col-md-9  text-right">

              <div className="dropleft ">
                <button className="btn  border" onClick={() => setModalOpen(true)} aria-haspopup="true" aria-expanded="false"
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px'
                  }}>
                  <img className="" style={{ display: 'inline', width: '17px' }} src="/assets/icons/filterlines.svg" alt="" />
                  &nbsp;
                  <small className="d-none d-md-inline-block">
                    Filters
                  </small>
                </button>

                <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                  <button type="button" onClick={() => setOrderType('All')} className="rss dropdown-item btn border-0">All</button>
                  <button type="button" onClick={() => setOrderType('Abandoned')} className="rss dropdown-item btn border-0">Abandoned </button>
                  <button type="button" onClick={() => setOrderType('Completed')} className="rss dropdown-item btn border-0">Completed</button>
                </div>
              </div>


            </div>
          </div>


        </div>

        <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
          <div className='p-3 '>
            <div className="d-md-flex justify-content-between">
              <div className="pb-3 pb-md-0">
                <h5 className="table_desc_header">
                  {orderType} Purchases
                </h5>
                <small style={{ fontWeight: '200', fontSize: '12px', display: 'inline-block', marginTop: '0px' }}>
                  Customers who purchased from your outlets recently

                </small>

              </div>
              <div className="pt-md-2">
                <div className="dropleft ">
                  <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className={`${styles.export_btn} btn m-0`}>
                    <span style={{
                      paddingTop: '8px',


                    }}>
                      <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                      Export
                    </span>
                    &nbsp;
                    &nbsp;
                    <span style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',

                    }}>
                      <img src="/assets/icons/arrowDown.svg" alt="" />

                    </span>


                  </button>

                  <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                    <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                    <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                  </div>
                </div>

              </div>


            </div>

          </div>
          <Scrollbar>
            <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}  >
              <table className={`${styles.table} table-hover table-striped`}>
                <thead className={`${styles.thead}`}>


                  <tr>
                    {TABLE_HEAD.map((el, i) => {
                      let sIndex = i < 1 ? 1 : false;

                      return (
                        <th className="text-muted">{el.label} {sIndex && (
                          <span style={{ cursor: 'pointer' }}>
                            <img className="pl-2" src="/assets/icons/down.svg" alt="" />
                          </span>
                        )}</th>



                      )
                    })}

                  </tr>

                </thead>
                <tbody>
                  {USERLIST.map((row, i) => {
                    const { productUnit, price, created_at } = row;
                    const customStatus = i % 2 === 0 ? 'Completed' : 'Abandoned'

                    return (
                      <tr style={{ cursor: 'pointer' }} className={`${styles.tr}`}>
                        <td onClick={() => handleViewClick(1)}>
                          <div className=" ">
                            {`KVC12${i}`}


                          </div>

                        </td>

                        <td onClick={() => handleViewClick(1)}>
                          <p>
                            <BootstrapTooltip title={
                              <div>
                                Nestle Milo, Pure life table water, <br /> Maggi, Nestcafe
                              </div>
                            }>
                              <span>
                                {productUnit}
                              </span>

                            </BootstrapTooltip>


                          </p>
                        </td>

                        <td onClick={() => handleViewClick(1)}>
                          <p>
                            {price}

                          </p>
                        </td>



                        <td onClick={() => handleViewClick(1)}>
                          <p>
                            {created_at}

                          </p>
                        </td>
                        <td>
                          <div className="dropleft ">

                            <span
                              id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"

                              className={`${styles.action_button} px-3 py-2`}  style={{
                                cursor: 'pointer',

                              }}>
                              <img src="/assets/icons/action.svg" alt="" />

                            </span>

                            <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                              <button type="button" onClick={() => handleViewClick(1)} className="rss dropdown-item btn border-0">View</button>
                              {customStatus === 'Abandoned' && <button
                                className="rss btn border-0 dropdown-item"
                                type="button"
                                onClick={handleSendReminderClick}
                              >
                                Send Reminder
                              </button>}
                            </div>
                          </div>

                        </td>
                      </tr>

                    )


                  })}

                </tbody>

              </table>

            </div>


          </Scrollbar>

          <div className="card border-0 p-0 m-0">
            <div className="card-body pt-0 mt-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-item-center pt-2">
                  Page 1 of <b className="pl-1"> 5</b>
                </div>
                <div className={`${styles.pagination_button_container}`}>
                  <button className="btn">Previous</button>
                  &nbsp;
                  <button className="btn">Next</button>
                </div>

              </div>

            </div>
          </div>
        </Card>




      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">

                <div className="mb-3">
                  <label htmlFor="name">Product</label>
                  <Select
                    labelId="name"
                    id="name"
                    fullWidth
                    size="small"
                    value={product}
                    required
                    onChange={handleProductChange}
                  >

                    <MenuItem value={1}>Select</MenuItem>
                    <MenuItem value={'Toiletries'}>Toiletries</MenuItem>
                    <MenuItem value={'Biscuite'}>Biscuite</MenuItem>
                    <MenuItem value={'Paper'}>Paper</MenuItem>
                  </Select>


                  <div className="products mt-2">
                    {productsAdAdded.map((el, i) => {

                      return (
                        <div className={`${styles.product_selected}`}>
                          <div className=" d-flex justify-content-between">
                            <span >{el}</span>
                            &nbsp;
                            &nbsp;
                            <span style={{ cursor: 'pointer' }} onClick={() => removeFromList(i)}>
                              <img style={{ width: '5px' }} src="/assets/icons/x.svg" alt="" />

                            </span>

                          </div>
                        </div>
                      )
                    })}
                  </div>


                </div>
                <label htmlFor="name">Date </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '70%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        id="name"
                        placeholder="Start"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-sflex   align-items-center " style={{ heigsht: '40px' }}>
                    {/* sss */}
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='date'
                        placeholder="End "
                        size="small"

                      />


                    </div>

                  </div>

                </div>

              </div>
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Price </label>

                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="price"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>
                <label htmlFor="ounit">Order Unit </label>
                <div className=" d-flex">
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        id="ounit"
                        placeholder="Min"
                        size="small"

                      />


                    </div>

                  </div>
                  <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                    <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                  </div>
                  <div className="" style={{ width: '100%' }}>
                    <div className="mb-3">
                      <TextField
                        required
                        type='number'
                        placeholder="Max "
                        size="small"

                      />


                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div className="mb-4">
              <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
                Proceed

              </button>


            </div>

          </form>


        </div>


      </GlobalModal>


    </>

  ) : <OrderDetails onChange={handleChange} />
}
