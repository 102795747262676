import ComponentRepeater from "./componentRepeater";

const Admin = () => {
  
  return (
    <>
      <ComponentRepeater  name = "brandOwner" heading="Brand Owners" />
      <div className="mt-3">
        <ComponentRepeater  name = "shopOwner" heading="Shop Owners" />
      </div>
    </>
  );
};

export default Admin;
