import styles from "./Order.module.css"

import {
  Card,
  Avatar,
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import USERLIST from '../../_mock/order';
import OrderDetails from "./OrderDetailsBrandOwner"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Storeowners from './SuperAdmin/Storeowners';
import Abandoned from './SuperAdmin/Abandoned';
import Completed from './SuperAdmin/Completed';
import bus from 'src/bus'
import axiosInstance from "src/axiosInstance";
import toast from 'react-hot-toast';
import axios from "axios";
import moment from "moment";
import { BallTriangle } from 'react-loader-spinner';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate, useParams } from "react-router-dom";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'customerName', label: 'Shop Owner', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'productUnit', label: 'Product Unit', alignRight: false },
  { id: 'customerLocation', label: 'Location', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Date ordered', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function changeView(d){
  localStorage.setItem('ptvo',d);
}

// ptvo=PAGE TO VIEW (ORDER)
let app_view = localStorage.getItem('ptvo') ?? 0;
export default function OrderRetailerPage() {
  const [category, setCategory] = useState(1);
  const [value, setValue] = useState('1');
  const [appView, setAppview] = useState(app_view);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const { categoryId } = useParams();

  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvo')
  }, [])

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function navigate(d) {
    changeView(d)
    setAppview(d)
  }

  const handleViewClick = (d) => {
    navigate(d)
    // handle view click action
  };

  const handleChange = (/* args passed from the child component */) => {
    handleViewClick(0)
  }
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  return  Number(appView) === 0 ?  (
    <>
     <div className="wrapper px-2">
        <div   className="header d-block d-md-none mb-3">
            <h4 className={`mb-0 pb-0`}>Orders</h4>
            <p style={{fontSize: '14px'}} className={`mb-0 pb-0`}>
            Manage and monitor your orders here.
            </p>
        </div>
        <TabContext value={value}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs ">
                <Tab label="Store Owners " value="1" />
                <Tab label="Shoppers  " value="3" />
                <Tab label="Brands  " value="2" />
            </TabList>
            <TabPanel value="1" style={{paddingLeft:'0'}}>
                <Storeowners key={3}  viewChange={navigate} />
            </TabPanel>
            <TabPanel value="2" style={{paddingLeft:'0'}}>
                <Abandoned key={4}  viewChange={navigate} />
            </TabPanel>
            <TabPanel value="3" style={{paddingLeft:'0'}}>
                <Completed key={5}  viewChange={navigate} />
            </TabPanel>
        </TabContext>
     </div>
     <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top= '50%'
        left= '50%'
        transform= 'translate(-50%, -50%)'
        width= '500px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border= '1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow= '24'
        p= '4'
        key={2}
      >
        <div className={`modal_content`}>
          <div className={`modal_header`}>
            <h3>Choose  Category</h3>
            <span onClick={handleClose} style={{cursor:'pointer'}}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>
          

          <form action="">
          <div className="mb-3">
            <label htmlFor="name">Category Name</label>
            <Select
                labelId="s"
                id="s"
                fullWidth
                size="small"
                value={category}
                required
                onChange={handleCategoryChange}
              >
                
                <MenuItem value={1}>Breveges</MenuItem>
                <MenuItem value={2}>Table water</MenuItem>
                <MenuItem value={3}>Snacks</MenuItem>
            </Select>
            

            </div>
            <div className="mb-4">
            <button type="button" onClick={handleClose} className={`modal_btn btn btn-block`}>
              Add

            </button>
            
            
            </div>

          </form>


        </div>
       
       
      </GlobalModal>
    </>
  ) : <OrderDetails categoryId={categoryId} key={1} onChange={handleChange} />
}
