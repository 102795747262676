
export const useProducts = () => {
    return [
        {name: 'All'},
        {name: 'Nestcafe'},
        {name: 'Nestcafe'},
        {name: 'Beverages'},
        {name: 'Water'},
    ]


}