

// ----------------------------------------------------------------------
import styles from "../../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import {
    Card,
} from '@mui/material';
import Scrollbar from '../../../../components/scrollbar';
// sections
// mock
import Button from '@mui/material/Button';
import bus from 'src/bus';
import axiosInstance from "src/axiosInstance"

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { createRef } from "react";
import { useLayoutEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner'
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const style2 = {
    position: 'absolute',
    height: '250px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 3,
};
const style1 = {
    position: 'absolute',
    height: '500px',
    overflow: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 3,
};

// ptvp=PAGE TO VIEW (SALES)

export default function RetailerProductSingle({ categoryId }) {
    let textInput = createRef();
    const [openDialog, setDiologOpen] = useState(false);
    const [categoriesData, setCatdata] = useState([]);
    const navigate = useNavigate()
    const [open1, setOpen1] = useState(false);
    const [openStockModal, setOpenStockModal] = useState(false);
    const handleClose1 = () => setOpen1(false);
    const [url, setUrl] = useState('/assets/miloContainer.svg');
    const [isPending, setIsPending] = useState(false);
    const [categoryName, setCategoryName] = useState(JSON.parse(localStorage.getItem("shopSingleProduct")))
    const notifyError = (err) => toast.error(err);
    const notifyToast = (msg) => toast.success(msg);
    const [reloadData, setReloadData] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isCategoryRemove, setIsCategoryRemove] = useState(false)
    const [searchvalue, setSearchValue] = useState('')
    const [availableProductID, setAvailableProductID] = useState('')

    const handleDialogClose = () => {
        setDiologOpen(false);
    };

    useLayoutEffect(() => () => {
        // Your code here.
    }, [])

    const searchFilter = (event) => {
        setSearchValue(event.target.value);
    };

    const handleFile = (e) => {
        if (e.target.files[0]) {
            setUrl(URL.createObjectURL(e.target.files[0]))
        }
    }

    const handleButtonClick = () => {
        textInput.current.click()
    }

    const reload = () => {
        navigate(`/app/products`)
    }
    useEffect(() => {
        fetchCategorySingle()
        // fetchData()
    }, [reloadData, searchvalue]);
    bus.on('reload', () => {
        // fetchData()
    })

    const handleProductDelete = (id) => {
        setIsDeleting(true)
        axiosInstance.delete('/shop/product/delete/' + id)
        .then(res => {
            if (res.status < 300) {
                notifyToast(res.data.message)
                setReloadData(!reloadData)
            }
        }).catch(err => {
            try{
                notifyError(err.response.data.message)
            }
            catch{
                notifyError("Something went wrong, please try again later")
            }
        }).finally(() => setIsDeleting(false))
    }

    const handleProductCategoryRemove = (id) => {
        setIsCategoryRemove(true)
        axiosInstance.delete('/shop/product/delete/' + id)
        .then(res => {
            if (res.status < 300) {
                notifyToast(res.data.message)
                setReloadData(!reloadData)
            }
        }).catch(err => {
            try{
                notifyError(err.response.data.message)
            }
            catch{
                notifyError("Something went wrong, please try again later")
            }
        }).finally(() => setIsCategoryRemove(false))
    }

    const fetchCategorySingle = (page = 1, pageSize = 10, search = searchvalue) => {
        setIsPending(true);
        axiosInstance.get('/shop/product/' + categoryId, {
            params: {
                search
            }
        })
            .then(res => {
                setCatdata(res.data.data)
            }).catch(err => {
                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to fetch store, try again later");
                }

            }).finally(() => setIsPending(false))
    }

    const handleAvailableProduct = (val) => {
        setIsPending(true);
        const param = {
            stock_available: 1
        }
        axiosInstance.post('/shop/product/add/' + val, param)
        .then(res => {
            if (res.status < 300) {
                setOpenStockModal(false)
                setReloadData(!reloadData)
                notifyToast(res.data.message)
            }else {
                notifyError(res.data.message);
            }
        }).catch(err => {
            try {
                notifyError(err.response.data.message);
            } catch (error) {
                notifyError("Unable to process data, try again later");

            }

        }).finally(() => setIsPending(false))
    }

    return (
        <>
            <div className={` px-2`}>
                <div className={`${styles.filters}`}>
                    <div className="row">
                        <div className={`${styles.hold_input} col-md-3 col-6`}>
                            <img src="/assets/icons/search.svg" 
                                style={{ marginLeft: '10px'}}
                                alt="" />
                            <input type="text" 
                                value={searchvalue}
                                onChange={searchFilter}
                                placeholder="Search" 
                                style={{ 
                                    textIndent: '25px', 
                                    width: '60%' 
                                }} 
                                className=" form-control" 
                            />
                        </div>
                    </div>
                </div>
                <Card style={{ marginTop: '10px', borderRadius: '10px' }} 
                    className='p-0'>
                    <div className='p-4 border-bottom'>
                        <div className="mb-3 d-inline-block cursor_pointer" 
                            onClick={reload}>
                            <img src="/assets/icons/Chevronleft.svg" 
                                className="pr-1" 
                                alt="" 
                            />
                        </div>
                        <div className="d-md-flex justify-content-between">
                            <div>
                                <h4>
                                    {categoryName}
                                </h4>
                                <p className="mb-0 header_control">
                                    Add products that are available 
                                    in your store
                                </p>
                            </div>
                        </div>
                    </div>
                    <Scrollbar>
                        <div className={`py-3 px-3  ${styles.cover_products}`}>
                            <div className={`${styles.customized_row}`}>
                                {(!isPending && categoriesData.length > 0) &&
                                    categoriesData.map((el,i) => (
                                        <div className="  mb-2">
                                            <div className="card" 
                                                style={{ 
                                                    backgroundColor: 'inherit !important' 
                                                }}>
                                                <div className="card-body" 
                                                    style={{ 
                                                        backgroundColor: 'inherit !important' 
                                                    }}>
                                                    <div className={`${styles.customized_row1}`}>
                                                        <div className=" ">
                                                            <img style={{
                                                                    width: '60%'
                                                                }} 
                                                                src={el?.product_image} 
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className={`${styles.product_txt}`}>
                                                            <h6>
                                                                {el?.product_name}
                                                            </h6>
                                                            <p>
                                                                {el?.description}
                                                            </p>
                                                            <div className="d-flex justify-content-between">
                                                                <span>
                                                                    {el?.product_spec ? el?.product_spec : ''}
                                                                </span>
                                                            </div>
                                                            <div className={`${styles.product_price}`}>
                                                                {!el.already_exist
                                                                    ?
                                                                        <div className="row pt-2">
                                                                            <div className="col-12 text-center">
                                                                                <button 
                                                                                    className="btn_primary btn btn-block btn-[#53138D] bg-[#8c2d915d]"
                                                                                    onClick={()=> handleAvailableProduct(el._id)}
                                                                                >
                                                                                    <small>Available In Store</small>
                                                                                    {
                                                                                        isPending &&
                                                                                        <CircularProgress style={{ color: "white" }} size="1rem" />
                                                                                    }
                                                                                </button>
                                                                            </div>
                                                                            {/* <div className="col-6">
                                                                                <button
                                                                                    className="btn_danger_outline btn
                                                                                                btn-inline-block btn-danger ml-1"
                                                                                    onClick={() => handleProductCategoryRemove(el?._id)}
                                                                                >
                                                                                    {
                                                                                        !isCategoryRemove &&
                                                                                        <small>Remove</small>
                                                                                    }
                                                                                    
                                                                                    {
                                                                                        isCategoryRemove &&
                                                                                        <CircularProgress style={{ color: "#fff" }} size="1rem" />
                                                                                    }
                                                                                </button>
                                                                            </div> */}
                                                                        </div>
                                                                    :
                                                                        <div className="row pt-2">
                                                                            <div className="col-12">
                                                                                <button style={{ 
                                                                                            width: '100%'
                                                                                        }} 
                                                                                        className="btn_primary_outline btn
                                                                                                   btn-inline-block"
                                                                                >
                                                                                    Added
                                                                                </button>
                                                                                {/* <span style={{ 
                                                                                            cursor: 'pointer', 
                                                                                            width: '10%', 
                                                                                            display: 'inline-block', 
                                                                                            textAlign:'center' 
                                                                                        }}
                                                                                >
                                                                                    {
                                                                                        !isDeleting &&
                                                                                        <img src="/assets/icons/trash-2.svg" 
                                                                                            onClick={() => handleProductDelete(el?._id)}
                                                                                            alt="delete" 
                                                                                            className={`${styles.reduce_icons}`} 
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        isDeleting &&
                                                                                        <CircularProgress style={{ color: "#53138D" }} size="1rem" />
                                                                                    }
                                                                                </span> */}
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                        <div className="d-flex justify-content-center">
                            {isPending &&
                            <BallTriangle

                                height={50}
                                width={55}
                                radius={5}
                                color="#53138D"
                                ariaLabel="ball-triangle-loading"
                                wrapperClass={{}}
                                wrapperStyle=""
                                visible={true}
                            />

                            }

                        </div>
                        {
                            (!categoriesData.length && !isPending) &&

                            <div className="alert alert-[#53138D] text-center">
                            <h4>No record found</h4>
                            </div>
                        }
                    </div>
                    </Scrollbar>
                </Card>
            </div>
            {/*  DIALOG*/}
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="card border-0">
                    <div className="card-body border-0 ">
                        <div className={`${styles.customized_row}`}>
                            <div className="text-center">
                                <img style={{ width: '40px' }} 
                                    src="/assets/icons/caution.svg" 
                                    alt=""
                                />
                            </div>
                            <div>
                                <h4>
                                    Delete Product
                                </h4>
                                <p style={{
                                    fontSize: '14px',
                                    color: '#6B7280',
                                }}>
                                    Are you sure you want to delete this product? 
                                    Everything about this product will be permanently 
                                    removed from our servers forever. 
                                    This action cannot be undone.
                                </p>
                            </div>
                        </div>
                        <DialogActions>
                            <Button className={`${styles.cancel_delete}`} 
                                onClick={handleDialogClose}>Cancel
                            </Button>
                            <Button className={`${styles.delete_product}`} 
                                onClick={handleDialogClose}>Delete
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
            {/* Withdraw */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open1}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={open1}>
                    <Box className={`mobile_modal_size`} sx={style1}>
                        <div className={`${styles.modal_content}`}>
                            <div className={`${styles.modal_header}`}>
                                <h3>Edit Product </h3>
                                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                                    <img src="/assets/icons/x.svg" alt="" />

                                </span>

                            </div>
                            <p>
                                Edit product to this category
                            </p>




                            <form action="">
                                <p className={`${styles.modal_product_title}`}>Product Image </p>
                                <input type="file" hidden ref={textInput} name="" onChange={handleFile} />
                                <div className={`${styles.retailer_modal_product_img}`} style={{
                                    backgroundImage: `url('${url}')`,


                                }}>
                                    <span style={{ cursor: 'pointer' }} onClick={() => handleButtonClick()}>
                                        <img
                                            className={`${styles.retailer_product_image}`}
                                            src="/assets/image.svg"
                                            alt=""

                                        />

                                    </span>
                                </div>
                                <div className="mb-3">
                                    <label className={`${styles.modal_product_title}`} htmlFor="pname">Product Name</label>
                                    <TextField
                                        required
                                        type='text'
                                        id="pname"
                                        placeholder=""
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <div className="mb-3">
                                    <label className={`${styles.modal_product_title}`} htmlFor="desc">Description </label>
                                    <TextField
                                        required
                                        id="desc"
                                        multiline
                                        rows='4'
                                        cols='3'
                                        placeholder=" Best Product"
                                        size="small"
                                        fullWidth

                                    />


                                </div>
                                <div className="mb-3">
                                    <label className={`${styles.modal_product_title}`} htmlFor="stock">Stock </label>
                                    <TextField
                                        required
                                        id="stock"
                                        type="number"
                                        size="small"
                                        fullWidth

                                    />


                                </div>


                                <div className="mb-3">
                                    <button type="submit" className={`${styles.modal_btn} btn btn-block`}>
                                        Save
                                    </button>


                                </div>

                            </form>


                        </div>

                    </Box>
                </Fade>
            </Modal>

        </>
    )
}
