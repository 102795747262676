import styles from "./Page.module.css"
import Scrollbar from '../../components/scrollbar';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect } from "react";
import { BallTriangle } from 'react-loader-spinner'
import { Pagination } from "src/components";
// import bus from 'src/bus';

// sections
// mock
import { useState } from "react"
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------



const style = {
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
width: 900,
height: 580,
overflow:'auto',
bgcolor:'#F5F5F5',
border: '1px solid #F5F5F5',
borderRadius:'5px',
boxShadow: 24,
p: 4,
};
export default function OrderPage() {
const [open, setOpen] = useState(false);
let [shouldFetch] = useState(0);
const [outletData, setdata] = useState([]);
const [outletPagination, setOutletPagination] = useState({total:0});
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(10);

const [isPending,setIsPending] = useState(false);
const [isDeleting,setIsDeleting] = useState(false);
const notify = () => toast.success('Outlet saved .');
const notifyError = (err) => toast.error(err);
const [search, setSearch]=useState({search:'', category:'', location:''});
const [endpoint, changeEndPoint]= useState('/outlet/add-outlet');
const [outlets]= useState({});
const [formValues, setFormValues] = useState({
  outlet_name: '',
  outlet_email: '',
  outlet_manager: '',
  phone_number: '',
  outlet_location: '',
  outlet_category: 1,
  outlet_country: 10,
  outlet_state: 10,
});
const handleChange = (event) => {
  const { name, value } = event.target;
  setFormValues((formValues) => ({
    ...formValues,
    [name]: value
  }));
};

const [showText, setShowText] = useState(false);

const changeType = () => {
  setShowText(!showText)
}
const [openInviteAdmin, setOpenInviteAdmin] = useState(false);

const handleCloseInviteAdmin = () => setOpenInviteAdmin(false);

const handleClose = () => setOpen(false);



const [openDialog, setDiologOpen] = useState(false);



const handleDialogClose = () => {
  setDiologOpen(false);
};

useEffect(() => {
  bus.on('open-outlet', () => {
    changeEndPoint('/outlet/add-outlet')
    setOpen(true)
  });

  bus.on('open-invite-modal', () => {
    setOpenInviteAdmin(true)
  });

  return () => {
    bus.off('open-outlet', () => {
      changeEndPoint('/outlet/add-outlet')

    });
  };
}, []);

useEffect(() => {
  // fetchData()
}, [shouldFetch, search.search]);


const fetchData =(page = 1, pageSize = 10) => {
  setIsPending(true);
  axiosInstance.get('/outlet/get', {
    params: {
      search: search.search,
      page,
      pageSize
    }
  })
  .then(res => {
    setdata(res.data.data.data)
    setOutletPagination(res.data.data.pagination)


  }).catch(err => {

    try {
      notifyError(err.response.data.message);

    } catch (error) {
      // notifyError("Unable to fetch outlet, try again later");

    }

  }).finally(() => setIsPending(false))
}

const handleSubmit = (event) => {
  event.preventDefault();

  // Do something with the form values
  setIsPending(true);
  axiosInstance[formValues.hasOwnProperty('_id') ? 'put' : 'post'](endpoint, formValues)
  .then(res => {
    if(res.status === 201) {
      notify(res.data.message);
      fetchData(currentPage, itemsPerPage)
      setFormValues({
        outlet_name: '',
        outlet_email: '',
        outlet_manager: '',
        phone_number: '',
        outlet_location: '',
        outlet_category: 1,
        outlet_country: 10,
        outlet_state: 10,
      })
      handleClose();

    } 
  }).catch(err => {

    try {
      notifyError(err.response.data.message);

    } catch (error) {
      // notifyError("Unable to save outlet, try again later");

    }

  }).finally(() => setIsPending(false))
};

const handleDelete = () => {

 setIsDeleting(true)
  axiosInstance.delete(endpoint)
  .then(res => {
    if(res.status < 300) {
      notify(res.data.message);
      fetchData(currentPage, itemsPerPage)

      handleClose();

    } 
  }).catch(err => {

    try {
      notifyError(err.response.data.message);

    } catch (error) {
      // notifyError("Unable to save outlet, try again later");

    }

  }).finally(() => {
    setIsDeleting(false)
    handleDialogClose()
  })
};

const processFilter=(e) => {
  const { name, value } = e.target;
  setSearch(() => ({
    ...search,
    [name]: value
  }));


}








return    (
  <>
      <div className="wrapper px-2 mt-4">
    <div className={`${styles.filters}`   }>
      <div className="d-flex justify-content-between px-0 ">
        <div className={`${styles.hold_input} col-md-4 col-12 px-0  `}>
          <img src="/assets/icons/search.svg" alt="" />
          <input type="text" placeholder="Search" name="search" value={search.search} onChange={processFilter} style={{textIndent:'25px',width:'100%'}} className=" form-control" />

        </div>
      </div>


    </div>
    <Scrollbar>
      <div className={`card p-0  m-0 mt-4 ${styles.card_table}`}>
          <div className="head_wrapper" style={{fontSize: '14px'}}>
              <div className={`row px-4 px-md-5 py-3 ${styles.show_control}`}>
                  <div className="col-4 p-0 py-1 py-md-0">
                      <div className={`${styles.show_numbers}`}>
                          <span className="font-weight-bold text-dark">Show </span>
                          <div className={`${styles.number_cont} d-flex py-1 px-3`}>
                              <span className="d-inline-block">10</span>
                              <span className={`d-inline-block ${styles.svg_div}`}>
                                  <svg className="d-block mx-auto mt-1 mb-1" width="8" height="8" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.58334 6.09039V0.743164M3.58334 0.743164L0.909729 3.41677M3.58334 0.743164L6.25695 3.41677" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                  <svg className="d-block mx-auto mb-1 mt-1" width="8" height="8" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.58334 0.743164V6.09039M3.58334 6.09039L6.25695 3.41677M3.58334 6.09039L0.909729 3.41677" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </span>
                          </div>
                          <button className="btn btn_neutral ml-2">Apply</button>
                      </div>
                  </div>
                  {/* <div className="col-8 p-0 py-1 py-md-0">
                      <div className="d-flex ml-auto" style={{width:' fit-content', justifyContent: 'space-between', gap: '10px'}}>
                        <div>
                          <button  className={`btn dropdown-toggle ${styles.filter_btn}`} data-toggle="dropdown">
                            <i className="bi bi-filter p-0" style={{fontSize: '20px', lineHeight: '0', verticalAlign: 'middle'}}></i>
                            <span className={`${styles.display_filter}`}>&nbsp; Filters</span>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <button className="dropdown-item" type="button">Action</button>
                            <button className="dropdown-item" type="button">Another action</button>
                            <button className="dropdown-item" type="button">Something else here</button>
                          </div>
                        </div>
                          <div className="btn-group">
                              <button type="button" className={`${styles.sortby_btn} btn dropdown-toggle`} data-toggle="dropdown" aria-expanded="false">
                              <span className={`text-muted ${styles.display_sortby}`} style={{fontSize: '14px'}}>Sort by </span><i className="bi bi-chevron-down font-weight-bold ml-2 text-muted" style={{fontSize: '14px', lineHeight: 0, verticalAlign: 'middle'}}></i>
                              </button>
                              <div className="dropdown-menu dropdown-menu-right">
                              <button className="dropdown-item" type="button">Action</button>
                              <button className="dropdown-item" type="button">Another action</button>
                              <button className="dropdown-item" type="button">Something else here</button>
                              </div>
                          </div>
                      </div>
                  </div> */}
              </div>
          </div>
          <div className={`${styles.table_section} border-bottom`}>
              <table className="table px-0 table-striped table-hover" style={{fontSize: '13px'}} >
                  <thead>
                    <tr>
                      <th scope="col"
                      style={{
                        width:'20px'
                      }}
                      >S/N</th>
                      <th 
                      style={{
                        width:'60%'
                      }}
                      scope="col">Admin name</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-dark">
                    <tr>
                      <td>1</td>
                      <th>
                        <div className=" ">
                          <img
                            className="float-md-left  mr-2"
                            src={'/assets/outlet1.svg'}
                            alt=""
                            style={{
                            }}
                          />
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                              <strong>
                                Ariene McCoy
                              </strong>
                              <br />
                              <small >
                                mccoy@yahoo.com
                              </small>

                            </p>

                          </div>

                        </div>
                      </th>
                      <td>25/02/22</td>
                      <td>
                          <div className="btn-group">
                              <button type="button" className="btn m-0 p-0 button_b" data-toggle="dropdown" aria-expanded="false">
                                  <i className="bi bi-three-dots-vertical m-0 p-0 button_i"></i>
                              </button>
                              <div className="dropdown-menu dropdown-menu-right">
                                <button className="dropdown-item" onClick={()=>setOpen(true)} type="button">View</button>
                                <button className="dropdown-item" type="button">Disable</button>
                                {/* <button className="dropdown-item" type="button">Something else here</button> */}
                              </div>
                            </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <th>
                        <div className=" ">
                          <img
                            className="float-md-left  mr-2"
                            src={'/assets/outlet1.svg'}
                            alt=""
                            style={{
                            }}
                          />
                          <div className=" ">
                            <p className="mb-0" style={{ fontSize: '14px' }}>
                              <strong>
                                Micheal Gillard
                              </strong>
                              <br />
                              <small >
                                micheal@gmail.com
                              </small>

                            </p>

                          </div>

                        </div>
                      </th>
                      <td>25/02/22</td>
                      <td>
                          <div className="btn-group">
                              <button type="button" className="btn m-0 p-0 button_b" data-toggle="dropdown" aria-expanded="false">
                                  <i className="bi bi-three-dots-vertical m-0 p-0 button_i"></i>
                              </button>
                              <div className="dropdown-menu dropdown-menu-right">
                                <button className="dropdown-item" type="button" onClick={()=>setOpen(true)}>View</button>
                                <button className="dropdown-item" type="button">Disable</button>
                                {/* <button className="dropdown-item" type="button">Something else here</button> */}
                              </div>
                            </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
          </div>
          <div className="d-flex justify-content-center">
              {isPending && 
              <BallTriangle

                height={50}
                width={55}
                radius={5}
                color="#53138D"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />

              }
          </div>
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={outletPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
      </div>


          {/* {
            (!outletData.length && !isPending)  && 
            <div className="alert alert-primary text-center">
              <h4>No record found</h4>
            </div>
          } */}


    </Scrollbar>



    {/* View Modals */}
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={open}>
        <Box sx={style} className={`mobile_modal_size`}>
          <div className={`${styles.modal_content}`}  >
            <div className={`${styles.modal_header} mb-0`}>
              <div>
                <h5 style={{
                  fontSize: '19px',
                  fontWeight: '700',
                }}>Bankole Stephen</h5>
              </div>
              <div>
                <span onClick={handleClose} style={{cursor:'pointer'}}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
            </div>
            
            <form onSubmit={handleSubmit} className="mt-0">
              <div className="row">
                <div className="col-md-5">
                <div className="mb-3">
                  <label htmlFor="name">Email Address</label>
                  <TextField
                    required
                    type='text'
                    value={formValues.outlet_name}
                    name="outlet_name"
                    onChange={handleChange}
                    id="name"
                    placeholder="sample@email.com"
                    size="small"
                    fullWidth
                  />
                </div>
                </div>
                <div className="col-md-7">
                  <div className="mb-3">
                    <label htmlFor="name">Password</label> <br />
                    <TextField
                      required
                      type={showText ? 'text' : 'password'}
                      value={formValues.outlet_name}
                      name="outlet_name"
                      onChange={handleChange}
                      id="name"
                      placeholder="Oulet 1"
                      size="small"
                      fullWidth
                      className="width_size"
                    />
                    
                        <span className="icon mx-2 pointer" onClick={changeType}>
                          {
                            !showText &&
                            <img src="/assets/icons/eye.svg" alt="" />
                          }
                          {
                            showText &&
                            <img src="/assets/icons/eye-off.svg" alt="" />
                          }
                        </span>
                        <button className="btn btn-[#53138D] bg-[#8c2d915d] text-white">Edit Role</button>
                    </div>
                </div>
                
              </div>
              
              <div className="alert alert-success text-center p-1" role="alert">
                What this role can access
              </div>
              <div className="row" style={{
                color: '#585c63',
                fontSize:'14px'
              }}>
                <div className="col-md-4">
                  <p className="adjust_p_margin">
                    <strong>
                      Dashboard
                    </strong>
                  </p>
                  <p className="adjust_p_margin">
                    User Can View Dashboard
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="adjust_p_margin">
                    <strong>
                      Stores
                    </strong>
                  </p>
                  <p className="adjust_p_margin">
                    User Can View Stores
                  </p>
                  <p className="adjust_p_margin">
                    User Can Manage Stores
                  </p>
                  <p className="adjust_p_margin">
                    User Can Delete Stores
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="adjust_p_margin">
                    <strong>
                      Admin Users
                    </strong>
                  </p>
                  <p className="adjust_p_margin">
                    User Can View Admin Users
                  </p>
                  <p className="adjust_p_margin">
                    User Can Delete Admin Users
                  </p>
                </div>
              </div>
              <div className="alert alert-danger text-center p-1 mt-5" role="alert">
                What this role can't access
              </div>
              <div className="row" style={{
                color: '#585c63',
                fontSize:'14px'
              }}>
                <div className="col-12">
                  <div className="text-center">
                    Empty
                  </div>
                </div>
                
              </div>
            </form>
          </div>
        
        </Box>
      </Fade>
    </Modal>
    
    {/* Invite Admin Modals */}
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openInviteAdmin}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openInviteAdmin}>
        <Box sx={style} className={`mobile_modal_size`}>
          <div className={`${styles.modal_content}`}  >
            <div className={`${styles.modal_header} mb-0`}>
              <div>
                <h5 style={{
                  fontSize: '19px',
                  fontWeight: '700',

                }}>Bankole Stephen</h5>

              </div>
              <div>
                <span onClick={handleCloseInviteAdmin} style={{cursor:'pointer'}}>
                  <img src="/assets/icons/x.svg" alt="" />

                </span>

              </div>

            </div>

            <form onSubmit={handleSubmit} className="mt-0">
              <div className="row">
                <div className="col-md-10 col-9">
                  <div className="mb-3">
                    <label htmlFor="name">Email Address</label>
                    <TextField
                      required
                      type='text'
                      // value={formValues.outlet_name}
                      name="outlet_name"
                      onChange={handleChange}
                      id="name"
                      placeholder="sample@email.com"
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-md-2 col-3 upper_space">
                  <button className="btn btn-[#53138D] bg-[#8c2d915d] text-white" style={{ background: '#53138D',  }}> Send Invite</button>
                </div>
              </div>

              <div className="row">
                <div className="col-9">
                  <strong>
                    <p>Select Permissions For This Role</p>
                  </strong>
                </div>
                <div className="col-3 text-right">
                  <p>Select All</p>
                </div>
              </div>

              <div className="row" style={{
                color: '#585c63',
                fontSize:'14px'
              }}>
                <div className="col-md-4">
                  <p className="adjust_p_margin">
                    <strong>
                      Dashboard
                    </strong>
                  </p>
                  <p className="adjust_p_margin">
                    User Can View Dashboard
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="adjust_p_margin">
                    <strong>
                      Stores
                    </strong>
                  </p>
                  <p className="adjust_p_margin">
                    User Can View Stores
                  </p>
                  <p className="adjust_p_margin">
                    User Can Manage Stores
                  </p>
                  <p className="adjust_p_margin">
                    User Can Delete Stores
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="adjust_p_margin">
                    <strong>
                      Admin Users
                    </strong>
                  </p>
                  <p className="adjust_p_margin">
                    User Can View Admin Users
                  </p>
                  <p className="adjust_p_margin">
                    User Can Delete Admin Users
                  </p>
                </div>
              </div>

            </form>


          </div>

        </Box>
      </Fade>
    </Modal>

    {/* <GlobalModal
      open={modalOpen}
      onClose={handleClose2}
      position='absolute'
      top= '50%'
      left= '50%'
      transform= 'translate(-50%, -50%)'
      width= '740px !important'
      overflow='auto'
      bgcolor='#F5F5F5'
      border= '1px solid #F5F5F5'
      borderRadius='5px'
      boxShadow= '24'
      p='25px'
    >
      <div  className={`${styles.modal_content}`}>
        <div className={`${styles.modal_header} mb-0`}>
        <h5 style={{
          fontSize: '19px',
          fontWeight: '700',
        }}>Filter</h5>
          <span onClick={handleClose2} style={{cursor:'pointer'}}>
            <img src="/assets/icons/x.svg" alt="" />
          </span>
        </div>
        
        <form action="" className="mt-0">
          <div className="row">
            <div className="col-md-6 mb-1">
              <div className="mb-3">
                <label htmlFor="loc">Location</label>
                <TextField
                  type='text'
                  id="loc"
                  placeholder="Enter Location"
                  name="location"
                  value={search.location}
                  onChange={processFilter}
                  size="small"
                  fullWidth
                />
                  
              </div>
              
            </div>
            <div className="col-md-6 mb-1">
              <label htmlFor="cat">Category</label>
                <Select
                    labelId="s"
                    id="cat"
                    fullWidth
                    size="small"
                    value={search.category}
                    name="category"
                    onChange={processFilter}
                  >
                    
                    <MenuItem value={1}>Select Category</MenuItem>
                </Select>
              
            </div>
          </div>
          <div className="mb-4">
          <button type="button" onClick={handleClose2} className={`${styles.modal_btn} btn btn-block`}>
            Proceed
          </button>
          
          
          </div>
        </form>
      </div>
    
    
    </GlobalModal> */}
  <Dialog
    open={openDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleDialogClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <div className="card border-0">
        <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
                <div className="text-center">
                    <img style={{width:'40px'}} src="/assets/icons/caution.svg" alt="" />

                </div>
                <div>
                    <h5>
                    Delete {outlets.outlet_name} outlet

                    </h5>
                    <p style={{
                        fontSize:'14px',
                        color: '#6B7280',
                    }}>
                    Are you sure you want to delete this outlet? All  your data will be permanently removed from our servers forever. This action cannot be undone.
                    </p>

                </div>

            </div>

            <DialogActions>
            <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
            {
              !isDeleting &&
              <Button className={`${styles.delete_product}`} onClick={handleDelete}>Delete</Button>
            }

            { isDeleting &&   <button disabled  className={`btn ${styles.delete_product}`}>

                { isDeleting && <CircularProgress style={{color:'white'}}  size="1rem"   />}
                </button>
              }
            </DialogActions>
        </div>
    </div>
  </Dialog>

 </div>
</>
) 
}