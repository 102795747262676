// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
import { useNavigate } from 'react-router-dom';
import { useContext, useState, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Card, CardContent } from '@mui/material';
// sections
// mock
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import HelpIcon from '@mui/icons-material/Help';
import { BaseUrlContext } from 'src/context/BaseUrlContext';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useAuth } from 'src/hooks/useAuth';
import toast from 'react-hot-toast';
import axiosInstance from 'src/axiosInstance';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import bus from 'src/bus';

// ----------------------------------------------------------------------

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};
const style2 = {
  position: 'absolute',
  height: '250px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  // boxShadow: 24,
  p: 3,
};
const customStyle = { backgroundColor: '#F3F4F6 !important' };
export default function SettingsPage() {
  const { dispatch, data } = useAuth();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [name, setName] = useState('');
  const [body, setBody] = useState('');
  const [password, setPassword] = useState('');
  const [nPassword, setNewPassword] = useState('');
  const [cPassword, setConfirmPassword] = useState('');
  const [showText, setShowText] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const [showText2, setShowText2] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPending1, setIsPending1] = useState(false);
  const [isPending2, setIsPending2] = useState(false);
  const [openDialog, setDialogOpen] = useState(false);
  const [openMyDialog, setOpenMyDialog] = useState(false);

  const notify = () => toast.success('Password reset successful.');
  const msgnotify = () => toast.success('Message Sent .');
  const delnotify = () => toast.success('Account successfully deleted .');
  const notifyError = (errorMessage) => toast.error(errorMessage);
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    // window.location.reload()
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNameChange = (event, newValue) => {
    setName(event.target.value);
  };
  const handlePasswordChange = (event, newValue) => {
    setPassword(event.target.value);
  };
  const handleNPasswordChange = (event, newValue) => {
    setNewPassword(event.target.value);
  };
  const handleCPasswordChange = (event, newValue) => {
    setConfirmPassword(event.target.value);
  };
  const handleBodyChange = (event, newValue) => {
    setBody(event.target.value);
  };

  const changeType = (type = null) => {
    if (type !== null) {
      if (type === 1) setShowText1(!showText1);
      if (type === 2) setShowText2(!showText2);
    } else {
      setShowText(!showText);
    }
  };

  const LogoutUser = () => {
    localStorage.removeItem('auth');
    dispatch({ type: 'LOGIN', payload: {} });

    setTimeout(() => {
      navigate('/login');
    }, 1000);
  };

  function handleUpdatePassword(e) {
    setIsPending(true);
    axiosInstance
      .post('/merchant/update-password', {
        current_password: password,
        new_password: nPassword,
        confirm_password: cPassword,
      })
      .then((res) => {
        // notify();
        if (res.status < 300) {
          setOpenMyDialog(true);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Something went wrong, please try again later');
        }
      })
      .finally(() => setIsPending(false));
  }

  function handleSendMessage() {
    setIsPending1(true);
    axiosInstance
      .put('/merchant/help-center', {
        subject: name,
        message: body,
      })
      .then((res) => {
        msgnotify();
        setName('');
        setBody('');
      })
      .catch((err) => {
        notifyError(err.response.data.message);
      })
      .finally(() => setIsPending1(false));
  }

  function handleDeleteAccount() {
    setDialogOpen(false);
    setIsPending2(true);
    axiosInstance
      .delete('/merchant/delete-account')
      .then((res) => {
        delnotify();
        localStorage.removeItem('auth');
        dispatch({ type: 'LOGIN', payload: {} });

        setTimeout(() => {
          navigate(data.merchant_type ? '/login' : '/auth');
        }, 1000);
      })
      .catch((err) => {
        notifyError(err.response.data.message);
      })
      .finally(() => setIsPending2(false));
  }

  return (
    <>
      <div className="wrapper mt-md-0 mt-4 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Settings</h4>
            </div>
          </div>
        </div>
        <div className="card" style={{ height: '100%' }}>
          <div className="row">
            <div
              className="col-md-4 border-right pr-0"
              style={{ height: '100vh', position: 'relative' }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                <Tab
                  className={`${
                    value === 0 ? styles.active : styles.default_cl
                  } ${styles.control_button}`}
                  label={
                    <div>
                      <div className="d-flex align-items-center">
                        <LockIcon style={{ color: '#F53D3D' }} />
                        &nbsp;
                        <span>Password</span>
                      </div>

                      <small>
                        This is where you get to change your password and see
                        all passwords available for change.
                      </small>
                    </div>
                  }
                />
                <Tab
                  style={{ maxWidth: '100% !important' }}
                  className={`${
                    value === 1 ? styles.active : styles.default_cl
                  } ${styles.control_button}`}
                  label={
                    <div>
                      <div className="d-flex align-items-center">
                        <HelpIcon style={{ color: '#7839EE' }} />
                        &nbsp;
                        <span>Help Center</span>
                      </div>

                      <small>
                        Use our comprehensive resource of help guidelines and
                        FAQS &nbsp; &nbsp;&nbsp; &nbsp;
                      </small>
                    </div>
                  }
                />
                <Tab
                  className={`${
                    value === 2 ? styles.active : styles.default_cl
                  } ${styles.control_button}`}
                  label={
                    <div>
                      <div className="d-flex align-items-center">
                        <DeleteIcon style={{ color: '#F97316' }} />
                        &nbsp;
                        <span>Delete Account</span>
                      </div>

                      <small>
                        This is where you get to delete your account permanently
                        from this application
                      </small>
                    </div>
                  }
                />
              </Tabs>
              <div
                className="logout"
                style={{ position: 'absolute', bottom: '2%', left: '7%' }}
              >
                <button
                  type="button"
                  onClick={() => bus.emit('open-logout-modal')}
                  className="btn "
                >
                  <img
                    src="/assets/icons/logout.svg"
                    alt=""
                    style={{ width: '30%' }}
                  />
                  &nbsp; Log out
                </button>
              </div>
            </div>
            <div className="col-md-8 pl-0" style={{ height: '100vh' }}>
              {value === 0 && (
                <div className={`${styles.card_n_body} card-body pl-0`}>
                  <div className={`${styles.content_header_container}`}>
                    <h5>Password Info</h5>
                    <small style={{ fontWeight: 'lighter' }}>
                      View and change your password settings
                    </small>
                  </div>
                  <div className={`${styles.content_body}`}>
                    <div
                      className={`${styles.row_container} row border-bottom pb-4`}
                    >
                      <div className="col-6">
                        <label htmlFor="p">Current password</label>
                      </div>
                      <div className="col-6 position_settings">
                        <TextField
                          required
                          type={showText ? 'text' : 'password'}
                          placeholder="************"
                          id="p"
                          size="small"
                          value={password}
                          onChange={handlePasswordChange}
                          fullWidth
                        />
                        <div
                          className="icon mt-2"
                          onClick={() => changeType(null)}
                        >
                          {!showText && (
                            <img src="/assets/icons/eye.svg" alt="" />
                          )}
                          {showText && (
                            <img src="/assets/icons/eye-off.svg" alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.row_container} row border-bottom pb-4`}
                    >
                      <div className="col-6 ">
                        <label htmlFor="n">Enter new password</label>
                      </div>
                      <div className="col-6 position_settings">
                        <TextField
                          required
                          type={showText1 ? 'text' : 'password'}
                          placeholder="************"
                          id="n"
                          size="small"
                          value={nPassword}
                          onChange={handleNPasswordChange}
                          fullWidth
                        />
                        <div
                          className="icon mt-2"
                          onClick={() => changeType(1)}
                        >
                          {!showText1 && (
                            <img src="/assets/icons/eye.svg" alt="" />
                          )}
                          {showText1 && (
                            <img src="/assets/icons/eye-off.svg" alt="" />
                          )}
                        </div>
                        <small className={`${styles.small}`}>
                          Your password should have 8 characters
                        </small>
                      </div>
                    </div>
                    <div
                      className={`${styles.row_container} row border-bottom pb-4`}
                    >
                      <div className="col-6">
                        <label htmlFor="c">Confirm password</label>
                      </div>
                      <div className="col-6 position_settings">
                        <TextField
                          required
                          type={showText2 ? 'text' : 'password'}
                          placeholder="************"
                          id="c"
                          size="small"
                          value={cPassword}
                          onChange={handleCPasswordChange}
                          fullWidth
                        />
                        <div
                          className="icon mt-2"
                          onClick={() => changeType(2)}
                        >
                          {!showText2 && (
                            <img src="/assets/icons/eye.svg" alt="" />
                          )}
                          {showText2 && (
                            <img src="/assets/icons/eye-off.svg" alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`text-right mt-4 ${styles.buttons}`}>
                      <button
                        className="btn btn-sm "
                        style={{
                          background: '#FFFFFF',
                          border: '1px solid #D0D5DD',
                        }}
                      >
                        Cancel
                      </button>
                      &nbsp; &nbsp;
                      <button
                        onClick={handleUpdatePassword}
                        className={`btn btn-[#af3585] text-white btn-sm ${styles.btn_custom_color}`}
                        style={{ background: '#af3585' }}
                      >
                        <span style={{ marginRight: '5px' }}>
                          Update password
                        </span>
                        {isPending && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {value === 1 && (
                <div className={`${styles.card_n_body} card-body pl-0`}>
                  <div className={`${styles.content_header_container}`}>
                    <h5>Help Center</h5>
                    <small>Let us know what we can help you with</small>
                  </div>
                  <div className={`${styles.content_body}`}>
                    <div
                      className={`${styles.row_container} row border-bottom pb-4`}
                    >
                      <div className="col-6">
                        <label htmlFor="sub">Subject</label>
                      </div>
                      <div className="col-6">
                        <TextField
                          required
                          id="sub"
                          size="small"
                          value={name}
                          placeholder="Input"
                          onChange={handleNameChange}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div
                      className={`${styles.row_container} row border-bottom pb-4`}
                    >
                      <div className="col-6">
                        <label htmlFor="m">Your message</label>
                      </div>
                      <div className="col-6">
                        <TextField
                          required
                          id="m"
                          size="small"
                          placeholder="Text area"
                          multiline
                          rows="5"
                          cols="5"
                          value={body}
                          onChange={handleBodyChange}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="text-right mt-4">
                      <button
                        onClick={handleSendMessage}
                        className={`btn btn-[#af3585] text-white btn-sm ${styles.btn_custom_color}`}
                      >
                        <span style={{ marginRight: '5px' }}>Send Message</span>
                        {isPending1 && (
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {value === 2 && (
                <div className={`${styles.card_n_body} card-body pl-0`}>
                  <div className={`${styles.content_header_container}`}>
                    <h5>Delete account</h5>
                    <small>We are sorry to see you go</small>
                  </div>
                  <div className={`${styles.content_body} pt-3`}>
                    <p>
                      Once you delete your account, it cant be restored. you
                      would loose access to past messages and <br />
                      events. if you are sure you want to delete your account,
                      we would email you with the final steps at <br />
                      candidate@gmail.com
                    </p>
                    <div className="text-right mt-4">
                      {!isPending2 && (
                        <button
                          style={{ cursor: 'pointer' }}
                          onClick={handleClickOpen}
                          className={`btn btn-danger btn-sm ${styles.btn_custom_color}`}
                        >
                          <span style={{ marginRight: '5px' }}>
                            Delete Account
                          </span>
                        </button>
                      )}
                      {isPending2 && (
                        <button
                          className={`btn btn-danger btn-sm ${styles.btn_custom_color}`}
                        >
                          <CircularProgress
                            style={{ color: 'white' }}
                            size="1rem"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openMyDialog}
        onClose={() => setOpenMyDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openMyDialog}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>
                Password Updated
              </h5>
              <p
                className="text-center"
                style={{ textAlign: 'center !important' }}
              >
                You have successfully updated your password.
              </p>
              <button
                onClick={() => setOpenMyDialog(false)}
                className={`modal_btn hover btn mx-auto border px-4 bg-light text-dark`}
              >
                Done
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/*  DIALOG*/}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
        className={!openDialog ? styles.hiddenDialog : ''} // Apply a class to hide the modal overlay
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img
                  style={{ width: '40px' }}
                  src="/assets/icons/caution.svg"
                  alt=""
                />
              </div>
              <div>
                <h4>Delete Account</h4>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6B7280',
                  }}
                >
                  Are you sure you want to delete this account? All your data
                  will be permanently removed from our servers forever. This
                  action cannot be undone.
                </p>
              </div>
            </div>

            <DialogActions>
              <Button
                className={`${styles.cancel_delete}`}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
              <Button
                className={`${styles.delete_account}`}
                onClick={handleDeleteAccount}
              >
                Delete
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </>
  );
}
