/* eslint-disable no-unreachable */
// component

// ----------------------------------------------------------------------

const auth = JSON.parse(localStorage.getItem('auth')) || {};
// console.log(auth)
const navConfigSA = [
  {
    title: 'dashboard',
    path: '/app/dashboard',
    icon: '/assets/icons/home.svg',
    type: 'all',
    permission_alias: 'view-dashboard',
  },
  {
    title: 'customers',
    path: '/app/customers',
    icon: '/assets/icons/users.svg',
    type: 'superadmin',
    permission_alias: 'view-customers',
  },
  {
    title: 'Stores',
    path: '/app/stores',
    icon: '/assets/icons/server.svg',
    type: 'brandandsuperadmin',
    permission_alias: 'view-outlets',
  },
  {
    title: 'sales',
    path: '/app/sales',
    icon: '/assets/icons/target.svg',
    type: 'all',
    permission_alias: 'view-sales',
  },
  {
    title: 'purchases',
    path: '/app/purchases',
    icon: '/assets/icons/plus-square.svg',
    type: 'superadmin',
    permission_alias: 'view-purchase',
  },
  {
    title: 'products',
    path: '/app/products',
    icon: '/assets/icons/package.svg',
    type: 'all',
    permission_alias: 'view-products',
  },
  {
    title: 'transactions',
    path: '/app/transactions',
    icon: '/assets/icons/credit-card.svg',
    type: 'superadmin',
    permission_alias: 'view-finances',
  },
  {
    title: 'pricing',
    path: '/app/pricing',
    icon: '/assets/icons/tag.png',
    type: 'superadmin',
    permission_alias: 'view-pricing',
  },
  {
    title: 'Subscriptions',
    path: '/app/subscription',
    icon: '/assets/icons/award.png',
    type: 'superadmin',
    permission_alias: 'view-subscription',
  },
  {
    title: ' Audit',
    path: '/app/audit',
    icon: '/assets/icons/beenhere.svg',
    type: 'superadmin',
    permission_alias: 'view-audits',
  },
  {
    title: 'Administration',
    path: '/app/administration',
    icon: '/assets/icons/adminicon.svg',
    type: 'shopandsuperadmin',
    permission_alias: 'view-permissions',
  },
  {
    title: 'marketing',
    path: '/app/marketing',
    icon: '/assets/icons/stars.svg',
    type: 'superadmin',
    permission_alias: 'view-marketing',
  },
  {
    title: 'data insight',
    path: '/app/data_insight',
    icon: '/assets/icons/bar-chart.svg',
    type: 'all',
    permission_alias: 'view-data-insights',
  },
  // {
  //   title: "incremental insights",
  //   path: "/app/incremental_insight",
  //   icon: "/assets/icons/activity.svg",
  //   type: "brand",
  //   permission_alias: "view-incremental-insights",
  // },
];

const navConfigB = [
  {
    title: 'dashboard',
    path: '/app/dashboard',
    icon: '/assets/icons/home.svg',
    type: 'all',
    permission_alias: 'view-dashboard',
  },
  {
    title: 'sales',
    path: '/app/sales',
    icon: '/assets/icons/target.svg',
    type: 'brand',
    permission_alias: 'view-sales',
  },
  {
    title: 'Stores',
    path: '/app/stores',
    icon: '/assets/icons/server.svg',
    type: 'brand',
    permission_alias: 'view-outlets',
  },
  {
    title: 'products',
    path: '/app/products',
    icon: '/assets/icons/package.svg',
    type: 'brand',
    permission_alias: 'view-products',
  },
  {
    title: 'Shoppers Review',
    path: '/app/shoppers',
    icon: '/assets/icons/users.svg',
    type: 'brand',
    permission_alias: 'view-shoppers',
  },
  {
    title: 'data insight',
    path: '/app/data_insight',
    icon: '/assets/icons/bar-chart.svg',
    type: 'shop',
    permission_alias: 'view-data-insights',
  },
];

const navConfigS = [
  {
    title: 'dashboard',
    path: '/app/dashboard',
    icon: '/assets/icons/home.svg',
    type: 'shop',
    permission_alias: 'view-dashboard',
  },
  {
    title: 'orders',
    path: '/app/orders',
    icon: '/assets/icons/codepen.svg',
    type: 'shop',
    permission_alias: 'view-orders',
  },
  {
    title: 'sales',
    path: '/app/sales',
    icon: '/assets/icons/target.svg',
    type: 'shop',
    permission_alias: 'view-sales',
  },
  {
    title: 'products',
    path: '/app/products',
    icon: '/assets/icons/package.svg',
    type: 'shop',
    permission_alias: 'view-products',
  },
  {
    title: 'Shoppers',
    path: '/app/shoppers',
    icon: '/assets/icons/users.svg',
    type: 'shop',
    permission_alias: 'view-shoppers',
  },
  {
    title: 'Wallet',
    path: '/app/wallets',
    icon: '/assets/icons/credit-card.svg',
    type: 'shop',
    permission_alias: 'view-wallets',
  },
  {
    title: 'data insight',
    path: '/app/data_insight',
    icon: '/assets/icons/bar-chart.svg',
    type: 'shop',
    permission_alias: 'view-datainsight',
  },
  {
    title: 'Administration',
    path: '/app/administration',
    icon: '/assets/icons/adminicon.svg',
    type: 'shop',
    permission_alias: 'view-administration',
  },
  {
    title: ' Audit',
    path: '/app/audit',
    icon: '/assets/icons/beenhere.svg',
    type: 'shop',
    permission_alias: 'view-audit',
  },
];

export default auth.merchant_type === 'shop'
  ? navConfigS.filter((el) => {
      if (auth.merchant_type) {
        let status = false;
        (auth.direct_permissions || []).forEach((perm) => {
          if (perm.permission_alias === el.permission_alias) status = true;
        });
        return status;
      }
    })
  : auth.merchant_type === 'brand'
  ? navConfigB.filter((el) => {
      if (auth.merchant_type) {
        return el;
      }
    })
  : navConfigSA.filter((el) => {
      if (auth.user_type) {
        let status = false;
        (auth.direct_permissions || []).forEach((perm) => {
          if (perm.permission_alias === el.permission_alias) status = true;
        });
        return status;
      }
    });
// if (el.type !== "all") {
//   if (el.type === "superadmin" && auth.merchant_type === "superadmin")
//     return true;
//   // else return false;
//   if (el.type === "shop" && auth.merchant_type === "shop") return true;
//   // else return false;
//   if (el.type === "brand" && auth.merchant_type === "brand") return true;
//   if (el.type === "allusers" && auth.merchant_type !== "superadmin")
//     return true;
//   if (el.type === "brandandsuperadmin" && auth.merchant_type !== "shop")
//     return true;
//   if (el.type === "shopandsuperadmin" && auth.merchant_type !== "brand")
//     return true;
//   if (el.type === "shopandbrand" && auth.user_type !== "admin") return true;
//   else return false;

// } else if (auth.user_type !== null) {
//     let status= false;
//     (auth.direct_permissions || []).forEach(perm => {
//       if(perm.permission_alias === el.permission_alias) status=true;
//     })
//     return status;
// }
// return true;
