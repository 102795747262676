// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
import { useEffect, useState } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import axiosInstance from 'src/axiosInstance';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import { useCategoriesApi } from 'src/context/CategoriesApi';
import CategoriesModal from '../Onboarding/CategoriesModal';
import TextField from '@mui/material/TextField';
import { Avatar } from '@mui/material';
import bus from 'src/bus';
import LiveSelect from 'react-select';
import { useApiKeys } from 'src/hooks/useApiKeys';
// sections
// mock
// ----------------------------------------------------------------------
export default function ProfilePage() {
  const categories = useCategoriesApi();
  const { dispatch, data } = useAuth();
  const bank_name =
    data.merchant_type === 'brand' ? data.bank_name : data.store_bank_name;

  const dataType =
    data.merchant_type === 'shop'
      ? data.products_sold
      : data.product_categories;

  const [show, setShow] = useState(true);
  const [loadingState, setLoadingState] = useState(true);
  const [catData, setCatData] = useState(
    dataType?.map((el) => el?.category_name)
  );
  const [profileInfo, setProfileInfo] = useState({ ...data });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const notifyError = (err) => toast.error(err);
  const notify = (msg) => toast.success(msg);
  const [url, setUrl] = useState(null);
  const [bank_info, setBankInfo] = useState({
    bank_name: '',
    bank_code: '',
    account_no: '',
    account_name: '',
  });
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: '',
  });
  console.log('this is the data: ', data);
  const [banks, setBanks] = useState([]);
  const apiKeys = useApiKeys();
  const [nameEnquiryLoading, setNameEnquiryLoading] = useState(false);
  const [amount, setAmountValue] = useState('');

  const handleValueChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setProfileInfo((prev) => {
      return {
        ...profileInfo,
        [name]: value,
      };
    });
  };
  const passProducts = (value) => {
    setCatData(value);
  };

  const fetchStates = async () => {
    if (!profileInfo.country) return;
    setStates([]);
    setLoadingState(true);

    try {
      const statesFetch = await fetch(
        `https://countriesnow.space/api/v0.1/countries/states`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            country: profileInfo.country,
          }),
        }
      );
      const statesJson = await statesFetch.json();
      const statesData = statesJson.data.states;

      setStates(() => {
        return statesData
          .filter((el) => el.name)
          .sort((a, b) => a.name.localeCompare(b.name));
      });
      setLoadingState(false);
    } catch (error) {
      console.error('Error fetching states:', error);
      setLoadingState(false);
    }
  };
  const fetchCountries = async () => {
    const countriesApi = await fetch('https://restcountries.com/v3.1/all');
    const countriesJson = await countriesApi.json();
    const countriesData = countriesJson.map((country) => ({
      code: country.cca2,
      name: country.name.common,
    }));
    setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)));
  };

  useEffect(() => {
    fetchCountries();
    fetchStates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo.country]);

  const handleEditProfile = () => {
    setIsPending(true);
    const endpoint = '/merchant/edit-profile';
    const payload = { ...profileInfo };
    console.log(profileInfo);
    // return;

    if (data.merchant_type === 'shop') {
      payload.store_bank_name = bank_info.bank_name;
      payload.store_account_name = bank_info.account_name;
      payload.store_account_number = bank_info.account_no;
    }
    if (data.merchant_type === 'brand') {
      payload.bank_name = bank_info.bank_name;
      payload.bank_account_name = bank_info.account_name;
      payload.bank_account_number = bank_info.account_no;
    }
    delete payload?.products_sold;
    delete payload?.product_categories;
    // payload.location = JSON.stringify(payload.store_address || payload.business_address) || '';
    if (payload.team_members) {
      delete payload.team_members;
    }

    let catId = categories
      .filter((category) => catData.includes(category.category_name))
      .map((category) => category._id);

    const formData = new FormData();
    for (let i = 0; i < catId.length; i++) {
      if (data.merchant_type === 'shop')
        formData.append('products_sold', catId[i]);
      else formData.append('product_categories', catId[i]);
    }

    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    if (url) {
      if (data.merchant_type === 'shop') {
        if (formData.has('store_logo')) formData.delete('store_logo');
        formData.append('store_logo', url);
      } else {
        if (formData.has('business_logo')) formData.delete('business_logo');
        formData.append('business_logo', url);
      }
    }

    axiosInstance
      .put(endpoint, formData)
      .then((res) => {
        if (res.status < 300) {
          if (res.data.data) {
            const authData = { ...res.data.data, token: data.token };
            dispatch({ type: 'LOGIN', payload: authData });
            localStorage.setItem('auth', JSON.stringify(authData));
          }
          setShow(true);
          notify(res.data.message);
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch {
          notifyError('Something went wrong, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const getUploadedFile = (e) => {
    if (e.target.files[0]) {
      if (data.merchant_type === 'brand')
        data.business_logo = URL.createObjectURL(e.target.files[0]);
      else data.store_logo = URL.createObjectURL(e.target.files[0]);

      setUrl(e.target.files[0]);
    }
  };

  const handleBankChange = (e, nameParam = null) => {
    const value = e.value;
    const name = e.label;
    setBankInfo((prev) => {
      return {
        ...prev,
        [nameParam]: name,
        bank_code: value,
      };
    });
  };
  const handleBankAccountChange = (key, value) => {
    setBankInfo((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    nameEnquiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank_info.account_no]);

  // useEffect(() => {
  //     const dataInfo = bank_info;
  //     if (data.merchant_type === 'brand') {
  //         dataInfo.account_no = data.bank_account_number
  //         dataInfo.account_name = data.bank_account_name
  //     } else {
  //         dataInfo.account_no = data.store_account_number
  //         dataInfo.account_name = data.store_account_name
  //     }
  //     if(banks.length)
  //     setBankInfo(dataInfo)
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [bank_info.bank_code])

  const fetchBanks = async () => {
    const banksApi = await fetch('https://api.paystack.co/bank');
    const banks = await banksApi.json();
    if (banks.status) {
      setBanks(banks.data);
      if (show) {
        for (let i = 0; i < banks.data.length; i++) {
          if (banks.data[i].name === bank_name) {
            console.log(banks.data[i].code);
            setSelectedOption({
              label: banks.data[i].name,
              value: banks.data[i].code,
            });
            setBankInfo({
              bank_name: banks.data[i].name,
              bank_code: banks.data[i].code,
              account_no:
                data.merchant_type === 'brand'
                  ? data.bank_account_number
                  : data.store_account_number,
              account_name:
                data.merchant_type === 'brand'
                  ? data.bank_account_name
                  : data.store_account_name,
            });
          }
        }
      }
    }
  };
  const nameEnquiry = async () => {
    if (bank_info?.account_no.length < 10 || bank_info?.account_no.length > 11)
      return;
    setNameEnquiryLoading(true);
    const accountNameApi = await fetch(
      `https://api.paystack.co/bank/resolve?account_number=${bank_info.account_no}&bank_code=${bank_info.bank_code}`,
      {
        headers: {
          Authorization: `Bearer ${apiKeys.PASTACK.SECRET_KEY}`,
        },
      }
    );
    const accountName = await accountNameApi.json();
    setNameEnquiryLoading(false);

    if (accountName.status) {
      const data = bank_info;
      data.account_name = accountName.data.account_name;
      setBankInfo(data);
    } else {
      const data = bank_info;
      data.account_name = '';
      setBankInfo(data);
      notifyError(accountName.message);
    }
  };

  return (
    <>
      <div className={`wrapper mt-md-0 mt-4 pt-2 px-2`}>
        <div className={`${styles.header} d-block d-md-none mb-4`}>
          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h4 className={`mb-1 mb-md-0 pb-0`}>Settings</h4>
            </div>
          </div>
        </div>
      </div>

      {/* BRAND */}
      {data.merchant_type === 'brand' && (
        <div className={`${styles.holder} px-2`}>
          {show && (
            <div className={` mb-5`} id="updated">
              <div className="content">
                <div className={`${styles.sub_header} ${styles.row} row`}>
                  <div className={`${styles.p_info} col-sm-8 px-0`}>
                    <h6>Personal info</h6>
                    <p className="">
                      Update your logo and personal details here
                    </p>
                  </div>
                  <div
                    className={`${styles.edit_btn} col-sm-4 px-0 text-md-right pb-1`}
                  >
                    <button
                      className="btn btn-[#af3585] ml-auto"
                      onClick={() => setShow(false)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Brand ID</p>
                  </div>
                  <div className="col-md-5 p-0 ">
                    <p>{data.brandID}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Business Logo</p>
                  </div>
                  <div className="col-md-5 p-0 ">
                    <div className="logo mb-3">
                      <Avatar
                        style={{
                          width: '36px',
                          borderRadius: '50%',
                        }}
                        src={data.business_logo || data.business_image}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Business name</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.business_name}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Business email address</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>
                      <i className="bi bi-envelope bi_envelope"></i>{' '}
                      {data.business_email}
                    </p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Phone Number</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.business_phone_number}</p>
                  </div>
                </div>

                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Business Address</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.business_address}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Country</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.country}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>State</p>
                  </div>
                  <div clas5="col-md-5 p-0">
                    <p>{data.state}</p>
                  </div>
                </div>
                {/* <div className={`${styles.row} row`}>
                                    <div className="col-md-3 p-0">
                                        <p>Bank Name</p>
                                    </div>
                                    <div clas5="col-md-5 p-0">
                                        <p>{data.bank_name}</p>
                                    </div>
                                </div>
                                <div className={`${styles.row} row`}>
                                    <div className="col-md-3 p-0">
                                        <p>Bank Acc No</p>
                                    </div>
                                    <div clas5="col-md-5 p-0">
                                        <p>{data.bank_account_number}</p>
                                    </div>
                                </div>
                                <div className={`${styles.row} row`}>
                                    <div className="col-md-3 p-0">
                                        <p>Bank Acc Name</p>
                                    </div>
                                    <div clas5="col-md-5 p-0">
                                        <p>{data.bank_account_name}</p>
                                    </div>
                                </div> */}
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p
                      style={{
                        padding: '0',
                        margin: '0',
                        lineHeight: '1',
                      }}
                    >
                      Business description
                    </p>
                    <small
                      className="text-muted p-0"
                      style={{
                        fontSize: '10px',
                        lineHeight: '0px',
                      }}
                    >
                      Write a short introduction
                    </small>
                  </div>
                  <div className="col-md-5 py-md-0 py-3 px-0">
                    <p>{data.business_description}.</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Products Categories</p>
                  </div>
                  <div className="col-md-5 p-0 pb-1">
                    <div
                      style={{
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      {catData.map((item, i) => {
                        return (
                          <div key={i} className={`${styles.product_selected}`}>
                            <div className=" d-flex justify-content-between">
                              <span>{item}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>LinkedIn Url</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.linkedIn_url}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Website </p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.business_website}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!show && (
            <div className="mb-5" id="editupdate">
              <div className="content">
                <form action="" className="pt-0 mt-0">
                  <div className={`${styles.sub_header} ${styles.row} row`}>
                    <div className={`${styles.p_info} col-sm-8 px-0`}>
                      <h6>Personal info</h6>
                      <p className="">
                        Update your logo and personal details here
                      </p>
                    </div>
                    <div
                      className={`${styles.edit_btn} col-sm-4 px-0 ml-auto pb-1`}
                    >
                      <div
                        className={`${styles.update_sect} d-flex ml-auto `}
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        <div>
                          <button
                            type="button"
                            onClick={() => setShow(true)}
                            style={{
                              background: '#FFFFFF',
                              color: '#344054',
                            }}
                            className={`${styles.cancel_btn} btn mx-3`}
                          >
                            Cancel
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => handleEditProfile()}
                            style={{
                              background: '#af3585',
                              color: '#ffffff',
                            }}
                            className="btn"
                          >
                            Save
                            {isPending && (
                              <CircularProgress
                                style={{ color: 'white' }}
                                size="1rem"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-2 p-0">
                      <p>Business Logo</p>
                    </div>
                    <div className="col-md-6 p-0">
                      <div
                        className={`${styles.row} row`}
                        style={{
                          borderTop: 'none',
                          padding: '0 !important',
                          margin: '0 !important',
                        }}
                      >
                        <div className="col-md-2 mx-0 px-0">
                          <div
                            className="logo my-lg-4 mb-3 mx-auto"
                            style={{
                              width: '50px',
                            }}
                          ></div>
                        </div>
                        <div className="col-md-10 mx-0 px-0">
                          <div className={`${styles.cstm_file} custom-file`}>
                            <div
                              className={`${styles.cstm_file_label} custom-file-label`}
                              htmlFor="customFile"
                              style={{
                                position: 'relative',
                                zIndex: '0',
                              }}
                            >
                              <div
                                className="mx-auto"
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  position: 'absolute',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  top: '50%',
                                  left: '40px',
                                  transform: 'translate(0%, -50%)',
                                }}
                              >
                                <Avatar
                                  style={{ width: '100%' }}
                                  src={data.business_logo}
                                  alt=""
                                />
                              </div>
                              <p className="p-0 m-0 mt-3 mb-2">
                                <i className="bi bi-cloud-arrow-up"></i>
                              </p>
                              <p>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    document
                                      .getElementById('customFileInput')
                                      .click();
                                  }}
                                >
                                  Click to upload
                                </span>
                                or drag and drop
                              </p>
                              <input
                                type="file"
                                onChange={getUploadedFile}
                                className="custom-file-input"
                                id="customFileInput"
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg, image/jpg"
                              />
                              <p>PNG, JPG or PDF</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Business name</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          type="text"
                          onChange={handleValueChange}
                          value={profileInfo.business_name}
                          className="form-control shadow-none"
                          id="bizName"
                          placeholder="Business Name"
                          aria-describedby="bizName"
                          name="business_name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Business email address</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          type="email"
                          onChange={handleValueChange}
                          name="business_email"
                          value={profileInfo.business_email}
                          className="form-control  shadow-none"
                          id="bizEmail"
                          placeholder="Business Email"
                          aria-describedby="bizEmail"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Business Phone Number</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          onChange={handleValueChange}
                          name="business_phone_number"
                          value={profileInfo.business_phone_number}
                          type="tel"
                          className="form-control shadow-none"
                          id="bizPhone"
                          aria-describedby="bizPhone"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Business Address</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          onChange={handleValueChange}
                          name="business_address"
                          value={profileInfo.business_address}
                          type="text"
                          className="form-control shadow-none"
                          id="bizAddress"
                          placeholder="Business Address"
                          aria-describedby="bizAddress"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Country</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <select
                          onChange={handleValueChange}
                          id={`${styles.country_list}`}
                          name="country"
                          value={profileInfo.country}
                        >
                          <option className="form-control shadow-none" value="">
                            Select Country
                          </option>
                          {countries.map((el, i) => {
                            return (
                              <option
                                key={i}
                                className="form-control shadow-none"
                                value={el.name}
                              >
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>State</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <select
                          onChange={handleValueChange}
                          id={`${styles.country_list}`}
                          name="state"
                          value={profileInfo.state}
                        >
                          <option className="form-control shadow-none" value="">
                            Select State
                          </option>
                          {states.map((el, i) => {
                            return (
                              <option
                                key={i}
                                className="form-control shadow-none"
                                value={el.name}
                              >
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>Bank</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <LiveSelect
                                                    defaultValue={selectedOption}
                                                    name="bank_name"
                                                    onChange={(e) => handleBankChange(e, "bank_name")}
                                                    options={banks.map((option) => ({
                                                        value: option.code,
                                                        label: option.name,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>
                                                Account Number
                                            </p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <TextField
                                                    required
                                                    id="cnumber"
                                                    type="number"
                                                    rows="1"
                                                    size="small"
                                                    fullWidth
                                                    value={bank_info.account_no}
                                                    name="account_no"
                                                    disabled={!bank_info.bank_code}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                    if (inputValue === '' || parseFloat(inputValue) >= 0) {
                                                        handleBankAccountChange('account_no', inputValue);
                                                    }
                                                    }}
                                                    inputProps={{
                                                        inputMode: 'decimal',
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                                            e.preventDefault();
                                                        }
                                                    }}

                                                />
                                                {
                                                    nameEnquiryLoading &&
                                                    <CircularProgress
                                                        style={{
                                                            color: '#af3585'
                                                        }}
                                                        size="0.8rem"
                                                    />
                                                }
                                                {
                                                    !nameEnquiryLoading &&
                                                    <small
                                                        style={{
                                                            fontSize: '12px',
                                                            marginTop: '5px'
                                                        }}
                                                    >
                                                        {
                                                            bank_info.account_name
                                                        }
                                                    </small>
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p
                        style={{
                          padding: '0',
                          margin: '0',
                          lineHeight: '1',
                        }}
                      >
                        Business description
                      </p>
                      <small
                        className="text-muted p-0"
                        style={{
                          fontSize: '10px',
                          lineHeight: '0px',
                        }}
                      >
                        Write a short introduction
                      </small>
                    </div>
                    <div className="col-md-5 py-md-0 py-3 px-0">
                      <div className={`${styles.form_group}`}>
                        <textarea
                          rows="4"
                          className="form-control shadow-none"
                          name="business_description"
                          value={profileInfo.business_description}
                          id="bizName"
                          onChange={handleValueChange}
                          placeholder="Write a brief description about your business..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Products Categories</p>
                    </div>
                    <div className="col-md-5 p-0 pb-2">
                      <div
                        className={`mb-3 inputs_n_label ${styles.push_notification_icon}`}
                      >
                        <TextField
                          required
                          id="businessDesc"
                          placeholder="Select Categories"
                          size="small"
                          disabled
                          fullWidth
                        />
                        <img
                          onClick={() =>
                            bus.emit('open-categories-onboarding', catData)
                          }
                          className="icon cursor_pointer"
                          src="/assets/icons/ShapeRight.svg"
                          alt=""
                        />
                      </div>
                      {catData.map((item, i) => {
                        return (
                          <div key={i} className={`${styles.product_selected}`}>
                            <div className=" d-flex justify-content-between">
                              <span>{item}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>LinkedIn Url</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          onChange={handleValueChange}
                          name="linkedIn_url"
                          value={profileInfo.linkedIn_url}
                          type="text"
                          className="form-control  shadow-none"
                          id="bizAddress"
                          placeholder="linked url"
                          aria-describedby="bizAddress"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Website </p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          onChange={handleValueChange}
                          name="linkedIn_url"
                          value={profileInfo.business_website}
                          type="text"
                          className="form-control  shadow-none"
                          id="bizAddress"
                          placeholder="linked url"
                          aria-describedby="bizAddress"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
      {/* SHOP */}
      {data.merchant_type === 'shop' && (
        <div className={`${styles.holder} px-2`}>
          {show && (
            <div className={` mb-5`} id="updated">
              <div className="content">
                <div className={`${styles.sub_header} ${styles.row} row`}>
                  <div className={`${styles.p_info} col-sm-8 px-0`}>
                    <h6>Personal info</h6>
                    <p className="">
                      Update your logo and personal details here
                    </p>
                  </div>
                  {/* Shop invited admin role should not be able to edit the profile */}
                  {data?.is_main_merchant && (
                    <div
                      className={`${styles.edit_btn} col-sm-4 px-0 text-md-right pb-1`}
                    >
                      <button
                        className="btn btn-[#af3585] text-red ml-auto"
                        onClick={() => setShow(false)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Store ID</p>
                  </div>
                  <div className="col-md-5 p-0 ">
                    <p>{data.shopID}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Store Logo</p>
                  </div>
                  <div className="col-md-5 p-0 ">
                    <div className="logo mb-3">
                      <Avatar
                        style={{ width: '36px', borderRadius: '50%' }}
                        src={data.store_logo}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Store name</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.store_name}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Store email address</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>
                      <i className="bi bi-envelope bi_envelope"></i>{' '}
                      {data.email}
                    </p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Store Phone Number</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.store_phone_number}</p>
                  </div>
                </div>

                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Store Address</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.store_address}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Country</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.country}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>State</p>
                  </div>
                  <div clas5="col-md-5 p-0">
                    <p>{data.state}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Bank Name</p>
                  </div>
                  <div clas5="col-md-5 p-0">
                    <p>{data.store_bank_name}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Bank Acc No</p>
                  </div>
                  <div clas5="col-md-5 p-0">
                    <p>{data.store_account_number}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Bank Acc Name</p>
                  </div>
                  <div clas5="col-md-5 p-0">
                    <p>{data.store_account_name}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p
                      style={{
                        padding: '0',
                        margin: '0',
                        lineHeight: '1',
                      }}
                    >
                      Store description
                    </p>
                    <small
                      className="text-muted p-0"
                      style={{
                        fontSize: '10px',
                        lineHeight: '0px',
                      }}
                    >
                      Write a short introduction
                    </small>
                  </div>
                  <div className="col-md-5 py-md-0 py-3 px-0">
                    <p>{data.store_description}</p>
                  </div>
                </div>
                {data.merchant_type === 'shop' && (
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Products Categories</p>
                    </div>
                    <div className="col-md-5 p-0 pb-1">
                      <div
                        style={{
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        {catData.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className={`${styles.product_selected}`}
                            >
                              <div className=" d-flex justify-content-between">
                                <span>{item}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>LinkedIn Url</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.linkedIn_url}</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p>Website</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p>{data.store_website}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!show && (
            <div className="mb-5" id="editupdate">
              <div className="content">
                <form action="" className="pt-0 mt-0">
                  <div className={`${styles.sub_header} ${styles.row} row`}>
                    <div className={`${styles.p_info} col-sm-8 px-0`}>
                      <h6>Personal info</h6>
                      <p className="">
                        Update your logo and personal details here
                      </p>
                    </div>
                    <div
                      className={`${styles.edit_btn} col-sm-4 px-0 ml-auto pb-1`}
                    >
                      <div
                        className={`${styles.update_sect} d-flex ml-auto `}
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        <div>
                          <button
                            type="button"
                            onClick={() => setShow(true)}
                            style={{ background: '#FFFFFF', color: '#344054' }}
                            className={`${styles.cancel_btn} btn mx-3`}
                          >
                            Cancel
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => handleEditProfile()}
                            style={{
                              background: '#af3585',
                              color: '#ffffff',
                            }}
                            className="btn"
                          >
                            Save
                            {isPending && (
                              <CircularProgress
                                style={{ color: 'white' }}
                                size="1rem"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-2 p-0">
                      <p>Store Logo</p>
                    </div>
                    <div className="col-md-6 p-0">
                      <div
                        className={`${styles.row} row`}
                        style={{
                          borderTop: 'none',
                          padding: '0 !important',
                          margin: '0 !important',
                        }}
                      >
                        <div className="col-md-2 mx-0 px-0">
                          <div
                            className="logo my-lg-4 mb-3 mx-auto"
                            style={{
                              width: 'fit-content',
                            }}
                          >
                            {/* <img style={{ width: '100px' }} src='/assets/icons/sideMenuFooter.svg' alt="" /> */}

                            {/* <img style={{ width: '50%' }} src={data.store_logo} alt="" /> */}
                          </div>
                        </div>
                        <div className="col-md-10 mx-0 px-0">
                          <div className={`${styles.cstm_file} custom-file`}>
                            <div
                              className={`${styles.cstm_file_label} custom-file-label`}
                              htmlFor="customFile"
                              style={{
                                position: 'relative',
                                zIndex: '0',
                              }}
                            >
                              <div
                                className="mx-auto"
                                style={{
                                  width: '50%',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '15%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              >
                                <img
                                  style={{ width: '30%' }}
                                  src={data.store_logo}
                                  alt=""
                                />
                              </div>
                              <p className="p-0 m-0 mt-3 mb-2">
                                <i className="bi bi-cloud-arrow-up"></i>
                              </p>
                              <p>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    document
                                      .getElementById('customFileInput')
                                      .click();
                                  }}
                                >
                                  Click to upload
                                </span>
                                or drag and drop
                              </p>
                              <input
                                type="file"
                                onChange={getUploadedFile}
                                className="custom-file-input"
                                id="customFileInput"
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg, image/jpg"
                              />
                              <p>PNG, JPG or PDF</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Store name</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          type="text"
                          onChange={handleValueChange}
                          value={profileInfo.store_name}
                          className="form-control shadow-none"
                          id="bizName"
                          placeholder="Store Name"
                          aria-describedby="bizName"
                          name="store_name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Store email address</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          type="email"
                          onChange={handleValueChange}
                          name="store_email"
                          value={profileInfo.email}
                          className="form-control shadow-none"
                          id="bizEmail"
                          placeholder="Business Email"
                          aria-describedby="bizEmail"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Store Phone Number</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          onChange={handleValueChange}
                          name="store_phone_number"
                          value={profileInfo.store_phone_number}
                          type="tel"
                          className="form-control shadow-none"
                          id="bizPhone"
                          aria-describedby="bizPhone"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Store Address</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          onChange={handleValueChange}
                          name="store_address"
                          value={profileInfo.store_address}
                          type="text"
                          className="form-control shadow-none"
                          id="bizAddress"
                          placeholder="Business Address"
                          aria-describedby="bizAddress"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Country</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <select
                          onChange={handleValueChange}
                          id={`${styles.country_list}`}
                          name="country"
                          value={profileInfo.country}
                        >
                          <option className="form-control shadow-none" value="">
                            Select Country
                          </option>
                          {countries.map((el, i) => {
                            return (
                              <option
                                key={i}
                                className="form-control shadow-none"
                                value={el.name}
                              >
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>State</p>
                    </div>
                    <div className="col-md-5 p-0">
                      {loadingState && (
                        <CircularProgress
                          style={{
                            color: '#af3585',
                          }}
                          size="0.8rem"
                        />
                      )}
                      <div className={`${styles.form_group}`}>
                        <select
                          onChange={handleValueChange}
                          id={`${styles.country_list}`}
                          name="state"
                          value={profileInfo.state}
                        >
                          <option className="form-control shadow-none" value="">
                            Select State
                          </option>
                          {states.map((el, i) => {
                            return (
                              <option
                                key={i}
                                className="form-control shadow-none"
                                value={el.name}
                              >
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Bank</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <LiveSelect
                          defaultValue={selectedOption}
                          name="bank_name"
                          onChange={(e) => handleBankChange(e, 'bank_name')}
                          options={banks.map((option) => ({
                            value: option.code,
                            label: option.name,
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Account Number</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <TextField
                          required
                          id="cnumber"
                          type="number"
                          rows="1"
                          size="small"
                          fullWidth
                          value={bank_info.account_no}
                          name="account_no"
                          disabled={!bank_info.bank_code}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              inputValue === '' ||
                              parseFloat(inputValue) >= 0
                            ) {
                              handleBankAccountChange('account_no', inputValue);
                            }
                          }}
                          inputProps={{
                            inputMode: 'decimal',
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === '-' ||
                              e.key === 'e' ||
                              e.key === '.'
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {nameEnquiryLoading && (
                          <CircularProgress
                            style={{
                              color: '#af3585',
                            }}
                            size="0.8rem"
                          />
                        )}

                        {!nameEnquiryLoading && (
                          <small
                            style={{
                              fontSize: '12px',
                              marginTop: '5px',
                            }}
                          >
                            {bank_info.account_name}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p
                        style={{
                          padding: '0',
                          margin: '0',
                          lineHeight: '1',
                        }}
                      >
                        Store description
                      </p>
                      <small
                        className="text-muted p-0"
                        style={{
                          fontSize: '10px',
                          lineHeight: '0px',
                        }}
                      >
                        Write a short introduction
                      </small>
                    </div>
                    <div className="col-md-5 py-md-0 py-3 px-0">
                      <div className={`${styles.form_group}`}>
                        <textarea
                          onChange={handleValueChange}
                          rows="4"
                          className="form-control  shadow-none"
                          name="store_description"
                          value={profileInfo.store_description}
                          id="bizName"
                          placeholder="Write a brief description about your business..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {data.merchant_type === 'shop' && (
                    <div className={`${styles.row} row`}>
                      <div className="col-md-3 p-0">
                        <p>Products Categories</p>
                      </div>
                      <div className="col-md-5 p-0 pb-2">
                        <div
                          className={`mb-3 inputs_n_label ${styles.push_notification_icon}`}
                        >
                          <TextField
                            required
                            id="businessDesc"
                            placeholder="Select Categories"
                            size="small"
                            disabled
                            fullWidth
                          />
                          <img
                            onClick={() =>
                              bus.emit('open-categories-onboarding', catData)
                            }
                            className="icon cursor_pointer"
                            src="/assets/icons/ShapeRight.svg"
                            alt=""
                          />
                        </div>

                        {catData.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className={`${styles.product_selected}`}
                            >
                              <div className=" d-flex justify-content-between">
                                <span>{item}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>LinkedIn Url</p>
                    </div>
                    <div className="col-md-5 p-0">
                      <div className={`${styles.form_group}`}>
                        <input
                          onChange={handleValueChange}
                          name="linkedIn_url"
                          value={profileInfo.linkedIn_url}
                          type="text"
                          className="form-control  shadow-none"
                          id="bizAddress"
                          placeholder="linked url"
                          aria-describedby="bizAddress"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className="col-md-3 p-0">
                      <p>Website </p>
                    </div>
                    <div className="col-md-5 p-0">
                      <p>{data.store_website}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
      {/* ADMIN */}
      {data.user_type === 'admin' && (
        <div className={`${styles.holder} px-2`}>
          {show && (
            <div className={` mb-5`} id="updated">
              <div className="content">
                {/* <div className={`${styles.sub_header} ${styles.row} row`}>
                                    <div className={`${styles.p_info} col-sm-8 px-0`}>
                                        <h6>Personal info</h6>
                                        <p className="">Update your logo and personal details here</p>
                                    </div>
                                    <div className={`${styles.edit_btn} col-sm-4 px-0 text-md-right pb-1`}>
                                        <button className="btn btn-[#af3585] text-white ml-auto" onClick={() => setShow(false)}>Edit</button>
                                    </div>
                                </div> */}
                <div className={`${styles.row} row`}>
                  <div className="col-md-3 p-0">
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: '900',
                        color: '#000000',
                      }}
                    >
                      Admin Info
                    </p>
                  </div>
                  <div className="col-md-5 p-0 ">
                    <div className="logo mb-3">
                      <Avatar
                        style={{ width: '36px', borderRadius: '50%' }}
                        src={data.store_logo}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className={`col-md-3 p-0`}>
                    <p className={` ${styles.admin_text}`}>Admin name</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p className={` ${styles.admin_text}`}>{data.first_name}</p>
                  </div>
                </div>

                <div className={`${styles.row} row`}>
                  <div className={`col-md-3 p-0`}>
                    <p className={` ${styles.admin_text}`}>Email Address</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p className={` ${styles.admin_text}`}>
                      <i className="bi bi-envelope bi_envelope"></i> &nbsp;
                      {data.email}
                    </p>
                  </div>
                </div>

                <div className={`${styles.row} row`}>
                  <div className={`col-md-3 p-0`}>
                    <p className={` ${styles.admin_text}`}>Phone Number</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p className={` ${styles.admin_text}`}>
                      {data.phone_number || '---'}
                    </p>
                  </div>
                </div>

                <div className={`${styles.row} row`}>
                  <div className={`col-md-3 p-0`}>
                    <p className={` ${styles.admin_text}`}>Profile Image</p>
                  </div>
                  <div className="col-md-5 p-0">
                    {/* <p className={` ${styles.admin_text}`}>
                                            Image
                                        </p> */}
                    <Avatar
                      src={data.store_logo}
                      height={'60px'}
                      className="mb-3"
                      width={'60px'}
                      alt="p_photo"
                    />
                  </div>
                </div>
                <div className={`${styles.row} row`}>
                  <div className={`col-md-3 p-0`}>
                    <p className={` ${styles.admin_text}`}>Admin Role</p>
                  </div>
                  <div className="col-md-5 p-0">
                    <p className={` ${styles.admin_text}`}>Super Admin</p>
                  </div>
                </div>
                <div className={`${styles.row} row`}> </div>
              </div>
            </div>
          )}

          {/* {!show &&
                        <div className="mb-5" id="editupdate">
                            <div className="content">
                                <form action="" className="pt-0 mt-0">
                                    <div className={`${styles.sub_header} ${styles.row} row`}>
                                        <div className={`${styles.p_info} col-sm-8 px-0`}>
                                            <h6>Personal info</h6>
                                            <p className="">Update your logo and personal details here</p>
                                        </div>
                                        <div className={`${styles.edit_btn} col-sm-4 px-0 ml-auto pb-1`}>
                                            <div className={`${styles.update_sect} d-flex ml-auto `} style={{
                                                width: 'fit-content'
                                            }}>
                                                <div>
                                                    <button type="button" onClick={() => setShow(true)} style={{ background: '#FFFFFF', color: '#344054' }} className={`${styles.cancel_btn} btn mx-3`}>Cancel</button>
                                                </div>
                                                <div>
                                                    <button type="button" onClick={() => setShow(true)} style={{ background: '#af3585', color: '#ffffff' }} className="btn">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-2 p-0">
                                            <p>Store Logo</p>
                                        </div>
                                        <div className="col-md-6 p-0">
                                            <div className={`${styles.row} row`} style={{
                                                borderTop: 'none',
                                                padding: '0 !important',
                                                margin: '0 !important'
                                            }}>
                                                <div className="col-md-2 mx-0 px-0">
                                                    <div className="logo my-lg-4 mb-3 mx-auto" style={{
                                                        width: 'fit-content',
                                                    }}>
                                                        <Avatar style={{ width: '50%' }} src={data.store_logo} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-10 mx-0 px-0">
                                                    <div className={`${styles.cstm_file} custom-file c`}>
                                                        <input type="file" className="custom-file-input shadow-none" id="customFile" />
                                                        <div className={`${styles.cstm_file_label} custom-file-label`} htmlFor="customFile">
                                                            <p className="p-0 m-0 mt-3 mb-2"><i className="bi bi-cloud-arrow-up"></i></p>
                                                            <p><span>Click to upload</span> or drag and drop</p>
                                                            <p>PNG, JPG or PDF</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>Business name</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <input type="text" onChange={handleValueChange} value={profileInfo.store_name} className="form-control  shadow-none" id="bizName" placeholder="Business Name" aria-describedby="bizName" name="store_name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>Store email address</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <input type="email" onChange={handleValueChange} name="store_email" value={profileInfo.store_email} className="form-control  shadow-none" id="bizEmail" placeholder="Business Email" aria-describedby="bizEmail" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>Store Phone Number</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <input onChange={handleValueChange} name="store_phone_number" value={profileInfo.store_phone_number} type="tel" className="form-control  shadow-none" id="bizPhone" placeholder="+234 800 000 0000" aria-describedby="bizPhone" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>Store Address</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <input onChange={handleValueChange} name="store_address" value={profileInfo.store_address} type="text" className="form-control  shadow-none" id="bizAddress" placeholder="Business Address" aria-describedby="bizAddress" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>Country</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <select onChange={handleValueChange} id={`${styles.country_list}`} name="country" value={profileInfo.country} >
                                                    <option className="form-control  shadow-none" value="">Select Country</option>
                                                    {countries.map((el, i) => {
                                                        return (
                                                            <option key={i} className="form-control  shadow-none" value={el.name}>{el.name}</option>

                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>State</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <select onChange={handleValueChange} id={`${styles.country_list}`} name="state" value={profileInfo.state}>
                                                    <option className="form-control  shadow-none" value="">Select State</option>
                                                    {states.map((el, i) => {
                                                        return (
                                                            <option key={i} className="form-control  shadow-none" value={el.name}>{el.name}</option>

                                                        )
                                                    })}
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p style={{
                                                padding: '0',
                                                margin: '0',
                                                lineHeight: '1'
                                            }}>Store description</p>
                                            <small className="text-muted p-0" style={{
                                                fontSize: '10px',
                                                lineHeight: '0px'
                                            }}>Write a short introduction</small>
                                        </div>
                                        <div className="col-md-5 py-md-0 py-3 px-0">
                                            <div className={`${styles.form_group}`}>
                                                <textarea rows="4" className="form-control  shadow-none" name="store_description" value={profileInfo.store_description} id="bizName" placeholder="Write a brief description about your business..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        data.merchant_type === 'shop' &&
                                        <div className={`${styles.row} row`}>
                                            <div className="col-md-3 p-0">
                                                <p>Products Sold</p>
                                            </div>
                                            <div className="col-md-5 p-0 pb-2">
                                                <select onChange={handleValueChange} className={`${styles.country_list} mb-2`} >
                                                    <option className="form-control  shadow-none" value="">Select Products</option>
                                                    <option className="form-control  shadow-none" value="">Toiletries</option>
                                                    <option className="form-control  shadow-none" value="">Machinery</option>
                                                    <option className="form-control  shadow-none" value="">Biscuit</option>
                                                    <option className="form-control  shadow-none" value="">Paper</option>
                                                </select>
                                                {data?.products_sold.map((item, i) => {
                                                    return (
                                                        <div key={i} className={`${styles.product_selected}`}>
                                                            <div className=" d-flex justify-content-between">
                                                                <span >{item}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    }
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>LinkedIn Url</p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <div className={`${styles.form_group}`}>
                                                <input onChange={handleValueChange} name="linkedIn_url" value={profileInfo.linkedIn_url} type="text" className="form-control  shadow-none" id="bizAddress" placeholder="linked url" aria-describedby="bizAddress" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.row} row`}>
                                        <div className="col-md-3 p-0">
                                            <p>Website </p>
                                        </div>
                                        <div className="col-md-5 p-0">
                                            <p>{data.store_website}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    } */}
        </div>
      )}
      <CategoriesModal productsData={passProducts} />
    </>
  );
}
