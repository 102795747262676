

// ----------------------------------------------------------------------
import styles from "./BrandOwner.module.css"
import { useEffect,useState } from "react"
import LiveLocation from "src/pages/LiveLocation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BallTriangle } from 'react-loader-spinner';
import StarRating from "src/context/Ratings";
import LiveSelect from 'react-select';
import {
  Avatar,
} from '@mui/material';
// sections
// mock
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import { useCategories } from "src/hooks/useCategories";
import axiosInstance from "src/axiosInstance";
import moment from "moment";
import toast from 'react-hot-toast';
import bus from 'src/bus';


// ptvp=PAGE TO VIEW (PRODUCT)

export default function DashboardAppPage() {
  const categories = useCategories();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dataFilter, setDataFilter] = useState('today');
  const [reviewData, setReviewData] = useState([
    {
      users: [],
    }
  ]);
  const [isPending, setIsPending] = useState(false);
  const [searchvalue, setSearchValue] = useState('');
  const notifyError = (err) => toast.error(err);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedOption] = useState(null);
  const [locationProcess, setProcessLocation] = useState('');

  const searchFilter = (e) => {
    setSearchValue(e.target.value)
  }

  const handleCatChange = (event) => {
    // console.log(event.value);
    setSelectedCategory(event.value);

  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const processLocation = (e) => {
    console.log(e);
    setProcessLocation(e.value)
  }

  useLayoutEffect(() => () => {
    localStorage.removeItem('ptvp');

    // Your code here.
  }, []);
  

const fetchData = () => {
  setIsPending(true)
  axiosInstance.get(`/brand/shoppers-review/all?search=${searchvalue}`, {
    params: {
      dateFrom: startDate,
      dateTo: endDate,
      location: locationProcess,
      category: selectedCategory
    }
  })
  .then(res => {
    if(res.status < 300) {
      setReviewData(res.data.data.data)
    }
  }).catch(err => {
    try{
      notifyError(err.response.message)
    }
    catch{
      notifyError('Something went wrong, try again later')
    }
  }).finally(() => setIsPending(false))
}

useEffect(() => {
  bus.on('dataFilter', (el) => {
    // console.log(el);
    setDataFilter(el)
  })
  fetchData()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchvalue, dataFilter])


  return (
    <>
      <div className="wrapper mt-4 px-2">
        <div className="header d-block d-md-none mb-4">
          <div className={`${styles.product_actions} d-md-flex justify-content-between`}>
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Shoppers</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Monitor shoppers transactions, feedback and reviews
              </p>
            </div>
          </div>
        </div>
        <div className="row px-3">
          <div className={`${styles.hold_input}   col-md-3 col-6  pl-0 my-3`}>
            <img src="/assets/icons/search.svg" alt="" />
            <input 
              type="text" 
              value={searchvalue} 
              onChange={searchFilter} 
              placeholder="Search" 
              style={{ 
                textIndent: '25px', 
                width: '100%' 
              }} 
              className=" form-control" 
            />

          </div>
          <div className="col-md-9 col-6  text-right">
            <button 
              className="btn border" 
              onClick={() => setModalOpen(true)}
              style={{
                backgroundColor: '#fff',
                border: '1px solid #D0D5DD',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                borderRadius: '4px'
              }}>
              <img 
                className="" 
                style={{ 
                  display: 'inline', 
                  width: '28%' 
                }} 
                src="/assets/icons/filterlines.svg" 
                alt="" 
              />
              &nbsp;
              <small className="d-none d-md-inline-block">
                Filters
              </small>
            </button>
          </div>
        </div>
        <div className="card mt-2">
          <div className="card-body">
            <div id="accordion">
              {(!isPending && reviewData.length > 0) && reviewData?.map((el,i) => (
                <div key={el?._id} className={`card mb-2`}>
                  <div className={`card-body ${styles.accordion_header}`}>
                    <div style={{ cursor: 'pointer' }} data-toggle="collapse" href={`#collapseOne${i}`}>
                      <div className={`d-flex justify-content-between`}>
                        <div className=" " style={{ width: '50%' }}>
                          <div className={`d-flex ${styles.review_contents}`}>
                            <div style={{
                            }}>
                              <img style={{
                                width: '51px',
                              }} src={el?.product_info?.product_image} alt="" />
                            </div>
                            &nbsp;
                            <div style={{
                            }}>
                              <p style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                                marginBottom: '5px'
                              }}>
                                <strong>
                                  {el?.product_info?.product_name}

                                </strong>
                              </p>
                              <div className="d-flex">
                                <small>
                                  <StarRating rating={el?.average_rating} />
                                </small>
                                  {el?.average_rating !== undefined ? (
                                  <small
                                    style={{
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      fontSize: '13px',
                                      marginTop: '4px',
                                      marginLeft: '4px',
                                      color: '#FFA500'
                                    }}
                                  >
                                    {el.average_rating.toFixed(1)}
                                  </small>
                                ) : null}
                              </div>
                              <p style={{
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '150%',
                                color: '#6B7280'
                              }}>
                                {el?.total_reviews} {el?.total_reviews > 1 ? 'Reviews' : 'Review'} 
                              </p>
                            </div>
                          </div>
                        </div>
                        <div  >
                          <img src="/assets/icons/accordionDown.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {el?.users?.map((val,e) => (
                    <div key={e} id={`collapseOne${i}`} className="collapse " data-parent="#accordion">
                      <div className="card-body border-top">
                        <div className={`${styles.review_content_header}`}>
                          <Avatar className={`${styles.avatar}`} src={val?.image} alt="" />
                          <div className={`${styles.contact_info}`}>
                            <p style={{
                              marginBottom: '0',
                              fontSize: '14px',
                            }}>{val?.name}</p>
                            <p style={{
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '150%',
                              color: ' #6B7280',
                              marginBottom: '0'

                            }}>
                              {val?.email}
                            </p>
                            <div className="d-flex">
                              <small>
                                <StarRating rating={val?.rating}/>
                              </small>
                              {val?.rating !== undefined ? (
                                <small style={{
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  marginTop: '4px',
                                  marginLeft: '4px',
                                  color: '#FFA500'
                                }}>
                                  {val?.rating.toFixed(1)}
                                </small>
                              ) : null}
                            </div>
                            <p className="mb-0  " style={{
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '150%',
                              color: ' #6B7280',
                              marginTop: '0',
                              textAlign: 'right'

                            }}>
                              {moment(val?.date).format('DD/MM/YYYY')}

                            </p>
                            <div>
                              <p className={`my-3 ${styles.review_content_body}`}>
                                {val?.about_it}

                              </p>

                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div className="d-flex justify-content-center">
                {isPending &&
                  <BallTriangle
                    height={50}
                    width={55}
                    radius={5}
                    color="#53138D"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />

                }

              </div>
              {
                (!reviewData.length && !isPending) &&

                <div className="alert alert-[#53138D] text-center">
                  <h4>No record found</h4>
                </div>

              }


            </div>
          </div>
        </div>
      </div>
      <GlobalModal
        open={modalOpen}
        onClose={handleClose}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='750px !important'
        overflow='auto'
        bgcolor='#FFFFFF'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        backgroundColor='#FFFFFF'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span 
              onClick={handleClose} 
              style={{ 
                cursor: 'pointer' 
              }}
            >
              <img 
                src="/assets/icons/x.svg" 
                alt="" 
              />
            </span>
          </div>
          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <label htmlFor="price">Date </label>
                <div className="mb-3 d-flex">
                  <div className="" style={{ width: '48%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Start"
                        required
                      />
                    </div>
                  </div>
                  <div 
                    className="px-1 d-flex align-items-center" 
                    style={{ 
                      height: '40px' 
                    }}
                  >
                    {/* sss */}
                    <img 
                      src="/assets/icons/line.svg" 
                      style={{ 
                        width: '14px' 
                      }} 
                      alt="" 
                    />
                  </div>
                  <div className="" style={{ width: '48%' }}>
                    <div className="mb-3">
                      <DatePicker
                        showIcon
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="End"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <LiveLocation 
                    label='Location' 
                    passData={processLocation} 
                  />
                </div>
              </div>
              <div className="col-md-6 mb-1">
                {/* <label htmlFor="product">Product</label>
                <div>
                  {productLists.map((el, i) => (
                    <div className="categories mr-2 mb-3" key={i}>
                      <input 
                        type="checkbox" 
                        value={el.name} 
                        name="" 
                        className="myCheckbox" 
                      />
                      &nbsp;
                      {el.name}
                    </div>
                  ))}
                </div> */}
                <div>
                  <label htmlFor="cat">
                    Category
                  </label>
                  <div>
                  <LiveSelect
                    defaultValue={selectedOption}
                    onChange={handleCatChange}
                    options={categories?.map((option) => ({
                      value: option.name,
                      label: option.name,
                    }))}
                  />
                    {/* {categories.map((el, i) => (
                      <div 
                        className="categories mr-2 mb-3" 
                        key={i}
                      >
                        <input 
                          type="checkbox" 
                          value={el.name} 
                          checked={console.log('hgj')}
                          name="" 
                          className="myCheckbox" 
                        />
                        &nbsp;
                        {el.name}
                      </div>
                    ))} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <button 
                type="button" 
                onClick={() => fetchData()}
                className={`${styles.modal_btn} btn btn-block`}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </GlobalModal>
    </>
  );
}
