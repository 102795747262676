import styles from "./Order.module.css"
import { useEffect, useState } from "react"
import {
    Card,
    Avatar
} from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import Modal from '@mui/material/Modal';
// sections
// mock
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import axiosInstance from "src/axiosInstance";
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { forwardRef } from "react";
import Slide from '@mui/material/Slide';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import bus from 'src/bus';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    overflow: 'auto',
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'details', label: 'Product Details', alignRight: false },
    { id: 'quantity', label: 'Quantity', alignRight: false },
    { id: 'unitPrice', label: 'Unit price', alignRight: false },
    { id: 'totalPrice', label: 'Total price', alignRight: false },

];

// ----------------------------------------------------------------------



export default function OrderPage({ onChange, categoryId }) {

    const navigate = useNavigate();
    const [modal_desc] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [showCheck, setShowCheck] = useState(false);
    const [refundIds, setRefundIds] = useState([]);
    const [loadingState, setLoadingState] = useState(false)
    const [handleOpenDialog, setHandleOpenDialog] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [dashboardOrderValue] = useState(JSON.parse(localStorage.getItem('orders_info')) || {})
    const [newValue] = useState(JSON.parse(localStorage.getItem('order_details_info')) || {})
    const [endpoint, setEndpoint] = useState(false);
    const [orderData, setOrderData] = useState({
        subtotal: 0,
        product_items: []
    })
    const [isPending, setIsPending] = useState(false)
    // const [orderInfo, setOrderInfo] = useState(JSON.parse(localStorage.getItem('order_info')))

    const notifyError = (err) => toast.error(err);

    function handleClick(event) {
        event.preventDefault();
        localStorage.removeItem('order_details_info')
        navigate('/app/orders')
    }
    const [modalTitle] = useState('');

    useEffect(() => {
        console.log(newValue._id);
        bus.on('getOrders', (val) => {
            if (val) {
                console.log(val);
                setEndpoint(true)
            }
        })

        // console.log(dashboardOrderValue?._id._id);
    }, [])

    const fetchData = () => {
        setIsPending(true);
        const endpoint = '/brand/order/';
        let endpointUrl = '';
      
        if (newValue) {
          endpointUrl = endpoint + newValue._id;
        }
      
        if (dashboardOrderValue && dashboardOrderValue._id) {
          endpointUrl = endpoint + dashboardOrderValue._id._id;
        }
      
        axiosInstance.get(endpointUrl)
          .then(res => {
            if (res.data.data.length) {
              setOrderData(res.data.data[0]);
            }
          }).catch(err => {
            try {
              notifyError(err.response.data.message);
            } catch (error) {
              notifyError("Unable to fetch orders, please try again later");
            }
          }).finally(() => setIsPending(false));
      };
      

    const refund = () => {
        setLoadingState(true);
        const order_id = newValue?._id || dashboardOrderValue._id._id;

        const requestData = {
            product_ids: refundIds,
        };

        axiosInstance.post(`/brand/order/refund/${order_id}`, requestData)
            .then(res => {
                if (res.data.success) {
                    setHandleOpenDialog(false)
                    setOpenDialog(true)
                }
            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Something went wrong , please try again later");

                }
            }).finally(() => setLoadingState(false))
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {

        setTotalAmount(
            orderData?.product_items
                .filter((el, i) => refundIds
                    .includes(el.product_id))
                .map(el => Number(el.total_price))
                .reduce((ac, val) => ac + val, 0)
            || 0
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refundIds])

    const handleCheck = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        const refundIdsArray = [...refundIds];

        if (checked) {
            if (!refundIdsArray.includes(value))
                refundIdsArray.push(value)
        } else {
            const index = refundIdsArray.indexOf(value);
            refundIdsArray.splice(index, 1)
        }

        setRefundIds(refundIdsArray)
    }


    return (
        <>
            <div className={` mt-4 mt-md-0 pt-2 px-2`}>
                <button onClick={handleClick} className="btn pl-0">
                    {/* <img src="/assets/icons/Steplink.svg" alt="" /> &nbsp; */}
                    <img 
                        src="/assets/icons/Chevronleft.svg" 
                        alt="" 
                        style={{
                            border: "1px solid lightgrey",
                            padding: "10px",
                            borderRadius: "5px"
                        }}
                    /> &nbsp;
                    Orders
                </button>
                <div className={`${styles.header} mt-1`}>
                    <div className="row">
                        <div className="col-md-9">
                            <img src={orderData.shop_logo || dashboardOrderValue?.store_info?.store_logo} height={'70px'} width={'70px'} className="float-md-left mr-3" alt="" />
                            <div >
                                <h1 style={{ display: 'inline-block' }}>
                                    {orderData?.shop_name}
                                </h1>
                                {
                                    <small>
                                        ({orderData?.category})
                                    </small>
                                }
                                <p className="mb-1">
                                    <small>
                                        <img className="d-inline-block mr-1" src="/assets/icons/mail2.svg" alt="" />
                                        {orderData?.shop_email ? orderData?.shop_email : '---'}
                                    </small>
                                    &nbsp;&nbsp;&nbsp;
                                    <small>
                                        <img className="d-inline-block mr-1" src="/assets/icons/phone.svg" alt="" />
                                        {orderData?.shop_phone_number ? orderData?.shop_phone_number : '---'}
                                    </small>
                                </p>
                                <p>
                                    <small className="ml-md-3">
                                        <img className="d-inline-block mr-1 ml-md-5 pl-md-4" src="/assets/icons/map.svg" alt="" />

                                        {newValue?.customer_location || newValue?.location?.address}
                                    </small>
                                </p>
                            </div>
                        </div>
                        {
                            orderData.status === 'pending' &&
                            <div className="col-md-3 text-right" >
                                {
                                    !showCheck &&
                                    <button onClick={() => setShowCheck(true)} className={`modal_btn btn `} >
                                        Refund
                                    </button>

                                }
                                {
                                    showCheck &&
                                    <button onClick={() => {
                                        setShowCheck(false); setRefundIds([])
                                    }} className={`modal_btn btn `} >
                                        Cancel

                                    </button>

                                }
                            </div>
                        }
                    </div>
                </div>
                <Card style={{ marginTop: '50px', borderRadius: '10px', overflow: 'hidden' }} className=' ss p-0' >

                    {/* <Scrollbar style={{ overflowX: 'hidden' }}> */}
                    <div className="row" style={{ height: '60vh', overflow: 'hidden' }}>
                        <div className="col-md-8 mb-1 h-100" style={{ overflowX: 'hidden' }}>
                            <div>
                                <div className='pl-4 pr-3 py-3 pb-0 mb-0' style={{ position: 'sticky', top: '0', zIndex: '99', background: 'white' }}>
                                    <h3 className="font-weight-bold">
                                        Orders
                                    </h3>
                                    <small style={{ fontWeight: '200', fontSize: '12px' }}>
                                        Order Number: {orderData?.order_number}
                                    </small>
                                    <small style={{ display: 'inline-block', float: 'right' }}>
                                        {orderData?.product_items?.length ? Number(orderData?.product_items?.length).toLocaleString() : 0}
                                        {Number(orderData?.product_items?.length).toLocaleString() > 1 ? " items" : " item"}
                                    </small>

                                </div>
                                <Scrollbar style={{ overflowX: 'hidden' }}>
                                    <div className={`table-responsive-sm mt-0 p-3`} style={{
                                        paddingTop: '0 !important',
                                    }}>
                                        <table className={`${styles.table} mt-0`}>
                                            <thead className={`${styles.thead}`}>


                                                <tr>
                                                    {TABLE_HEAD.map((el, i) => {
                                                        return (
                                                            <th key={i} className="text-muted">{el.label}
                                                            </th>
                                                        )
                                                    })}

                                                </tr>

                                            </thead>
                                            <tbody>
                                                {
                                                    (!isPending && orderData?.product_items?.length > 0) && orderData?.product_items.map((el, i) => (
                                                        <tr key={el?._id} className={`${styles.tr}`}>
                                                            <td>
                                                                <div className=" ">
                                                                    <div className=" ">
                                                                        {
                                                                            showCheck &&
                                                                            <div className="d-inline-block" >
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={refundIds.includes(el.product_id)}
                                                                                            onChange={handleCheck}
                                                                                            value={el.product_id}

                                                                                        />
                                                                                    }
                                                                                />

                                                                            </div>

                                                                        }
                                                                        <img
                                                                            src={el?.product_image}
                                                                            alt=""
                                                                            style={{
                                                                                width: '20%',
                                                                            }}
                                                                        />
                                                                        &nbsp;
                                                                        &nbsp;
                                                                        <div style={{
                                                                            display: 'inline-block'
                                                                        }}>
                                                                            <p className="mb-0" style={{ fontSize: '14px' }}>
                                                                                <span>
                                                                                    {el?.product_name}
                                                                                </span>


                                                                            </p>
                                                                            <span style={{ fontSize: '10px' }}>
                                                                                50kg


                                                                            </span>

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </td>
                                                            <td>
                                                                {Number(el?.quantity).toLocaleString()}
                                                            </td>
                                                            <td>
                                                                ₦ {Number(el?.unit_price).toLocaleString()}
                                                            </td>
                                                            <td>
                                                                ₦ {Number(el?.total_price).toLocaleString()}


                                                            </td>

                                                        </tr>
                                                    ))
                                                }

                                            </tbody>

                                        </table>

                                    </div>
                                    <div className="d-flex justify-content-center">
                                        {isPending &&
                                            <BallTriangle

                                                height={50}
                                                width={55}
                                                radius={5}
                                                color="#53138D"
                                                ariaLabel="ball-triangle-loading"
                                                wrapperClass={{}}
                                                wrapperStyle=""
                                                visible={true}
                                            />

                                        }
                                    </div>
                                    {
                                        (!orderData?.product_items?.length && !isPending) &&

                                        <div className="alert alert-[#53138D] text-center">
                                            <h4>No record found</h4>
                                        </div>
                                    }

                                </Scrollbar>
                            </div>
                        </div>
                        <div className="col-md-4 h-100" style={{ background: '#E5E7EB', overflow: 'hidden' }}>
                            <div className=" pt-4  px-2 border-bottom" style={{ background: 'inherit !important' }}>
                                <h4 className="font-weight-bold">Summary</h4>


                            </div>
                            <div className="border-bottom-white mt-4 px-2">
                                <div className="d-flex justify-content-between">
                                    <p style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        lineHeight: ' 150%',
                                        color: '#1F2937'
                                    }}>
                                        Total Order
                                    </p>
                                    <p style={{
                                        fontWeight: '400',
                                        fontSize: '13px',
                                        lineHeight: ' 150%',
                                        color: '##374151'
                                    }}>
                                        ₦ {Number(orderData?.subtotal).toLocaleString()}
                                    </p>

                                </div>


                            </div>

                            <div className={`${styles.sub_total} py-4 px-2`} style={{
                                position: 'absolute',
                                width: '89%',
                                bottom: 0,

                            }}>
                                {
                                    showCheck &&
                                    <div>
                                        <button
                                            disabled={!refundIds.length}
                                            onClick={() => {
                                                setHandleOpenDialog(true);
                                            }} className={`modal_btn btn btn-block`} >
                                            Proceed

                                        </button>
                                    </div>
                                }
                                <div className="d-flex mt-3 pt-4 justify-content-between" style={{
                                    borderTop: '2px solid #FFFFFF '
                                }}>
                                    <p style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        lineHeight: ' 150%',
                                        color: '#1F2937'
                                    }}>
                                        Total
                                    </p>
                                    <p className="font-weight-bold" style={{
                                        fontSize: '20px',
                                        lineHeight: ' 150%',
                                        color: '##374151'
                                    }}>
                                        ₦ {Number(orderData?.subtotal).toLocaleString()}
                                    </p>

                                </div>


                            </div>


                        </div>
                    </div>

                </Card>


            </div >
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableEnforceFocus
            >
                <Fade in={openDialog}>
                    <Box className={`mobile_modal_size modal_bg`} sx={style1}>
                        <div className={`text-center add_to_cart_content`}>
                            <img src="/assets/icons/circleCheck.svg" alt="" />
                            <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>{modalTitle}</h5>
                            <p className='text-center' style={{ textAlign: 'center !important' }}>
                                {modal_desc}

                            </p>
                            <button onClick={() => navigate('/app/orders')} className={`modal_btn hover btn btn-block`}>
                                Go to Orders
                            </button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                open={handleOpenDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setHandleOpenDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="card border-0" >
                    <div className="card-body border-0 ">
                        <div className={`${styles.customized_row}`}>
                            <div className="">
                                <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

                            </div>
                            <div >
                                <p style={{ fontWeight: 'bold', fontSize: '19px' }}>
                                    Total amount to be refunded {Number(totalAmount).toLocaleString()}

                                </p>
                                <p style={{
                                    fontSize: '14px',
                                    color: '#6B7280',
                                }}>
                                    Continue with this operation ?
                                </p>

                            </div>

                        </div>

                        <DialogActions>
                            <Button className={`${styles.cancel_delete}`} onClick={() => setHandleOpenDialog(false)}>Cancel</Button>
                            <Button className={`${styles.delete_product}`} onClick={refund}>
                                Yes   {loadingState &&
                                    <CircularProgress style={{ color: '#53138D' }} size="1rem" />

                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
