

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import {
  Avatar,
  Card,
  CardHeader,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import DiscountModal from "../Modals/DiscountModal";

import * as React from 'react';
import bus from 'src/bus';
import Voucher from "../Modals/Voucher.js";
// sections
// mock
import Button from '@mui/material/Button';
import GlobalModal from "src/Modals/GlobalModal";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useLayoutEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useAuth } from "src/hooks/useAuth";
import axiosInstance from "src/axiosInstance";
import toast from 'react-hot-toast';
import { BallTriangle } from 'react-loader-spinner'
import DiscountTimer from "src/hooks/useDiscountCountDown";
import { shortenText } from "src/utils";
// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style1 = {
  position: 'absolute',
  width: '600px',
  height: '650px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  background: '#FFFFFF',
  p: 3,
};

// ptvp=PAGE TO VIEW (SALES)
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export default function RetailerSingleView({ categoryId }) {
  const [openDialog, setDiologOpen] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const navigate = useNavigate();
  const { data } = useAuth();
  const handleGoToCartClose = () => setopenGoToCart(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [search, setSearch] = useState({ search: '', type: '', location: '' });
  const [deleteProduct, setDeleteProduct] = useState(false)
  const [refetchProduct, setRefetchProduct] = useState(false)
  const [productData, setProductData] = useState('')
  const [isPending, setIsPending] = useState(false)
  const [searchvalue, setSearchValue] = useState('')
  const [singlePromoData, setSinglePromoData] = useState([])
  const notifyError = (err) => toast.error(err);
  const notify = (msg) => toast.success(msg);
  const [expired, setExpired] = useState(false);
  
  const processFilter = (e) => {
    const { name, value } = e.target;
    setSearch(() => ({
      ...search,
      [name]: value
    }));


  }

  const handleClickOpen = (val) => {
    setDiologOpen(true);
    setProductData(val)
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const deleteSingleProduct = (e) => {
    setDeleteProduct(true)
    setTimeout(() => {
      handleDialogClose(false);
    },[1000])
    // setmodalTitle('Product deleted')
    axiosInstance.delete('/admin/product/delete-discount/'+productData)
    .then(res=> {
      if(res.status < 300){
        // setopenPopUp(true)
        notify(res.data.message)
        setRefetchProduct(!refetchProduct)
      }
    }).catch(err => {

      try {
        notifyError(err.response.data.message);

      } catch (error) {
        notifyError("Unable to delete product, try again later");

      }

    }).finally(() => setDeleteProduct(false))
  }


  useLayoutEffect(() => () => {
    // Your code here.
  }, [])

  const reload = () => {
    navigate(`/app/products`)
  }

  const openDiscount = () => {
    bus.emit('open-discount', false);

  }


  const fetchData = (page = 1, pageSize = 10, search = searchvalue) => {
    setIsPending(true);
    axiosInstance.get('/admin/product/brand-discount-product/'+categoryId, {
      page,
      pageSize,
      search,
    })
      .then(res => {
        setSinglePromoData(res.data.data)

      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch discounts, please try again later");

        }

      }).finally(() => setIsPending(false))
  }

  useEffect(() => {
    fetchData()
  },[refetchProduct])

  useEffect(() => {
    bus.on('expired', (val) => {
      if(val) {
        setExpired(true)
      } else {
        setExpired(false)
      }
    })
  }, [])




  return (
    <>
      <div className={`mt-3 px-2`}>

        <div className={`${styles.filters} `}>
          <div className="row">
            <div className={`${styles.hold_input1} col-md-3 col-8 mb-2  `}>
              <img src="/assets/icons/search.svg" alt="" />
              <input type="text" placeholder="Search" style={{ textIndent: '25px', width: '100%' }} className=" form-control" />

            </div>
            <div className="col-md-9 col-4 text-right">
              {/* <button className="btn  border"  onClick={() => setModalOpen1(true)}   
                  style={{
                    backgroundColor:'#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow:  '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px'
                  }}>
                  <img className="" style={{display:'inline',width:'28%'}} src="/assets/icons/filterlines.svg" alt="" /> 
                  <span className="d-none d-md-inline-block">
                  &nbsp;
                  </span>
                  <small className="d-none d-md-inline-block">
                    Filters
                  </small>
              </button> */}

            </div>

          </div>


        </div>

        <Card style={{ marginTop: '10px', borderRadius: '10px' }} className='p-0'>
          <div className="mb-0 p-3 d-inline-block cursor_pointer" onClick={reload}>
            <img src="/assets/icons/Chevronleft.svg" className="pr-1" alt="" />

          </div>
          <CardHeader
            className='p-2 p-md-4'
            title={
              <div className="d-md-flex control_text justify-content-between">
                <div>
                  <h4 style={{ fontSize: '24px', fontWeight: '600' }}>Promo And Discounts</h4>
                  <p style={{ fontSize: '16px', fontWeight: 'lighter' }} className="text-muted">
                    Brands running discounts on FlexDeals
                  </p>
                </div>
                <div className="d-flex">
                  &nbsp;
                  &nbsp;
                  <div>
                    <button id="dropdownMenuButton" className={`btn m-0`} style={{
                      verticalAlign: 'middle',
                      background: '#53138D',
                      color: ' #FFFFFF',
                      borderRadius: '8px'
                    }}
                      onClick={() => openDiscount()}
                    >
                      Create Discount
                    </button>
                  </div>
                </div>
              </div>
            }
          />
          <Scrollbar>
            <div className={`py-3 px-md-4 ${styles.cover_products}`}>
              <div className={`${styles.customized_row}`}>
                {(!isPending && singlePromoData.length > 0) && singlePromoData.map((el,i) => {
                  // console.log(el?.discount_id.createdAt);
                  return(
                  <div className="mb-2">
                    <div className="card " style={{ position: 'relative !important', overflow: 'hidden' }}>
                      <div className="card-body">
                        <div className={`${styles.customized_row1}`} style={{minHeight: '190px'}}>
                          <div className="bg-light" 
                            style={{
                              overflow: "hidden",
                              maxHeight: "190px",
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <img style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }} src={el?.product_image} alt="" />

                          </div>
                          <div className={`${styles.product_txt} h-100`}>
                            <h6>{el?.product_name}</h6>
                            <div >
                              <div className="mt-2 mb-0 d-flex justify-content-between">
                                <h6 style={{ fontWeight: 'lighter' }}>
                                  Type:
                                </h6>
                                <h6>
                                  { el?.discount_id ? el?.discount_id?.discount_type?.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : '---'}
                                </h6>
                              </div>
                              <div className="mt-2 mb-0 d-flex justify-content-between">
                                <h6 style={{ fontWeight: 'lighter' }}>
                                  Duration:
                                </h6>
                                <h6 className="text-danger" style={{ fontWeight: 'lighter' }}>
                                  {el?.is_approved ?
                                    <DiscountTimer start={el?.discount_id?.discount_start_date} end={el?.discount_id?.discount_end_date} />
                                    : "00:00:00:00"
                                  }
                                  {/* {moment(el?.updatedAt).format('DD/MM/YYYY')} */}
                                </h6>
                              </div>
                              <div className="mt-2 mb-0 d-flex justify-content-between">
                                <h6 style={{ fontWeight: 'lighter' }}>
                                  Category:

                                </h6>
                                <div className="">
                                  <span style={{
                                    background: '#EFF6FF',
                                    padding: '5px',
                                    color: ' #8C2D91',
                                    border: '1px solid #E5E7EB',
                                    borderRadius: '5px'


                                  }} className="d-inline-block mx-1 ">
                                    {el?.product_category?.category_name}
                                  </span>
                                  {/* <span style={{
                                    background: '#EFF6FF',
                                    padding: '5px',
                                    color: ' #8C2D91',
                                    border: '1px solid #E5E7EB',
                                    borderRadius: '5px'


                                  }} className="d-inline-block mx-1 ">
                                    S.Owners

                                  </span> */}
                                </div>
                              </div>
                              <div className="mt-3 mb-0 d-flex justify-content-between">
                                <h6 style={{ fontWeight: 'lighter' }}>
                                  Description:

                                </h6>
                                <h6>
                                    <BootstrapTooltip title={
                                        <div>
                                          <span>
                                            {el?.description}
                                          </span>
                                        </div>
                                    }>
                                      <h6 className="text-success" style={{ fontWeight: 'lighter' }}>
                                        {/* 15% Off Product */}
                                        {el?.description ? shortenText(el?.description) : ''}
                                      </h6>
                                    </BootstrapTooltip>
                                  </h6>
                              </div>
                              <div className="row  pt-2">
                                <div className="col-6 text-center border-right">
                                {/* bus.emit('open-discount', { edit: el }) */}
                                  <span onClick={() => bus.emit('open-discount', { edit: el } )} style={{ cursor: 'pointer' }}>
                                    <img src="/assets/icons/edit.svg" alt="edit" />
                                  </span>
                                </div>
                                <div className="col-6 text-center">
                                  <span 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => handleClickOpen(el?._id)}
                                    disabled={expired}
                                  >
                                    <img src="/assets/icons/trash-2.svg" alt="delete" />
                                  </span>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                )})}

                 <div className="d-flex justify-content-center">
                  {isPending &&
                    <BallTriangle

                      height={50}
                      width={55}
                      radius={5}
                      color="#53138D"
                      ariaLabel="ball-triangle-loading"
                      wrapperClass={{}}
                      wrapperStyle=""
                      visible={true}
                    />

                  }

                </div>
                {
                  (!singlePromoData.length && !isPending) &&

                  <div className="alert alert-[#53138D] text-center">
                    <h4>No record found</h4>
                  </div>

                }


              </div>


            </div>




          </Scrollbar>

        </Card>




      </div>

      {/*  DIALOG*/}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box className={`mobile_modal_size ${styles.modal_bg}`} sx={style1} style={{ height: '300px' }}>
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Product Added to Cart</h5>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing  elit. Eius aliquam
                laudantium explicabo pariatur iste dolorem animi vitae error totam. At
                sapiente aliquam accusamus facere veritatis.

              </p>
              <div className={`${styles.buttons}  pt-2`}>
                <button className={`${styles.cancel} `} onClick={handleGoToCartClose}>
                  Cancel
                </button>
                &nbsp;
                <button className={`${styles.go_to} `} onClick={() => navigate('/app/carts/')}>

                  Go to cart
                </button>

              </div>

            </div>



          </Box>
        </Fade>

      </Modal>
      <GlobalModal
        open={modalOpen1}
        onClose={() => setModalOpen1(false)}
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        width='740px !important'
        overflow='auto'
        bgcolor='#F5F5F5'
        border='1px solid #F5F5F5'
        borderRadius='5px'
        boxShadow='24'
        p='25px'
      >
        <div className={`${styles.modal_content}`}>
          <div className={`${styles.modal_header} mb-0`}>
            <h5 style={{
              fontSize: '19px',
              fontWeight: '700',

            }}>Filter</h5>
            <span onClick={() => setModalOpen1(false)} style={{ cursor: 'pointer' }}>
              <img src="/assets/icons/x.svg" alt="" />

            </span>

          </div>


          <form action="" className="mt-0">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div className="mb-3">
                  <label htmlFor="loc">Type of Discount</label>
                  <Select
                    labelId="s"
                    id="cat"
                    fullWidth
                    size="small"
                    value={search.type}
                    name="type"

                    onChange={processFilter}
                  >

                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value='amountOff'>Amount Off Product</MenuItem>
                    <MenuItem value='BuyX'>Buy X Get Y</MenuItem>
                  </Select>


                </div>

              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="cat">Status</label>
                <Select
                  labelId="s"
                  id="cat"
                  fullWidth
                  size="small"
                  value={search.status}
                  name="status"

                  onChange={processFilter}
                >

                  <MenuItem value=''>Select Status</MenuItem>
                  <MenuItem value='approved'>Approved</MenuItem>
                  <MenuItem value='waiting'>Waiting Approval</MenuItem>
                </Select>


              </div>
            </div>
            <div className="mb-4">
              <button type="button" onClick={() => setModalOpen1(false)} className={`${styles.modal_btn} btn btn-block`}>
                Proceed

              </button>


            </div>

          </form>


        </div>


      </GlobalModal>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

              </div>
              <div>
                <h4>
                  Delete Discount

                </h4>
                <p style={{
                  fontSize: '14px',
                  color: '#6B7280',
                }}>
                  Are you sure you want to delete this product? All your data will be permanently removed from our servers forever. This action cannot be undone.
                </p>

              </div>

            </div>

            <DialogActions>
              <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
              <Button className={`${styles.delete_product}`} onClick={deleteSingleProduct}>
                Delete

              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <DiscountModal />

      <Voucher key={4} />


    </>
  )
}
