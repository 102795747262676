import styles from "../Outlet.module.css"

import { 
    Card, 
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from "@mui/material";
import Scrollbar from '../../../components/scrollbar';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import GlobalModal from "src/Modals/GlobalModal";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect } from "react";
import { BallTriangle } from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
// sections
// mock
import { useState } from "react"
import Backdrop from '@mui/material/Backdrop';
import LiveLocation from "src/pages/LiveLocation";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Select from '@mui/material/Select';
import bus from 'src/bus';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import Filter1 from "src/pages/Filter/Filter1";
import moment from "moment";
import { downloadFile, downloadFile2, shortenText } from "src/utils";
import RemoveMinus from '../../../hooks/useRemoveNegatives'
import { Pagination } from "src/components";
// import Table from "src/theme/overrides/Table";


// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const TABLE_HEAD = [
    { id: 'storeName', label: 'Store Name', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'storeId', label: 'Store ID', alignRight: false },
    { id: 'outletManager', label: 'Store Owner', alignRight: false },
    { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'brandName', label: 'Brand Name', alignRight: false },
    { id: 'dateCreated', label: 'Date Created', alignRight: false },
    { id: 'outletLocation', label: 'Store Location', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 580,
    overflow: 'auto',
    bgcolor: '#F5F5F5',
    border: '1px solid #F5F5F5',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};
export default function AllStores() {
    const [open, setOpen] = useState(false);
    const [outletData, setdata] = useState([]);
    const [storeCount, setStoreCount] = useState({
        boldCount: 0,
        percentageDiff: 0,
        smallCount: 4,
    });
    const [outletPagination, setOutletPagination] = useState({ total: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [text, setText] = useState({
        title: 'Add Store',
        sub: 'Add information about this store',
    });
    const [exporting, setExporting] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [fetchStore, setFetchStore] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const notify = (msg = null) => toast.success(msg === null ? 'Store saved .' : msg);
    const notifyError = (err) => toast.error(err);
    const [searchvalue, setSearchvalue] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [endpoint, changeEndPoint] = useState('/admin/stores/add-store');
    const [outlets] = useState({});
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [formValues, setFormValues] = useState({
        store_name: '',
        store_email: '',
        store_manager: '',
        phone_number: '',
        store_location: '',
        store_category: 'Kiosk',
        country: 'Nigeria',
        state: 'Lagos',
        latitude: '',
        longitude: ''
    });
    const [loadingState, setLoadingState] = useState(false)
    const [dataFilter, setDataFilter] = useState('today')
    const [exportFile] = useState(null)
    const [refetchData, setRefetchData] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [modalCSVOpen, setModalCSVOpen] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const [storeReload, setStoreReload] = useState(false)
    const [openVerify, setOpenVerify] = useState(false);
    const handleOpenVerify = () => setOpenVerify(false);
    const [filterSearch, setFilterSearch] = useState({
        filterLocation: '',
        category: 'select',
        status: 'select',
        filterStatus: '',
        location: '',
        startDateFilter: null,
        endDateFilter: null,
    });

    const handleCategoryChange = (e) => {
        if (e && e.target && e.target.name) {
            setFilterSearch(prev => {
                // console.log(...prev);
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((formValues) => ({
            ...formValues,
            [name]: value
        }));
    };

    const handleSubmitFilter = () => {
        setModalOpen(false)
        fetchData()
    }

    const searchFilter = (e) => {
        setSearchvalue(e.target.value)
    }

    const fetchCountries = async () => {
        const countriesApi = await fetch('https://restcountries.com/v3.1/all')
        const countriesJson = await countriesApi.json();
        const countriesData = countriesJson.map(country => ({
            code: country.cca2,
            name: country.name.common
        }));
        setCountries(countriesData)
    }

    const fetchStates = async () => {
        if (formValues.country === '') return;
        setStates([])
        setFormValues(prev => {
            return {
                ...prev,
                outlet_state: ''
            }
        })
        setLoadingState(true);
        const statesFetch = await fetch(`https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`, {
        });
        const statesJson = await statesFetch.json();
        const statesData = statesJson
        setStates(() => {
            return statesData.filter((el, i) => el.country_name === formValues.country)
        })
        setLoadingState(false)
    }

    useEffect(() => {
        fetchCountries()
        fetchStates()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.country])
    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(function (position) {


    //         // get latitude and longitude
    //         const latitude = position.coords.latitude;
    //         const longitude = position.coords.longitude;
    //         setFormValues(val => {
    //             return {
    //                 ...val,
    //                 latitude,
    //                 longitude

    //             }
    //         });
    //     });

    // }, [location])
    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const { lat, lng } = await getLatLng(results[0]);
        setFormValues((prev) => {
            return {
                ...prev,
                latitude: lat,
                longitude: lng,
            }
        });
        setAddr(value);
    }

    const setAddr = (val) => {
        setFormValues((formValues) => ({
            ...formValues,
            store_address: val
        }));
    }

    const handleClose = () => setOpen(false);
    const handleClose2 = () => {
        setModalOpen(false);
        setModalCSVOpen(false)
    };
    const [openDialog, setDiologOpen] = useState(false);
    const [openDialog2, setDiologOpen2] = useState(false);
    const [deleteValue, setDeleteValue] = useState({})
    const handleOpenDialog = (val) => {
        setDiologOpen2(true)
        setDeleteValue(val)
    }
    const handleEditDialog = (val) => {
        setText({
            title: 'Edit Store',
            sub: 'Edit information about this store',
        })
        setOpen(true)
        setFormValues(val)
    }

    const handleDialogClose = () => {
        setDiologOpen(false);
    };

    const handleCloseDialog = () => {
        setDiologOpen2(false);
    };


    useEffect(() => {
        bus.on('open-outlet', () => {
            changeEndPoint('/outlet/add-outlet')
            setFormValues({
                store_name: '',
                store_email: '',
                store_manager: '',
                phone_number: '',
                store_location: '',
                store_category: 'Kiosk',
                country: 'Nigeria',
                state: 'Lagos',
                latitude: '',
                longitude: ''
            })
            setText({
                title: 'Add Store',
                sub: 'Add information about this store',
            })
            setOpen(true)
        });

        bus.on('open-csv', () => {
            // setExportFile('csv')
            setModalCSVOpen(true)

        })

        return () => {
            bus.off('open-outlet', () => {
                changeEndPoint('/outlet/add-outlet')

            });
        };
    }, []);

    const totalStore = (dateFilter = dataFilter) => {
        let filter_payload = {}
        if (dataFilter !== '') {
            filter_payload.dateFilter = dataFilter
        }
        setFetchStore(true);
        axiosInstance.get('/admin/stores/get-total-card?', {
            params: {
                dateFilter
            }
        })
            .then(res => {
                setStoreCount(res.data.data)
            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to fetch store, try again later");

                }

            }).finally(() => setFetchStore(false))
    }

    const handleVerifyDialog = () => {
        setOpenVerify(true)
    }


    useEffect(() => {
        bus.on('dataFilter', (el) => {
            // console.log(el);
            setDataFilter(el)
        })
        fetchData()
        totalStore()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchvalue, dataFilter, refetchData, storeReload]);

    const addStore = (e) => {
        e.preventDefault()
        let filter_payload = {}
        if (formValues.store_name !== '') {
            filter_payload.store_name = formValues.store_name
        }
        if (formValues.store_email !== '') {
            filter_payload.store_email = formValues.store_email
        }
        if (formValues.store_manager !== '') {
            filter_payload.store_manager = formValues.store_manager
        }
        if (formValues.store_category !== '') {
            filter_payload.store_category = formValues.store_category
        }
        if (formValues.store_address !== '') {
            filter_payload.store_address = formValues.store_address
        }
        if (formValues.store_phone_number !== '') {
            filter_payload.store_phone_number = formValues.store_phone_number
        }
        if (formValues.country !== '') {
            filter_payload.country = formValues.country
        }
        if (formValues.state !== '') {
            filter_payload.state = formValues.state
        }
        if (formValues.latitude !== '') {
            filter_payload.latitude = formValues.latitude
        }
        if (formValues.longitude !== '') {
            filter_payload.longitude = formValues.longitude
        }
        setIsPending(true);
        var endpoint = formValues.hasOwnProperty('_id') ? '/admin/stores/edit-store/' + formValues._id : '/admin/stores/add-store'
        axiosInstance[formValues.hasOwnProperty('_id') ? 'put' : 'put'](endpoint, filter_payload)
            .then(res => {
                handleClose()
                // setdata(res.data.data)
                setText({
                    title: 'Add Store',
                    sub: 'Add information about this store',
                })
                setRefetchData(!refetchData)
            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to fetch store, try again later");

                }

            }).finally(() => setIsPending(false))
    }

    // const handleDownloadFile = async (extension) => {
    //     try {
    //         const result = await axiosInstance.get(
    //             `/admin/stores?location&page=${outletPagination.next}&perpage=10&export_type=${extension}`
    //         );
    //         await downloadFile(result.data.data, extension);
    //     } catch (err) {
    //         console.log(err.message);
    //     }
    // };

    // useEffect(() => {
    //     addStore()
    //     fetchData()
    // }, []);

    const fetchData = async (page = 1, perpage = 10, file = null) => {
        setIsPending(true)
        let filter_payload = {}
        if (filterSearch.status !== 'select' && filterSearch.status !== 'all') {
            filter_payload.status = filterSearch.status
        }
        if (searchvalue !== '') {
            filter_payload.search = searchvalue
        }
        if (startDate !== '' && startDate) {
            filter_payload.dateStart = startDate
        }
        if (endDate !== '' && endDate) {
            filter_payload.dateEnd = endDate
        }
        if (filterSearch.status !== 'select' && filterSearch.category !== '') {
            filter_payload.category = filterSearch.category
        }
        if (filterSearch.location !== '') {
            filter_payload.location = filterSearch.location
        }
        if (file) {
            let queryParams = new URLSearchParams({ ...filter_payload, page, perpage, export_type: file }).toString();
            setExporting(true);

            try {
                await downloadFile2('All stores', `/admin/stores?${queryParams}`, file);
            } finally {
                // alert()
                setExporting(false);
            }
            return;
        }
        axiosInstance.get('/admin/stores', {
            params: {
                page,
                perpage,
                file,
                ...filter_payload
            }
        })
            .then(res => {
                setdata(res.data.data.data)
                setOutletPagination(res.data.data.pagination)

                filterSearch.location = ''
                filterSearch.category = 'select'
                filterSearch.status = 'select'
                filterSearch.startDateFilter = ''
                filterSearch.endDateFilter = ''
            }).catch(err => {
                try {
                    notifyError(err.response.data.message);
                } catch (error) {
                    notifyError("Unable to fetch store, try again later");
                }
            }).finally(() => setIsPending(false))
    }

    const [selectedFile, setSelectedFile] = useState(null);

    const handleCsvFile = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
        // const selectedFile = e.target.files[0];
    }

    const handleSubmitCSV = () => {
        if (selectedFile) {
            setExportLoading(true)
            setModalCSVOpen(false)
            const formData = new FormData();
            formData.append('file', selectedFile);

            axiosInstance.post('/admin/stores/import-csv', formData)
                .then(res => {
                    if (res.status < 300) {
                        notify(res.data.message)
                        setStoreReload(!storeReload)
                        // create file link in browser's memory
                        const href = URL.createObjectURL(res.data)
                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a')
                        link.href = href
                        link.setAttribute('download', 'CSV.csv')
                        document.body.appendChild(link)
                        link.click()
                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)
                    }
                }).finally(() => {
                    setExportLoading(false)
                })
        }
    }

    const handleDelete = () => {

        setIsDeleting(true)
        axiosInstance.delete(endpoint)
            .then(res => {
                if (res.status < 300) {
                    notify(res.data.message);
                    fetchData(currentPage, itemsPerPage)

                    handleClose();

                }
            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to save store, try again later");

                }

            }).finally(() => {
                setIsDeleting(false)
                handleDialogClose()
            })
    };

    const processLocation = (e) => {
        const value = {
            target: {
                name: 'location',
                value: e.formatted_address
            }
        }
        handleCategoryChange(value)
    };


    const [delFromStore, setDelFromStore] = useState(false)

    const handleDeleteStore = () => {
        setDiologOpen2(false)
        axiosInstance.post('/admin/stores/disable-store/' + deleteValue._id)
            .then(res => {
                if (res.status < 300) {
                    setDelFromStore(true)
                    notify(res.data.message);
                    fetchData(currentPage, itemsPerPage)
                }
            }).catch(err => {

                try {
                    notifyError(err.response.data.message);

                } catch (error) {
                    notifyError("Unable to delete store, try again later");
                }

            }).finally(() => {
                setIsDeleting(false)
            })
    }

    // useEffect(() => {
    //     if (delFromStore) {
    //         deleteStore(); 
    //     }
    // }, [delFromStore])

    return (
        <>
            <div className=" mt-4 px-2">
                <div className="header d-block d-md-none mb-4">
                    <div className="d-md-flex justify-content-between">
                        <div className="mb-3">
                            <h4 className={`mb-1 mb-md-0 pb-0`}>Stores</h4>
                            <p style={{ fontSize: '14px' }} 
                                className={`mb-0 pb-0`}
                            >
                                An overview of all stores
                            </p>
                        </div>
                        <div className="">
                            <button className={`btn ${styles.import}`}>
                                Import CSV File
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`${styles.analytics}  `}>
                    <div className="col-md-6">
                        <Filter1 width='100%' />
                    </div>
                    <div className={`row`}>
                        <div className={`col-md-6 mb-2`}>
                            <div className={`${styles.card}`}>
                                <div className={`card-body`}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex justify-content-between">
                                                <small>Added Stores</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.numbers}`}>
                                        <div>
                                            <span>
                                                {
                                                    storeCount.boldCount ? 
                                                    Number(storeCount.boldCount).toLocaleString() : 
                                                    0
                                                } 
                                            </span>
                                            {" "}
                                            <small>
                                                from {
                                                        storeCount.smallCount ? 
                                                        Number(storeCount.smallCount).toLocaleString() : 
                                                        0
                                                    }
                                            </small>
                                        </div>
                                        {
                                            Number(storeCount.boldCount) >= 
                                            Number(storeCount.smallCount) ?
                                            <div>
                                                <div className={styles.percent}>
                                                    <img src="/assets/icons/up.svg" alt="" /> 
                                                    {" "}
                                                    <small>
                                                        {storeCount?.percentageDiff}
                                                    </small>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className={`${styles.percent2}`}>
                                                    <img src="/assets/icons/downr.svg" alt="" /> 
                                                    {" "}
                                                    <small>
                                                        {
                                                            storeCount?.percentageDiff ?
                                                            Math.abs(storeCount?.percentageDiff) +'%'
                                                            // <RemoveMinus number={storeCount?.percentageDiff} /> :
                                                            :
                                                            0
                                                        }
                                                    </small>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className={`col-md-6 mb-2`}>
                            <div className={`${styles.card}`}>
                                <div className={`card-body`}>
                                    <div className="d-flex justify-content-between">
                                        <small>Total Stock Available</small>
                                    </div>
                                    <div className={`${styles.numbers}`}>
                                        <div>
                                            <span>70,976</span><small> from 67,000</small>
                                        </div>
                                        <div>
                                            <div className={styles.percent}>
                                                <img src="/assets/icons/up.svg" alt="" /> 
                                                <small>12%</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={`${styles.filters} mt-4`}>
                    <div className="row">
                        <div className={`${styles.hold_input} col-md-3 col-6   `}>
                            <img src="/assets/icons/search.svg" alt="" />
                            <input
                                type="text"
                                value={searchvalue}
                                onChange={searchFilter}
                                placeholder="Search"
                                name="search"
                                style={{ textIndent: '25px', width: '100%' }}
                                className=" form-control" />
                        </div>
                        <div className="col-md-9 col-6  text-right">
                            <button className="btn  border"
                                onClick={() => setModalOpen(true)}
                                style={{
                                    backgroundColor: '#fff',
                                    border: '1px solid #D0D5DD',
                                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                    borderRadius: '4px'
                                }}>
                                <img className=""
                                    style={{ display: 'inline', width: '17px' }}
                                    src="/assets/icons/filterlines.svg" alt="" />
                                &nbsp;
                                <small className="d-none d-md-inline-block">
                                    Filters
                                </small>
                            </button>
                        </div>
                    </div>
                </div>
                <Card style={{ marginTop: '50px', borderRadius: '10px' }}
                    className='p-0'>
                    <div className='p-3 '>
                        <div className="d-md-flex justify-content-between">
                            <div className="pb-3 pb-md-0">
                                <h5 className="table_desc_header">
                                    Store list
                                </h5>
                                <small 
                                    style={{ 
                                        fontWeight: '200', 
                                        fontSize: '14px', 
                                        display: 'inline-block', 
                                        marginTop: '0px' 
                                    }}
                                >
                                    Details and status of stores on Retail Xpress
                                </small>
                            </div>
                            <div className="pt-md-2">
                                {/* <div className="dropleft ">
                                    <button 
                                        id="dropdownMenuButton" 
                                        data-toggle="dropdown mb-1" aria-haspopup="true" 
                                        aria-expanded="false" 
                                        className={`${styles.export_btn1} btn m-0`}
                                    >
                                        <span style={{
                                            paddingTop: '8px',
                                        }}>
                                            <img src="/assets/icons/downloadblue.svg" alt="" /> &nbsp;
                                            Export
                                        </span>
                                        &nbsp;
                                        &nbsp;
                                        <span style={{
                                            borderLeft: '1px solid #D1D5DB',
                                            display: 'inline-block',
                                            height: 'inherit',
                                            paddingTop: '8px',
                                            paddingLeft: '8px',
                                            textAlign: 'center',
                                        }}>
                                            <img src="/assets/icons/chevron-downblue.svg" alt="" />
                                        </span>
                                    </button>
                                    <div className="dropdown-menu drop-left mb-1" aria-labelledby="dropdownMenuButton">
                                        <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                                        <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                                        <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                                    </div>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <button onClick={() => handleOpen()} className={`btn mt-2 mt-md-0 ${styles.add_outlet}`}>
                                        <img src="/assets/icons/plus.svg" className="pr-1" alt="" />
                                        Add New Store
                                    </button>
                                </div> */}
                                {/* {loadingState && */}
                                {/* <CircularProgress style={{ color: '#53138D',display:'inline-block' }} size="1rem" /> */}
                                {/* } */}
                                <div className="dropleft ">
                                    <button 
                                        id="dropdownMenuButton" 
                                        data-toggle="dropdown" 
                                        aria-haspopup="true" 
                                        aria-expanded="false" 
                                        className={`${styles.export_btn} btn m-0`}
                                    >
                                        <span style={{
                                            paddingTop: '8px',
                                        }}>
                                            <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                                            {
                                                !exporting &&
                                                <span>Export</span>
                                            }
                                            {
                                                exporting &&
                                                <CircularProgress 
                                                    style={{ 
                                                        color: 'white', 
                                                        display: 'inline-block' 
                                                    }} 
                                                    size="1rem" 
                                                />
                                            }
                                        </span>
                                        &nbsp;
                                        &nbsp;
                                        <span style={{
                                            borderLeft: '1px solid white',
                                            display: 'inline-block',
                                            height: 'inherit',
                                            paddingTop: '8px',
                                            paddingLeft: '8px',
                                            textAlign: 'center',
                                        }}>
                                            <img src="/assets/icons/arrowDown.svg" alt="" />
                                        </span>
                                    </button>
                                    <div
                                        className="dropdown-menu drop-left"
                                        aria-labelledby="dropdownMenuButton">
                                        <button
                                            onClick={() => fetchData(currentPage, outletPagination?.total, "csv")}

                                            type="button"
                                            className="rss dropdown-item btn border-0"
                                        >
                                            CSV
                                        </button>
                                        <button
                                            onClick={() => fetchData(currentPage, outletPagination?.total, "excel")}
                                            type="button"
                                            className="rss dropdown-item btn border-0"
                                        >
                                            Excel
                                        </button>
                                        <button
                                            onClick={() => fetchData(currentPage, outletPagination?.total, "pdf")}
                                            type="button"
                                            className="rss dropdown-item btn border-0"
                                        >
                                            PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Scrollbar>
                        <div className={`pt-3 pb-3 pl-0 pr-0`}>
                            <table sclassName={`${styles.table} table-hover table-striped `} style={{ minWidth: '100em', }}>
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        {TABLE_HEAD.map((el, i) => {
                                            let sIndex = i < 1 ? 1 : false;
                                            return (
                                                <th key={i} className="text-muted">
                                                    {el.label} {sIndex && (
                                                        <span style={{ cursor: 'pointer' }}>
                                                            <img className="pl-2" src="/assets/icons/down.svg" alt="" />
                                                        </span>
                                                    )}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(outletData?.length > 0 && !isPending) &&
                                        outletData?.map((el, i) => {
                                            return (
                                                <tr key={i} className={`${styles.tr} `}>
                                                    <td component="th" scope="el">
                                                        <div className=" ">
                                                            <p className="mb-0">
                                                                <strong>
                                                                    {el?.store_name ? el?.store_name : '---'}
                                                                </strong>
                                                            </p>
                                                            <span style={{ fontSize: '13px' }}>
                                                                {el?.store_email ? el?.store_email : '---'}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {el?.store_category ? el?.store_category : '---'}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {el?.shopID ? el?.shopID : '---'}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {el?.store_manager ? el?.store_manager : '---'}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {el?.store_phone_number ? el?.store_phone_number : '---'}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {/* {
                                                            i > 2 &&
                                                            <span className="verified_badge">
                                                                <img src="/assets/icons/check-circlel.svg" alt="" />
                                                                &nbsp;
                                                                Verified
                                                            </span>
                                                        } */}
                                                        {/* { */}
                                                        {/* i < 3 && */}
                                                        <span className="">
                                                            {el?.verified_email ? (
                                                                <span className="verified_badge">
                                                                    <img src="/assets/icons/check-circlel.svg" alt="" />
                                                                    Verified
                                                                </span>
                                                            ) : (el?.verified_email === false || !el.verified_email) &&
                                                            <span className="not_verified_badge">
                                                                Not Verified
                                                            </span>
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                <strong>
                                                                    {el?.brand_name ? el?.brand_name : '---'}
                                                                </strong>
                                                            </span><br />
                                                            <small>
                                                                {el?.brandID ? el?.brandID : '---'}
                                                            </small>
                                                            {/* {el.outlet_location} */}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                {moment(el?.createdAt).format("DD/MM/YYYY")}
                                                            </span> <br />
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p style={{ textOverflow: 'ellipsis' }}>
                                                            {el?.store_address ? shortenText(el?.store_address) : '---'}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="dropleft ">
                                                            <span
                                                                id="dropdownMenuButton"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                className={`${styles.action_button} px-3 py-2`}  style={{
                                                                    cursor: 'pointer',
                                                                }}>
                                                                <img src="/assets/icons/action.svg" alt="" />
                                                            </span>
                                                            <div className="dropdown-menu drop-left"
                                                                aria-labelledby="dropdownMenuButton">
                                                                {
                                                                    el?.verified_email === false &&
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleVerifyDialog(el)}
                                                                        className="rss dropdown-item btn border-0"
                                                                    >
                                                                        Verify
                                                                    </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleEditDialog(el)}
                                                                    className="rss dropdown-item btn border-0"
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleOpenDialog(el)}
                                                                    className="rss dropdown-item btn border-0"
                                                                >
                                                                    Delete
                                                                </button>
                                                                {/* {
                                                                    el?.verified_email === false &&
                                                                    <button 
                                                                        type="button" 
                                                                        onClick={() => handleInviteDialog(el)} 
                                                                        className="rss dropdown-item btn border-0"
                                                                    >
                                                                        Send Invite 
                                                                    </button>
                                                                } */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-center">
                            {isPending &&
                                <BallTriangle
                                    height={50}
                                    width={55}
                                    radius={5}
                                    color="#53138D"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                />
                            }
                        </div>
                        {
                            (!outletData.length || outletData == null && !isPending) &&

                            <div className="alert alert-[#53138D] text-center">
                                <h4>No record found</h4>
                            </div>
                        }
                    </Scrollbar>
                    <div>
                        <Pagination
                            currentPage={currentPage}
                            dataPagination={outletPagination}
                            itemsPerPage={itemsPerPage}
                            setCurrentPage={setCurrentPage}
                            fetchData={fetchData}
                        />
                    </div>
                </Card>
                {/* Modals */}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableEnforceFocus
                >
                    <Fade in={open}>
                        <Box sx={style} className={`mobile_modal_size`}>
                            <div className={`${styles.modal_content}`}  >
                                <div className={`${styles.modal_header} mb-0`}>
                                    <div>
                                        <h5 style={{
                                            fontSize: '19px',
                                            fontWeight: '700',
                                        }}>
                                            {text.title}
                                        </h5>
                                        <p style={{
                                            fontSize: '11px',
                                            color: '#71717A',
                                        }}>
                                            {text.sub}
                                        </p>
                                    </div>
                                    <div>
                                        <span
                                            onClick={handleClose}
                                            style={{ cursor: 'pointer' }}>
                                            <img src="/assets/icons/x.svg"
                                                alt="" />
                                        </span>
                                    </div>
                                </div>
                                <form onSubmit={addStore}
                                    className="mt-0 form_label">
                                    <div className="mb-3">
                                        <label htmlFor="name">
                                            Store Name
                                        </label>
                                        <TextField
                                            required
                                            type='text'
                                            value={formValues.store_name}
                                            name="store_name"
                                            onChange={handleChange}
                                            id="name"
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="name">
                                            Store Category
                                        </label>
                                        <Select
                                            labelId="s"
                                            id="s"
                                            fullWidth
                                            size="small"
                                            value={formValues.store_category}
                                            name="store_category"
                                            required
                                            onChange={handleChange}
                                        >

                                            <MenuItem value='select'>
                                                Select Category
                                            </MenuItem>
                                            <MenuItem value='Kiosk'>
                                                Kiosk
                                            </MenuItem>
                                            <MenuItem value='Neighborhood store'>
                                                Neighborhood store
                                            </MenuItem>
                                            <MenuItem value='Supermarket'>
                                                Supermarket
                                            </MenuItem>
                                            <MenuItem value='E-commerce store'>
                                                E-commerce store
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="mname">
                                            Store Manager Name
                                        </label>
                                        <TextField
                                            required
                                            value={formValues.store_manager}
                                            name="store_manager"
                                            onChange={handleChange}
                                            type='text'
                                            id="mname"
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email">
                                            Email
                                        </label>
                                        <TextField
                                            required
                                            type='email'
                                            value={formValues.store_email}
                                            name="store_email"
                                            onChange={handleChange}
                                            id="email"
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phone">
                                            Phone Number
                                        </label>
                                        <TextField
                                            required
                                            type='phone'
                                            value={formValues.store_phone_number}
                                            name="store_phone_number"
                                            onChange={handleChange}
                                            id="phone"
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <PlacesAutocomplete
                                            value={formValues.store_address}
                                            onChange={setAddr}
                                            onSelect={handleSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <div className='my-4 inputs_n_label'>
                                                        <label htmlFor='loc'>Address</label>
                                                        <TextField
                                                            id="loc"
                                                            placeholder='Input your location'
                                                            size="small"
                                                            fullWidth
                                                            {...getInputProps({
                                                                placeholder: 'Search Places ...',
                                                                className: 'location-search-input',
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion, i) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    {/* <div className='my-4 inputs_n_label'>
                                    <label htmlFor='businessDesc'> Store Address*</label>
                                    <TextField
                                        required
                                        id="businessDesc"
                                        value={formValues.store_address}
                                        name='store_address'
                                        onChange={handleChange}
                                        placeholder='Input address'
                                        size="small"
                                        multiline
                                        rows='5'
                                        cols='5'
                                        fullWidth
                                    />
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-6 mb-1">
                                            <label htmlFor='s'>Country*</label>
                                            <Select
                                                labelId="s"
                                                id="s"
                                                fullWidth
                                                size="small"
                                                required
                                                value={formValues.country}
                                                name="country"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value='select'>Select Country</MenuItem>
                                                {countries.map((el, i) => {
                                                    return (
                                                        <MenuItem key={i} value={el.name}>{el.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className="col-md-6 mb-1">
                                            <label htmlFor='state'>State*</label>
                                            &nbsp;
                                            {loadingState &&
                                                <CircularProgress style={{ color: '#53138D' }} size="1rem" />
                                            }
                                            <Select
                                                labelId="s"
                                                id="s"
                                                fullWidth
                                                size="small"
                                                required
                                                value={formValues.state}
                                                name="state"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value='select'>Select State</MenuItem>
                                                {states.map((el, i) => {
                                                    return (
                                                        <MenuItem key={i} value={el.name}>{el.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="mb-1 mt-4">

                                        {!isPending &&
                                            <button type="submit" className={`${styles.modal_btn} btn btn-block`}>
                                                Save

                                            </button>


                                        }
                                        {isPending && <button disabled className={`btn btn-block ${styles.modal_btn}`}>
                                            Submitting &nbsp;
                                            {isPending && <CircularProgress style={{ color: 'white' }} size="1rem" />

                                            }
                                        </button>
                                        }



                                    </div>
                                </form>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
                <GlobalModal
                    open={modalOpen}
                    onClose={handleClose2}
                    position='absolute'
                    top='50%'
                    left='50%'
                    transform='translate(-50%, -50%)'
                    width='740px !important'
                    overflow='auto'
                    bgcolor='#FFFF'
                    border='1px solid #F5F5F5'
                    borderRadius='5px'
                    boxShadow='24'
                    p='25px'
                >
                    <div className={`${styles.modal_content}`}>
                        <div className={`${styles.modal_header} mb-0`}>
                            <h5 style={{
                                fontSize: '19px',
                                fontWeight: '700',

                            }}>Filter</h5>
                            <span onClick={handleClose2} style={{ cursor: 'pointer' }}>
                                <img src="/assets/icons/x.svg" alt="" />
                            </span>

                        </div>


                        <form action="" className="mt-0">
                            <div className="row">
                                <div className="col-md-6 mb-1">
                                    <div className="mb-3">
                                        <LiveLocation label="Location" passData={processLocation} />



                                    </div>

                                </div>
                                <div className="col-md-6 mb-1">
                                    <label htmlFor="cat">Category</label>
                                    <Select
                                        labelId="s"
                                        id="s"
                                        fullWidth
                                        size="small"
                                        value={filterSearch.category}
                                        name="category"
                                        required
                                        onChange={handleCategoryChange}
                                    >

                                        <MenuItem value='select'>Select Category</MenuItem>
                                        <MenuItem value='Kiosk'>Kiosk</MenuItem>
                                        <MenuItem value='Neighborhood store'>Neighborhood store</MenuItem>
                                        <MenuItem value='Supermarket'>Supermarket</MenuItem>
                                        <MenuItem value='E-commerce store'>E-commerce store</MenuItem>
                                    </Select>


                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="cat">Status</label>
                                    <Select
                                        labelId="mod"
                                        id="mod"
                                        fullWidth
                                        size="small"
                                        name='status'
                                        value={filterSearch.status}
                                        required
                                        onChange={handleCategoryChange}
                                    >


                                        <MenuItem value='select'>select</MenuItem>
                                        <MenuItem value='all'>All</MenuItem>
                                        <MenuItem value='verified'>Verified</MenuItem>
                                        <MenuItem value='not-verified'>Not Verified</MenuItem>
                                    </Select>


                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="cat">Date</label>
                                    <div className="row">
                                        <div className="col-6">

                                            <DatePicker
                                                showIcon
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                placeholderText="Start"
                                                required
                                            />
                                            {/* <input 
                                                type="date" 
                                                className="form-control" 
                                                name='startDateFilter'
                                                value={filterSearch.startDateFilter}
                                                onChange={handleCategoryChange}
                                            /> */}
                                        </div>
                                        <div className="col-6">
                                            <DatePicker
                                                showIcon
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                placeholderText="End"
                                                required
                                            />
                                            {/* <input 
                                                type="date" 
                                                className="form-control"
                                                name='endDateFilter'
                                                value={filterSearch.endDateFilter}
                                                onChange={handleCategoryChange}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <button type="button" onClick={handleSubmitFilter} className={`${styles.modal_btn} btn btn-block`}>
                                    Proceed

                                </button>


                            </div>

                        </form>


                    </div>


                </GlobalModal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openVerify}
                    onClose={handleOpenVerify}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableEnforceFocus
                >
                    <Fade in={openVerify}>
                        <Box className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`} sx={style} style={{ height: '25vh' }}>
                            <div className={`text-center ${styles.add_to_cart_content}`}>
                                <img src="/assets/icons/Illustration.svg" alt="" />
                                <h5 className="mt-3">Store Verified</h5>
                                <div>
                                    <button className={`${styles.go_to} d-block w-100 `} style={{ fontSize: '14px' }} onClick={() => setOpenVerify(false)}>
                                        Confirm
                                    </button>
                                </div>

                            </div>

                        </Box>
                    </Fade>
                </Modal>

                <GlobalModal
                    open={modalCSVOpen}
                    onClose={handleClose2}
                    position='absolute'
                    top='50%'
                    left='50%'
                    transform='translate(-50%, -50%)'
                    width='740px !important'
                    overflow='auto'
                    bgcolor='#FFFF'
                    border='1px solid #F5F5F5'
                    borderRadius='5px'
                    boxShadow='24'
                    p='25px'
                >
                    <div className={`${styles.modal_content}`}>
                        <div className={`${styles.modal_header} mb-0`}>
                            <h5 style={{
                                fontSize: '19px',
                                fontWeight: '700',

                            }}>Import CSV File</h5>
                            <span onClick={handleClose2} style={{ cursor: 'pointer' }}>
                                <img src="/assets/icons/x.svg" alt="" />

                            </span>

                        </div>


                        <form action="" className="mt-0">
                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <div className="mb-3">
                                        <label htmlFor="loc">Note: File is expected to come in the format below</label>
                                        <p>Store Name, Store Category, Store Manager, Store Email, Store Phone Number, Store Address, Country, State.</p>
                                        <input className="d-block" type="file" name="file" accept=".csv" onChange={handleCsvFile} />
                                    </div>

                                </div>
                            </div>
                            <div className="mb-4">
                                <button type="button" onClick={handleSubmitCSV} className={`${styles.modal_btn} btn btn-block mt-4`}>
                                    Proceed {exportLoading && <CircularProgress style={{ color: 'white' }} size="1rem" />}

                                </button>


                            </div>

                        </form>


                    </div>


                </GlobalModal>

                <Dialog
                    open={openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className="card border-0">
                        <div className="card-body border-0 ">
                            <div className={`${styles.customized_row}`}>
                                <div className="text-center">
                                    <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

                                </div>
                                <div>
                                    <h5>
                                        Delete {outlets.outlet_name} outlet

                                    </h5>
                                    <p style={{
                                        fontSize: '14px',
                                        color: '#6B7280',
                                    }}>
                                        Are you sure you want to delete this outlet? All  your data will be permanently removed from our servers forever. This action cannot be undone.
                                    </p>

                                </div>

                            </div>

                            <DialogActions>
                                <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
                                {
                                    !isDeleting &&
                                    <Button className={`${styles.delete_product}`} onClick={handleDelete}>Delete</Button>
                                }

                                {isDeleting && <button disabled className={`btn ${styles.delete_product}`}>

                                    {isDeleting && <CircularProgress style={{ color: 'white' }} size="1rem" />

                                    }
                                </button>
                                }
                            </DialogActions>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    open={openDialog2}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className="card border-0">
                        <div className="card-body border-0 ">
                            <div className={`${styles.customized_row}`}>
                                <div className="text-center">
                                    <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

                                </div>
                                <div>
                                    <h4>
                                        Delete {deleteValue.store_name} Store

                                    </h4>
                                    <p style={{
                                        fontSize: '14px',
                                        color: '#6B7280',
                                    }}>
                                        Are you sure you want to delete this store ? All your data will be permanently removed from our servers forever. This action cannot be undone.
                                    </p>

                                </div>

                            </div>

                            <DialogActions>
                                <Button className={`${styles.cancel_delete}`} onClick={handleCloseDialog}>Cancel</Button>
                                <Button className={`${styles.delete_account}`} onClick={handleDeleteStore}>Delete</Button>
                            </DialogActions>
                        </div>
                    </div>
                </Dialog>

            </div>
        </>
    )
}
