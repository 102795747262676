// ----------------------------------------------------------------------
import styles from './BrandOwner.module.css';
import { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import bus from 'src/bus';
import FilterModal from './filterModal';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from 'src/axiosInstance';
import moment from 'moment';
import { downloadFile2 } from 'src/utils';

import LiveSelect from 'react-select';
import { useApiKeys } from 'src/hooks/useApiKeys';
import { Card } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
// sections
// mock
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Filter1 from 'src/pages/Filter/Filter1';
import { BallTriangle } from 'react-loader-spinner';
import { Pagination } from 'src/components';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2,
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#FFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};
export default function RetailerWallet() {
  const apiKeys = useApiKeys();
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [open, setOpen] = useState(false);
  const [openReport, setReportOpen] = useState(false);
  const handleReportClose = () => setReportOpen(false);
  const [exporting, setExporting] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen21 = () => setOpen1(true);
  const [otp, setOtp] = useState(false);
  const handleOpen2 = () => setOpen(true);
  const [values, setValues] = useState(['', '', '', '']);
  const inputs = useRef([]);
  const [n, setN] = useState([]);
  const [modalTitle, setmodalTitle] = useState('');
  const [modal_desc, setmodal_desc] = useState('');
  const [selectedOption2] = useState(null);
  let [bank_info, setBankInfo] = useState({ account_no: '', account_name: '' });
  const [banks, setBanks] = useState([]);
  const [nameEnquiryLoading, setNameEnquiryLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [amount, setAmountValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(
    'Amount cannot exceed ₦1000 daily'
  );
  const [topAmount, setTopAmountPrice] = useState('');
  const notifyError = (err) => toast.error(err);
  const notifySuccess = (err) => toast.success(err);
  const [statusFilter, setStatusFilter] = useState(false);
  const handleClose1 = () => {
    setOpen1(false);
    setAmountValue('');
  };
  const [filterSearch, setFilterSearch] = useState({
    transaction_id: '',
    transaction_type: '',
    min_amount: '',
    max_amount: '',
    dateTo: '',
    dateFrom: '',
    status: '',
  });
  const [loader, setLoader] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const [withdrawalLoader, setWithdrawalLoader] = useState(false);

  let ar = [];
  const [dataFilter, setDataFilter] = useState('today');

  // const hasPermission = (permission) => {
  //   const authData = JSON.parse(localStorage.getItem('auth')) || [];
  //   console.log('authData:', authData.direct_permissions);

  //   return authData.direct_permissions.some((el => el.permission_alias === permission))
  // };

  const hasPermission = (permission) => {
    const authData = JSON.parse(localStorage.getItem('auth')) || [];

    const hasPermission = authData?.direct_permissions?.some((el) => {
      // console.log('el.permission_alias:', el.permission_alias);
      // console.log('permission:', permission);
      return el.permission_alias === permission;
    });

    return hasPermission;
  };

  const submitOtpWallet = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosInstance
      .post('/shop/wallet/process-withdrawal', {
        otp: n.join(''),
        amount: amount,
      })
      .then((res) => {
        if (res.status < 300) {
          setOtp(false);
          setOpenDialog(true);
          setmodalTitle('Withdraw complete');
          setmodal_desc(
            'Your withdrawal has been processed. Check your mail for a notice of withdrawal'
          );
        }
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
          // setOtp(false);
        } catch (error) {
          notifyError(
            'Unable to complete transaction process, try again later'
          );
        }
      })
      .finally(() => setLoader(false));
  };
  const submitFundWallet = (e) => {
    e.preventDefault();
    if (amount > 1000) {
      return notifyError(
        'The daily amount you can withdraw from your wallet cannot exceed ₦1000'
      );
    }
    if (amount < 100) {
      setError(true);
      setHelperText('You cannot initiate withdrawal less than ₦100');
      return;
    }
    setWithdrawalLoader(true);
    axiosInstance
      .post('/shop/wallet/initiate-withdrawal')
      .then((res) => {
        if (res.status < 300) {
          notifySuccess(res.data.message);
          setOtp(true);
          setOpen1(false);
        } else {
          notifyError(res.data.message);
        }
      })
      .finally(() => setWithdrawalLoader(false));
  };
  const moveFocus = (event) => {
    setTimeout(() => {
      const input = event.target;
      const nextInput = inputs.current[inputs.current.indexOf(input) + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }, 0);
  };

  const fetchCountries = async () => {
    const countriesApi = await fetch('https://restcountries.com/v3.1/all');
    const countriesJson = await countriesApi.json();
    const countriesData = countriesJson?.map((country) => ({
      code: country.cca2,
      name: country.name.common,
    }));
    setCountries(countriesData.sort((a, b) => a.name.localeCompare(b.name)));
  };
  const fetchStates = async () => {
    if (country === '') return;
    setStates([]);
    setState('');

    setLoadingState(true);
    const statesFetch = await fetch(
      `https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/states.json`,
      {}
    );
    const statesJson = await statesFetch.json();
    const statesData = statesJson;
    setStates(() => {
      return statesData
        .filter((el, i) => el.country_name === country)
        .sort((a, b) => a.name.localeCompare(b.name));
    });
    setLoadingState(false);
  };

  useEffect(() => {
    console.log(states);
  }, [states]);
  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    fetchStates();
  }, [country]);

  const fetchBanks = async () => {
    const banksApi = await fetch('https://api.paystack.co/bank');
    const banks = await banksApi.json();
    if (banks.status) {
      setBanks(banks.data);
    }
  };

  const nameEnquiry = async () => {
    if (bank_info.account_no.length < 10 || bank_info.account_no.length > 11)
      return;

    setNameEnquiryLoading(true);
    bank_info.account_name = '';
    const accountNameApi = await fetch(
      `https://api.paystack.co/bank/resolve?account_number=${bank_info.account_no}&bank_code=${bank_info.bank_code}`,
      {
        headers: {
          Authorization: `Bearer ${apiKeys.PASTACK.SECRET_KEY}`,
        },
      }
    );
    const accountName = await accountNameApi.json();
    setNameEnquiryLoading(false);

    if (accountName.status) {
      const data = bank_info;
      data.account_name = accountName.data.account_name;
      setBankInfo(data);
    } else {
      notifyError(accountName.message);
    }
  };

  const handleBankAccountChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBankInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    nameEnquiry();
  }, [bank_info.account_no]);

  useEffect(() => {
    const data = bank_info;
    data.account_no = '';
    setBankInfo(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank_info.bank_code]);

  const submitReport = (e) => {
    e.preventDefault();
    setOpenDialog(true);
    setmodalTitle('Report  Sent');
    setReportOpen(false);
  };
  const handleChange = (event) => {
    const input = event.target;
    const newValues = [...values];
    newValues[inputs.current.indexOf(input)] = input.value;
    ar.push(newValues);
    setN(newValues);
    setValues(newValues);
    moveFocus(event);

    // if(n.length ===4) {
    //   alert()
    //   const form = formRef.current;

    //   // Create a new `submit` event
    //   const submitEvent = new Event('submit');

    //   // Dispatch the event
    //   form.dispatchEvent(submitEvent);

    // }
  };

  const handleAmountChange = (value) => {
    if (parseFloat(value) > 1000) {
      setError(true);
      setHelperText('Amount cannot exceed ₦1000 daily');
    } else {
      setError(false);
      setHelperText('Amount cannot exceed ₦1000 daily');
    }
    setAmountValue(value);
  };

  const handleBankChange = (e) => {
    const value = e.value;
    setBankInfo((prev) => {
      return {
        ...prev,
        bank_code: value,
      };
    });
  };

  useEffect(() => {
    bus.on('open-wallet-topup', () => {
      handleOpen2();
    });

    bus.on('open-wallet-withdraw', () => {
      handleOpen21();
    });
  }, []);

  useEffect(() => {
    bus.on('dataFilter', (el) => {
      setDataFilter(el);
    });

    bus.on('search-filter', (el) => {
      setFilterSearch(el);
      setStatusFilter(!statusFilter);
    });

    fetchData();
    fetchCardData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, dataFilter]);

  // pagination
  const [walletData, setdata] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [fundPending, setFundPending] = useState(false);
  const [walletPagination, setWalletPagination] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  bus.on('reload', () => {
    fetchData();
    fetchCardData();
  });

  const fetchData = async (page = 1, pageSize = 10, export_type = '') => {
    if (export_type) {
      setExporting(true);

      let queryParams = new URLSearchParams({
        page,
        pageSize,
        transaction_id: filterSearch.transaction_id,
        transaction_type: filterSearch.transaction_type,
        status: filterSearch.status,
        startDate: filterSearch.dateFrom,
        endDate: filterSearch.dateTo,
        min_amount: filterSearch.min_amount,
        max_amount: filterSearch.max_amount,
        export_type: export_type,
      }).toString();

      try {
        await downloadFile2(
          'All Wallets',
          `/shop/wallet/transactions?${queryParams}`,
          export_type
        );
      } finally {
        // alert()
        setExporting(false);
      }
      return;
    }
    setIsPending(true);
    axiosInstance
      .get('/shop/wallet/transactions', {
        params: {
          page,
          pageSize,
          export_type,
          transaction_id: filterSearch.transaction_id,
          transaction_type: filterSearch.transaction_type,
          status: filterSearch.status,
          dateFrom: filterSearch.dateFrom,
          dateTo: filterSearch.dateTo,
          min_amount: filterSearch.min_amount,
          max_amount: filterSearch.max_amount,
        },
      })
      .then((res) => {
        setdata(res.data.data.data);
        setAccountDetails(res.data.data.wallet_info);
        setWalletPagination(res.data.data.pagination);
        // bus.emit('reset-search-filter')
        // setFilterSearch({
        //   transaction_id: '',
        //   transaction_type: '',
        //   min_amount: '',
        //   max_amount: '',
        //   dateTo: null,
        //   dateFrom: null,
        //   status: '',
        // });
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch wallet transactions, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const fetchCardData = (period = dataFilter) => {
    setIsPending(true);
    axiosInstance
      .get('/shop/wallet/card?period=' + period)
      .then((res) => {
        setCardData(res.data.data);
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError('Unable to fetch wallet analytics, try again later');
        }
      })
      .finally(() => setIsPending(false));
  };

  const topWalletData = (e) => {
    e.preventDefault();
    let amount = Number(topAmount);
    setFundPending(true);
    axiosInstance
      .post('/shop/wallet/top-up', {
        amount,
        payment_gateway: 'paystack',
      })
      .then((res) => {
        window.open(res.data.data.url, '_self');
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
          setOpen(false);
          setTopAmountPrice('');
        } catch (error) {
          notifyError('Something went wrong , please try again later');
          setOpen(false);
          setTopAmountPrice('');
        }
      })
      .finally(() => setFundPending(false));
  };

  const parseString = (v) => {
    return v.replace(/_/g, ' ');
  };

  useEffect(() => {
    hasPermission('manage-wallets');
  }, []);

  return (
    <>
      <div className="wrapper px-2 mt-4">
        <div className="header d-block d-md-none mb-4">
          <div
            className={`${styles.wallet_actions} d-md-flex justify-content-between`}
          >
            <div className=" ">
              <h4 className={`mb-0 pb-0`}>Wallet</h4>
              <p style={{ fontSize: '14px' }} className={`mb-0 mt-2 pb-0`}>
                Manage all your cash transactions
              </p>
            </div>
            {hasPermission('manage-wallets') && (
              <div className={`${styles.wallet_button} mt-3 mt-md-0`}>
                <button
                  type="button"
                  onClick={handleOpen2}
                  className={`btn ${styles.top_up_button}`}
                >
                  <span>Top Up</span>
                </button>
                &nbsp; &nbsp;
                <button
                  onClick={handleOpen21}
                  className={`btn ${styles.transfer}`}
                >
                  <span>Withdraw</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.analytics}`}>
          <Filter1 width="61%" />

          <div className={`${styles.customized_row}`}>
            <div>
              <div className={` ${styles.customized_row1} mb-2`}>
                <div className={`${styles.card} mb-4`}>
                  <div className={`card-body`}>
                    <div className="d-flex justify-content-between">
                      <small>Cash Inflow </small>
                    </div>
                    <div className={`${styles.numbers} mt-3`}>
                      <div>
                        <span>
                          ₦{' '}
                          {cardData?.income
                            ? Number(
                                cardData?.income?.current_income
                              ).toLocaleString()
                            : 0}
                        </span>
                        &nbsp;
                        <small>
                          from ₦
                          {cardData?.income
                            ? Number(
                                cardData?.income?.previous_income
                              ).toLocaleString()
                            : 0}
                        </small>
                      </div>
                      <div>
                        {Number(cardData?.income?.current_income) >=
                        Number(cardData?.income?.previous_income) ? (
                          <div className={styles.percent}>
                            <img
                              src="/assets/icons/up.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {cardData?.income?.percentage_income > 0
                                ? Number(
                                    cardData?.income?.percentage_income
                                  ).toLocaleString()
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2}`}>
                            <img
                              src="/assets/icons/downr.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {cardData?.income
                                ? Math.abs(
                                    Number(
                                      cardData?.income?.percentage_income
                                    ).toLocaleString()
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.card} mb-4`}>
                  <div className={`card-body`}>
                    <div className="d-flex justify-content-between">
                      <small>Cash in Hand</small>
                    </div>
                    <div className={`${styles.numbers} mt-3`}>
                      <div>
                        <span>
                          ₦{' '}
                          {cardData?.cash
                            ? Number(
                                cardData?.cash?.current_cash
                              ).toLocaleString()
                            : 0}
                        </span>
                        &nbsp;
                        <small>
                          from ₦
                          {cardData?.cash
                            ? Number(
                                cardData?.cash?.previous_cash
                              ).toLocaleString()
                            : 0}
                        </small>
                      </div>
                      <div>
                        {Number(
                          cardData?.cash?.current_cash
                        ).toLocaleString() >=
                        Number(
                          cardData?.cash?.previous_cash
                        ).toLocaleString() ? (
                          <div className={styles.percent}>
                            <img
                              src="/assets/icons/up.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {cardData?.cash?.previous_cash > 0
                                ? Number(
                                    cardData?.cash?.previous_cash
                                  ).toLocaleString()
                                : 0}
                              %
                            </small>
                          </div>
                        ) : (
                          <div className={`${styles.percent2}`}>
                            <img
                              src="/assets/icons/downr.svg"
                              className=" "
                              alt=""
                            />
                            <small>
                              {cardData?.cash
                                ? Math.abs(
                                    Number(
                                      cardData?.cash?.previous_cash
                                    ).toLocaleString()
                                  )
                                : 0}
                              %
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={` ${styles.customized_row1} `}>
                <div className={` mb-2 pr-0`}>
                  <div className={`${styles.card}`}>
                    <div className={`card-body`}>
                      <div className="d-flex justify-content-between">
                        <small>Total Withdrawal </small>
                      </div>
                      <div className={`${styles.numbers}`}>
                        <div>
                          <span>
                            ₦{' '}
                            {cardData?.expense
                              ? Number(
                                  cardData?.expense?.current_expense
                                ).toLocaleString()
                              : 0}
                          </span>
                          &nbsp;
                          <small>
                            from ₦
                            {cardData?.expense
                              ? Number(
                                  cardData?.expense?.previous_expense
                                ).toLocaleString()
                              : 0}
                          </small>
                        </div>
                        <div>
                          {Number(
                            cardData?.expense?.current_expense
                          ).toLocaleString() >=
                          Number(
                            cardData?.expense?.previous_expense
                          ).toLocaleString() ? (
                            <div className={styles.percent}>
                              <img
                                src="/assets/icons/up.svg"
                                className=" "
                                alt=""
                              />
                              <small>
                                {cardData?.expense?.percentage_expense > 0
                                  ? Number(
                                      cardData?.expense?.percentage_expense
                                    ).toLocaleString()
                                  : 0}
                                %
                              </small>
                            </div>
                          ) : (
                            <div className={`${styles.percent2}`}>
                              <img
                                src="/assets/icons/downr.svg"
                                className=" "
                                alt=""
                              />
                              <small>
                                {cardData?.expense
                                  ? Math.abs(
                                      Number(
                                        cardData?.expense?.percentage_expense
                                      ).toLocaleString()
                                    )
                                  : 0}
                                %
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`mb-2`}>
                  <div className={`${styles.card}`}>
                    <div className={`card-body`}>
                      <div className="d-flex justify-content-between">
                        <small>Total Deposit </small>
                      </div>
                      <div className={`${styles.numbers}`}>
                        <div>
                          <div>
                            <span>
                              ₦{' '}
                              {cardData?.deposit
                                ? Number(
                                    cardData?.deposit?.current_deposit
                                  ).toLocaleString()
                                : 0}
                            </span>
                            &nbsp;
                            <small>
                              from ₦
                              {cardData?.deposit
                                ? Number(
                                    cardData?.deposit?.previous_deposit
                                  ).toLocaleString()
                                : 0}
                            </small>
                          </div>
                        </div>
                        <div>
                          {Number(
                            cardData?.deposit?.current_deposit
                          ).toLocaleString() >=
                          Number(
                            cardData?.deposit?.previous_deposit
                          ).toLocaleString() ? (
                            <div className={styles.percent}>
                              <img
                                src="/assets/icons/up.svg"
                                className=" "
                                alt=""
                              />
                              <small>
                                {cardData?.deposit?.percentage_deposit > 0
                                  ? Number(
                                      cardData?.deposit?.percentage_deposit
                                    ).toLocaleString()
                                  : 0}
                                %
                              </small>
                            </div>
                          ) : (
                            <div className={`${styles.percent2}`}>
                              <img
                                src="/assets/icons/downr.svg"
                                className=" "
                                alt=""
                              />
                              <small>
                                {cardData?.deposit
                                  ? Math.abs(
                                      Number(
                                        cardData?.deposit?.percentage_deposit
                                      ).toLocaleString()
                                    )
                                  : 0}
                                %
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className={`${styles.card2} card `} style={{ padding: '' }}>
                {/* <div>
                </div> */}
                <div className={`${styles.wallet}`}>
                  <img
                    className={`${styles.img} ${styles.img3}`}
                    src="/assets/newccr.png"
                    alt=""
                    style={{ borderRadius: '50px' }}
                  />

                  {/* Background color */}
                  <div
                    className={` ${styles.darkcardbg}`}
                    style={{ borderRadius: '50px' }}
                  ></div>

                  <div
                    className={`${styles.content}`}
                    style={{
                      height: '250px',
                      borderRadius: '50px',
                    }}
                  >
                    <div className={`${styles.content_header}`}>
                      <div>
                        {/* ₦‎ 26,500,853 */}₦{' '}
                        {accountDetails.balance
                          ? Number(
                              accountDetails.balance.$numberDecimal
                            ).toLocaleString()
                          : '0.00'}
                      </div>
                      <div>
                        <img src="/assets/icons/Vector.svg" alt="" />
                      </div>
                    </div>
                    <p className={`${styles.sub_text}`}>Your Balance</p>
                    <div className={`${styles.content_body}`}>
                      <p className={`${styles.sub_text}`}>Account Details</p>

                      <div className={`${styles.bank_info}`}>
                        <div>
                          <p className={`${styles.name} font-weight-bold`}>
                            {accountDetails.store_account_name}
                          </p>
                          {/* <p className={`${styles.name}`}>
                            {cardData.account_details?cardData.account_details.bank_name:''}
                          </p> */}
                          <p className={`${styles.account_number}`}>
                            {accountDetails.store_account_number}
                          </p>
                        </div>
                        {/* <img className="mt-2" src="/assets/Logomark.svg" alt="" /> */}
                        <span
                          style={{
                            fontSize: '9px',
                            fontWeight: '700',
                            color: '#FFFFFF',
                          }}
                        >
                          {accountDetails?.store_bank_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card
          style={{ marginTop: '10px', borderRadius: '10px' }}
          className="p-0"
        >
          <div className="p-4 d-sm-flex justify-content-between">
            <div>
              <h5 className="table_desc_header">All Transactions</h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <small
                  style={{
                    fontWeight: '200',
                    fontSize: '12px',
                    color: '#4b5563',
                  }}
                >
                  Your transaction history
                </small>
              </div>
            </div>
            <div className="d-flex mt-3 mt-sm-0">
              <div className="dropleft mb-1 ">
                <button
                  className="btn d-flex justify-content-center align-items-center border"
                  onClick={() => bus.emit('open-filter-wallet')}
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #D0D5DD',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    borderRadius: '4px',
                  }}
                >
                  <img
                    className=""
                    style={{ display: 'inline', width: '17px' }}
                    src="/assets/icons/filterlines.svg"
                    alt=""
                  />
                  &nbsp;
                  <small className="d-none d-md-inline-block">Filters</small>
                </button>
              </div>
              <span className="">&nbsp; &nbsp;</span>
              <div className="dropleft ">
                <button
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className={`${styles.export_btn} btn m-0`}
                >
                  <span
                    style={{
                      paddingTop: '8px',
                    }}
                  >
                    <img src="/assets/icons/download.svg" alt="" /> &nbsp;
                    {!exporting && <span>Export</span>}
                    {exporting && (
                      <CircularProgress
                        style={{
                          color: 'white',
                          display: 'inline-block',
                        }}
                        size="1rem"
                      />
                    )}
                  </span>
                  &nbsp; &nbsp;
                  <span
                    style={{
                      borderLeft: '1px solid white',
                      display: 'inline-block',
                      height: 'inherit',
                      paddingTop: '8px',
                      paddingLeft: '8px',
                      textAlign: 'center',
                    }}
                  >
                    <img src="/assets/icons/arrowDown.svg" alt="" />
                  </span>
                </button>
                <div
                  className="dropdown-menu drop-left"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    onClick={() => fetchData(currentPage, 200000, 'csv')}
                    type="button"
                    className="rss dropdown-item btn border-0"
                  >
                    CSV
                  </button>
                  <button
                    onClick={() => fetchData(currentPage, 200000, 'excel')}
                    type="button"
                    className="rss dropdown-item btn border-0"
                  >
                    Excel
                  </button>
                  <button
                    onClick={() => fetchData(currentPage, 200000, 'pdf')}
                    type="button"
                    className="rss dropdown-item btn border-0"
                  >
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Scrollbar>
            <div className={`table-responsive-sm `}>
              <table className={`${styles.table} table-striped`}>
                <thead>
                  <tr className={`${styles.tr} ${styles.thead_tr} pb-3`}>
                    <td width={'30%'}>Transaction Id</td>
                    <td>Type</td>
                    <td>Action</td>
                    <td>Date</td>
                    <td>Amount</td>
                    <td>Status</td>
                  </tr>
                </thead>

                <tbody className="">
                  {!isPending &&
                    walletData.length >= 1 &&
                    walletData.map((el, i) => {
                      return (
                        <tr key={el?._id} className={`${styles.tr} `}>
                          <td>
                            <b>{el?.transaction_id}</b>
                          </td>
                          <td>
                            <span>
                              {el?.transaction_type
                                ?.split('_')
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(' ')}
                            </span>
                          </td>
                          <td>
                            <span style={{ textTransform: 'capitalize' }}>
                              {parseString(el?.transaction_action)}
                            </span>
                          </td>
                          <td>{moment(el?.createdAt).format('DD/MM/YYYY')}</td>
                          <td>
                            ₦{' '}
                            {el?.amount
                              ? Number(
                                  el?.amount?.$numberDecimal
                                ).toLocaleString()
                              : 0}
                          </td>
                          <td>
                            {/* <span className="success">
                            {el.transaction_status}
                          </span> */}
                            <p>
                              <span
                                className={`${
                                  el?.transaction_status === 'pending'
                                    ? styles.pending
                                    : styles.customer
                                }`}
                              >
                                {el?.transaction_status}
                              </span>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {isPending && (
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              )}
            </div>
            {!walletData.length && !isPending && (
              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>
            )}
          </Scrollbar>
          {/* pagination */}
          <div>
            <Pagination
              currentPage={currentPage}
              dataPagination={walletPagination}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              fetchData={fetchData}
            />
          </div>
        </Card>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header mb-0`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    marginBottom: '0',
                  }}
                >
                  Withdraw
                </h5>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Withdraw from your wallet
              </p>

              <form onSubmit={submitFundWallet} className="mt-0">
                <div className="mb-0 relative_position">
                  <label htmlFor="amount"> Amount </label>
                  <span>
                    <img src="/assets/icons/naira.svg" alt="" />
                  </span>
                  <TextField
                    required
                    type="number"
                    id="amount"
                    maxLength={10}
                    value={amount}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || parseFloat(inputValue) > 0) {
                        handleAmountChange(inputValue);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e' || e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                    onFocus={(e) => {
                      const inputValue = e.target.value;
                      if (parseFloat(inputValue) > 1000) {
                        handleAmountChange('1000');
                      }
                    }}
                    error={error}
                  />
                </div>
                <small className="text-danger" style={{ fontSize: '10px' }}>
                  {' '}
                  {helperText}{' '}
                </small>
                <div className="mb-4 mt-4">
                  <button type="submit" className={`modal_btn btn btn-block`}>
                    Proceed
                    {withdrawalLoader && (
                      <CircularProgress
                        style={{ color: '#fff' }}
                        size="0.8rem"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header mb-0`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Top up
                </h5>
                <span
                  onClick={() => setOpen(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Fund your wallet
              </p>

              <form onSubmit={topWalletData}>
                <div className="mb-4 relative_position">
                  <label htmlFor="amount">Amount</label>
                  <span>
                    <img src="/assets/icons/naira.svg" alt="" />
                  </span>
                  <TextField
                    required
                    type="number"
                    id="amount"
                    size="small"
                    fullWidth
                    value={topAmount}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || parseFloat(inputValue) > 0) {
                        setTopAmountPrice(inputValue);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e' || e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>

                <div className="mb-4">
                  <button type="submit" className={`modal_btn btn btn-block`}>
                    Proceed{' '}
                    {fundPending && (
                      <CircularProgress
                        style={{ color: '#fff' }}
                        size="0.8rem"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={otp}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={otp}>
          <Box sx={style2} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Confirmation
                </h5>
                <span
                  onClick={() => setOtp(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Enter OTP sent to your mail to confirm <br /> transaction
              </p>

              <form onSubmit={submitOtpWallet}>
                <div className="container  pt-5 px-5 pb-5 text-center mb-4">
                  <div
                    className={`d-flex  justify-content-center input_container`}
                  >
                    {/*  */}
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[0] = el)}
                      />
                    </div>
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[1] = el)}
                      />
                    </div>
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[2] = el)}
                      />
                    </div>
                    <div className=" p-0 ml-2">
                      <input
                        type="number"
                        maxLength={1}
                        className="form-control"
                        onChange={handleChange}
                        ref={(el) => (inputs.current[3] = el)}
                      />
                    </div>
                  </div>
                  <small
                    className="text-[#53138D]"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  >
                    Resend Otp
                  </small>
                </div>

                <div className="mb-4">
                  <button type="submit" className={`modal_btn btn btn-block`}>
                    Proceed{' '}
                    {loader && (
                      <CircularProgress
                        style={{ color: '#fff' }}
                        size="0.8rem"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openDialog}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>
                {modalTitle}
              </h5>
              <p
                className="text-center"
                style={{ textAlign: 'center !important' }}
              >
                {modal_desc}
              </p>
              <button
                onClick={() => setOpenDialog(false)}
                className={`modal_btn hover btn btn-block`}
              >
                Back to Wallet
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openReport}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openReport}>
          <Box sx={style} className={`mobile_modal_size`}>
            <div className={`modal_content`}>
              <div className={`modal_header`}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  Help Center
                </h5>
                <span onClick={handleReportClose} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />
                </span>
              </div>
              <p
                className="text-muted mt-0"
                style={{
                  fontSize: '14px',
                }}
              >
                Report any issue related to this transaction
              </p>

              <form onSubmit={submitReport}>
                <div className="mb-4">
                  <label htmlFor="subject">Subject</label>
                  <TextField
                    required
                    type="text"
                    id="subject"
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="complaint">Your complaint</label>
                  <TextField
                    required
                    type="text"
                    id="complaint"
                    size="small"
                    multiline
                    rows="5"
                    cols="5"
                    fullWidth
                  />
                </div>

                <div className="mb-4 text-right">
                  <button type="submit" className={`modal_btn btn `}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <FilterModal />
    </>
  );
}
