import styles from "../BrandOwner.module.css"

import {
    Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import bus from 'src/bus';
import { Pagination } from "src/components";

// sections
// mock
import { useEffect, useState } from "react"
import { useLayoutEffect } from "react";
import GlobalModal from "src/Modals/GlobalModal";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useAuth } from "src/hooks/useAuth";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import Filter from "./Filter";
import axiosInstance from "src/axiosInstance"
import toast from 'react-hot-toast';
import moment from "moment";
import { BallTriangle } from 'react-loader-spinner'
import CircularProgress from '@mui/material/CircularProgress';
import {
    Avatar
} from '@mui/material';
import USERLIST from '../../../_mock/order';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'sn', label: 'S/N', alignRight: false },
    { id: 'brandname', label: 'Brand name', alignRight: false },
    { id: 'stores', label: 'Stores', alignRight: false },
    { id: 'Balance', label: 'PO Balance', alignRight: false },
    { id: 'view', label: 'View', alignRight: false },
];
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));
// ----------------------------------------------------------------------

function changeView(d) {
    localStorage.setItem('ptvs', d);
}

// ptvs=PAGE TO VIEW (SALES)

export default function BrandsIndex() {
    
    const [product, setProduct] = useState(1);
    const [productsAdAdded, setProductsAdded] = useState([]);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const open1 = Boolean(anchorEl1);
    const [searchvalue, setSearchvalue] = useState('');
    const [isPending, setIsPending] = useState('');
    const [brandsData, setBrandsData] = useState([]);
    const [dataPagination, setDataPagination] = useState('');
    const notify = (msg =null) => toast.success(msg === null ?'Store saved .' : msg);
    const notifyError = (err) => toast.error(err);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterSearch, setFilterSearch] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [filterStatus, setFilterStatus] = useState(false)
    


    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };
    
    const handleProductChange = (event) => {
        setProduct(event.target.value);
        let data = productsAdAdded
        let val = event.target.value
        if (val != 1) {
            if (data.includes(val) == false) {
                data.push(val)
                setProductsAdded(data)

            }
        }
    };

    const removeFromList = (index) => {
        let data = [...productsAdAdded]
        data = data.filter((_, i) => i !== index);
        if (data['length'] === 0) setProduct(1)
        setProductsAdded(data)

    }

    useLayoutEffect(() => () => {
        localStorage.removeItem('ptvs')
    }, [])


    
    const navigateToPage = (val) => {
        localStorage.setItem('selected_user_insight', JSON.stringify(val) || '')
        console.log(val)
        navigate('/app/brand_insight/' + val._id)
    }

    const getInitials = (val) => {
        if (val.name) {
            const splitName = val.name.split(' ');
            const initials = splitName.map(name => name.charAt(0)).join('').toUpperCase();
            return initials;
        }
    };

    const searchFilter = (e) => {
        setSearchvalue(e.target.value)
      }
    
      const fetchData = (page = 1, pageSize = 10,) => {
        
        setIsPending(true);
        handleClose(false)
        axiosInstance.get('/admin/data-insights/brands-data-insights?', {
            params: {
                page,
                pageSize,
                search: searchvalue,
                ...filterSearch,
            
            }
        })
            .then(res => {
              // console.log(res.data.data);
              if(res.status < 300) {
                setBrandsData(res.data.data.data)
              }
                setDataPagination(res.data.data.pagination)
                setFilterSearch({
                  transaction_status: '',
                  transaction_type: '',
                  dateEnd: '',
                  dateStart: '',
                  transaction_max_price: '',
                  transaction_min_price: '',
                })
                console.log(brandsData);
    
    
            }).catch(err => {
    
                try {
                    notifyError(err.response.data.message);
    
                } catch (error) {
                    notifyError("Unable to fetch data insight, please try again later");
    
                }
    
            }).finally(() => setIsPending(false))
      }
      
    
      useEffect(() => {
        fetchData()
      }, [searchvalue,filterStatus])

      useEffect(() => {
        bus.on('filterSearch', (val) => {
            console.log(val);
            setFilterSearch(val)
            setFilterStatus(!filterStatus)
        })
        console.log(filterSearch);
      }, [filterSearch])

    return (
        <>
            <div className=" pt-2 ">

                <div className={`${styles.analytics}  `}>

                </div>
                <div className={`${styles.filters} mt-4`}>
                    <div className="row">
                        <div className={`${styles.hold_input} col-6 col-md-3   `}>
                            <img src="/assets/icons/search.svg" alt="" />
                            <input 
                                type="text" 
                                value={searchvalue} 
                                onChange={searchFilter} 
                                placeholder="Search" 
                                style={{ 
                                    textIndent: '25px', 
                                    width: '100%' 
                                }} 
                                className=" form-control" 
                            />
                        </div>
                        <div className="col-6 col-md-9  text-right">
                            {/* <div className="dropleft ">
                                <button className="btn  border" onClick={() =>  bus.emit('open-filter')} aria-haspopup="true" aria-expanded="false"
                                    style={{
                                        backgroundColor: '#fff',
                                        border: '1px solid #D0D5DD',
                                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                        borderRadius: '4px'
                                    }}>
                                    <img className="" style={{ display: 'inline', width: '17px' }} src="/assets/icons/filterlines.svg" alt="" />
                                    &nbsp;
                                    <small className="d-none d-md-inline-block">
                                        Filters
                                    </small>
                                </button>
                                <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <Card style={{ marginTop: '50px', borderRadius: '10px' }} className='p-0'>
                    <div className='p-3 '>
                        <div className="d-md-flex justify-content-between">
                            <div className="pb-3 pb-md-0">
                                <h5 className="table_desc_header">
                                    {/* {orderType}  */}
                                    Brand list
                                </h5>
                                <small style={{ fontWeight: '200', fontSize: '12px', display: 'inline-block', marginTop: '0px' }}>
                                    Performance of registered brands on FlexDeals
                                </small>
                            </div>
                            <div className="pt-md-2">
                                <div className="dropleft ">
                                    <button 
                                        id="dropdownMenuButton" 
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                                        className={`${styles.export_btn} btn m-0 mr-5`}
                                        style={{
                                        fontSize:'11px'
                                        }}
                                    >
                                        <span style={{
                                        paddingTop: '8px',
                                        }}> 
                                        <img src="/assets/icons/downloadwhite.png" alt="" /> &nbsp;
                                        Export
                                        </span>
                                        &nbsp;
                                        &nbsp;
                                        <span style={{
                                        borderLeft:'1px solid white',
                                        display:'inline-block',
                                        height:'inherit',
                                        paddingTop: '8px',
                                        paddingLeft: '8px',
                                        textAlign:'center',
                                        }}>
                                        <img src="/assets/icons/chevron-down.png" alt="" /> 
                                        </span>
                                    </button>
                                    <div className="dropdown-menu drop-left" aria-labelledby="dropdownMenuButton">
                                        <button type="button" className="rss dropdown-item btn border-0">CSV</button>
                                        <button type="button" className="rss dropdown-item btn border-0">Excel </button>
                                        <button type="button" className="rss dropdown-item btn border-0">PDF </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Scrollbar>
                        <div className={`table-responsive-sm pt-3 pb-3 pl-0 pr-0`}  >
                            <table className={`${styles.table} table-hover table-striped`}>
                                <thead className={`${styles.thead}`}>
                                    <tr>
                                        {TABLE_HEAD.map((el, i) => {
                                            let sIndex = i === 1 ? 1 : false;
                                            return (
                                                <th className="text-muted">{el.label} {sIndex && (
                                                    <span style={{ cursor: 'pointer' }}>
                                                        <img className="pl-2" src="/assets/icons/down.svg" alt="" />
                                                    </span>
                                                )}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!isPending && brandsData.length >= 1) && brandsData.map((el,i) => (
                                        <tr key={i} className={`${styles.tr}`}>
                                            <td>
                                                {(currentPage - 1) * itemsPerPage + i + 1}
                                            </td>
                                            <td>
                                                <div className=" " style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {/* <Avatar className={`${styles.avatrr} mr-3 float-md-left`} alt="" src="/assets/image1.svg" /> */}
                                                    <div className="initials">{getInitials(el)}</div>
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    <div>
                                                        <span>
                                                            <strong>{el?.name ? el?.name : '---'}</strong>
                                                        </span>
                                                        <br />
                                                        <span>
                                                        {el?.email ? el?.email : '---'}
                                                        </span>

                                                    </div>
                                                </div>
                                            </td>
                                            <td >
                                                <span>
                                                    {el?.stores}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    ₦ {el?.balance ? Number(el.balance).toLocaleString() : 0}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="cursor_pointer" onClick={() => navigateToPage(el)}>
                                                    <img src="/assets/icons/viewbutton.svg" alt="" />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-center">
                        {isPending &&
                        <BallTriangle

                            height={50}
                            width={55}
                            radius={5}
                            color="#53138D"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                        />
                        }
                    </div>
                    {
                        (!brandsData.length && !isPending) &&

                        <div className="alert alert-[#53138D] text-center">
                        <h4>No record found</h4>
                        </div>

                    }

                    </Scrollbar>
                    <div>
                        <Pagination
                            currentPage={currentPage}
                            dataPagination={dataPagination}
                            itemsPerPage={itemsPerPage}
                            setCurrentPage={setCurrentPage}
                            fetchData={fetchData}
                        />
                    </div>
                </Card>
            </div>

            <GlobalModal
                open={modalOpen}
                onClose={handleClose}
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                width='740px !important'
                overflow='auto'
                bgcolor='#F5F5F5'
                border='1px solid #F5F5F5'
                borderRadius='5px'
                boxShadow='24'
                p='25px'
            >
                <div className={`${styles.modal_content}`}>
                    <div className={`${styles.modal_header} mb-0`}>
                        <h5 style={{
                            fontSize: '19px',
                            fontWeight: '700',

                        }}>Filter</h5>
                        <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                            <img src="/assets/icons/x.svg" alt="" />

                        </span>

                    </div>


                    <form action="" className="mt-0">
                        <div className="row">
                            <div className="col-md-6 mb-1">

                                <div className="mb-3">
                                    <label htmlFor="name">Location</label>
                                    <TextField
                                        required
                                        type='text'
                                        id="loc"
                                        placeholder="Enter Location"
                                        size="small"
                                        fullWidth
                                        

                                    />


                                </div>

                                <div className="mb-3">
                                    <label htmlFor="name">Category</label>
                                    <Select
                                        labelId="name"
                                        id="name"
                                        fullWidth
                                        size="small"
                                        value={product}
                                        required
                                        onChange={handleProductChange}
                                    >

                                        <MenuItem value={1}>Select Category</MenuItem>
                                        <MenuItem value={'Toiletries'}>Category 1</MenuItem>
                                        <MenuItem value={'Biscuite'}>Category 2</MenuItem>
                                        {/* <MenuItem value={'Paper'}>Paper</MenuItem> */}
                                    </Select>


                                    <div className="products mt-2">
                                        {productsAdAdded.map((el, i) => {

                                            return (
                                                <div className={`${styles.product_selected}`}>
                                                    <div className=" d-flex justify-content-between">
                                                        <span >{el}</span>
                                                        &nbsp;
                                                        &nbsp;
                                                        <span style={{ cursor: 'pointer' }} onClick={() => removeFromList(i)}>
                                                            <img style={{ width: '5px' }} src="/assets/icons/x.svg" alt="" />

                                                        </span>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>


                                </div>

                                <div className="mb-3">
                                    <label htmlFor="name">Status</label>
                                    <Select
                                        labelId="name"
                                        id="name"
                                        fullWidth
                                        size="small"
                                        value={product}
                                        required
                                        onChange={handleProductChange}
                                    >

                                        <MenuItem value={1}>Select Status</MenuItem>
                                        <MenuItem value={'Toiletries'}>Pending</MenuItem>
                                        <MenuItem value={'Biscuite'}>Approved</MenuItem>
                                        {/* <MenuItem value={'Paper'}>Paper</MenuItem> */}
                                    </Select>


                                    <div className="products mt-2">
                                        {productsAdAdded.map((el, i) => {

                                            return (
                                                <div className={`${styles.product_selected}`}>
                                                    <div className=" d-flex justify-content-between">
                                                        <span >{el}</span>
                                                        &nbsp;
                                                        &nbsp;
                                                        <span style={{ cursor: 'pointer' }} onClick={() => removeFromList(i)}>
                                                            <img style={{ width: '5px' }} src="/assets/icons/x.svg" alt="" />

                                                        </span>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>


                                </div>

                            </div>
                            <div className="col-md-6 mb-1">
                                <label htmlFor="name">Date </label>

                                <div className=" d-flex">
                                    <div className="" style={{ width: '70%' }}>
                                        <div className="mb-3">
                                        <DatePicker
                                            showIcon
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="Start"
                                            required
                                        />


                                        </div>

                                    </div>
                                    <div className="px-1 d-sflex   align-items-center " style={{ heigsht: '40px' }}>
                                        {/* sss */}
                                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                    </div>
                                    <div className="" style={{ width: '100%' }}>
                                        <div className="mb-3">
                                        <DatePicker
                                            showIcon
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="End"
                                            required
                                        />


                                        </div>

                                    </div>

                                </div>
                                <label htmlFor="price">Price </label>

                                <div className=" d-flex">
                                    <div className="" style={{ width: '100%' }}>
                                        <div className="mb-3">
                                            <TextField
                                                required
                                                type='number'
                                                id="price"
                                                placeholder="Min"
                                                size="small"

                                            />


                                        </div>

                                    </div>
                                    <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                    </div>
                                    <div className="" style={{ width: '100%' }}>
                                        <div className="mb-3">
                                            <TextField
                                                required
                                                type='number'
                                                placeholder="Max "
                                                size="small"

                                            />


                                        </div>

                                    </div>

                                </div>
                                <label htmlFor="ounit">Order Unit </label>
                                <div className=" d-flex">
                                    <div className="" style={{ width: '100%' }}>
                                        <div className="mb-3">
                                            <TextField
                                                required
                                                type='number'
                                                id="ounit"
                                                placeholder="Min"
                                                size="small"

                                            />


                                        </div>

                                    </div>
                                    <div className="px-1 d-flex   align-items-center " style={{ height: '40px' }}>
                                        <img src="/assets/icons/line.svg" style={{ width: '30px' }} alt="" />
                                    </div>
                                    <div className="" style={{ width: '100%' }}>
                                        <div className="mb-3">
                                            <TextField
                                                required
                                                type='number'
                                                placeholder="Max "
                                                size="small"

                                            />


                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="mb-4">
                            <button type="button" onClick={handleClose} className={`${styles.modal_btn} btn btn-block`}>
                                Proceed

                            </button>


                        </div>

                    </form>


                </div>
            </GlobalModal>
            <Filter />
        </>
    )
}
