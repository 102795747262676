/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from './modals/marketingModals.module.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { Breadcrumb, FileInput } from "../../components";
import axiosInstance from "src/axiosInstance"
import { toast } from "react-hot-toast";
import ImagePreview from "src/components/file-input/ImagePreveiw";
import { BallTriangle } from 'react-loader-spinner';
import Styles from "./Outlet.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import bus from 'src/bus'


const styless = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: "auto",
  bgcolor: "#FFFFFF",
  border: "1px solid #F5F5F5",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const ComponentRepeater = ({ name = "mobile", heading = "Advert/Promos/Offers" }) => {
  const notifyError = (err) => toast.error(err);
  const notifyToast = (msg) => toast.success(msg);
  const [loading, setLoading] = useState(false);
  const [isProdPending, setIsProdPending] = useState(false);
  const [isUploadPending, setIsUploadPending] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [selectProductClick, setSelectProductClick] = useState(false);
  const [textInputRefs, setTextInputRefs] = useState([]);
  const [createdNewTag, setCreatedNewTag] = useState(false);
  const [tagDataPagination, setTagDataPagination] = useState({});
  const [dataFilter, setDataFilter] = useState([{
    searchvalue: "",
    searchvalue1: "",
    isFocused: false,
    isChecked: false,
    file:null,
    brandProductList: [],
    selectedBrandProducts: [],
    brandList: [],
    brand_id: "",
    selectedBrandName: "",
    isSearchBrandProducts: false,
  }]);
  const [replaceData, setReplaceData] = useState(null)
  const notify = (m) => toast.success(m);
  const [reload, setReload] = useState(false)
  const [overSizedImg, setOverSizedImg] = useState(false)
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClosePT = () => setProductTag(false);
  const [isPending, setIsPending] = useState(false);
  const [productTag, setProductTag] = useState(false);
  const [valChanged, setvalChanged] = useState(false);
  const [proceedToUpload, setProceedToUpload] = useState(false);
  const handleCloseProceed = () => setProceedToUpload(false);
  const [addTag, setAddTag] = useState({
      color: "",
      name: "",
      fromBanner: true
    });

  let maxWidth, maxHeight;
  if (name === "brandOwner" || name === "shopOwner") {
    maxWidth = 821;
    maxHeight = 150;
  } else if (name === "mobile") {
    maxHeight = 630;
    maxWidth = 1280;
  } else if (name === "hero" || name === "dealsOfTheDay") {
    maxWidth = 1080;
    maxHeight = 720;
  }

  const [mobileUploads, setMobileUploads] = useState([{ key: "k", file: "" }]);

  const addFileInput = () => {

    const length = mobileUploads.length;
    const newLen = length + 1;
    const data = {
      key: `k${newLen.toString()}`,
      file: "",
    };
    const newList = mobileUploads.concat(data);
    setMobileUploads(newList);
    processFilterData(1)
  };

  const processFilterData = (times) => {
    for (let i = 0; i < times; i++) {
      const arrayData = dataFilter
      arrayData.push({
        searchvalue: "",
        file:null,
        searchvalue1: "",
        isFocused: false,
        isChecked: false,
        productList: [],
        brandList: [],
        brand_id: "",
        brandProductList: [],
        selectedBrandProducts: [],
        tagData: [],
        selectedBrandName: "",
        tag_id: "",
        newTagId: "",
        createdNewTag: false,
        isSearchBrandProducts: false,
      })
      setDataFilter(arrayData)
    }
  }

  const reduceFileInput = () => {
    const newList = mobileUploads.slice(0, mobileUploads.length - 1);
    setMobileUploads(newList);
  };

  const searchFilter = (index, e, key) => {
    const data = dataFilter
    if (data[index]) {
      data[index][key] = e.target.value
    }
    setDataFilter(data)

    if (key === "searchvalue") {

      BrandList(index, e.target.value)
    } else {
      handleBrandSelect(index, e.target.value)
    }

  }
  const processFocus = (index) => {
    const data = dataFilter
    if (data[index]) {
      data[index].isFocused = true
    }
  }
  const updateDataFilterWithKey = (index, key, val) => {
    const data = dataFilter
    if (data[index]) {
      console.log(data[index][key], val);
      // console.log(data[index][key], val);
      data[index][key] = val
    }

  }

  // const handleReplace = (e, i) => {
  //   const data = [...mobileUploads];
  //   const currentData = data[i];
  //   if (e.target.files[0]) {
  //     currentData.imageUrl = URL.createObjectURL(e.target.files[0])
  //     currentData.file = e.target.files[0]
  //   }
  //   data[i] = currentData

  //   setMobileUploads(data)
  //   setReplaceData(currentData)

  // }

  const handleReplace = (e, i) => {
    const data = [...mobileUploads];
    const currentData = data[i];

    if (e.target.files[0]) {
      const file = e.target.files[0];
      const imageUrl = URL.createObjectURL(file);

      // Create an image element to get the actual dimensions of the image
      const img = new Image();
      img.onload = () => {
        // Specify the maximum allowed dimensions based on the scenario
        let maxWidth, maxHeight;
        if (name === "brandOwner" || name === "shopOwner") {
          maxWidth = 821;
          maxHeight = 150;
        } else if (name === "mobile") {
          maxHeight = 630;
          maxWidth = 1280;
        } else if (name === "hero" || name === "dealsOfTheDay") {
          maxWidth = 1080;
          maxHeight = 720;
        }

        if ((img.width > maxWidth || img.height > maxHeight) || (img.width < maxWidth || img.height < maxHeight)) {
          notifyError(`Image dimensions must be ${maxWidth}px by ${maxHeight}px.`);
          return;
        }

        // For other cases where name is not 'mobile'
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);

        canvas.toBlob((blob) => {
          currentData.imageUrl = URL.createObjectURL(blob);
          currentData.file = blob;
          data[i] = currentData;
          setMobileUploads(data);
          setReplaceData(currentData);
        });
      };

      img.src = imageUrl;
    }
  };

  const fetchData = () => {
    setDataFilter([{
      searchvalue: "",
      file:null,
      searchvalue1: "",
      isFocused: false,
      isChecked: false,
      productList: [],
      brandList: [],
      brand_id: "",
      brandProductList: [],
      selectedBrandProducts: [],
      tagData: [],
      selectedBrandName: "",
      tag_id: "",
      newTagId: "",
      createdNewTag: false,
      isSearchBrandProducts: false,
    }])
    setLoading(true)
    axiosInstance.get(`/admin/marketing/media/${name}`)
      .then(res => {
        setMobileUploads(res.data.data)
        processFilterData(res.data.data.length)

      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to upload banner, try again later");

        }

      }).finally(() => setLoading(false))
  }

  const replaceImage = () => {
    if (replaceData === null) return
    const formData = new FormData();
    formData.append("imageFile", replaceData.file)
    axiosInstance.put(`/admin/marketing/media-replace/${replaceData._id}`, formData)
      .then(res => {
        notify(res.data.message)
        setReplaceData(null)
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Something went wrong, try again later");

        }

      }).finally(() => setLoading(false))
  }

  const deleteItem = (id) => {
    setLoading(true)
    axiosInstance.delete(`/admin/marketing/media-delete/${id}`)
      .then(res => {
        notify(res.data.message)
        setReload(!reload)
      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Something went wrong, try again later");

        }

      }).finally(() => setLoading(false))
  }

  const addImage = (file, index) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {

      if (img.width !== maxWidth || img.height !== maxHeight) {
        notifyError(`Image dimensions must be ${maxWidth} by ${maxHeight}px.`);
        setOverSizedImg(true);
        bus.emit('overSizedImg', true)
        return;
      }
      console.log(name)
      if (name !== 'mobile' && name !== "dealsOfTheDay" && name !== "hero" && name !== "shopOwner") {
        const formData = new FormData();
        formData.append("imageFile", file);
        formData.append("imageType", name);
        // formData.append("product_ids", dataFilter[index].selectedBrandProducts);
        axiosInstance
          .post(`/admin/marketing/media-upload`, formData)
          .then((res) => {
            notify(res.data.message);
            setReload(!reload);
            setOverSizedImg(false)
          })
          .catch((err) => {
            try {
              notifyError(err.response.data.message);
            } catch (error) {
              notifyError("Something went wrong, try again later");
            }
          });
      } else {
        updateDataFilterWithKey(index, "file", file)
      }


    };
   


  };

  const handleButtonClick = (index) => {

    textInputRefs[index].current.click();
  }

  const BrandList = (index, val) => {
    setIsProdPending(true)
    
    axiosInstance
      .get(`/admin/product/brands?search=${val}`)
      .then(res => {
        updateDataFilterWithKey(index, "brandList", res.data.data)
      })
      .finally(() => setIsProdPending(false))
  }

  // const BrandProductList = (index, val="") => {
  //   axiosInstance
  //     .get(`/admin/product?search=${val}`)
  //     .then(res => {
  //       setProductList(res.data.data);
  //     })
  //     .finally(() => setIsProdPending(false))
  // }

  const handleBrandSelect = (index, val) => {
    setIsProdPending(true)
    const data = dataFilter;
    if (data[index]) {
      // console.log(val);
      // data[index].selectedBrandName = val.business_name
      dataFilter[index].isSearchBrandProducts = true
      const endpoint = val._id ? `/admin/product?&search=&brand_id=${val._id}` : `/admin/product?search=${data[index].searchvalue1}&brand_id=${data[index].brand_id}`
      axiosInstance
      .get(endpoint)
      .then(res => {
        data[index].brandProductList = res.data.data
        data[index].brand_id = val._id ? val._id : data[index].brand_id
        setDataFilter(data)
        dataFilter[index].isSearchBrandProducts = true

      })
      .finally(() => setIsProdPending(false))
    }
  }

  const handleProductSelect = (val, index) => {
    const data = dataFilter;
    if (data[index]) {
      if (data[index].selectedBrandProducts.includes(val)) {
        // Remove the product ID from the array
        data[index].selectedBrandProducts = data[index].selectedBrandProducts.filter(id => id !== val);
      } else {
        // Add the product ID to the array
        data[index].selectedBrandProducts.push(val);
      }
      // console.log(data[index].selectedBrandProducts)
      setSelectProductClick(!selectProductClick);
    }
  };
  
  const handleToUpload = (index) => {
    if (dataFilter[index]) {
      if(!dataFilter[index].file) {
        notifyError("Upload banner before proceeding");
        
        return;
      }
      setIsUploadPending(true)
      const formData = new FormData();
      formData.append("imageFile", dataFilter[index].file);
      formData.append("imageType", name);

      for (let i = 0; i < dataFilter[index].selectedBrandProducts.length; i++) {

        formData.append("product_ids", dataFilter[index].selectedBrandProducts[i]);
      }

      if(dataFilter[index].selectedBrandProducts.length) {
        formData.append("tag_id", dataFilter[index].tag_id)
      }

      // Continue with the upload logic
      axiosInstance
        .post(`/admin/marketing/media-upload`, formData)
        .then((res) => {
          notify(res.data.message);
          setReload(!reload);
        })
        .catch((err) => {
          try {
            notifyError(err.response.data.message);
          } catch (error) {
            notifyError("Something went wrong, try again later");
          }
        }).finally(() => {
          setIsUploadPending(false)
          setOpen(false)
          setProceedToUpload(false)
          dataFilter[index].isSearchBrandProducts = false
        });
    }

  }

  const handleProductTagModal = () => {
    setOpen(!open)
    setProductTag(!productTag)
  }

  const fetchTags = (index) => {
    setIsPending(true);
    setOpen(true)
    axiosInstance
      .post("/admin/product/get-all-product-tags", {
        product_id: dataFilter[index].selectedBrandProducts
      })
      .then((res) => {
        console.log(res.data.data);
        dataFilter[index].tagData = res.data.data;
        
        // setTagDataPagination(res.data.data.pagination);
      })
      .catch((err) => {
        try {
          notifyError('Oops! No records.');
          // notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Unable to fetch product tags, please try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const handleTagValue = (index, val) => {
    dataFilter[index].tag_id = val.tag_id
  };

  const createTagForm = (index) => {
    setIsPending(true);
    axiosInstance
      .post("/admin/product/create-tag", addTag)
      .then((res) => {
        setProductTag(false)
        setProceedToUpload(true)
        notifyToast(res.data.message);
        handleClosePT();
        dataFilter[index].tag_id = res.data.data._id
        setCreatedNewTag(!createdNewTag)
        dataFilter[index].createdNewTag = true
        setvalChanged(!valChanged);
        setAddTag({
          color: "",
          name: "",
        });
        bus.emit('productTag')
      })
      .catch((err) => {
        try {
          notifyError(err.response.data.message);
        } catch (error) {
          notifyError("Something went wrong , please try again later");
        }
      })
      .finally(() => setIsPending(false));
  };

  const setVal = (key, val) => {
    setAddTag((prev) => {
      return {
        ...prev,
        [key]: val,
      };
    });
  };


  const renderUploadsCount = () => {
    return (
      <>
        {mobileUploads &&
          mobileUploads.map((item, index) => {
            console.log(item);

            return (
              <>
                <div className="mt-4" key={index} style={{ position: 'relative' }}>
                  {
                    !item._id &&
                    <div>
                      <FileInput uploaded={(file) => addImage(file, index)} maxWidth={maxWidth} maxHeight={maxHeight} />
                   
                      {
                        overSizedImg &&
                        <small className="text-danger">
                          Please note that the operation was not processed because image uploaded is either above or below the size limit. Please upload the recommended image size to continue.
                        </small>
                      }
                    </div>
                  }
                  {
                    item._id &&
                    <ImagePreview data={item.imageUrl} />
                  }
                  {
                    item._id &&
                    <div>

                      <input ref={textInputRefs[index]} onChange={(e) => handleReplace(e, index)} type="file" style={{ display: 'none' }} />
                      <div className="ml-auto mt-2 px-3" style={{ position: 'absolute', bottom: '15px', left: '0', right: '0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <button onClick={() => handleButtonClick(index)} className="px-2 py-1 mx-2 rounded border-0" style={{ backgroundColor: '#333 !important', fontSize: '14px' }}>
                          <img style={{ width: '15px' }} src="/assets/icons/refresh-ccw.png" alt="" /> Replace</button>
                        <button onClick={() => deleteItem(item._id)} className="px-2 py-1 mx-2 rounded border-0" style={{ backgroundColor: '#333 !important', fontSize: '14px' }}>
                          <img style={{ width: '15px' }} src="/assets/icons/trash-2.png" alt="" /> Remove</button>
                      </div>
                    </div>
                  }
                </div>
                {
                  ((name === "mobile" || name === "dealsOfTheDay" || name === "hero" || name === "shopOwner") && !item._id) &&
                  <>
                  <div className="d-flex">
                    {
                      dataFilter[index].isSearchBrandProducts &&
                      <p 
                        className="ml-auto"
                      >
                        <button 
                          className="btn btn-light mt-2"
                          style={{
                            border: "1px lightgrey solid"
                          }}
                          onClick={() => BrandList(dataFilter[index].isSearchBrandProducts = false)}
                        >
                          Refetch Brands⎌
                        </button>
                      </p>
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div className={`${Styles.marketing_attach_wrap} d-flex m-0 mt-3`} style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: '15px', width: '40em', maxWidth: '100%' }}>
                      <div className="w-50">
                        <p className="m-0 mt-2 text-muted" style={{ whiteSpace: 'nowrap' }}>Attach a product (Optional)</p>
                      </div>
                      {!dataFilter[index].isSearchBrandProducts && (
                        <>
                        <h3>
                        {dataFilter[index]?.selectedBrandName}
                        </h3>
                          <div className="w-100">
                            <div className='hold_input4 mb-3' style={{ width: '100%' }}>
                              <img src="/assets/icons/search.svg" alt="" />
                              <input
                                type="text"
                                value={dataFilter[index]?.searchvalue}
                                onChange={(val) => searchFilter(index, val, "searchvalue")}
                                onFocus={() => processFocus(index)}
                                placeholder="Search Brand.."
                                style={{
                                  textIndent: '25px',
                                  width: '100%'
                                }}
                                className="form-control"
                              />
                            </div>
                            {dataFilter[index]?.isFocused && !isProdPending && dataFilter[index]?.brandList.length > 0 && (
                              dataFilter[index]?.brandList.slice(0,3).map((el, i) => (
                                <div key={i}>
                                  <div
                                    className="mb-4"
                                    onClick={() => handleBrandSelect(index, el)}
                                    style={{
                                      padding: '12px 16px',
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      gap: '10px',
                                      boxShadow: '0 0 5px 1px #bbb',
                                      borderRadius: '5px',
                                      cursor: "pointer"
                                    }}
                                  >
                                    <div style={{ width: '50px', height: '50px' }}>
                                      <img
                                        src={el?.business_logo}
                                        alt="brand_logo"
                                        style={{
                                          maxWidth: "60%"
                                        }}
                                      />
                                    </div>
                                    <h5 className="m-0 font-weight-bold">
                                      {el?.business_name}
                                    </h5>
                                  </div>
                                </div>
                              ))
                            )}
                            {/* {
                              dataFilter[index]?.isFocused && !isProdPending && !dataFilter[index]?.brandList.length && (
                                <div>
                                  <div
                                    className="mb-4"
                                    style={{
                                      padding: '12px 16px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: '10px',
                                      boxShadow: '0 0 5px 1px #bbb',
                                      borderRadius: '5px',
                                    }}
                                  >
                                      <div
                                        style={{ 
                                          height: '70px', 
                                          display: 'flex', 
                                          justifyContent: 'center', 
                                          alignItems: 'center' 
                                        }}>
                                          No records found
                                      </div>
                                  </div>
                                </div>
                              )
                            } */}
                            <div className="d-flex justify-content-center">
                              {isProdPending && (
                                <BallTriangle
                                  height={30}
                                  width={35}
                                  radius={5}
                                  color="#53138D"
                                  ariaLabel="ball-triangle-loading"
                                  wrapperClass={{}}
                                  wrapperStyle=""
                                  visible={true}
                                />
                              )}
                            </div>
                            <div className="">
                              <button
                                type="submit"
                                className="d-block ml-auto"
                                onClick={() => handleToUpload(index)}
                                style={{
                                  border: 'none',
                                  padding: '10px 30px',
                                  borderRadius: '5px',
                                  background: 'royalblue',
                                  color: 'white'
                                }}
                              >
                                Upload
                                {
                                  isUploadPending && 
                                  <CircularProgress style={{ color: 'white' }} size="0.8rem" />
                                }
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {dataFilter[index].isSearchBrandProducts && (
                        <>
                          <div className="w-100">
                            <div className='hold_input4 mb-3' style={{ width: '100%' }}>
                              <img src="/assets/icons/search.svg" alt="" />
                              <input
                                type="text"
                                value={dataFilter[index]?.searchvalue1}
                                onChange={(val) => searchFilter(index, val, "searchvalue1")}
                                onFocus={() => processFocus(index)}
                                placeholder="Search Product.."
                                style={{
                                  textIndent: '25px',
                                  width: '100%'
                                }}
                                className="form-control"
                              />
                            </div>
                            {!isProdPending && dataFilter[index]?.brandProductList.length > 0 && (
                              dataFilter[index]?.brandProductList.map((el, i) => (
                                <div key={i}>
                                  <div
                                    className="mb-4"
                                    onClick={() => handleProductSelect(el._id, index)}
                                    style={{
                                      padding: '12px 16px',
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      gap: '10px',
                                      boxShadow: '0 0 5px 1px #bbb',
                                      borderRadius: '5px',
                                      cursor: "pointer"
                                    }}
                                  >
                                      <div style={{ width: '50px', height: '50px', }}>
                                        <img
                                          src={el?.product_image}
                                          alt="brand_logo"
                                          style={{
                                            maxWidth: "60%"
                                          }}
                                        />
                                      </div>
                                      <h5 className="m-0 font-weight-bold">
                                        {el?.product_name}
                                      </h5>
                                      {
                                        dataFilter[index].selectedBrandProducts.includes(el._id) && (
                                          <input
                                            className="ml-auto"
                                            type="checkbox"
                                            readOnly
                                            checked={dataFilter[index].selectedBrandProducts.includes(el._id)}
                                          />
                                        )
                                      }
                                  </div>
                                </div>
                              ))
                            )}
                            {
                              !isProdPending && !dataFilter[index]?.brandProductList.length && (
                                <div>
                                  <div
                                    className="mb-4"
                                    style={{
                                      padding: '12px 16px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: '10px',
                                      boxShadow: '0 0 5px 1px #bbb',
                                      borderRadius: '5px',
                                    }}
                                  >
                                      <div
                                        style={{ 
                                          height: '70px', 
                                          display: 'flex', 
                                          justifyContent: 'center', 
                                          alignItems: 'center' 
                                        }}>
                                          No records found
                                      </div>
                                  </div>
                                </div>
                              )
                            }
                            <div className="d-flex justify-content-center">
                              {isProdPending && (
                                <BallTriangle
                                  height={30}
                                  width={35}
                                  radius={5}
                                  color="#53138D"
                                  ariaLabel="ball-triangle-loading"
                                  wrapperClass={{}}
                                  wrapperStyle=""
                                  visible={true}
                                />
                              )}
                            </div>
                            <div className="">
                              <button
                                type="submit"
                                className="d-block ml-auto"
                                // onClick={() => handleToUpload(index)}
                                onClick={() => [name === 'mobile' || name === 'website' ? fetchTags(index) : handleToUpload(index)]}
                                style={{
                                  border: 'none',
                                  padding: '10px 30px',
                                  borderRadius: '5px',
                                  background: 'royalblue',
                                  color: 'white'
                                }}
                              >
                                Upload 
                                {
                                  isUploadPending && 
                                  <CircularProgress 
                                    style={{ 
                                      color: 'white' 
                                    }} 
                                    size="0.8rem" 
                                  />
                                }
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  </>
                }
                {/* Existing Tags */}
                {
                  open &&
                  <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                          timeout: 500,
                      }}
                      style={{
                        background: "transparent"
                      }}
                      >
                      <Fade in={open}>
                      <Box className={`mobile_modal_size`} sx={styless}>
                          <div className={`${styles.modal_content}`}>
                          <div className={`${styles.modal_header}`}>
                              <h3>Select a Tag</h3>
                              <button 
                                  className='btn btn-light p-2'
                                  onClick={() => handleProductTagModal()}
                                  style={{
                                    border: "lightgrey 1px solid",
                                    borderRadius: "5px"
                                  }}
                              >
                                Create New Tag
                              </button>
                          </div>

                          <form style={{ marginTop: "10px" }}>
                            {
                              !isUploadPending && dataFilter[index]?.tagData?.length > 0 &&
                              dataFilter[index]?.tagData.map((el,i) => (
                                <div 
                                  key={i}
                                  className="mb-3 mt-2 p-4 d-flex" 
                                  style={{ borderBottom: "lightgrey 1px solid"}}
                                >
                                  <img
                                    className=" mr-2"
                                    src={!el?.color ? '/assets/Ellipse_grey.png' : `/assets/Ellipse_${el?.color?.toLowerCase()}.png`}
                                    alt=""
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  <span className="ml-2">
                                      {el?.tag_name}
                                  </span>
                                  <input
                                      type="radio"
                                      required={dataFilter[index].selectedBrandProducts.length > 1}
                                      value={el.tag_id}
                                      name="name"
                                      onChange={() => handleTagValue(index, el)}
                                      className="ml-auto"
                                  />
                                </div>
                              ))
                            }
                            {
                              !isUploadPending && !dataFilter[index]?.tagData?.length && (
                                <div>
                                    <div
                                      className="mb-3 mt-2 p-4 d-flex" 
                                      style={{ 
                                        height: '70px', 
                                        display: 'flex', 
                                        justifyContent: 'left', 
                                        alignItems: 'left',
                                        borderBottom: "lightgrey 1px solid",
                                        fontStyle: 'italic'
                                      }}>
                                        No records found
                                    </div>
                                </div>
                              )
                            }
                              <div className="mb-4">
                              {isUploadPending && (
                                  <button
                                  disabled
                                  className={`btn btn-block mt-2 ${styles.modal_btn}`}
                                  >
                                  Submitting &nbsp;
                                  {isUploadPending && (
                                      <CircularProgress
                                      style={{ color: "white" }}
                                      size="1rem"
                                      />
                                  )}
                                  </button>
                              )}
                              {!isUploadPending && dataFilter[index]?.tagData?.length && (
                                  <button
                                  type="button"
                                  className={`${styles.modal_btn} btn btn-block btn-lg`}
                                  onClick={() => handleToUpload(index)}
                                  >
                                  Proceed
                                  </button>
                              )}
                              {
                                !isUploadPending && !dataFilter[index]?.tagData?.length && (
                                  <button
                                  type="button"
                                  className={`${styles.modal_btn} btn btn-block btn-lg`}
                                  onClick={() => handleProductTagModal()}
                                  >
                                    Create New Tag
                                  </button>
                                )
                              }
                              <div className="d-flex justify-content-center">
                                {isPending && (
                                  <BallTriangle
                                    height={30}
                                    width={35}
                                    radius={5}
                                    color="#53138D"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperClass={{}}
                                    wrapperStyle=""
                                    visible={true}
                                  />
                                )}
                              </div>
                              </div>
                          </form>
                          </div>
                      </Box>
                      </Fade>
                  </Modal> 
                }
                  {/* Create New Tag */}
                {
                  productTag &&
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={productTag}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableEnforceFocus
                >
                    <Fade in={productTag}>
                    <Box className={`mobile_modal_size`} sx={styless}>
                        <div className={`${styles.modal_content}`}>
                        <div className={`${styles.modal_header}`}>
                            <h3>Create Product Tag</h3>
                            <span
                            onClick={handleClosePT}
                            style={{ cursor: "pointer", marginBottom: "0px" }}
                            >
                            <img src="/assets/icons/x.svg" alt="" />
                            </span>
                        </div>

                        <form style={{ marginTop: "10px" }}>
                            <div className="mb-3 mt-2">
                            <label
                                htmlFor="name"
                                style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                                Tag Name*
                            </label>
                            <input
                                type="text"
                                value={addTag.name}
                                name="name"
                                onChange={(e) => setVal("name", e.target.value)}
                                className="form-control"
                                placeholder="Enter Name"
                            />
                            </div>

                            <div className="mb-3">
                            <label
                                htmlFor="name"
                                style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                                Choose Color*
                            </label>{" "}
                            <br />
                            <span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "red")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_red.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "yellow")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_yellow.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "green")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_green.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "blue")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_blue.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "pink")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_pink.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "grey")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_grey.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "purple")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_purple.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "teal")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_teal.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "orangered")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_orangered.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "lightgreen")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_lightgreen.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                                <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setVal("color", "darkpurple")}
                                >
                                <img
                                    className=" mr-3"
                                    src={"/assets/Ellipse_darkpurple.png"}
                                    alt=""
                                    style={{ height: "35px", width: "35px" }}
                                />
                                </span>
                            </span>{" "}
                            <br />
                            {addTag.color}
                            </div>

                            <div className="mb-4">
                            {isPending && (
                                <button
                                disabled
                                className={`btn btn-block ${styles.modal_btn}`}
                                >
                                Submitting &nbsp;
                                {isPending && (
                                    <CircularProgress
                                    style={{ color: "white" }}
                                    size="1rem"
                                    />
                                )}
                                </button>
                            )}
                            {!isPending && (
                                <button
                                type="button"
                                onClick={() => createTagForm(index)}
                                className={`${styles.modal_btn} btn btn-block btn-lg`}
                                >
                                Add
                                </button>
                            )}
                            </div>
                        </form>
                        </div>
                    </Box>
                    </Fade>
                </Modal>
                }
                {
                  proceedToUpload &&
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={proceedToUpload}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableEnforceFocus
                >
                    <Fade in={proceedToUpload}>
                    <Box className={`mobile_modal_size`} sx={styless}>
                        <div className={`${styles.modal_content}`}>
                        <div className={`${styles.modal_header}`}>
                            <h3>Upload Banner</h3>
                            <span
                            onClick={handleCloseProceed}
                            style={{ cursor: "pointer", marginBottom: "0px" }}
                            >
                            <img src="/assets/icons/x.svg" alt="" />
                            </span>
                        </div>

                        <form style={{ marginTop: "10px" }}>
                          <div className="text-center">
                            <img src="/assets/icons/Illustration.svg" alt="" />
                            <h5 className="mt-3">Successfully Created Tag </h5>
                            <div className="mb-3 mt-3">
                              {isPending && (
                                  <button
                                  disabled
                                  className={`btn btn-block ${styles.modal_btn}`}
                                  >
                                  Submitting &nbsp;
                                  {isPending && (
                                      <CircularProgress
                                      style={{ color: "white" }}
                                      size="1rem"
                                      />
                                  )}
                                  </button>
                              )}
                              {!isPending && (
                                  <button
                                  type="button"
                                  onClick={() => handleToUpload(index)}
                                  className={`${styles.modal_btn} btn btn-block btn-lg`}
                                  >
                                  Proceed to Upload Banner
                                  </button>
                              )}
                              </div>
                          </div>
                        </form>
                        </div>
                    </Box>
                    </Fade>
                </Modal>
                }
              </>
            )
          })}
      </>
    );
  };


  useEffect(() => {
    fetchData()
  }, [reload])

  useEffect(() => {
    setTextInputRefs((prevRefs) => {
      const newRefs = [...prevRefs];
      while (newRefs.length < mobileUploads.length) {
        newRefs.push(React.createRef());
      }
      return newRefs;
    });
  }, [mobileUploads]);

  useEffect(() => {
    replaceImage()
  }, replaceData)

  return (
    <>
    <div className="px-2">
      <Breadcrumb
        title={heading}
        amount={mobileUploads?.length}
        handleAdd={addFileInput}
        handleReduce={reduceFileInput}
        disableReduce={mobileUploads.filter(el => el._id === null).length > 0}
      />

      <br />
      {
        !loading &&
        renderUploadsCount()
      }
      <div className="d-flex justify-content-center">
        {loading &&
          <BallTriangle

            height={50}
            width={55}
            radius={5}
            color="#53138D"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        }
      </div>
    </div>
    </>
  );
};

export default ComponentRepeater;
