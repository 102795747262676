

// ----------------------------------------------------------------------
import styles from "../BrandOwner.module.css"
import { useEffect, useState } from "react"
import TextField from '@mui/material/TextField';
import {
  Card,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
// sections
// mock
import Button from '@mui/material/Button';
import DiscountModal from "../Modals/DiscountModal";
import ProductsModal from "../Modals/ProductsModal";
import bus from 'src/bus';
import { useCategories } from "src/hooks/useCategories";
import Menu from '@mui/material/Menu';
import axiosInstance from "src/axiosInstance"
import { BallTriangle } from 'react-loader-spinner'
import CircularProgress from '@mui/material/CircularProgress';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createRef } from "react";
import { useLayoutEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { BorderRightRounded } from "@mui/icons-material";

// ----------------------------------------------------------------------
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};
const style1 = {
  position: 'absolute',
  height: '500px',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3,
};
const style1d = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  overflow: 'auto',
  bgcolor: '#FFFFFF',
  border: '1px solid #F5F5F5',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

// ptvp=PAGE TO VIEW (SALES)

export default function SingleViewProduct({ categoryId }) {
  let textInput = createRef();
  const [openDialog, setDiologOpen] = useState(false);
  const [action_type, setActionType] = useState('Add')
  const navigate = useNavigate();


  const [openDialogDelete, setopenDialogDelete] = useState(false);
  const [notify, setNotify] = useState(false);
  const categories = useCategories()
  const [isPending, setIsPending] = useState(false);



  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setActionType('Add')

    setOpen1(true)
  };
  const handleOpen2 = () => {
    setOpen1(true)
    setActionType('Edit')
    setUrl('/assets/miloContainer.svg')
  };
  const handleDelete = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [modalTitle, setmodalTitle] = useState('');
  const [modal_desc, setmodal_desc] = useState('');
  const [weight, setWeight] = useState(20);
  const [url, setUrl] = useState('');
  // const {data} = useAuth();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open2 = Boolean(anchorEl1);
  const notifyError = (err) => toast.error(err);
  const notifyToast = (err) => toast.success(err);

  const [newProducts, setNewProducts] = useState({
    notifications: [],
    cases_type: 'Cartons'
  });
  const [categoryName, setCategoryName] = useState(JSON.parse(localStorage.getItem('categoryName')))
  const [currentData, setcurrentData] = useState({});
  const [showDiscount, setShowDiscount] = useState(false);
  const [openGoToCart, setopenGoToCart] = useState(false);
  const [data, setdata] = useState([]);
  const [catData, setCatdata] = useState([]);
  const [pagination, setPagination] = useState({ total: 0 });
  const [loadingState, setLoadingState] = useState(false);
  const [searchvalue, setSearchValue] = useState('');
  const [openPopUp, setopenPopUp] = useState(false);
  const handleGoToCartClose = () => setopenGoToCart(false);

  const searchFilter = (e) => {
    setSearchValue(e.target.value)
  }

  const handleClickOpen = (val) => {
    setcurrentData(val)
    setDiologOpen(true);
  };

  const handleDialogClose = () => {
    setDiologOpen(false);
  };

  const handleDialogDeleteClose = () => {
    setopenDialogDelete(false);
  };

  const handleCloseList = (key, value) => {
    setAnchorEl1(null);
    setNewProducts((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    }
    );
  };

  const handleClickList1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const discountPage = () => {
    handleGoToCartClose()
    setShowDiscount(true)
    // bus.emit('open-discount', true);
  }

  const reload = () => {
    navigate(`/app/products`)
  }
  useEffect(() => {
    fetchCategorySingle()
    fetchData()
  }, [searchvalue]);
  const refreshPage = ()=> {
    fetchData();

  }
  const fetchCategorySingle = (page = 1, pageSize = 10) => {
    setIsPending(true);
    axiosInstance.get('/product-category/all', {
      headers:{
        'x-auth-token': ` ${JSON.parse(localStorage.getItem('auth'))?.token}`
      }
    })
      .then(res => {
        setCatdata(res.data.data)


      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch store, try again later");

        }

      }).finally(() => setIsPending(false))
  }

  const fetchData = (page = 1, pageSize = 10, search = searchvalue) => {
    setIsPending(true);
    axiosInstance.get('/brand/product/get/' + categoryId, {
      params: {
        search
      }
    })
      .then(res => {
        setdata(res.data.data[0])
        setPagination(res.data.data.pagination)


      }).catch(err => {

        try {
          notifyError(err.response.data.message);

        } catch (error) {
          notifyError("Unable to fetch products, please try again later");

        }

      }).finally(() => setIsPending(false))
  }

  const deleteProduct = () => {
    setLoadingState(true);
    axiosInstance.delete('/brand/product/delete-product/' + currentData._id)
    .then(res => {
      fetchData()
      notifyToast('Product Deleted')
      handleDialogClose()
      

    }).catch(err => {

      try {
        notifyError(err.response.data.message);

      } catch (error) {
        notifyError("Unable to delete product, try again later");

      }

    }).finally(() =>  setLoadingState(false))
  }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]))

    }
  }

  const handleButtonClick = () => {
    textInput.current.click()
  }

  const deleteCategory = (e) => {
    e.preventDefault();
    setopenDialogDelete(false);
    setmodalTitle('Category deleted')
    setopenPopUp(true)

  }

  const handleProductSubmit = (e) => {
    e.preventDefault();
    setOpen1(false);
    setopenGoToCart(true)
  }

  return (
    <>
      <div className={` mt-3 px-2`}>
        <div className={`${styles.filters}`}>
          <div className="row ml-2">
            <div 
              className="mb-3 p-1 d-inline-block cursor_pointer" 
              style={{
                background: '#fff', 
                border: '1px solid lightgray',
                borderRadius: '5px 0px 0px 5px'
              }}
              onClick={reload}
            >
              <img src="/assets/icons/Chevronleft.svg" className="pr-1" alt="" />
            </div>
            <span 
              className="m-1 text-muted"
            >
              Back to Products
            </span>
          </div>
          <div className="row">
            <div className={`${styles.hold_input} col-md-3`}>
              <img 
                src="/assets/icons/search.svg" 
                style={{marginLeft: '9px'}} 
                alt=""
              />
              <input 
                type="text" 
                placeholder="Search" 
                value={searchvalue}
                onChange={searchFilter}
                style={{ 
                  textIndent: '25px', 
                  width: '100%' 
                }} 
                className=" form-control" 
              />
            </div>
          </div>
        </div>
        <Card style={{ marginTop: '15px', borderRadius: '10px' }} className='p-0'>
          <div className='p-4 border-bottom'>
            <div className="d-md-flex justify-content-between">
              <div>
                <h4 style={{fontWeight: 'bold'}}>
                  {categoryName}
                </h4>
              </div>
              {/* <div className="">
                <button 
                  type="button" 
                  onClick={setopenDialogDelete} 
                  className={`btn mb-2 mb-md-0 ${styles.delete_category}`}
                >
                  <span>

                    Delete Category
                  </span>
                </button>
                &nbsp;
                &nbsp;
                <button onClick={() => bus.emit('open-product', { _id: categoryId })} className={`${styles.add_product_btn} btn m-0 p-2`}>
                  <img src="/assets/icons/plus.svg" className="pr-1" alt="" />
                  Add Product
                </button>
              </div> */}
            </div>
          </div>
          <Scrollbar>
            {
              (!isPending && data?.products?.length > 0) &&
              <div className={`py-3 px-md-4 ${styles.cover_products}`}>
                <div className={`${styles.customized_row}`} >
                  {data?.products?.map((el, i) => (
                    <div key={i} className="mb-2 mt-1">
                      <div className="card" style={{minHeight: '200px'}}>
                        <div className="card-body">
                          <div className={`${styles.customized_row1}`}>
                            <div className=" bg-light" 
                            style={{
                              overflow: "hidden",
                              maxHeight: "150px",
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <img style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              }} 
                              src={el?.product_image} 
                              alt=""
                            />
                            </div>
                            <div className={`${styles.product_txt}`}>
                              <h6>{el.product_name}</h6>
                              <p>
                                {el?.description}
                              </p>
                              <div className="d-flex mt-4 justify-content-between">
                                <span>
                                {el?.product_spec ? el?.product_spec : ''}
                                </span>
                                <div className={`${styles.dropdown_wrap}`}>
                                  <button 
                                    id="dropdownMenuButton" 
                                    data-toggle="dropdown" 
                                    aria-haspopup="true" 
                                    aria-expanded="false"
                                    style={{ fontSize: '11px', cursor: 'pointer' }}
                                    className={`btn btn-[#53138D] bg-[#8c2d915d] ml-1 p-1 mt-5`}
                                  >
                                    More info
                                  </button>
                                  <div 
                                    className={`${styles.product_dialog_two} dropdown-menu p-1`}
                                    aria-labelledby="dropdownMenuButton"                        
                                  >
                                    <div 
                                      className={`${styles.dropdown_item} rss d-flex text-muted justify-content-between dropdown-item btn border-0`}
                                      style={{
                                        fontSize: '10px'
                                      }}
                                    >
                                        <small>
                                          Total Quantity
                                        </small>
                                        <small>
                                          {Number(el?.more_info?.quantity_sold + el?.more_info?.quantity_remaining || 0).toLocaleString()}
                                        </small>
                                    </div>
                                    <div 
                                      className={`${styles.dropdown_item} rss d-flex text-muted justify-content-between dropdown-item btn border-0`}
                                      style={{
                                        fontSize: '10px'
                                      }}
                                    >
                                        <small>
                                          Quantity Sold
                                        </small>
                                        <small>
                                          {Number(el?.more_info?.quantity_sold || 0).toLocaleString()}
                                        </small>
                                    </div>
                                    <div 
                                      className={`${styles.dropdown_item} rss d-flex text-muted justify-content-between dropdown-item btn border-0`}
                                      style={{
                                        fontSize: '10px'
                                      }}
                                    >
                                        <small>
                                          Quantity Remaining
                                        </small>
                                        <small>
                                          {Number(el?.more_info?.quantity_remaining || 0).toLocaleString()}
                                        </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`${styles.product_price}`}>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }
            <div className="d-flex align-items-center pb-4  justify-content-center">
              {isPending &&
                <BallTriangle
                  height={50}
                  width={55}
                  radius={5}
                  color="#53138D"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              }
            </div>
            {
              (!data?.products?.length && !isPending) &&
              <div className="alert alert-[#53138D] text-center">
                <h4>No record found</h4>
              </div>
            }
          </Scrollbar>
        </Card>
      </div>
      {/*  DIALOG*/}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img 
                  style={{ 
                    width: '40px' 
                  }} 
                  src="/assets/icons/caution.svg" 
                  alt="" 
                />
              </div>
              <div>
                <h4>
                  Delete {currentData.product_name} Product

                </h4>
                <p style={{
                  fontSize: '14px',
                  color: '#6B7280',
                }}>
                  Are you sure you want to delete this product? All your data will be permanently removed from our servers forever. This action cannot be undone.
                </p>

              </div>

            </div>

            <DialogActions>
              <Button className={`${styles.cancel_delete}`} onClick={handleDialogClose}>Cancel</Button>
              <Button className={`${styles.delete_product}`} onClick={deleteProduct}>
                Delete   {loadingState &&
                      <CircularProgress style={{ color: '#53138D' }} size="1rem" />

                    }
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openDialogDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogDeleteClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="card border-0">
          <div className="card-body border-0 ">
            <div className={`${styles.customized_row}`}>
              <div className="text-center">
                <img style={{ width: '40px' }} src="/assets/icons/caution.svg" alt="" />

              </div>
              <div>
                <h4>
                  Delete Category

                </h4>
                <p style={{
                  fontSize: '14px',
                  color: '#6B7280',
                }}>
                  Are you sure you want to delete this category? All your data will be permanently removed from our servers forever. This action cannot be undone.
                </p>

              </div>

            </div>

            <DialogActions>
              <Button className={`${styles.cancel_delete}`} onClick={handleDialogDeleteClose}>Cancel</Button>
              <Button onClick={deleteCategory} className={`${styles.delete_product}`}  >Delete</Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      {/* Withdraw */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={open1}>
          <Box className={`mobile_modal_size`} sx={style1}>
            <div className={`${styles.modal_content}`}>
              <div className={`${styles.modal_header}`}>
                <h3>{action_type === 'Add' ? 'Add' : ' Edit'} Product </h3>
                <span onClick={handleClose1} style={{ cursor: 'pointer' }}>
                  <img src="/assets/icons/x.svg" alt="" />

                </span>

              </div>
              <p>
                {action_type === 'Add' ? 'Add' : ' Edit'}  product to this category
              </p>




              <form onSubmit={handleProductSubmit}>
                <p className={` label`}>Product Image* </p>
                <input type="file" hidden ref={textInput} name="" onChange={handleFile} />
                <div className={`${styles.retailer_modal_product_img}`} style={{
                  backgroundImage: `url('${url}')`,


                }}>
                  <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => handleButtonClick()}>
                    <img
                      className={`${styles.retailer_product_image}`}
                      src="/assets/image.svg"
                      alt=""

                    />
                    <p className="mb-0 text-center text-info" style={{ fontSize: '10px' }} >Click to upload</p>

                  </div>
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="pname">Product Name*</label>
                  <TextField
                    required
                    type='text'
                    id="pname"
                    placeholder=""
                    size="small"
                    fullWidth

                  />


                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="category">Product Category*</label>
                  <div>
                    {categories.map((el, i) => (
                      <div className="categories" key={i}>
                        {(el.name === 'Beverages' && action_type === 'Edit') &&
                          <input checked type="checkbox" name="" id="myCheckbox" />
                        }
                        {el.name !== 'Beverages' &&
                          <input type="checkbox" name="" id="myCheckbox" />
                        }
                        &nbsp;
                        {el.name}


                      </div>

                    ))}
                  </div>
                </div>
                <label className={`label`} htmlFor="price">Pricing * </label>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <small className="small">Per Case</small>
                      <TextField
                        required
                        id="price"
                        type="number"
                        placeholder=" ₦"
                        size="small"
                        fullWidth

                      />


                    </div>

                  </div>
                  <div className="col-6">
                    <small className="small">Per Product*</small>
                    <TextField
                      required
                      id="price"
                      type="number"
                      placeholder=" ₦"
                      size="small"
                      fullWidth

                    />

                  </div>
                </div>
                <label className={`label`} htmlFor="price">Stock * </label>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3" style={{ position: 'relative' }}>
                      <small className="small">Number of Cases</small>
                      <TextField
                        required
                        id="price"
                        type="number"
                        size="small"
                        fullWidth

                      />
                      <div className="absolute">
                        <small className="small">
                          {newProducts.cases_type}
                        </small>
                        &nbsp;
                        <span style={{ cursor: 'pointer' }} onClick={handleClickList1}>
                          <img src="/assets/icons/downicon.svg" alt="" />


                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl1}
                          open={open2}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {['Cartons', 'Crates'].map((count) => (
                            <MenuItem onClick={() => handleCloseList('cases_type', count)}>
                              {count}
                            </MenuItem>
                          ))}
                        </Menu>

                      </div>
                    </div>

                  </div>
                  <div className="col-6">
                    <small className="small">Number of Product in a Cases*</small>
                    <TextField
                      required
                      type="number"
                      size="small"
                      fullWidth

                    />

                  </div>
                </div>
                <div className="mb-3">
                  <label className={`label`} htmlFor="desc">Description * </label>
                  <TextField
                    id="desc"
                    multiline
                    rows='4'
                    cols='3'
                    placeholder=" Best Product"
                    size="small"
                    fullWidth

                  />


                </div>


                <div className="mb-3">
                  <button type="submit" className={`${styles.modal_btn} btn btn-block`}>
                    Save
                  </button>


                </div>

              </form>


            </div>

          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPopUp}
        onClose={() => setopenPopUp(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openPopUp}>
          <Box className={`mobile_modal_size modal_bg`} sx={style1d}>
            <div className={`text-center add_to_cart_content`}>
              <img src="/assets/icons/circleCheck.svg" alt="" />
              <h5 className="mt-3 mb-2" style={{ fontWeight: 'bold' }}>{modalTitle}</h5>
              <p className='text-center mb-3' style={{ textAlign: 'center !important' }}>
                {modal_desc}

              </p>
              <button onClick={() => reload(0)} className={`modal_btn hover btn btn-block`}>
                Go to Product

              </button>

            </div>



          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGoToCart}
        onClose={handleGoToCartClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={openGoToCart}>
          <Box className={`mobile_modal_size pt-md-3 ${styles.modal_bg}`} sx={style}>
            <div className={`text-center ${styles.add_to_cart_content}`}>
              <img src="/assets/icons/Illustration.svg" alt="" />
              <h5 className="mt-3">Product Added </h5>
              <div>
                {action_type === 'Add' &&
                  <button className={`${styles.go_to} d-block w-100 `} style={{ fontSize: '14px' }} onClick={() => discountPage()}>
                    Proceed to Create Discount
                  </button>

                }
                {
                  action_type === 'Edit' &&
                  <button onClick={() => reload(0)} className={`modal_btn hover btn btn-block`}>
                    Go to Product

                  </button>

                }

              </div>

            </div>



          </Box>
        </Fade>
      </Modal>
      <DiscountModal closeModal={() => setShowDiscount(false)} />
      <ProductsModal closeModal={() => setShowDiscount(false)} refresh={refreshPage}  />
    </>
  )
}
